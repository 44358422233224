import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';

import { Account } from '@/types';
import { useCreateAccountMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchCurrencies } from '@/pages/Currencies/components';
import { SearchOrganizations } from '@/pages/Organizations/components';

export interface CreateAccountProps extends DialogProps {
  onClose(): void;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createAccount, createAccountResponse] = useCreateAccountMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Account>({
    initialValues: {
      organization: null,
      bik: '',
      bank_name: '',
      currency: null,
      iik: '',
    },
    validationSchema: yup.object().shape({
      organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      bik: yup.string().required(messages.form.REQUIRED),
      bank_name: yup.string().required(messages.form.REQUIRED),
      currency: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      iik: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(account, { resetForm }) {
      try {
        await createAccount(account).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить банковский счет</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                format="##################"
                name="iik"
                label="ИИК"
                placeholder="Введите значение"
                value={values.iik}
                onChange={handleChange}
                disabled={createAccountResponse.isLoading}
                error={touched.iik && Boolean(errors.iik)}
                helperText={touched.iik ? errors.iik : ''}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">KZ</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                format="##################"
                name="bik"
                label="БИК"
                placeholder="Введите значение"
                value={values.bik}
                onChange={handleChange}
                disabled={createAccountResponse.isLoading}
                error={touched.bik && Boolean(errors.bik)}
                helperText={touched.bik ? errors.bik : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="bank_name"
                label="Название банка"
                placeholder="Введите значение"
                value={values.bank_name}
                onChange={handleChange}
                disabled={createAccountResponse.isLoading}
                error={touched.bank_name && Boolean(errors.bank_name)}
                helperText={touched.bank_name ? errors.bank_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCurrencies
                value={values.currency}
                onChange={(event, option) => setFieldValue('currency', option)}
                disabled={createAccountResponse.isLoading}
                getOptionLabel={(option) => option.short_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="currency"
                    label="Валюта"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.currency && errors.currency)}
                    helperText={touched.currency && errors.currency}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchOrganizations
                value={values.organization}
                onChange={(event, option) => setFieldValue('organization', option)}
                disabled={createAccountResponse.isLoading}
                getOptionLabel={(option) => option.full_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="organization"
                    label="Организация"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createAccountResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createAccountResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateAccount;
