import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { TerroristOrganization } from '@/types';
import { useGetTerroristOrganizationsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchTerroristOrganizations<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<TerroristOrganization> } & Omit<
  AutocompleteProps<TerroristOrganization, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetTerroristOrganizationsQueryProps = useAsyncSearch<
    TerroristOrganization,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetTerroristOrganizationsQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetTerroristOrganizationsQueryProps} />;
}

export default SearchTerroristOrganizations;
