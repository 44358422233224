import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Holiday, ListResponse } from '@/types';

const holidayApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Holidays'],
});

const holidayApi = holidayApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getHolidays: builder.query<ListResponse<Holiday>, AxiosRequestConfig<Holiday>>({
      query: (config) => ({ url: '/admin/holiday', ...config }),
      providesTags: ['Holidays'],
    }),
    createHoliday: builder.mutation<Holiday, Holiday>({
      query: (holiday) => ({ url: '/admin/holiday', method: 'POST', data: holiday }),
      invalidatesTags: ['Holidays'],
    }),
    updateHoliday: builder.mutation<Holiday, Holiday>({
      query: (holiday) => ({ url: '/admin/holiday/' + holiday.id, method: 'PUT', data: holiday }),
      invalidatesTags: ['Holidays'],
    }),
    deleteHoliday: builder.mutation<unknown, Holiday>({
      query: (holiday) => ({ url: '/admin/holiday/' + holiday.id, method: 'DELETE' }),
      invalidatesTags: ['Holidays'],
    }),
  }),
});

const { useGetHolidaysQuery, useCreateHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation } =
  holidayApi;

export { useGetHolidaysQuery, useCreateHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation };
