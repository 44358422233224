import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { IntervalCollateralRate } from '@/types';
import { useGetIntervalCollateralRatesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchIntervalCollateralRates<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<IntervalCollateralRate> } & Omit<
  AutocompleteProps<IntervalCollateralRate, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetIntervalCollateralRatesQueryProps = useAsyncSearch<
    IntervalCollateralRate,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetIntervalCollateralRatesQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetIntervalCollateralRatesQueryProps} />;
}

export default SearchIntervalCollateralRates;
