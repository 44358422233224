import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { District, ListResponse } from '@/types';

const districtApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Districts'],
});

const districtApi = districtApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDistricts: builder.query<ListResponse<District>, AxiosRequestConfig<District>>({
      query: (config) => ({ url: '/admin/district', ...config }),
      providesTags: ['Districts'],
    }),
    createDistrict: builder.mutation<District, District>({
      query: (district) => ({ url: '/admin/district', method: 'POST', data: district }),
      invalidatesTags: ['Districts'],
    }),
    updateDistrict: builder.mutation<District, District>({
      query: (district) => ({ url: '/admin/district/' + district.id, method: 'PUT', data: district }),
      invalidatesTags: ['Districts'],
    }),
    deleteDistrict: builder.mutation<unknown, District>({
      query: (district) => ({ url: '/admin/district/' + district.id, method: 'DELETE' }),
      invalidatesTags: ['Districts'],
    }),
  }),
});

const { useGetDistrictsQuery, useCreateDistrictMutation, useUpdateDistrictMutation, useDeleteDistrictMutation } =
  districtApi;

export { useGetDistrictsQuery, useCreateDistrictMutation, useUpdateDistrictMutation, useDeleteDistrictMutation };
