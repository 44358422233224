import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports, ListResponse } from '@/types';
import { Pagination } from '@/components';

export interface CashboxOperationsProps {
  list?: ListResponse<Reports.CashboxOperations>;
  page: number;
  onChangePage(page: number): void;
}

const CashboxOperationsTable: React.FC<CashboxOperationsProps> = ({ list, page, onChangePage }) => {
  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>№</TableCell>
                <TableCell rowSpan={2}>Вид документа</TableCell>
                <TableCell rowSpan={2}>Номер</TableCell>
                <TableCell rowSpan={2}>Дата</TableCell>
                <TableCell rowSpan={2}>Клиент</TableCell>
                <TableCell rowSpan={2}>Нач. остаток</TableCell>

                <TableCell colSpan={2} align="center">
                  Приход
                </TableCell>

                <TableCell colSpan={2} align="center">
                  Расход
                </TableCell>

                <TableCell rowSpan={2}>Кон. остаток</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Общий</TableCell>
                <TableCell>в т.ч. внеломбардный</TableCell>

                <TableCell>Общий</TableCell>
                <TableCell>в т.ч. внеломбардный</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((cashboxOperation, index) => {
                const { type, arrival, consumption, customer, date_time, end_balance, number, start_balance } =
                  cashboxOperation;

                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>{number}</TableCell>
                    <TableCell>{String(date_time)}</TableCell>
                    <TableCell>{customer ?? '—'}</TableCell>
                    <TableCell>{Math.round(start_balance || 0)}</TableCell>

                    <TableCell>{Math.round(arrival.common || 0)}</TableCell>
                    <TableCell>{Math.round(arrival.outer || 0)}</TableCell>

                    <TableCell>{Math.round(consumption.common || 0)}</TableCell>
                    <TableCell>{Math.round(consumption.outer || 0)}</TableCell>

                    <TableCell>{Math.round(end_balance || 0)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>
    </>
  );
};

export default CashboxOperationsTable;
