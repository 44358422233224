import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Sample } from '@/types';
import { useGetSamplesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchSamples<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  getOptions,
  prefetch,
  ...props
}: {
  config?: AxiosRequestConfig<Sample>;
  getOptions?(options: readonly Sample[]): void;
  prefetch?: boolean;
} & Omit<AutocompleteProps<Sample, Multiple, DisableClearable, FreeSolo>, 'options'>) {
  const { options, ...asyncGetSamplesQueryProps } = useAsyncSearch<Sample, Multiple, DisableClearable, FreeSolo>(
    useGetSamplesQuery,
    'id',
    value,
    config,
    getOptions,
    prefetch,
  );

  return <Autocomplete options={options} {...props} {...asyncGetSamplesQueryProps} />;
}

export default SearchSamples;
