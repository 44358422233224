import NumberFormat from 'react-number-format';
import { FieldArray, Form, Formik, FormikHelpers, getIn } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { CollateralItemChar, CollateralItemCharTypes, Nomenclature } from '@/types';
import { useUpdateNomenclatureMutation } from '@/services';
import { COLLATERAL_TYPES } from '@/utils/collateral-types';
import { messages } from '@/utils/helpers';
import { COLLATERAL_ITEM_CHARS } from '@/utils/collateral-item-chars';
import { TrashFlat } from '@/assets/icons';
import { SearchNomenclatureGroups } from '@/pages/NomenclatureGroups/components';
import { SearchUnits } from '@/pages/Units/components';

export interface UpdateNomenclatureProps extends DialogProps {
  nomenclature: Nomenclature;
  onClose(): void;
}

const UpdateNomenclature: React.FC<UpdateNomenclatureProps> = ({ nomenclature, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateNomenclature, updateNomenclatureResponse] = useUpdateNomenclatureMutation();

  const COLLATERAL_CHAR: CollateralItemChar = { name: '', type: '', value: '', required: false };

  const initialValues: Nomenclature = {
    collateral_type: '',
    chars: [COLLATERAL_CHAR],
    length_unit: null,
    name: '',
    nomenclature_group: null,
    visible: 1,
    weight_unit: null,
  };

  const validationSchema = yup.object().shape({
    collateral_type: yup.string().required(messages.form.SELECT_FROM_LIST),
    chars: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        type: yup.string(),
        value: yup.string(),
        required: yup.boolean(),
      }),
    ),
    length_unit: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    name: yup.string().required(messages.form.REQUIRED),
    nomenclature_group: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    visible: yup.bool().required(messages.form.REQUIRED),
    weight_unit: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  });

  async function handleSubmit(nomenclature: Nomenclature, { resetForm }: FormikHelpers<Nomenclature>) {
    try {
      await updateNomenclature(nomenclature).unwrap();

      onClose();
      resetForm();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать номенклатуру</DialogTitle>

      <Formik<Nomenclature>
        initialValues={nomenclature ?? initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <SearchNomenclatureGroups
                    value={values.nomenclature_group}
                    onChange={(event, option) => setFieldValue('nomenclature_group', option)}
                    disabled={updateNomenclatureResponse.isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="nomenclature_group"
                        label="Номенклатурная группа"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.nomenclature_group && errors.nomenclature_group)}
                        helperText={touched.nomenclature_group && errors.nomenclature_group}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="name"
                    label="Наименование"
                    placeholder="Введите значение"
                    value={values.name}
                    onChange={handleChange}
                    disabled={updateNomenclatureResponse.isLoading}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchUnits
                    value={values.length_unit}
                    onChange={(event, option) => setFieldValue('length_unit', option)}
                    disabled={updateNomenclatureResponse.isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="length_unit"
                        label="Единица измерения длины"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.length_unit && errors.length_unit)}
                        helperText={touched.length_unit && errors.length_unit}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchUnits
                    value={values.weight_unit}
                    onChange={(event, option) => setFieldValue('weight_unit', option)}
                    disabled={updateNomenclatureResponse.isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="weight_unit"
                        label="Единица измерения массы"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.weight_unit && errors.weight_unit)}
                        helperText={touched.weight_unit && errors.weight_unit}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    select
                    name="collateral_type"
                    label="Тип залога"
                    value={values.collateral_type}
                    onChange={handleChange}
                    disabled={updateNomenclatureResponse.isLoading}
                    error={touched.collateral_type && Boolean(errors.collateral_type)}
                    helperText={touched.collateral_type ? errors.collateral_type : ''}
                    fullWidth
                  >
                    {COLLATERAL_TYPES.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <FieldArray name="chars">
                    {({ push, replace, remove }) => (
                      <>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={3} align="center">
                                  Характеристики
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>Наименование</TableCell>
                                <TableCell>Тип поля</TableCell>
                                <TableCell>Знач. по умолчанию</TableCell>
                                <TableCell>Обязательное поле</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {values.chars.map((char, index) => {
                                const field = (name: string) => `chars[${index}].${name}`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <TextField
                                        name={field('name')}
                                        placeholder="Введите значение"
                                        value={char.name}
                                        onChange={handleChange}
                                        disabled={updateNomenclatureResponse.isLoading}
                                        error={getIn(touched, field('name')) && Boolean(getIn(errors, field('name')))}
                                        fullWidth
                                        size="small"
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <TextField
                                        select
                                        name={field('type')}
                                        value={char.type}
                                        onChange={(event) => {
                                          setFieldValue(field('type'), event.target.value);
                                          setFieldValue(field('min_value'), 0);
                                          setFieldValue(
                                            field('value'),
                                            COLLATERAL_ITEM_CHARS.find(({ value }) => value === event.target.value)
                                              ?.defaultValue,
                                          );
                                        }}
                                        disabled={updateNomenclatureResponse.isLoading}
                                        error={getIn(touched, field('type')) && Boolean(getIn(errors, field('type')))}
                                        fullWidth
                                        size="small"
                                      >
                                        {COLLATERAL_ITEM_CHARS.map(({ value, label }) => (
                                          <MenuItem key={value} value={value}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </TableCell>

                                    <TableCell align="center">
                                      {char.type === CollateralItemCharTypes.STRING && (
                                        <TextField
                                          name={field('value')}
                                          value={char.value}
                                          placeholder="Введите значение"
                                          onChange={handleChange}
                                          disabled={updateNomenclatureResponse.isLoading}
                                          error={
                                            getIn(touched, field('value')) && Boolean(getIn(errors, field('value')))
                                          }
                                          fullWidth
                                          size="small"
                                        />
                                      )}

                                      {char.type === CollateralItemCharTypes.NUMERIC && (
                                        <Stack direction="row" spacing={1}>
                                          <NumberFormat
                                            customInput={TextField}
                                            name={field('value')}
                                            placeholder="Введите значение"
                                            value={Number(char.value)}
                                            onValueChange={({ value }) => setFieldValue(field('value'), value)}
                                            disabled={updateNomenclatureResponse.isLoading}
                                            error={
                                              getIn(touched, field('value')) && Boolean(getIn(errors, field('value')))
                                            }
                                            size="small"
                                          />

                                          <NumberFormat
                                            customInput={TextField}
                                            label="мин. значение"
                                            name={field('min_value')}
                                            placeholder="Минимальное значение"
                                            value={char.min_value}
                                            onValueChange={({ value }) => setFieldValue(field('min_value'), value)}
                                            disabled={updateNomenclatureResponse.isLoading}
                                            error={
                                              getIn(touched, field('min_value')) &&
                                              Boolean(getIn(errors, field('min_value')))
                                            }
                                            size="small"
                                          />
                                        </Stack>
                                      )}

                                      {char.type === CollateralItemCharTypes.BOOLEAN && (
                                        <FormControlLabel
                                          label="Отмечено"
                                          control={
                                            <Checkbox
                                              name={field('value')}
                                              value={char.value}
                                              checked={Boolean(char.value)}
                                              disabled={updateNomenclatureResponse.isLoading}
                                              onChange={(event, checked) => setFieldValue(field('value'), checked)}
                                            />
                                          }
                                        />
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      <Checkbox
                                        name={field('required')}
                                        value={char.required}
                                        checked={Boolean(char.required)}
                                        disabled={updateNomenclatureResponse.isLoading}
                                        onChange={(event, checked) => setFieldValue(field('required'), checked)}
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <Tooltip title="Удалить">
                                        <IconButton
                                          aria-label="Удалить"
                                          disabled={updateNomenclatureResponse.isLoading}
                                          onClick={() =>
                                            values.chars.length === 1 ? replace(0, COLLATERAL_CHAR) : remove(index)
                                          }
                                        >
                                          <TrashFlat sx={{ fontSize: 16 }} />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                          <Button
                            variant="contained"
                            disabled={updateNomenclatureResponse.isLoading}
                            size="small"
                            onClick={() => push(COLLATERAL_CHAR)}
                          >
                            Добавить
                          </Button>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <FormControlLabel
                    label="Отображать"
                    control={
                      <Switch
                        name="visible"
                        value={values.visible}
                        checked={Boolean(values.visible)}
                        onChange={(event, checked) => setFieldValue('visible', checked ? 1 : 0)}
                        disabled={updateNomenclatureResponse.isLoading}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                disabled={updateNomenclatureResponse.isLoading}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Button type="submit" variant="contained" disabled={updateNomenclatureResponse.isLoading}>
                Редактировать
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateNomenclature;
