import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';

import { Shift } from '@/types';
import { useDeleteShiftMutation } from '@/services';

export interface DeleteShiftProps extends DialogProps {
  shift: Shift;
  onClose(): void;
}

const DeleteShift: React.FC<DeleteShiftProps> = ({ shift, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteShift, deleteShiftResponse] = useDeleteShiftMutation();

  async function handleDeleteShift() {
    try {
      await deleteShift(shift).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteShiftResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteShiftResponse.isLoading} onClick={handleDeleteShift}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteShift;
