import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { NomenclatureGroupInterval } from '@/types';
import { useUpdateNomenclatureGroupIntervalMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateNomenclatureGroupIntervalProps extends DialogProps {
  nomenclatureGroupInterval: NomenclatureGroupInterval;
  onClose(): void;
}

const UpdateNomenclatureGroupInterval: React.FC<UpdateNomenclatureGroupIntervalProps> = ({
  nomenclatureGroupInterval,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateNomenclatureGroupInterval, updateNomenclatureGroupIntervalResponse] =
    useUpdateNomenclatureGroupIntervalMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<NomenclatureGroupInterval>({
    initialValues: nomenclatureGroupInterval ?? {
      name: '',
      sum_from: '',
      sum_to: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      sum_from: yup.number().required(messages.form.REQUIRED),
      sum_to: yup
        .number()
        .moreThan(yup.ref('sum_from'), 'Сумма (макс.) должна быть больше суммы (мин.)')
        .required(messages.form.REQUIRED),
    }),
    async onSubmit(nomenclatureGroupInterval, { resetForm }) {
      try {
        await updateNomenclatureGroupInterval(nomenclatureGroupInterval).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать интервал</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateNomenclatureGroupIntervalResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                type="tel"
                name="sum_from"
                label="Сумма (мин.)"
                placeholder="Введите значение"
                value={values.sum_from}
                onChange={handleChange}
                disabled={updateNomenclatureGroupIntervalResponse.isLoading}
                error={touched.sum_from && Boolean(errors.sum_from)}
                helperText={touched.sum_from ? errors.sum_from : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                type="tel"
                name="sum_to"
                label="Сумма (макс.)"
                placeholder="Введите значение"
                value={values.sum_to}
                onChange={handleChange}
                disabled={updateNomenclatureGroupIntervalResponse.isLoading}
                error={touched.sum_to && Boolean(errors.sum_to)}
                helperText={touched.sum_to ? errors.sum_to : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateNomenclatureGroupIntervalResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateNomenclatureGroupIntervalResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateNomenclatureGroupInterval;
