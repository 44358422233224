import { Customer } from '@/types';

export const STEPS = [
  'Основная информация',
  'Паспортные данные',
  'Место проживания',
  'Фото документа',
  'Дополнительная информация',
];

export const COMMON: Customer.Common = { name: '', father_name: '', last_name: '', gender: '', born_at: null };

export const IDENTITY: Customer.Identity = { identity_front_side: null, identity_back_side: null };

export const PASSPORT_DATA: Customer.PassportData = {
  document_type: null,
  document_series: '',
  document_number: '',
  document_issued_by: '',
  document_issued_date: null,
  document_expire_date: null,
};

export const RESIDENS_PLACE: Customer.ResidencePlace = {
  iin: '',
  country: null,
  is_foreign_citizen: 0,
  is_matches_with_official_address: 0,

  actual_area: null,
  actual_district: null,
  actual_locality: null,
  actual_region: null,
  actual_street: null,
  actual_flat_number: '',
  actual_house_number: '',

  official_area: null,
  official_district: null,
  official_locality: null,
  official_region: null,
  official_street: null,
  official_flat_number: '',
  official_house_number: '',
};

export const ADDITIONAL: Customer.Additional = {
  avatar: null,
  customer_category: null,
  customer_status: null,
  operator: null,
  phone_number: '',
};
