import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { ElectronicBrand, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteElectronicBrand, UpdateElectronicBrand } from '.';

export interface ElectronicBrandListProps {
  list?: ListResponse<ElectronicBrand>;
  page: number;
  onChangePage(page: number): void;
}

const ElectronicBrandsTable: React.FC<ElectronicBrandListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentElectronicBrand, setCurrentElectronicBrand] = useState<ElectronicBrand | null>(null);

  function openUpdateModal(electronicBrand: ElectronicBrand) {
    setCurrentElectronicBrand(electronicBrand);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(electronicBrand: ElectronicBrand) {
    setCurrentElectronicBrand(electronicBrand);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((electronicBrand) => {
                const { name, id } = electronicBrand;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(electronicBrand)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(electronicBrand)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateElectronicBrand
        electronicBrand={currentElectronicBrand!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteElectronicBrand
        electronicBrand={currentElectronicBrand!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default ElectronicBrandsTable;
