import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureGroupChar, ListResponse } from '@/types';

const nomenclatureGroupCharApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureGroupChars'],
});

const nomenclatureGroupCharApi = nomenclatureGroupCharApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureGroupChars: builder.query<
      ListResponse<NomenclatureGroupChar>,
      AxiosRequestConfig<NomenclatureGroupChar>
    >({
      query: (config) => ({ url: '/admin/nomenclature-group-char', ...config }),
      providesTags: ['NomenclatureGroupChars'],
    }),
    createNomenclatureGroupChar: builder.mutation<NomenclatureGroupChar, NomenclatureGroupChar>({
      query: (nomenclatureGroupChar) => ({
        url: '/admin/nomenclature-group-char',
        method: 'POST',
        data: nomenclatureGroupChar,
      }),
      invalidatesTags: ['NomenclatureGroupChars'],
    }),
    updateNomenclatureGroupChar: builder.mutation<NomenclatureGroupChar, NomenclatureGroupChar>({
      query: (nomenclatureGroupChar) => ({
        url: '/admin/nomenclature-group-char/' + nomenclatureGroupChar.id,
        method: 'PUT',
        data: nomenclatureGroupChar,
      }),
      invalidatesTags: ['NomenclatureGroupChars'],
    }),
    deleteNomenclatureGroupChar: builder.mutation<unknown, NomenclatureGroupChar>({
      query: (nomenclatureGroupChar) => ({
        url: '/admin/nomenclature-group-char/' + nomenclatureGroupChar.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['NomenclatureGroupChars'],
    }),
  }),
});

const {
  useGetNomenclatureGroupCharsQuery,
  useCreateNomenclatureGroupCharMutation,
  useUpdateNomenclatureGroupCharMutation,
  useDeleteNomenclatureGroupCharMutation,
} = nomenclatureGroupCharApi;

export {
  useGetNomenclatureGroupCharsQuery,
  useCreateNomenclatureGroupCharMutation,
  useUpdateNomenclatureGroupCharMutation,
  useDeleteNomenclatureGroupCharMutation,
};
