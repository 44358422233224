import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { CashboxOperation, CashboxOperations } from '@/types';
import { useUpdateCashboxOperationMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { CASHBOX_OPERATIONS } from '@/utils/cashbox-operations';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchOrganizations } from '@/pages/Organizations/components';

export interface UpdateCashboxOperationProps extends Omit<DialogProps, 'cashboxOperations'> {
  cashboxOperations: CashboxOperation;
  onClose(): void;
}

const UpdateCashboxOperation: React.FC<UpdateCashboxOperationProps> = ({ cashboxOperations, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateCashboxOperation, updateCashboxOperationResponse] = useUpdateCashboxOperationMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<CashboxOperation>({
    initialValues: cashboxOperations ?? {
      addition: '',
      customer: null,
      organization: null,
      point: null,
      reason: '',
      status: CashboxOperations.INCOME,
      sum: 0,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      addition: yup.string(),
      customer: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      reason: yup.string(),
      status: yup.string().required(messages.form.REQUIRED),
      sum: yup.number().required(messages.form.REQUIRED),
    }),
    async onSubmit(cashboxOperations, { resetForm }) {
      try {
        await updateCashboxOperation(cashboxOperations).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать запись в кассе</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchOrganizations
                value={values.organization}
                onChange={(event, option) => setFieldValue('organization', option)}
                disabled={updateCashboxOperationResponse.isLoading}
                getOptionLabel={(option) => option.full_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="organization"
                    label="Организация"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={updateCashboxOperationResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCustomers
                value={values.customer}
                onChange={(event, option) => setFieldValue('customer', option)}
                disabled={updateCashboxOperationResponse.isLoading}
                getOptionLabel={({ name, last_name, father_name }) =>
                  `${last_name ?? ''} ${name} ${father_name ?? ''} `
                }
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customer"
                    label="Клиент"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.customer && errors.customer)}
                    helperText={touched.customer && errors.customer}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="sum"
                label="Сумма"
                placeholder="Введите значение"
                value={values.sum}
                onChange={handleChange}
                disabled={updateCashboxOperationResponse.isLoading}
                error={touched.sum && Boolean(errors.sum)}
                helperText={touched.sum ? errors.sum : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="reason"
                label="Основание"
                placeholder="Введите значение"
                value={values.reason}
                onChange={handleChange}
                disabled={updateCashboxOperationResponse.isLoading}
                error={touched.reason && Boolean(errors.reason)}
                helperText={touched.reason ? errors.reason : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="status"
                label="Вид операции"
                value={values.status}
                onChange={handleChange}
                disabled={updateCashboxOperationResponse.isLoading}
                error={touched.status && Boolean(errors.status)}
                helperText={touched.status ? errors.status : ''}
                fullWidth
              >
                {CASHBOX_OPERATIONS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="addition"
                label="Дополнительно"
                placeholder="Введите значение"
                value={values.addition}
                onChange={handleChange}
                disabled={updateCashboxOperationResponse.isLoading}
                error={touched.addition && Boolean(errors.addition)}
                helperText={touched.addition ? errors.addition : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateCashboxOperationResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateCashboxOperationResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCashboxOperation;
