import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import 'moment/locale/ru';

import { store } from '@/store';
import { AuthProvider, RolesProvider } from '@/providers';
import reportWebVitals from '@/reportWebVitals';
import { RouteAdapter } from '@/components';
import App from '@/App';
import theme from '@/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                  <AuthProvider>
                    <RolesProvider>
                      <App />
                    </RolesProvider>
                  </AuthProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
