import { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { District, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteDistrict, UpdateDistrict } from '../components';

export interface DistrictListProps {
  list?: ListResponse<District>;
  page: number;
  onChangePage(page: number): void;
}

const DistrictsTable: React.FC<DistrictListProps> = ({ list, page, onChangePage }) => {
  const { areaId, regionId } = useParams<{ areaId: string; regionId: string }>();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentDistrict, setCurrentDistrict] = useState<District | null>(null);

  function openUpdateModal(district: District) {
    setCurrentDistrict(district);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(district: District) {
    setCurrentDistrict(district);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Населенные пункты</TableCell>
                <TableCell>Улицы</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((district) => {
                const { name, id } = district;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Tooltip title="Населенные пункты">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/regions/${regionId}/areas/${areaId}/districts/${id}/localities`}
                          aria-label="Населенные пункты"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Улицы">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/regions/${regionId}/areas/${areaId}/districts/${id}/streets`}
                          aria-label="Улицы"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(district)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(district)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateDistrict
        district={currentDistrict!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteDistrict
        district={currentDistrict!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default DistrictsTable;
