import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Role } from '@/types';
import { useUpdateRoleMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateRoleProps extends DialogProps {
  userRole: Role;
  onClose(): void;
}

const UpdateRole: React.FC<UpdateRoleProps> = ({ userRole, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateRole, updateRoleResponse] = useUpdateRoleMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Role>({
    initialValues: userRole ?? {
      code: '',
      name: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      code: yup.string().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(role, { resetForm }) {
      try {
        await updateRole(role).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать роль</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateRoleResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateRoleResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateRoleResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateRole;
