import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CollateralItemState } from '@/types';
import { useGetCollateralItemStatesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCollateralItemStates<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<CollateralItemState> } & Omit<
  AutocompleteProps<CollateralItemState, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCollateralItemStatesQueryProps = useAsyncSearch<
    CollateralItemState,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetCollateralItemStatesQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetCollateralItemStatesQueryProps} />;
}

export default SearchCollateralItemStates;
