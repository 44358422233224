import moment from 'moment';
import { DateParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { Grid, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { useGetPaymentsToExpertsReportQuery } from '@/services';
import { AppBar, Empty, Error, Loader } from '@/components';

import { PaymentsToExpertsTable } from './components';

const PaymentsToExpertsReportPage: React.FC = () => {
  const [{ date_from, date_to, page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    date_from: withDefault(DateParam, moment().startOf('month').toDate()),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const {
    data: paymentsToExperts,
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = useGetPaymentsToExpertsReportQuery({
    params: { page, date_from: moment(date_from).format('YYYY-MM-DD'), date_to: moment(date_to).format('YYYY-MM-DD') },
  });

  return (
    <>
      <AppBar title="Выплаты экспертам" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <DatePicker
              mask="__.__.____"
              label="Дата от"
              value={date_from}
              maxDate={moment(date_to)}
              onChange={(value) => setQuery({ date_from: moment(value).toDate() })}
              disabled={isLoading || isFetching}
              renderInput={(params) => <TextField {...params} name="date_from" variant="filled" size="small" />}
            />

            <DatePicker
              mask="__.__.____"
              label="Дата до"
              value={date_to}
              maxDate={moment()}
              onChange={(value) => setQuery({ date_to: moment(value).toDate() })}
              disabled={isLoading || isFetching}
              renderInput={(params) => <TextField {...params} name="date_to" variant="filled" size="small" />}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой данных по выплатам! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(paymentsToExperts?.data?.length) ? (
            <PaymentsToExpertsTable list={paymentsToExperts} page={page} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Данные отсутствуют" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentsToExpertsReportPage;
