import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { NomenclatureRateCategory } from '@/types';
import { useCreateNomenclatureRateCategoryMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateNomenclatureRateCategoryProps extends DialogProps {
  onClose(): void;
}

const CreateNomenclatureRateCategory: React.FC<CreateNomenclatureRateCategoryProps> = ({ onClose, ...props }) => {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const [createNomenclatureRateCategory, createNomenclatureRateCategoryResponse] =
    useCreateNomenclatureRateCategoryMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<NomenclatureRateCategory>({
    initialValues: {
      name: '',
      nomenclature_id: +id!,
      code: 0,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      code: yup.number().required(messages.form.REQUIRED),
    }),
    async onSubmit(nomenclatureRateCategory, { resetForm }) {
      try {
        await createNomenclatureRateCategory(nomenclatureRateCategory).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить тариф</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="code"
                type="tel"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={createNomenclatureRateCategoryResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createNomenclatureRateCategoryResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createNomenclatureRateCategoryResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createNomenclatureRateCategoryResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNomenclatureRateCategory;
