import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';

import { Cridentials } from '@/types';
import { useAuth } from '@/hooks';
import { messages } from '@/utils/helpers';
import { SuperLobmardPrimary } from '@/assets/icons';

const Login: React.FC = () => {
  const { isLoading, handleLogin } = useAuth();

  const { values, touched, errors, handleChange, handleSubmit } = useFormik<Cridentials>({
    initialValues: { login: '', password: '' },
    validationSchema: yup.object().shape({
      login: yup.string().required(messages.form.REQUIRED),
      password: yup.string().required(messages.form.REQUIRED).min(8, messages.form.MIN_LENGTH()),
    }),
    onSubmit: handleLogin,
  });

  return (
    <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh', p: 2 }}>
      <Paper sx={{ maxWidth: 460, width: '100%', p: 4, m: 'auto', borderRadius: 5 }}>
        <Stack alignItems="center" spacing={1} sx={{ mb: 2 }}>
          <SuperLobmardPrimary width={190} />

          <Typography component="h1" variant="h1" gutterBottom>
            Вход
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit}>
          <Stack alignItems="center" spacing={3}>
            <TextField
              name="login"
              value={values.login}
              label="Логин"
              placeholder="Введите логин"
              autoComplete="login"
              error={Boolean(touched.login && errors.login)}
              helperText={touched.login && errors.login}
              onChange={handleChange}
              disabled={isLoading}
              fullWidth
            />

            <TextField
              name="password"
              value={values.password}
              label="Пароль"
              type="password"
              placeholder="Введите пароль"
              autoComplete="current-password"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              disabled={isLoading}
              fullWidth
            />

            <Button disabled={isLoading} type="submit" size="large" variant="contained">
              Войти
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
