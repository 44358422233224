import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Locality, ListResponse } from '@/types';

const localityApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Localities'],
});

const localityApi = localityApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getLocalities: builder.query<ListResponse<Locality>, AxiosRequestConfig<Locality>>({
      query: (config) => ({ url: '/admin/locality', ...config }),
      providesTags: ['Localities'],
    }),
    createLocality: builder.mutation<Locality, Locality>({
      query: (locality) => ({ url: '/admin/locality', method: 'POST', data: locality }),
      invalidatesTags: ['Localities'],
    }),
    updateLocality: builder.mutation<Locality, Locality>({
      query: (locality) => ({ url: '/admin/locality/' + locality.id, method: 'PUT', data: locality }),
      invalidatesTags: ['Localities'],
    }),
    deleteLocality: builder.mutation<unknown, Locality>({
      query: (locality) => ({ url: '/admin/locality/' + locality.id, method: 'DELETE' }),
      invalidatesTags: ['Localities'],
    }),
  }),
});

const { useGetLocalitiesQuery, useCreateLocalityMutation, useUpdateLocalityMutation, useDeleteLocalityMutation } =
  localityApi;

export { useGetLocalitiesQuery, useCreateLocalityMutation, useUpdateLocalityMutation, useDeleteLocalityMutation };
