import { Point } from '@/types';

export enum ScannerPortTypes {
  NONE = 'none',
  KEYBOARD = 'keyboard',
  COM1 = 'COM1',
  COM2 = 'COM2',
  COM3 = 'COM3',
  COM4 = 'COM4',
  COM5 = 'COM5',
  COM6 = 'COM6',
  COM7 = 'COM7',
  COM8 = 'COM8',
  COM9 = 'COM9',
}

export interface Computer {
  id?: number;
  point: Point | null;
  point_id?: Point['id'];
  code: string;
  vpn_ip: string;
  vpn_username: string;
  scaner_port_type: ScannerPortTypes | '';
  sensitivity: number;
  comment: string;
}
