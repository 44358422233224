import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Nomenclature } from '@/types';
import { useGetNomenclaturesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatures<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Nomenclature> } & Omit<
  AutocompleteProps<Nomenclature, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclaturesQueryProps = useAsyncSearch<Nomenclature, Multiple, DisableClearable, FreeSolo>(
    useGetNomenclaturesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetNomenclaturesQueryProps} />;
}

export default SearchNomenclatures;
