import { useState } from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material';

import { useGetCountriesQuery } from '@/services';
import { AppBar, Empty, Error, Loader } from '@/components';
import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';

import { CountriesTable, CreateCountry } from './components';

const CountriesPage: React.FC = () => {
  const [{ order_direction, page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
  });

  const [createModalOpened, setOpenedCreateModal] = useState(false);

  const {
    data: currencies,
    isLoading,
    isSuccess,
    isError,
  } = useGetCountriesQuery({ params: { order_direction, page, order_by: 'id' } });

  return (
    <>
      <AppBar title="Страны" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
            <Box sx={{ width: 310 }}>
              <TextField
                select
                value={order_direction}
                label="Сортировать по"
                disabled={isLoading}
                onChange={(event) => setQuery({ order_direction: event.target.value })}
                variant="filled"
                fullWidth
                size="small"
              >
                {ORDER_DIRECTIONS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box sx={{ width: 210 }}>
              <Button
                disabled={isLoading}
                onClick={() => setOpenedCreateModal(true)}
                size="large"
                variant="contained"
                fullWidth
              >
                Добавить
              </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item lg={6} md={7} xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой стран! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(currencies?.data?.length) ? (
            <CountriesTable list={currencies} page={page} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Страны отсутствуют" />
          )}
        </Grid>
      </Grid>

      <CreateCountry onClose={() => setOpenedCreateModal(false)} open={createModalOpened} />
    </>
  );
};

export default CountriesPage;
