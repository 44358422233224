import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Unit, ListResponse } from '@/types';

const unitApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Units'],
});

const unitApi = unitApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query<ListResponse<Unit>, AxiosRequestConfig<Unit>>({
      query: (config) => ({ url: '/admin/unit', ...config }),
      providesTags: ['Units'],
    }),
    createUnit: builder.mutation<Unit, Unit>({
      query: (unit) => ({ url: '/admin/unit', method: 'POST', data: unit }),
      invalidatesTags: ['Units'],
    }),
    updateUnit: builder.mutation<Unit, Unit>({
      query: (unit) => ({ url: '/admin/unit/' + unit.id, method: 'PUT', data: unit }),
      invalidatesTags: ['Units'],
    }),
    deleteUnit: builder.mutation<unknown, Unit>({
      query: (unit) => ({ url: '/admin/unit/' + unit.id, method: 'DELETE' }),
      invalidatesTags: ['Units'],
    }),
  }),
});

const { useGetUnitsQuery, useCreateUnitMutation, useUpdateUnitMutation, useDeleteUnitMutation } = unitApi;

export { useGetUnitsQuery, useCreateUnitMutation, useUpdateUnitMutation, useDeleteUnitMutation };
