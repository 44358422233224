import cookies from 'react-cookies';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Cookies } from '@/utils/enums';
import { catchError } from '@/utils/helpers';

export const HOST_NAME = process.env.REACT_APP_API_URL;

export const api = axios.create({
  withCredentials: true,
  baseURL: process.env.NODE_ENV === 'production' ? HOST_NAME + '/api/v1' : '/api/v1',
});

api.interceptors.request.use((config) => {
  const { headers } = config;
  const token = cookies.load(Cookies.TOKEN);

  if (headers && token) headers['Authorization'] = 'Bearer ' + cookies.load(Cookies.TOKEN);
  return config;
});

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error.response.status === 401 && error.config && !error.config.__isRetry) {
      try {
        cookies.remove(Cookies.TOKEN, { path: '/' });
      } catch (error) {
        console.error(error);
      }
    }

    throw error;
  },
);

const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig, unknown, unknown> => async (config) => {
  try {
    return await api(config);
  } catch (error) {
    return catchError(error as AxiosError);
  }
};

const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

export default baseApi;
