import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Pledge } from '@/types';
import { useDeletePledgeMutation } from '@/services';

export interface DeletePledgeProps extends DialogProps {
  pledge: Pledge;
  onClose(): void;
}

const DeletePledge: React.FC<DeletePledgeProps> = ({ pledge, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deletePledge, deletePledgeResponse] = useDeletePledgeMutation();

  async function handleDeletePledge() {
    try {
      await deletePledge(pledge).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{pledge?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{pledge?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deletePledgeResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deletePledgeResponse.isLoading} onClick={handleDeletePledge}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePledge;
