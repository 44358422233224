import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { TechnicalRequest } from '@/types';
import { useCreateTechnicalRequestMutation } from '@/services';
import { useAuth } from '@/hooks';
import { messages } from '@/utils/helpers';
import { SearchOrganizations } from '@/pages/Organizations/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchPositions } from '@/pages/Positions/components';

export interface CreateTechnicalRequestProps extends DialogProps {
  onClose(): void;
}

const CreateTechnicalRequest: React.FC<CreateTechnicalRequestProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { user, employee } = useAuth();

  const [createTechnicalRequest, createTechnicalRequestResponse] = useCreateTechnicalRequestMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<TechnicalRequest>({
    initialValues: {
      description: '',
      position: null,
      organization: user?.point?.organization,
      point: user?.point,
      short_description: '',
    },
    validationSchema: yup.object().shape({
      description: yup.string().required(messages.form.REQUIRED),
      position: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      short_description: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(technicalRequest, { resetForm }) {
      try {
        await createTechnicalRequest(technicalRequest).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить техническую заявку</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchOrganizations
                value={values.organization}
                onChange={(event, option) => setFieldValue('organization', option)}
                disabled={createTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.full_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="organization"
                    label="Организация"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={createTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPositions
                value={values.position}
                onChange={(event, option) => setFieldValue('position', option)}
                disabled={createTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="position"
                    label="Кому адресована заявка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="short_description"
                label="Краткое описание заявки"
                placeholder="Введите значение"
                value={values.short_description}
                onChange={handleChange}
                disabled={createTechnicalRequestResponse.isLoading}
                error={touched.short_description && Boolean(errors.short_description)}
                helperText={touched.short_description ? errors.short_description : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                multiline
                name="description"
                label="Описание"
                placeholder="Введите значение"
                value={values.description}
                onChange={handleChange}
                disabled={createTechnicalRequestResponse.isLoading}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description ? errors.description : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="author"
                label="Автор заявки"
                placeholder="Введите значение"
                value={`${employee?.last_name} ${employee?.name} ${employee?.father_name}`}
                onChange={handleChange}
                disabled={createTechnicalRequestResponse.isLoading}
                error={touched.short_description && Boolean(errors.short_description)}
                helperText={touched.short_description ? errors.short_description : ''}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createTechnicalRequestResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createTechnicalRequestResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTechnicalRequest;
