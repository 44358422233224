import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Shift, ListResponse, ShiftTypes } from '@/types';

const shiftApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Shifts'],
});

const shiftApi = shiftApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getShifts: builder.query<ListResponse<Shift>, AxiosRequestConfig<Shift>>({
      query: (config) => ({ url: '/admin/shift', ...config }),
      providesTags: ['Shifts'],
    }),
    getShift: builder.query<Shift, AxiosRequestConfig<Shift>>({
      query: (config) => ({ url: '/admin/shift/current-shift', ...config }),
    }),
    openShift: builder.mutation<Shift, Shift>({
      query: ({ organization, passing_employee, point, receiving_employee, ...shift }) => ({
        url: '/admin/shift',
        method: 'POST',
        data: {
          ...shift,
          organization_id: organization?.id,
          passing_employee_id: passing_employee?.id,
          point_id: point?.id,
          receiving_employee_id: receiving_employee?.id,
          type: ShiftTypes.OPEN,
        },
      }),
      invalidatesTags: ['Shifts'],
    }),
    closeShift: builder.mutation<Shift, Shift>({
      query: ({ organization, passing_employee, point, receiving_employee, id, ...shift }) => ({
        url: '/admin/shift',
        method: 'POST',
        data: {
          ...shift,
          organization_id: organization?.id,
          passing_employee_id: passing_employee?.id,
          point_id: point?.id,
          receiving_employee_id: receiving_employee?.id,
          previous_shift_id: id,
          type: ShiftTypes.CLOSE,
        },
      }),
      invalidatesTags: ['Shifts'],
    }),
    deleteShift: builder.mutation<unknown, Shift>({
      query: (shift) => ({ url: '/admin/shift/' + shift.id, method: 'DELETE' }),
      invalidatesTags: ['Shifts'],
    }),
  }),
});

const {
  useGetShiftsQuery,
  useLazyGetShiftQuery,
  useGetShiftQuery,
  useOpenShiftMutation,
  useCloseShiftMutation,
  useDeleteShiftMutation,
} = shiftApi;

export {
  useGetShiftsQuery,
  useLazyGetShiftQuery,
  useGetShiftQuery,
  useOpenShiftMutation,
  useCloseShiftMutation,
  useDeleteShiftMutation,
};
