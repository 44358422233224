import { Fragment } from 'react';
import moment from 'moment';
import { lighten, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports, ListResponse } from '@/types';
import { Pagination } from '@/components';
import theme from '@/theme';

export interface ActivePledgesProps {
  list?: ListResponse<Reports.ActivePledges>;
  page: number;
  onChangePage(page: number): void;
}

const ActivePledgesTable: React.FC<ActivePledgesProps> = ({ list, page, onChangePage }) => {
  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>№</TableCell>
                <TableCell rowSpan={2}>Дата</TableCell>
                <TableCell rowSpan={2}>Время</TableCell>
                <TableCell rowSpan={2}>№ договора</TableCell>
                <TableCell rowSpan={2}>Клиент</TableCell>
                <TableCell rowSpan={2}>Масса металла</TableCell>
                <TableCell colSpan={3} align="center">
                  Сумма оценки
                </TableCell>
                <TableCell rowSpan={2}>Исполнитель</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Залог</TableCell>
                <TableCell>Перезалог</TableCell>
                <TableCell>Общая (Залог + перезалог)</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((activePledge, index) => {
                const {
                  date,
                  customer_name,
                  expert_name,
                  name,
                  metal_mass,
                  pledge_sum,
                  repledge_sum,
                  total_sum,
                  collateral_items,
                  prolongations,
                } = activePledge;

                return (
                  <Fragment key={index}>
                    <TableRow sx={{ bgcolor: lighten(theme.palette.primary.light, 0.5) }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{moment(date).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{moment(date).format('HH:mm')}</TableCell>
                      <TableCell>
                        <b>{name ?? '—'}</b>
                      </TableCell>
                      <TableCell>
                        <b>{customer_name ?? '—'}</b>
                      </TableCell>
                      <TableCell>{metal_mass ?? '—'}</TableCell>

                      <TableCell>{pledge_sum}</TableCell>
                      <TableCell>{repledge_sum ?? '—'}</TableCell>
                      <TableCell>{total_sum}</TableCell>

                      <TableCell>{expert_name ?? '—'}</TableCell>
                    </TableRow>

                    {collateral_items.map((summary, index) => {
                      const { collateral_item, metal_mass, pledge_sum, repledge_sum, total_sum } = summary;

                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={5}>{collateral_item}</TableCell>
                          <TableCell>{Math.round(metal_mass)}</TableCell>
                          <TableCell>{Math.round(pledge_sum)}</TableCell>
                          <TableCell>{Math.round(repledge_sum)}</TableCell>
                          <TableCell>{Math.round(total_sum)}</TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    })}

                    {prolongations.map((summary, index) => {
                      const { name, text } = summary;

                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={3}>{text}</TableCell>
                          <TableCell colSpan={7}>{name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>
    </>
  );
};

export default ActivePledgesTable;
