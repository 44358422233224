import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Color } from '@/types';
import { useGetColorsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchColors<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Color> } & Omit<
  AutocompleteProps<Color, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetColorsQueryProps = useAsyncSearch<Color, Multiple, DisableClearable, FreeSolo>(
    useGetColorsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetColorsQueryProps} />;
}

export default SearchColors;
