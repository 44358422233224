import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { ElectronicBrand } from '@/types';
import { useUpdateElectronicBrandMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateElectronicBrandProps extends DialogProps {
  electronicBrand: ElectronicBrand;
  onClose(): void;
}

const UpdateElectronicBrand: React.FC<UpdateElectronicBrandProps> = ({ electronicBrand, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateElectronicBrand, updateElectronicBrandResponse] = useUpdateElectronicBrandMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<ElectronicBrand>({
    initialValues: electronicBrand ?? {
      name: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(electronicBrand, { resetForm }) {
      try {
        await updateElectronicBrand(electronicBrand).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать бренд электроники</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateElectronicBrandResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateElectronicBrandResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateElectronicBrandResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateElectronicBrand;
