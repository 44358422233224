import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';

import { NomenclatureGroupFine } from '@/types';
import { useDeleteNomenclatureGroupFineMutation } from '@/services';

export interface DeleteNomenclatureGroupFineProps extends DialogProps {
  nomenclatureGroupFine: NomenclatureGroupFine;
  onClose(): void;
}

const DeleteNomenclatureGroupFine: React.FC<DeleteNomenclatureGroupFineProps> = ({
  nomenclatureGroupFine,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteNomenclatureGroupFine, deleteNomenclatureGroupFineResponse] = useDeleteNomenclatureGroupFineMutation();

  async function handleDeleteNomenclatureGroupFine() {
    try {
      await deleteNomenclatureGroupFine(nomenclatureGroupFine).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteNomenclatureGroupFineResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteNomenclatureGroupFineResponse.isLoading}
          onClick={handleDeleteNomenclatureGroupFine}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNomenclatureGroupFine;
