import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Role, ListResponse } from '@/types';

const roleApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Roles'],
});

const roleApi = roleApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<ListResponse<Role>, AxiosRequestConfig<Role>>({
      query: (config) => ({ url: '/admin/role', ...config }),
      providesTags: ['Roles'],
    }),
    createRole: builder.mutation<Role, Role>({
      query: (role) => ({ url: '/admin/role', method: 'POST', data: role }),
      invalidatesTags: ['Roles'],
    }),
    updateRole: builder.mutation<Role, Role>({
      query: (role) => ({ url: '/admin/role/' + role.id, method: 'PUT', data: role }),
      invalidatesTags: ['Roles'],
    }),
    deleteRole: builder.mutation<unknown, Role>({
      query: (role) => ({ url: '/admin/role/' + role.id, method: 'DELETE' }),
      invalidatesTags: ['Roles'],
    }),
  }),
});

const { useGetRolesQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } = roleApi;

export { useGetRolesQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation };
