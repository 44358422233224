import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureGroupChar } from '@/types';
import { useGetNomenclatureGroupCharsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureGroupChars<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<NomenclatureGroupChar> } & Omit<
  AutocompleteProps<NomenclatureGroupChar, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclatureGroupCharsQueryProps = useAsyncSearch<
    NomenclatureGroupChar,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureGroupCharsQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetNomenclatureGroupCharsQueryProps} />;
}

export default SearchNomenclatureGroupChars;
