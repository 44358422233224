import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Document, ListResponse } from '@/types';
import { DOCUMENTS } from '@/utils/documents';
import { LANGS } from '@/utils/langs';
import { Pagination } from '@/components';
import { EditFlat } from '@/assets/icons';
import theme from '@/theme';

import { UpdateDocument } from '../components';

export interface DocumentListProps {
  list?: ListResponse<Document>;
  page: number;
  onChangePage(page: number): void;
}

const DocumentsTable: React.FC<DocumentListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);

  function openUpdateModal(document: Document) {
    setCurrentDocument(document);
    setOpenedUpdateModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Тип документа</TableCell>
                <TableCell>Язык</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((document) => {
                const { id, type, lan } = document;

                return (
                  <TableRow key={id}>
                    <TableCell>{DOCUMENTS.find((document) => document.value === type)?.label}</TableCell>
                    <TableCell>{LANGS.find((lang) => lang.value === lan)?.label}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(document)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateDocument
        document={currentDocument!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
    </>
  );
};

export default DocumentsTable;
