import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { FurCoatCategory } from '@/types';
import { useCreateFurCoatCategoryMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateFurCoatCategoryProps extends DialogProps {
  onClose(): void;
}

const CreateFurCoatCategory: React.FC<CreateFurCoatCategoryProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createFurCoatCategory, createFurCoatCategoryResponse] = useCreateFurCoatCategoryMutation();

  const initialValues: FurCoatCategory = { name: '', price: 0 };

  const validationSchema = yup.object().shape({
    name: yup.string().required(messages.form.REQUIRED),
    price: yup.number().required(messages.form.REQUIRED),
  });

  async function handleSubmit(furCoatCategory: FurCoatCategory, { resetForm }: FormikHelpers<FurCoatCategory>) {
    try {
      await createFurCoatCategory(furCoatCategory).unwrap();

      onClose();
      resetForm();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить категорию расценок</DialogTitle>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="name"
                    label="Наименование"
                    placeholder="Введите значение"
                    value={values.name}
                    onChange={handleChange}
                    disabled={createFurCoatCategoryResponse.isLoading}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="price"
                    label="Цена"
                    placeholder="Введите значение"
                    value={values.price}
                    onChange={handleChange}
                    disabled={createFurCoatCategoryResponse.isLoading}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price ? errors.price : ''}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                size="large"
                disabled={createFurCoatCategoryResponse.isLoading}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Button type="submit" variant="contained" size="large" disabled={createFurCoatCategoryResponse.isLoading}>
                Добавить
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateFurCoatCategory;
