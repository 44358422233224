import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Constant } from '@/types';
import { useGetConstantsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchConstants<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Constant> } & Omit<
  AutocompleteProps<Constant, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetConstantsQueryProps = useAsyncSearch<Constant, Multiple, DisableClearable, FreeSolo>(
    useGetConstantsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetConstantsQueryProps} />;
}

export default SearchConstants;
