import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { NomenclatureGroup, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteNomenclatureGroup, UpdateNomenclatureGroup } from '../components';

export interface NomenclatureGroupListProps {
  list?: ListResponse<NomenclatureGroup>;
  page: number;
  onChangePage(page: number): void;
}

const NomenclatureGroupsTable: React.FC<NomenclatureGroupListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentNomenclatureGroup, setCurrentNomenclatureGroup] = useState<NomenclatureGroup | null>(null);

  function openUpdateModal(nomenclatureGroup: NomenclatureGroup) {
    setCurrentNomenclatureGroup(nomenclatureGroup);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(nomenclatureGroup: NomenclatureGroup) {
    setCurrentNomenclatureGroup(nomenclatureGroup);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Процентная ставка залога</TableCell>
                <TableCell>Общая пеня (&lt;= 30)</TableCell>
                <TableCell>Общая пеня (&gt;= 30)</TableCell>
                <TableCell>Характеристики</TableCell>
                <TableCell>Интервалы</TableCell>
                <TableCell>Пени</TableCell>
                <TableCell>Скидки</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((nomenclatureGroup) => {
                const { name, collateral_rate_percent, gt_30_days, lt_30_days, id } = nomenclatureGroup;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(nomenclatureGroup)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(nomenclatureGroup)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{collateral_rate_percent}</TableCell>
                    <TableCell>{lt_30_days}</TableCell>
                    <TableCell>{gt_30_days}</TableCell>

                    <TableCell>
                      <Tooltip title="Характеристики">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/nomenclature-groups/${id}/chars`}
                          aria-label="Характеристики"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Интервалы">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/nomenclature-groups/${id}/intervals`}
                          aria-label="Интервалы"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Пени">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/nomenclature-groups/${id}/fines`}
                          aria-label="Пени"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Скидки">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/nomenclature-groups/${id}/discounts`}
                          aria-label="Скидки"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateNomenclatureGroup
        nomenclatureGroup={currentNomenclatureGroup!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteNomenclatureGroup
        nomenclatureGroup={currentNomenclatureGroup!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default NomenclatureGroupsTable;
