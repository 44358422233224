import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import { Auth, Cabinet } from '@/layouts';
import { useAuth } from '@/hooks';
import { ProtectedRoute, ScrollToTop } from '@/components';

const Router: React.FC = () => {
  const { token } = useAuth();

  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route element={<ProtectedRoute isAllowed={Boolean(token)} redirectTo="/auth/login" />}>
          <Route path="/*" element={<Cabinet />} />
        </Route>

        <Route path="/auth/*" element={<Auth />} />
      </Routes>
    </>
  );
};

export default Router;
