import { useRef, useEffect } from 'react';

function useDebouncedFn<T extends (...args: Parameters<T>) => ReturnType<T>>(
  func: T,
  delay: number = 500,
  cleanUp = false,
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function clearTimer() {
    if (!timeoutRef.current) return;

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args: Parameters<T>) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
}

export default useDebouncedFn;
