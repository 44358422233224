import NumberFormat from 'react-number-format';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Shift } from '@/types';

export interface ViewShiftProps extends DialogProps {
  shift: Shift | null;
  onClose(): void;
}

const ViewShift: React.FC<ViewShiftProps> = ({ shift, onClose, ...props }) => {
  if (!shift) return null;

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Информация о смене</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              value={shift.organization?.full_name}
              name="organization"
              label="Организация"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField value={shift.point?.name} name="point" label="Точка" fullWidth InputProps={{ readOnly: true }} />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              value={`${shift.receiving_employee?.last_name} ${shift.receiving_employee?.name} ${shift.receiving_employee?.father_name}`}
              name="receiving_employee"
              label="Принимающий смену"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              value={`${shift.passing_employee?.last_name} ${shift.passing_employee?.name} ${shift.passing_employee?.father_name}`}
              name="passing_employee"
              label="Передающий смену"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <NumberFormat
              customInput={TextField}
              readOnly
              name="pledges_count"
              label="Кол-во активных залогов"
              placeholder="Введите значение"
              value={shift.pledges_count}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <NumberFormat
              customInput={TextField}
              readOnly
              name="cash_amount"
              label="Касса на начало смены"
              placeholder="Введите значение"
              value={shift.cash_amount}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <NumberFormat
              customInput={TextField}
              name="keys_count"
              label="Кол-во ключей"
              placeholder="Введите значение"
              value={shift.keys_count}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button type="button" color="secondary" variant="outlined" onClick={onClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewShift;
