import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Computer } from '@/types';
import { useGetComputersQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchComputers<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Computer> } & Omit<
  AutocompleteProps<Computer, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetComputersQueryProps = useAsyncSearch<Computer, Multiple, DisableClearable, FreeSolo>(
    useGetComputersQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetComputersQueryProps} />;
}

export default SearchComputers;
