import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { District } from '@/types';
import { useGetDistrictsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchDistricts<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<District> } & Omit<
  AutocompleteProps<District, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetDistrictsQueryProps = useAsyncSearch<District, Multiple, DisableClearable, FreeSolo>(
    useGetDistrictsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetDistrictsQueryProps} />;
}

export default SearchDistricts;
