import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { GemCutShape } from '@/types';
import { useGetGemCutShapesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchGemCutShapes<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<GemCutShape> } & Omit<
  AutocompleteProps<GemCutShape, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetGemCutShapesQueryProps = useAsyncSearch<GemCutShape, Multiple, DisableClearable, FreeSolo>(
    useGetGemCutShapesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetGemCutShapesQueryProps} />;
}

export default SearchGemCutShapes;
