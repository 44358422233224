import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Point, ListResponse } from '@/types';

const pointApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Points'],
});

const pointApi = pointApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPoints: builder.query<ListResponse<Point>, AxiosRequestConfig<Point>>({
      query: (config) => ({ url: '/admin/point', ...config }),
      providesTags: ['Points'],
    }),
    createPoint: builder.mutation<Point, Point>({
      query: ({ cashbox, lead_employee, responsible_employee, organization, ...point }) => ({
        url: '/admin/point',
        method: 'POST',
        data: {
          ...point,
          cashbox_id: cashbox?.id,
          lead_employee_id: lead_employee?.id,
          responsible_employee_id: responsible_employee?.id,
          organization_id: organization?.id,
        },
      }),
      invalidatesTags: ['Points'],
    }),
    updatePoint: builder.mutation<Point, Point>({
      query: ({ cashbox, lead_employee, responsible_employee, organization, ...point }) => ({
        url: '/admin/point/' + point.id,
        method: 'PUT',
        data: {
          ...point,
          cashbox_id: cashbox?.id,
          lead_employee_id: lead_employee?.id,
          responsible_employee_id: responsible_employee?.id,
          organization_id: organization?.id,
        },
      }),
      invalidatesTags: ['Points'],
    }),
    deletePoint: builder.mutation<unknown, Point>({
      query: (point) => ({ url: '/admin/point/' + point.id, method: 'DELETE' }),
      invalidatesTags: ['Points'],
    }),
  }),
});

const { useGetPointsQuery, useCreatePointMutation, useUpdatePointMutation, useDeletePointMutation } = pointApi;

export { useGetPointsQuery, useCreatePointMutation, useUpdatePointMutation, useDeletePointMutation };
