import { Form, Formik, FormikHelpers } from 'formik';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { getPriceByCount } from '@/utils/gem-categories';
import { SearchGems } from '@/pages/Gems/components';
import { SearchGemCategories } from '@/pages/GemCategories/components';

import { DIAMOND_VALUATION, DIAMOND_VALUATION_SCHEMA } from '../../schemas';

export interface DiamondValuationProps extends Omit<DialogProps, 'onSubmit'> {
  diamondValuation: NomenclatureGroup.DiamondValuation | null;
  onSubmit(diamondValuation: NomenclatureGroup.DiamondValuation): void;
  onClose(): void;
}

const DiamondValuation: React.FC<DiamondValuationProps> = ({ diamondValuation, onClose, onSubmit, ...props }) => {
  function handleSubmit(
    diamondValuation: NomenclatureGroup.DiamondValuation,
    { resetForm }: FormikHelpers<NomenclatureGroup.DiamondValuation>,
  ) {
    onSubmit({ id: Date.now(), ...diamondValuation });
    resetForm();
    onClose();
  }

  return (
    <Dialog fullWidth onClose={onClose} {...props}>
      <DialogTitle fontWeight="bold">Оценка бриллиантов</DialogTitle>

      <Formik
        initialValues={(diamondValuation as NomenclatureGroup.DiamondValuation) ?? DIAMOND_VALUATION}
        validationSchema={DIAMOND_VALUATION_SCHEMA}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setValues, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={7} md={7} xs={12}>
                  <SearchGems
                    value={values.gem}
                    onChange={(event, gem) => setValues((values) => ({ ...values, gem, category: null }))}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, { name, path }) => (
                      <li {...props}>
                        <Avatar alt={name} src={`${process.env.REACT_APP_API_URL}/${path}`} sx={{ mr: 1 }} />
                        {name}
                      </li>
                    )}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="gem"
                        label="Драгоценный камень"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.gem && errors.gem)}
                        helperText={touched.gem && errors.gem}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchGemCategories
                    value={values.category}
                    config={{ params: { gem_id: values.gem?.id } }}
                    onChange={(event, category) =>
                      setValues((values) => ({
                        ...values,
                        category,
                        sum: getPriceByCount(category?.prices!, values.count) * values.count,
                      }))
                    }
                    disabled={!values.gem}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="category"
                        label="Категория драгоценного камня"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    name="count"
                    label="Количество (шт)"
                    placeholder="Введите значение"
                    value={values.count}
                    onChange={(event) =>
                      setValues((values) => ({
                        ...values,
                        count: +event.target.value,
                        sum: getPriceByCount(values.category?.prices!, +event.target.value) * +event.target.value,
                      }))
                    }
                    error={touched.count && Boolean(errors.count)}
                    helperText={touched.count ? errors.count : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    name="sum"
                    label="Цена (тг)"
                    placeholder="Введите значение"
                    value={getPriceByCount(values.category?.prices!, values.count)}
                    error={touched.sum && Boolean(errors.sum)}
                    helperText={touched.sum ? errors.sum : ''}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    name="sum"
                    label="Сумма (тг)"
                    placeholder="Введите значение"
                    value={values.sum}
                    error={touched.sum && Boolean(errors.sum)}
                    helperText={touched.sum ? errors.sum : ''}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button type="button" color="secondary" variant="outlined" onClick={onClose}>
                Закрыть
              </Button>

              <Button type="submit" variant="contained">
                {diamondValuation ? 'Обновить' : 'Добавить'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DiamondValuation;
