import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Bonus, ListResponse } from '@/types';

const bonusApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Bonuses'],
});

const bonusApi = bonusApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBonuses: builder.query<ListResponse<Bonus>, AxiosRequestConfig<Bonus>>({
      query: (config) => ({ url: '/admin/bonus', ...config }),
      providesTags: ['Bonuses'],
    }),
    createBonus: builder.mutation<Bonus, Bonus>({
      query: (bonus) => ({ url: '/admin/bonus', method: 'POST', data: bonus }),
      invalidatesTags: ['Bonuses'],
    }),
    updateBonus: builder.mutation<Bonus, Bonus>({
      query: (bonus) => ({ url: '/admin/bonus/' + bonus.id, method: 'PUT', data: bonus }),
      invalidatesTags: ['Bonuses'],
    }),
    deleteBonus: builder.mutation<unknown, Bonus>({
      query: (bonus) => ({ url: '/admin/bonus/' + bonus.id, method: 'DELETE' }),
      invalidatesTags: ['Bonuses'],
    }),
  }),
});

const { useGetBonusesQuery, useCreateBonusMutation, useUpdateBonusMutation, useDeleteBonusMutation } = bonusApi;

export { useGetBonusesQuery, useCreateBonusMutation, useUpdateBonusMutation, useDeleteBonusMutation };
