import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Prolongation, ListResponse } from '@/types';

const prolongationApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Prolongations', 'Pledges'],
});

const prolongationApi = prolongationApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getProlongations: builder.query<ListResponse<Prolongation>, AxiosRequestConfig<Prolongation>>({
      query: (config) => ({ url: '/admin/prolongation', ...config }),
      providesTags: ['Prolongations'],
    }),
    createProlongation: builder.mutation<Prolongation, Prolongation>({
      query: ({ pledge, ...prolongation }) => ({
        url: '/admin/prolongation',
        method: 'POST',
        data: { ...prolongation, pledge_id: pledge?.id },
      }),
      invalidatesTags: ['Prolongations', 'Pledges'],
    }),
    updateProlongation: builder.mutation<Prolongation, Prolongation>({
      query: ({ pledge, ...prolongation }) => ({
        url: '/admin/prolongation/' + prolongation.id,
        method: 'PUT',
        data: { ...prolongation, pledge_id: pledge?.id },
      }),
      invalidatesTags: ['Prolongations'],
    }),
    deleteProlongation: builder.mutation<unknown, Prolongation>({
      query: (prolongation) => ({ url: '/admin/prolongation/' + prolongation.id, method: 'DELETE' }),
      invalidatesTags: ['Prolongations'],
    }),
  }),
});

const {
  useGetProlongationsQuery,
  useCreateProlongationMutation,
  useUpdateProlongationMutation,
  useDeleteProlongationMutation,
} = prolongationApi;

export {
  useGetProlongationsQuery,
  useCreateProlongationMutation,
  useUpdateProlongationMutation,
  useDeleteProlongationMutation,
};
