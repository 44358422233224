import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { CustomerStatus } from '@/types';
import { useDeleteCustomerStatusMutation } from '@/services';

export interface DeleteCustomerStatusProps extends DialogProps {
  customerStatus: CustomerStatus;
  onClose(): void;
}

const DeleteCustomerStatus: React.FC<DeleteCustomerStatusProps> = ({ customerStatus, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCustomerStatus, deleteCustomerStatusResponse] = useDeleteCustomerStatusMutation();

  async function handleDeleteCustomerStatus() {
    try {
      await deleteCustomerStatus(customerStatus).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{customerStatus?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{customerStatus?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteCustomerStatusResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteCustomerStatusResponse.isLoading}
          onClick={handleDeleteCustomerStatus}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomerStatus;
