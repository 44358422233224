import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Role, Roles } from '@/types';
import { useCreateRoleMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateRoleProps extends DialogProps {
  onClose(): void;
}

const CreateRole: React.FC<CreateRoleProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createRole, createRoleResponse] = useCreateRoleMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Role>({
    initialValues: {
      code: Roles.CASHIER,
      name: '',
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(role, { resetForm }) {
      try {
        await createRole(role).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить роль</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={createRoleResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createRoleResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createRoleResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createRoleResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateRole;
