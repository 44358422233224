import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Country } from '@/types';
import { useCreateCountryMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateCountryProps extends DialogProps {
  onClose(): void;
}

const CreateCountry: React.FC<CreateCountryProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createCountry, createCountryResponse] = useCreateCountryMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Country>({
    initialValues: {
      code: '',
      name: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      code: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(country, { resetForm }) {
      try {
        await createCountry(country).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить страну</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код для ГКБ"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={createCountryResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createCountryResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={createCountryResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={createCountryResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCountry;
