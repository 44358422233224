import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Country, ListResponse } from '@/types';

const countryApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Countries'],
});

const countryApi = countryApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<ListResponse<Country>, AxiosRequestConfig<Country>>({
      query: (config) => ({ url: '/admin/country', ...config }),
      providesTags: ['Countries'],
    }),
    createCountry: builder.mutation<Country, Country>({
      query: (country) => ({ url: '/admin/country', method: 'POST', data: country }),
      invalidatesTags: ['Countries'],
    }),
    updateCountry: builder.mutation<Country, Country>({
      query: (country) => ({ url: '/admin/country/' + country.id, method: 'PUT', data: country }),
      invalidatesTags: ['Countries'],
    }),
    deleteCountry: builder.mutation<unknown, Country>({
      query: (country) => ({ url: '/admin/country/' + country.id, method: 'DELETE' }),
      invalidatesTags: ['Countries'],
    }),
  }),
});

const { useGetCountriesQuery, useCreateCountryMutation, useUpdateCountryMutation, useDeleteCountryMutation } =
  countryApi;

export { useGetCountriesQuery, useCreateCountryMutation, useUpdateCountryMutation, useDeleteCountryMutation };
