import { useState } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import {
  Buyout,
  BuyoutStatuses,
  Customer,
  Pledge,
  PledgeStatuses,
  ProlongationStatuses,
  RePledgeCalculation,
} from '@/types';
import {
  useCalculateRePledgeMutation,
  useCreateBuyoutMutation,
  useCreateProlongationMutation,
  useCreateRePledgeMutation,
} from '@/services';
import { useAuth, useConstant, useRoles, useShifts } from '@/hooks';
import { NomenclatureGroups, PaymentMethods } from '@/utils/enums';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { ClockThreeOutlined, CoinsFlat, TimesFlat } from '@/assets/icons';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchNomenclatureGroups } from '@/pages/NomenclatureGroups/components';
import { CreateBuyout } from '@/pages/Buyouts/components';
import { CreateProlongation } from '@/pages/Prolongations/components';
import theme from '@/theme';

import { CreateRePledge, CreatePledge } from '../components';
import { useCreatePledge } from '../hooks';
import { PLEDGE_SCHEMA } from '../schemas';

enum PledgeTabs {
  COMMON = 'common',
  COLLATERAL_ITEMS = 'collateralItems',
}

export interface UpdatePledgeProps extends Omit<DialogProps, 'onSubmit'> {
  pledge: Pledge;
  customer: Customer | null;
  disabled: boolean;
  refetch(): void;
  onSubmit(pledge: Pledge, { resetForm }: FormikHelpers<Pledge>): Promise<void>;
  onClose(): void;
}

const UpdatePledge: React.FC<UpdatePledgeProps> = ({
  pledge,
  customer,
  disabled,
  refetch,
  onSubmit,
  onClose,
  ...props
}) => {
  const pledge_days_count = useConstant('PLEDGE_DAYS_COUNT');

  const initialValues: Pledge = {
    comment: '',
    customer,
    collateral_items: [],
    days_count: +pledge_days_count.value,
    is_trusted_person: false,
    trusted_person: null,
    is_without_buyout: false,
    nomenclature_group: null,
    payment_method: PaymentMethods.CASH,
    point: null,
    rate_for_day: 0,
    return_date: null,
    return_sum: 0,
    return_sum_to_hand: 0,
    re_pledge: null,
    status: PledgeStatuses.CARRIED_OUT,
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать залог</DialogTitle>

      <Formik<Pledge>
        initialValues={pledge ?? initialValues}
        enableReinitialize
        validationSchema={PLEDGE_SCHEMA}
        onSubmit={onSubmit}
      >
        {() => <PledgeForm pledge={pledge} disabled={disabled} refetch={refetch} onClose={onClose} />}
      </Formik>
    </Dialog>
  );
};

const PledgeForm: React.FC<Omit<UpdatePledgeProps, 'open' | 'onSubmit' | 'customer'>> = ({
  pledge,
  disabled,
  refetch,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const { isAdmin, isCachier } = useRoles();
  const { isShiftOpened, isShiftLoading, openShiftLoading, handleLoadShift } = useShifts();

  const [activeTab, setActiveTab] = useState(PledgeTabs.COLLATERAL_ITEMS);
  const [rePledgeModalOpened, setOpenedRePledgeModal] = useState(false);
  const [createBuyoutModalOpened, setOpenedCreateBuyoutModal] = useState(false);
  const [createPledgeModalOpened, setOpenedCreatePledgeModal] = useState(false);
  const [createProlongationModalOpened, setOpenedCreateProlongationModal] = useState(false);

  const [createBuyout, createBuyoutResponse] = useCreateBuyoutMutation();
  const [createRePledge, createRePledgeResponse] = useCreateRePledgeMutation();
  const [calculateRePledge, calculateRePledgeResponse] = useCalculateRePledgeMutation();
  const [createProlongation, createProlongationResponse] = useCreateProlongationMutation();

  const { values, touched, errors, setValues, submitForm, setFieldValue, handleChange } = useFormikContext<Pledge>();

  const { createPledgeResponse, handleCreatePledge } = useCreatePledge(() => {
    onClose();
    setOpenedCreatePledgeModal(true);
  });

  async function handleBuyoutPledge() {
    try {
      await createBuyout({ pledge, status: BuyoutStatuses.CARRIED_OUT }).unwrap();

      onClose();
      setOpenedCreateBuyoutModal(false);
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  async function handleProlongPledge() {
    try {
      await createProlongation({ pledge, status: ProlongationStatuses.CARRIED_OUT }).unwrap();

      onClose();
      setOpenedCreateProlongationModal(false);
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  async function handleCreateRePledge(calculations: Buyout & RePledgeCalculation) {
    try {
      await createRePledge({ pledge, status: BuyoutStatuses.CARRIED_OUT }).unwrap();
      setFieldValue('status', PledgeStatuses.FINISHED);
      const re_pledge = await calculateRePledge(calculations).unwrap();

      setFieldValue('re_pledge', re_pledge.data);

      setOpenedCreatePledgeModal(true);
      setOpenedRePledgeModal(false);
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  function handleSubmitForm(status: keyof typeof PledgeStatuses) {
    setFieldValue('status', PledgeStatuses[status]);
    submitForm();
  }

  function handleCloseCreatePledgeDialog() {
    setOpenedCreatePledgeModal(false);
    onClose();
    // For smooth dialog closing
    setTimeout(() => refetch(), theme.transitions.duration.short);
  }

  const LOADING = [
    disabled,
    createBuyoutResponse.isLoading,
    createRePledgeResponse.isLoading,
    createProlongationResponse.isLoading,
    createPledgeResponse.isLoading,
    calculateRePledgeResponse.isLoading,
  ].some(Boolean);

  return (
    <>
      <Form>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, point) =>
                  setValues((values) => ({ ...values, point, collateral_items: [], rate_for_day: 0 }))
                }
                readOnly
                disabled={LOADING}
                getOptionLabel={(option) => String(option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchNomenclatureGroups
                value={values.nomenclature_group}
                onChange={(event, nomenclature_group) =>
                  setValues((values) => ({
                    ...values,
                    nomenclature_group,
                    collateral_items: [],
                    rate_for_day: 0,
                  }))
                }
                readOnly
                disabled={LOADING}
                getOptionLabel={(option) => String(option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="nomenclature_group"
                    label="Номенклатурная группа"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.nomenclature_group && errors.nomenclature_group)}
                    helperText={touched.nomenclature_group && errors.nomenclature_group}
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>

            <TabContext value={activeTab}>
              <Grid item xs={12}>
                <TabList disabled={LOADING} onChange={(event, newValue) => setActiveTab(newValue)}>
                  <Tab label="Основная информация" value={PledgeTabs.COMMON} />
                  <Tab label="Залоговые вещи" value={PledgeTabs.COLLATERAL_ITEMS} />
                </TabList>

                <TabPanel value={PledgeTabs.COMMON} sx={{ mx: -3 }}>
                  <Grid container spacing={3}>
                    <Grid item lg={7} md={7} xs={12}>
                      <SearchCustomers
                        value={values.customer}
                        onChange={(event, customer) =>
                          setValues((values) => ({ ...values, customer, collateral_items: [], rate_for_day: 0 }))
                        }
                        disabled={LOADING}
                        getOptionLabel={({ name, last_name, father_name }) =>
                          `${last_name} ${name} ${father_name ?? ''} `
                        }
                        readOnly
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            name="customer"
                            label="Залогодатель"
                            placeholder="Выберите из списка"
                            error={Boolean(touched.customer && errors.customer)}
                            helperText={touched.customer && errors.customer}
                            InputProps={{ readOnly: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                      <FormControlLabel
                        label="Без выкупа"
                        control={
                          <Switch
                            name="is_without_buyout"
                            value={values.is_without_buyout}
                            disabled
                            onChange={handleChange}
                          />
                        }
                      />
                    </Grid>

                    <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                      <FormControlLabel
                        label="Доверенное лицо"
                        control={
                          <Switch
                            name="is_trusted_person"
                            checked={values.is_trusted_person}
                            readOnly
                            disabled={LOADING}
                            onChange={handleChange}
                          />
                        }
                      />
                    </Grid>

                    <Grid item lg={7} md={7} xs={12}>
                      <SearchCustomers
                        value={values.trusted_person}
                        onChange={(event, option) => setFieldValue('trusted_person', option)}
                        disabled={LOADING || Boolean(!values.is_trusted_person)}
                        getOptionLabel={({ name, last_name, father_name }) =>
                          `${last_name ?? ''} ${name} ${father_name ?? ''} `
                        }
                        readOnly
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            name="trusted_person"
                            label="Доверенное лицо"
                            placeholder="Выберите из списка"
                            error={Boolean(touched.trusted_person && errors.trusted_person)}
                            helperText={touched.trusted_person && errors.trusted_person}
                            InputProps={{ readOnly: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <NumberFormat
                        customInput={TextField}
                        name="days_count"
                        type="tel"
                        label="Срок залога (кол-во дней)"
                        placeholder="Введите значение"
                        value={values.days_count}
                        onChange={handleChange}
                        disabled={LOADING}
                        error={touched.days_count && Boolean(errors.days_count)}
                        helperText={touched.days_count ? errors.days_count : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <DatePicker
                        mask="__.__.____"
                        label="Дата возврата"
                        value={values.return_date}
                        onChange={(value) => setFieldValue('return_date', moment(value).toDate())}
                        disabled={LOADING}
                        readOnly
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="return_date"
                            error={Boolean(touched.return_date && errors.return_date)}
                            helperText={touched.return_date && errors.return_date && String(errors.return_date)}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <NumberFormat
                        customInput={TextField}
                        name="rate_for_day"
                        label="Ставка за кредит (1 день)"
                        value={values.rate_for_day}
                        disabled={LOADING}
                        error={touched.rate_for_day && Boolean(errors.rate_for_day)}
                        helperText={touched.rate_for_day ? errors.rate_for_day : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={5} xs={12}>
                      <Typography gutterBottom>
                        <b>Сумма % за 1 день: </b>
                        {Math.round((+values.return_sum - +values.return_sum_to_hand) / 30)} (тг)
                      </Typography>

                      <Typography>
                        <b>% за {values.days_count} дней: </b>
                        {Math.round(+values.return_sum - +values.return_sum_to_hand)} (тг)
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <NumberFormat
                        customInput={TextField}
                        name="return_sum_to_hand"
                        label="На руки"
                        placeholder="Введите значение"
                        value={values.return_sum_to_hand}
                        onChange={handleChange}
                        disabled={LOADING}
                        error={touched.return_sum_to_hand && Boolean(errors.return_sum_to_hand)}
                        helperText={touched.return_sum_to_hand ? errors.return_sum_to_hand : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <NumberFormat
                        customInput={TextField}
                        name="return_sum"
                        label="К возврату"
                        placeholder="Введите значение"
                        value={values.return_sum}
                        onChange={handleChange}
                        disabled={LOADING}
                        error={touched.return_sum && Boolean(errors.return_sum)}
                        helperText={touched.return_sum ? errors.return_sum : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        select
                        name="payment_method"
                        label="Способ оплаты"
                        value={values.payment_method}
                        onChange={handleChange}
                        disabled={LOADING}
                        error={touched.payment_method && Boolean(errors.payment_method)}
                        helperText={touched.payment_method ? errors.payment_method : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      >
                        {PAYMENT_METHODS.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        multiline
                        name="comment"
                        label="Комментарий"
                        placeholder="Введите значение"
                        value={values.comment}
                        onChange={handleChange}
                        disabled={LOADING}
                        error={touched.comment && Boolean(errors.comment)}
                        helperText={touched.comment ? errors.comment : ''}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={PledgeTabs.COLLATERAL_ITEMS} sx={{ mx: -3 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Номенклатура</TableCell>
                          <TableCell>Начальная стоимость</TableCell>

                          {values.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                            <>
                              <TableCell>Проба</TableCell>
                              <TableCell>Вес изделия</TableCell>
                              <TableCell>Вес металла</TableCell>
                            </>
                          )}

                          <TableCell>Примечание</TableCell>
                          <TableCell>Характеристики</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {values.collateral_items.map((collateralItem, index) => {
                          const {
                              description,
                              nomenclature,
                              primary_price,
                              vendor_code,
                              nomenclature_chars,
                              nomenclature_group_chars,
                              properties,
                            } = collateralItem,
                            chars = [...nomenclature_chars, ...nomenclature_group_chars]
                              .map((char) => {
                                const hasValue =
                                    ['string', 'number'].includes(typeof char.value) && Boolean(char.value),
                                  hasBooleanValue = ['boolean'].includes(typeof char.value) && Boolean(char.value);
                                return hasValue ? `${char.name}: ${char.value}` : hasBooleanValue && char.name;
                              })
                              .filter(Boolean)
                              .join(', ');

                          return (
                            <TableRow key={`${vendor_code} + ${index}`}>
                              <TableCell>{nomenclature?.name || '—'}</TableCell>
                              <TableCell>{primary_price}</TableCell>

                              {values.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                                <>
                                  <TableCell>{properties?.sample?.name}</TableCell>
                                  <TableCell>{properties?.jewelry_weight}</TableCell>
                                  <TableCell>{properties?.metal_weight}</TableCell>
                                </>
                              )}

                              <TableCell>{description || '—'}</TableCell>
                              <TableCell title={chars || '—'}>{chars || '—'}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={LOADING} onClick={onClose}>
            Закрыть
          </Button>

          {isCachier && !isShiftOpened ? (
            <Button
              type="button"
              variant="contained"
              disabled={[LOADING, isShiftLoading, openShiftLoading].some(Boolean)}
              onClick={() => user && handleLoadShift(user, 'open')}
            >
              Открыть смену
            </Button>
          ) : (
            <>
              {![PledgeStatuses.REALIZED, PledgeStatuses.FINISHED, PledgeStatuses.CANCELLED].includes(
                values.status,
              ) && (
                <>
                  {isAdmin && (
                    <Button
                      type="button"
                      variant="contained"
                      disabled={LOADING}
                      startIcon={<TimesFlat />}
                      onClick={() => handleSubmitForm('CANCELLED')}
                    >
                      Отменить
                    </Button>
                  )}

                  {isCachier && (
                    <>
                      {!values.is_composite && (
                        <Button
                          type="button"
                          variant="contained"
                          disabled={LOADING}
                          onClick={() => setOpenedRePledgeModal(true)}
                        >
                          Перезалог
                        </Button>
                      )}

                      {!values.first_pledge_id && (
                        <>
                          <Button
                            type="button"
                            variant="contained"
                            disabled={LOADING}
                            startIcon={<CoinsFlat />}
                            onClick={() => setOpenedCreateBuyoutModal(true)}
                          >
                            Выкупить
                          </Button>

                          <Button
                            type="button"
                            variant="contained"
                            disabled={LOADING}
                            startIcon={<ClockThreeOutlined />}
                            onClick={() => setOpenedCreateProlongationModal(true)}
                          >
                            Продлить
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogActions>
      </Form>

      {!values.is_composite && (
        <>
          <CreateRePledge
            open={rePledgeModalOpened}
            pledge={values}
            disabled={LOADING}
            onSubmit={handleCreateRePledge}
            onClose={() => setOpenedRePledgeModal(false)}
          />

          <CreatePledge
            open={createPledgeModalOpened}
            pledge={values.re_pledge}
            disabled={LOADING}
            onSubmit={(pledge, helpers) =>
              handleCreatePledge({ ...pledge, status: PledgeStatuses.CARRIED_OUT }, helpers)
            }
            onClose={handleCloseCreatePledgeDialog}
          />
        </>
      )}

      {!values.first_pledge_id && (
        <>
          <CreateBuyout
            open={createBuyoutModalOpened}
            pledge={values.is_composite && values.full_pledge ? values.full_pledge : values}
            disabled={LOADING}
            onClose={() => setOpenedCreateBuyoutModal(false)}
            onSubmit={handleBuyoutPledge}
          />

          <CreateProlongation
            open={createProlongationModalOpened}
            pledge={values.is_composite && values.full_pledge ? values.full_pledge : values}
            disabled={LOADING}
            onClose={() => setOpenedCreateProlongationModal(false)}
            onSubmit={handleProlongPledge}
          />
        </>
      )}
    </>
  );
};

export default UpdatePledge;
