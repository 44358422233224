import { Customer, Organization, Point } from '@/types';

export enum CashboxOperations {
  INCOME = 1,
  OUTCOME = 2,
}

export interface CashboxOperation {
  id?: number;
  name?: string;
  organization?: Organization | null;
  organization_id?: Organization['id'];
  point?: Point | null;
  point_id?: Point['id'];
  customer: Customer | null;
  customer_id?: Customer['id'];
  sum: number;
  reason: string;
  addition: string;
  status: CashboxOperations;
  created_at?: Date;
}
