import { Form, Formik, FormikHelpers } from 'formik';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { getWeightByChars } from '@/utils/gem-cut-shapes';
import { SearchGems } from '@/pages/Gems/components';
import { SearchGemCutShapes } from '@/pages/GemCutShapes/components';

import { GEM_RESET, GEM_RESET_SCHEMA } from '../../schemas';

export interface GemResetProps extends Omit<DialogProps, 'onSubmit'> {
  gemReset: NomenclatureGroup.GemReset | null;
  onSubmit(gemReset: NomenclatureGroup.GemReset): void;
  onClose(): void;
}

const GemReset: React.FC<GemResetProps> = ({ gemReset, onClose, onSubmit, ...props }) => {
  function handleSubmit(
    gemReset: NomenclatureGroup.GemReset,
    { resetForm }: FormikHelpers<NomenclatureGroup.GemReset>,
  ) {
    onSubmit({ id: Date.now(), ...gemReset });
    resetForm();
    onClose();
  }

  return (
    <Dialog fullWidth onClose={onClose} {...props}>
      <DialogTitle fontWeight="bold">Сброс на камни</DialogTitle>

      <Formik
        initialValues={(gemReset as NomenclatureGroup.GemReset) ?? GEM_RESET}
        validationSchema={GEM_RESET_SCHEMA}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setValues, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <SearchGems
                    value={values.gem}
                    onChange={(event, option) => setFieldValue('gem', option)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, { name, path }) => (
                      <li {...props}>
                        <Avatar alt={name} src={`${process.env.REACT_APP_API_URL}/${path}`} sx={{ mr: 1 }} />
                        {name}
                      </li>
                    )}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="gem"
                        label="Тип камня"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.gem && errors.gem)}
                        helperText={touched.gem && errors.gem}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchGemCutShapes
                    value={values.cut_shape}
                    onChange={(event, option) => setFieldValue('cut_shape', option)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="cut_shape"
                        label="Форма огранки"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.cut_shape && errors.cut_shape)}
                        helperText={touched.cut_shape && errors.cut_shape}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6.5} md={6.5} xs={12}>
                  <TextField
                    type="number"
                    name="length"
                    label="Длина (мм)"
                    placeholder="Введите значение"
                    value={values.length}
                    onChange={(event) =>
                      setValues((values) => ({
                        ...values,
                        length: +event.target.value,
                        weight:
                          values.count *
                          getWeightByChars(
                            +event.target.value,
                            values.height,
                            values.width,
                            values.gem!,
                            values.cut_shape!,
                          ),
                      }))
                    }
                    disabled={!values.cut_shape?.has_length}
                    error={touched.length && Boolean(errors.length)}
                    helperText={touched.length ? errors.length : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6.5} md={6.5} xs={12}>
                  <TextField
                    type="number"
                    name="height"
                    label="Высота (мм)"
                    placeholder="Введите значение"
                    value={values.height}
                    onChange={(event) =>
                      setValues((values) => ({
                        ...values,
                        height: +event.target.value,
                        weight:
                          values.count *
                          getWeightByChars(
                            values.length,
                            +event.target.value,
                            values.width,
                            values.gem!,
                            values.cut_shape!,
                          ),
                      }))
                    }
                    disabled={!values.cut_shape?.has_height}
                    error={touched.height && Boolean(errors.height)}
                    helperText={touched.height ? errors.height : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6.5} md={6.5} xs={12}>
                  <TextField
                    type="number"
                    name="width"
                    label="Ширина (мм)"
                    placeholder="Введите значение"
                    value={values.width}
                    onChange={(event) =>
                      setValues((values) => ({
                        ...values,
                        width: +event.target.value,
                        weight:
                          values.count *
                          getWeightByChars(
                            values.length,
                            values.height,
                            +event.target.value,
                            values.gem!,
                            values.cut_shape!,
                          ),
                      }))
                    }
                    disabled={!values.cut_shape?.has_width}
                    error={touched.width && Boolean(errors.width)}
                    helperText={touched.width ? errors.width : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    name="count"
                    label="Количество (шт)"
                    placeholder="Введите значение"
                    value={values.count}
                    onChange={(event) =>
                      setValues((values) => ({
                        ...values,
                        count: +event.target.value,
                        weight:
                          +event.target.value *
                          getWeightByChars(values.length, values.height, values.width, values.gem!, values.cut_shape!),
                      }))
                    }
                    disabled={![values.length, values.height, values.width].some(Boolean)}
                    error={touched.count && Boolean(errors.count)}
                    helperText={touched.count ? errors.count : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    name="weight"
                    label="Вес (гр)"
                    placeholder="Введите значение"
                    value={values.weight}
                    onChange={(event) => setFieldValue('weight', +event.target.value)}
                    error={touched.weight && Boolean(errors.weight)}
                    helperText={touched.weight ? errors.weight : ''}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button type="button" color="secondary" variant="outlined" onClick={onClose}>
                Закрыть
              </Button>

              <Button type="submit" variant="contained">
                {gemReset ? 'Обновить' : 'Добавить'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default GemReset;
