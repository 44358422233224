import { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Locality, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteLocality, UpdateLocality } from '../components';

export interface LocalityListProps {
  list?: ListResponse<Locality>;
  page: number;
  onChangePage(page: number): void;
}

const LocalitiesTable: React.FC<LocalityListProps> = ({ list, page, onChangePage }) => {
  const { regionId, areaId, districtId } = useParams<{ regionId: string; areaId: string; districtId: string }>();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentLocality, setCurrentLocality] = useState<Locality | null>(null);

  function openUpdateModal(locality: Locality) {
    setCurrentLocality(locality);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(locality: Locality) {
    setCurrentLocality(locality);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Улицы</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((locality) => {
                const { name, id } = locality;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Tooltip title="Улицы">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/regions/${regionId}/areas/${areaId}/districts/${districtId}/localities/${id}/streets`}
                          aria-label="Улицы"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(locality)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(locality)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateLocality
        locality={currentLocality!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteLocality
        locality={currentLocality!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default LocalitiesTable;
