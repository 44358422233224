import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { Document } from '@/types';
import { useUpdateDocumentMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { DOCUMENTS } from '@/utils/documents';
import { LANGS } from '@/utils/langs';
import { RichEditor } from '@/components';

export interface UpdateDocumentProps extends DialogProps {
  document: Document;
  onClose(): void;
}

const UpdateDocument: React.FC<UpdateDocumentProps> = ({ document, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateDocument, updateDocumentResponse] = useUpdateDocumentMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Document>({
    initialValues: document ?? { html_content: '', lan: '', type: '' },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      html_content: yup.string().required(messages.form.REQUIRED),
      lan: yup.string().required(messages.form.SELECT_FROM_LIST),
      type: yup.string().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(document, { resetForm }) {
      try {
        await updateDocument(document).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullScreen {...props}>
      <DialogTitle fontWeight="bold">Редактировать шаблон документа</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="lan"
                label="Язык"
                value={values.lan}
                onChange={handleChange}
                disabled={updateDocumentResponse.isLoading}
                error={touched.lan && Boolean(errors.lan)}
                helperText={touched.lan ? errors.lan : ''}
                fullWidth
              >
                {LANGS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="type"
                label="Тип документа"
                value={values.type}
                onChange={handleChange}
                disabled={updateDocumentResponse.isLoading}
                error={touched.type && Boolean(errors.type)}
                helperText={touched.type ? errors.type : ''}
                fullWidth
              >
                {DOCUMENTS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <RichEditor
                value={values.html_content}
                onChange={(value) => setFieldValue('html_content', value)}
                disabled={updateDocumentResponse.isLoading}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateDocumentResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateDocumentResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateDocument;
