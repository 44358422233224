import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Holiday } from '@/types';
import { useDeleteHolidayMutation } from '@/services';

export interface DeleteHolidayProps extends DialogProps {
  holiday: Holiday;
  onClose(): void;
}

const DeleteHoliday: React.FC<DeleteHolidayProps> = ({ holiday, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteHoliday, deleteHolidayResponse] = useDeleteHolidayMutation();

  async function handleDeleteHoliday() {
    try {
      await deleteHoliday(holiday).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{holiday?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{holiday?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteHolidayResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteHolidayResponse.isLoading}
          size="small"
          onClick={handleDeleteHoliday}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteHoliday;
