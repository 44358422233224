import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { Buyout, Pledge, RePledgeCalculation, RePledgeOperationTypes } from '@/types';
import { useGetNomenclatureGroupIntervalPercentQuery } from '@/services';
import { useDebounce } from '@/hooks';
import { NomenclatureGroups } from '@/utils/enums';

import { OPERATION_TYPES, getSumOperationByType } from '../helpers';

export interface RePledgeCalculationsProps extends Omit<DialogProps, 'onSubmit'> {
  pledge: Pledge | null;
  fine: number;
  sumForDay: number;
  totalSumForBuyout: number;
  daysFromPledgeWasCreated: number;
  overdueDaysCount: number;
  disabled: boolean;
  onClose(): void;
  onSubmit(calculcations: Buyout & RePledgeCalculation): void;
}

const RePledgeCalculations: React.FC<RePledgeCalculationsProps> = ({
  open,
  pledge,
  fine,
  sumForDay,
  totalSumForBuyout,
  daysFromPledgeWasCreated,
  overdueDaysCount,
  disabled,
  onClose,
  onSubmit,
  ...props
}) => {
  const { values, setValues, setFieldValue, handleSubmit } = useFormik<Buyout & RePledgeCalculation>({
    initialValues: {
      days_count: daysFromPledgeWasCreated,
      pledge,
      re_pledge: pledge,
      sum: 0,
      type: RePledgeOperationTypes.DECREASE,
      partial_buyout_items: [],
    },
    enableReinitialize: true,
    onSubmit,
  });

  const debouncedSum = useDebounce(values.sum, 1000);

  const selectedSumByOperationType = (sum: number) =>
    getSumOperationByType({
      sum,
      fine,
      sumForDay,
      totalSumForBuyout,
      collateralItems: values.partial_buyout_items.length
        ? values.partial_buyout_items
        : values.re_pledge?.collateral_items || [],
      daysFromPledgeWasCreated,
    }).find(({ value }) => value === values.type)!;

  const { data: percent } = useGetNomenclatureGroupIntervalPercentQuery({
    params: {
      group_id: values.pledge?.nomenclature_group?.id,
      point_id: values.pledge?.point?.id,
      customer_status_id: values.pledge?.customer?.customer_status?.id,
      price:
        +values.pledge?.return_sum_to_hand! - +debouncedSum + Math.round(sumForDay * daysFromPledgeWasCreated + fine),
    },
  });

  return (
    <Dialog open={open} maxWidth="md" fullWidth {...props}>
      <DialogTitle>Предварительный расчёт</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Номенклатура</TableCell>
                      <TableCell>Начальная стоимость</TableCell>

                      {values.re_pledge?.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                        <TableCell>Вес изделия</TableCell>
                      )}

                      {(values.re_pledge?.collateral_items || []).length > 1 && (
                        <TableCell padding="checkbox">Пометка</TableCell>
                      )}

                      {[RePledgeOperationTypes.INCREASE].includes(values.type) && (
                        <>
                          <TableCell>Сумма добора</TableCell>
                          <TableCell>Макс. оценка</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {values.re_pledge?.collateral_items.map((collateral_item, index, array) => {
                      const { nomenclature, max_price, primary_price, vendor_code, properties } = collateral_item;

                      const extraAmount = Math.round(max_price - +primary_price);

                      function handleChangeCollateralItem() {
                        const has_collateral_item = values.partial_buyout_items.find(
                          ({ id }) => id === collateral_item.id,
                        );

                        setFieldValue(
                          'partial_buyout_items',
                          !has_collateral_item
                            ? [...values.partial_buyout_items, collateral_item]
                            : values.partial_buyout_items.filter(({ id }) => id !== collateral_item.id),
                        );
                      }

                      return (
                        <TableRow key={`${vendor_code} + ${index}`}>
                          <TableCell>{nomenclature?.name || '—'}</TableCell>
                          <TableCell>{primary_price}</TableCell>

                          {values.re_pledge?.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                            <TableCell>{properties?.jewelry_weight}</TableCell>
                          )}

                          {array.length > 1 && (
                            <TableCell padding="checkbox">
                              {([RePledgeOperationTypes.DECREASE].includes(values.type) || extraAmount > 0) && (
                                <Checkbox disabled={disabled} onChange={handleChangeCollateralItem} />
                              )}
                            </TableCell>
                          )}

                          {[RePledgeOperationTypes.INCREASE].includes(values.type) && (
                            <>
                              <TableCell>{extraAmount}</TableCell>
                              <TableCell>{max_price ?? '—'}</TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item lg={2.25} md={6} xs={12}>
              <TextField
                select
                name="type"
                label="Вид операции"
                placeholder="Выберите из списка"
                value={values.type}
                onChange={(event) =>
                  setValues((values) => {
                    return {
                      ...values,
                      sum: 0,
                      type: event.target.value as RePledgeOperationTypes,
                      re_pledge: { ...values.re_pledge!, return_sum_to_hand: 0 },
                    };
                  })
                }
                disabled={disabled}
                fullWidth
              >
                {OPERATION_TYPES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <TextField
                name="sum"
                label="Сумма увеличения/уменьшения"
                placeholder="Введите значение"
                value={values.sum}
                onChange={(event) =>
                  setValues((values) => {
                    return {
                      ...values,
                      sum: +event.target.value,
                      re_pledge: {
                        ...values.re_pledge!,
                        return_sum_to_hand:
                          +values.pledge?.return_sum_to_hand! + selectedSumByOperationType(+event.target.value).sum,
                      },
                    };
                  })
                }
                disabled={disabled}
                InputProps={{
                  inputProps: {
                    type: 'number',
                    min: Math.round(sumForDay * daysFromPledgeWasCreated + fine),
                    max: selectedSumByOperationType(values.sum).max,
                  },
                }}
                required
                fullWidth
              />
            </Grid>

            <Grid item lg={1.75} md={6} xs={12}>
              <TextField
                label="Сумма НЗБ"
                value={values.sum ? values.re_pledge?.return_sum_to_hand : 0}
                disabled={disabled}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item lg={2.5} md={6} xs={4}>
              <TextField
                label="Ставка за кредит (1 день)"
                value={percent ?? 0}
                fullWidth
                disabled={disabled}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item lg={2.5} md={6} xs={12}>
              <TextField
                label="Сумма % за 1 день"
                value={Math.round((values.re_pledge?.return_sum_to_hand! * (percent || 0)!) / 100)}
                disabled={disabled}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={disabled} onClick={onClose}>
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={disabled}>
            Провести
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RePledgeCalculations;
