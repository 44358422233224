import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CarBrand, ListResponse } from '@/types';

const carBrandApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CarBrands'],
});

const carBrandApi = carBrandApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCarBrands: builder.query<ListResponse<CarBrand>, AxiosRequestConfig<CarBrand>>({
      query: (config) => ({ url: '/admin/car-brand', ...config }),
      providesTags: ['CarBrands'],
    }),
    createCarBrand: builder.mutation<CarBrand, CarBrand>({
      query: (carBrand) => ({ url: '/admin/car-brand', method: 'POST', data: carBrand }),
      invalidatesTags: ['CarBrands'],
    }),
    updateCarBrand: builder.mutation<CarBrand, CarBrand>({
      query: (carBrand) => ({ url: '/admin/car-brand/' + carBrand.id, method: 'PUT', data: carBrand }),
      invalidatesTags: ['CarBrands'],
    }),
    deleteCarBrand: builder.mutation<unknown, CarBrand>({
      query: (carBrand) => ({ url: '/admin/car-brand/' + carBrand.id, method: 'DELETE' }),
      invalidatesTags: ['CarBrands'],
    }),
  }),
});

const { useGetCarBrandsQuery, useCreateCarBrandMutation, useUpdateCarBrandMutation, useDeleteCarBrandMutation } =
  carBrandApi;

export { useGetCarBrandsQuery, useCreateCarBrandMutation, useUpdateCarBrandMutation, useDeleteCarBrandMutation };
