import { Cridentials, User } from '@/types';
import baseApi from '@/api';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<User, Cridentials>({
      query: (authData) => ({ url: '/auth/password', method: 'POST', data: authData }),
    }),
  }),
});

const { useLoginMutation } = authApi;

export { useLoginMutation };
