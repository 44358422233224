import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Country } from '@/types';
import { useUpdateCountryMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateCountryProps extends DialogProps {
  country: Country;
  onClose(): void;
}

const UpdateCountry: React.FC<UpdateCountryProps> = ({ country, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateCountry, updateCountryResponse] = useUpdateCountryMutation();

  const { values, errors, touched, handleSubmit, handleChange, resetForm } = useFormik<Country>({
    initialValues: country ?? {
      code: '',
      name: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      code: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(country) {
      try {
        await updateCountry(country).unwrap();

        handleClose();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog onClose={handleClose} fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать страну</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код для ГКБ"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={updateCountryResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateCountryResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={updateCountryResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={updateCountryResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCountry;
