import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { Customer } from '@/types';
import { GENDERS } from '@/utils/genders';
import { UpdateCustomer } from '@/pages/Customers/components';
import { EditFlat, UserFlat } from '@/assets/icons';

export interface CustomerInfoProps {
  customer: Customer;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ customer }) => {
  const {
    id,
    avatar,
    born_at,
    country,
    created_by,
    customer_category,
    customer_status,
    document_type,
    document_series,
    document_number,
    document_issued_by,
    document_expire_date,
    document_issued_date,
    gender,
    father_name,
    name,
    operator,
    phone_number,
    is_foreign_citizen,
    iin,
    identity_front_side,
    identity_back_side,
    last_name,
    updated_by,

    actual_region,
    actual_area,
    actual_district,
    actual_locality,
    actual_street,
    actual_house_number,
    actual_flat_number,
    official_region,
    official_area,
    official_district,
    official_locality,
    official_street,
    official_house_number,
    official_flat_number,
  } = customer;

  const full_actual_address = [
    actual_region?.name,
    actual_area?.name,
    actual_district?.name,
    actual_locality?.name,
    actual_street?.name,
    actual_house_number ? 'Дом ' + actual_house_number : null,
    actual_flat_number ? 'Квартира ' + actual_flat_number : null,
  ]
    .filter(Boolean)
    .join(', ');

  const full_official_address = [
    official_region?.name,
    official_area?.name,
    official_district?.name,
    official_locality?.name,
    official_street?.name,
    official_house_number ? 'Дом ' + official_house_number : null,
    official_flat_number ? 'Квартира ' + official_flat_number : null,
  ]
    .filter(Boolean)
    .join(', ');

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Stack spacing={2} sx={{ position: 'sticky', top: 100 }}>
            <Paper sx={{ width: 320, height: 320, overflow: 'hidden' }}>
              {avatar ? (
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_STORAGE_URL}/${avatar}`}
                  sx={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid lightgrey',
                    borderRadius: 1,
                  }}
                >
                  <UserFlat fontSize="large" color="disabled" />
                  <Typography color="lightgrey" variant="h2">
                    Фото отсутствует
                  </Typography>
                </Box>
              )}
            </Paper>

            <Button component={RouterLink} variant="contained" to={`/customers/${id}/pledges`}>
              Залоги
            </Button>
          </Stack>

          <Stack sx={{ flexGrow: 1 }} spacing={2}>
            <Stack direction="row" alignItems="center" sx={{ p: 2 }}>
              <Typography component="h2" variant="h1">
                {last_name} {name} {father_name}
              </Typography>

              <Box>
                <Tooltip title="Редактировать клиента">
                  <IconButton aria-label="Редактировать клиента" onClick={() => setOpenedUpdateModal(true)}>
                    <EditFlat sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>

            <TableContainer component={Paper}>
              <Stack direction="row" spacing={2}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th">
                        <b>Пол</b>
                      </TableCell>

                      <TableCell>{GENDERS.find(({ value }) => value === gender)?.label}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Дата рождения</b>
                      </TableCell>

                      <TableCell>{moment(born_at).format('DD.MM.YYYY')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th">
                        <b>Создан</b>
                      </TableCell>

                      <TableCell>{created_by?.name}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Обновлён</b>
                      </TableCell>

                      <TableCell>{updated_by?.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            </TableContainer>

            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" sx={{ width: 200 }}>
                      <b>Страна</b>
                    </TableCell>

                    <TableCell>{country?.name ?? '—'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th">
                      <b>Иностранец</b>
                    </TableCell>

                    <TableCell>{is_foreign_citizen ? 'Да' : 'Нет'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th">
                      <b>ИИН</b>
                    </TableCell>

                    <TableCell>{iin}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th">
                      <b>Адрес проживания</b>
                    </TableCell>

                    <Tooltip title={full_official_address}>
                      <TableCell>{full_official_address}</TableCell>
                    </Tooltip>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th">
                      <b>Адрес прописки</b>
                    </TableCell>

                    <Tooltip title={full_actual_address}>
                      <TableCell>{full_actual_address}</TableCell>
                    </Tooltip>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box>
              <Grid container spacing={2}>
                <Grid item lg={8} md={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th">
                            <b>Вид документа удостоверяющего личность</b>
                          </TableCell>

                          <TableCell>{document_type?.name}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Серия</b>
                          </TableCell>

                          <TableCell>{document_series}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Номер документа</b>
                          </TableCell>

                          <TableCell>{document_number}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Срок годности УЛ (паспорта)</b>
                          </TableCell>

                          <TableCell>
                            {document_expire_date ? moment(document_expire_date).format('DD.MM.YYYY') : '—'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Кем выдан</b>
                          </TableCell>

                          <TableCell>{document_issued_by}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Когда выдан</b>
                          </TableCell>

                          <TableCell>
                            {document_issued_date ? moment(document_issued_date).format('DD.MM.YYYY') : '—'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item lg={4} md={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th">
                            <b>Категория</b>
                          </TableCell>

                          <TableCell>{customer_category?.name ?? '—'}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Статус</b>
                          </TableCell>

                          <TableCell>{customer_status?.name ?? '—'}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Мобильный оператор</b>
                          </TableCell>

                          <TableCell>{operator?.name}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th">
                            <b>Номер телефона</b>
                          </TableCell>

                          <TableCell>
                            <Link href={`tel:${phone_number}`} color="secondary">
                              {phone_number}
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>

            {[identity_front_side, identity_back_side].some(Boolean) && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {identity_front_side && <TableCell align="center">Лицевая сторона документа</TableCell>}
                      {identity_back_side && <TableCell align="center">Обратная сторона документа</TableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      {identity_front_side && (
                        <TableCell align="center">
                          <Box sx={{ height: 340, width: '100%' }}>
                            <Box
                              component="img"
                              src={`${process.env.REACT_APP_STORAGE_URL}/${identity_front_side}`}
                              sx={{ maxWidth: '100%', width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          </Box>
                        </TableCell>
                      )}

                      {identity_back_side && (
                        <TableCell align="center">
                          <Box sx={{ height: 340, width: '100%' }}>
                            <Box
                              component="img"
                              src={`${process.env.REACT_APP_STORAGE_URL}/${identity_back_side}`}
                              sx={{ maxWidth: '100%', width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </Stack>
      </Stack>

      <UpdateCustomer customer={customer} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
    </>
  );
};

export default CustomerInfo;
