import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Pledge } from '@/types';
import { useGetPledgesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchPledges<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Pledge> } & Omit<
  AutocompleteProps<Pledge, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetPledgesQueryProps = useAsyncSearch<Pledge, Multiple, DisableClearable, FreeSolo>(
    useGetPledgesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetPledgesQueryProps} />;
}

export default SearchPledges;
