import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Realization, ListResponse, Pledge } from '@/types';

const realizationApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Realizations', 'Pledges', 'RealizationPledges'],
});

const realizationApi = realizationApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRealizations: builder.query<ListResponse<Realization>, AxiosRequestConfig<Realization>>({
      query: (config) => ({ url: '/admin/realization', ...config }),
      providesTags: ['Realizations'],
    }),
    getRealizationPledges: builder.query<ListResponse<Pledge>, AxiosRequestConfig<Pledge>>({
      query: (config) => ({ url: '/admin/realization/get-pledges', ...config }),
      providesTags: ['RealizationPledges'],
    }),
    createRealization: builder.mutation<Realization, Realization>({
      query: ({ pledges, ...realization }) => ({
        url: '/admin/realization',
        method: 'POST',
        data: { ...realization, pledge_ids: pledges?.map(({ id }) => id) },
      }),
      invalidatesTags: ['Realizations', 'RealizationPledges'],
    }),
    deleteRealization: builder.mutation<unknown, Realization>({
      query: (realization) => ({ url: '/admin/realization/' + realization.id, method: 'DELETE' }),
      invalidatesTags: ['Realizations'],
    }),
  }),
});

const {
  useGetRealizationsQuery,
  useGetRealizationPledgesQuery,
  useCreateRealizationMutation,
  useDeleteRealizationMutation,
} = realizationApi;

export {
  useGetRealizationsQuery,
  useGetRealizationPledgesQuery,
  useCreateRealizationMutation,
  useDeleteRealizationMutation,
};
