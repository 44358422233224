import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Buyout, ListResponse } from '@/types';

const buyoutApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Buyouts', 'Pledges'],
});

const buyoutApi = buyoutApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBuyouts: builder.query<ListResponse<Buyout>, AxiosRequestConfig<Buyout>>({
      query: (config) => ({ url: '/admin/buyout', ...config }),
      providesTags: ['Buyouts'],
    }),
    createBuyout: builder.mutation<Buyout, Buyout>({
      query: ({ pledge, ...buyout }) => ({
        url: '/admin/buyout',
        method: 'POST',
        data: { ...buyout, pledge_id: pledge?.id },
      }),
      invalidatesTags: ['Buyouts', 'Pledges'],
    }),
    createRePledge: builder.mutation<Buyout, Buyout>({
      query: ({ pledge, ...buyout }) => ({
        url: '/admin/buyout',
        method: 'POST',
        data: { ...buyout, pledge_id: pledge?.id },
      }),
    }),
    updateBuyout: builder.mutation<Buyout, Buyout>({
      query: ({ pledge, ...buyout }) => ({
        url: '/admin/buyout/' + buyout.id,
        method: 'PUT',
        data: { ...buyout, pledge_id: pledge?.id },
      }),
      invalidatesTags: ['Buyouts'],
    }),
    deleteBuyout: builder.mutation<unknown, Buyout>({
      query: (buyout) => ({ url: '/admin/buyout/' + buyout.id, method: 'DELETE' }),
      invalidatesTags: ['Buyouts'],
    }),
  }),
});

const {
  useGetBuyoutsQuery,
  useCreateBuyoutMutation,
  useCreateRePledgeMutation,
  useUpdateBuyoutMutation,
  useDeleteBuyoutMutation,
} = buyoutApi;

export {
  useGetBuyoutsQuery,
  useCreateBuyoutMutation,
  useCreateRePledgeMutation,
  useUpdateBuyoutMutation,
  useDeleteBuyoutMutation,
};
