import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { TerroristOrganization } from '@/types';
import { useUpdateTerroristOrganizationMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateTerroristOrganizationProps extends Omit<DialogProps, 'terroristOrganization'> {
  terroristOrganization: TerroristOrganization;
  onClose(): void;
}

const UpdateTerroristOrganization: React.FC<UpdateTerroristOrganizationProps> = ({
  terroristOrganization,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateTerroristOrganization, updateTerroristOrganizationResponse] = useUpdateTerroristOrganizationMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<TerroristOrganization>({
    initialValues: terroristOrganization ?? {
      name: { en: '', ru: '' },
      note: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.object().shape({
        ru: yup.string().required(messages.form.REQUIRED),
        en: yup.string().required(messages.form.REQUIRED),
      }),
      note: yup.string(),
    }),
    async onSubmit(terroristOrganization, { resetForm }) {
      try {
        await updateTerroristOrganization(terroristOrganization).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать организацию</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name.ru"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name.ru}
                onChange={handleChange}
                disabled={updateTerroristOrganizationResponse.isLoading}
                error={touched.name?.ru && Boolean(errors.name?.ru)}
                helperText={touched.name?.ru ? errors.name?.ru : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="name.en"
                label="Наименование (англ.)"
                placeholder="Введите значение"
                value={values.name.en}
                onChange={handleChange}
                disabled={updateTerroristOrganizationResponse.isLoading}
                error={touched.name?.en && Boolean(errors.name?.en)}
                helperText={touched.name?.en ? errors.name?.en : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                name="note"
                label="Комментарий"
                placeholder="Введите значение"
                value={values.note}
                onChange={handleChange}
                disabled={updateTerroristOrganizationResponse.isLoading}
                error={touched.note && Boolean(errors.note)}
                helperText={touched.note ? errors.note : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateTerroristOrganizationResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateTerroristOrganizationResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateTerroristOrganization;
