import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Street, ListResponse } from '@/types';

const streetApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Streets'],
});

const streetApi = streetApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getStreets: builder.query<ListResponse<Street>, AxiosRequestConfig<Street>>({
      query: (config) => ({ url: '/admin/street', ...config }),
      providesTags: ['Streets'],
    }),
    createStreet: builder.mutation<Street, Street>({
      query: (street) => ({ url: '/admin/street', method: 'POST', data: street }),
      invalidatesTags: ['Streets'],
    }),
    updateStreet: builder.mutation<Street, Street>({
      query: (street) => ({ url: '/admin/street/' + street.id, method: 'PUT', data: street }),
      invalidatesTags: ['Streets'],
    }),
    deleteStreet: builder.mutation<unknown, Street>({
      query: (street) => ({ url: '/admin/street/' + street.id, method: 'DELETE' }),
      invalidatesTags: ['Streets'],
    }),
  }),
});

const { useGetStreetsQuery, useCreateStreetMutation, useUpdateStreetMutation, useDeleteStreetMutation } = streetApi;

export { useGetStreetsQuery, useCreateStreetMutation, useUpdateStreetMutation, useDeleteStreetMutation };
