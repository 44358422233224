import NumberFormat from 'react-number-format';
import { getIn, useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { All, Employee, EmployeePercent } from '@/types';
import { useUpdateEmployeeSalaryPercentMutation } from '@/services';
import { PERSENT_TYPES } from '@/utils/percent-types';
import { messages } from '@/utils/helpers';
import { SearchPoints } from '@/pages/Points/components';
import { SearchNomenclatureGroups } from '@/pages/NomenclatureGroups/components';

export interface UpdateEmployeeSalaryPercentProps extends DialogProps {
  employee?: Employee;
  onClose(): void;
}

const UpdateEmployeeSalaryPercent: React.FC<UpdateEmployeeSalaryPercentProps> = ({ employee, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateEmployeeSalaryPercent, updateEmployeeSalaryPercentResponse] = useUpdateEmployeeSalaryPercentMutation();

  const INITIAL_VALUES = PERSENT_TYPES.map(({ value }) => ({
    percent: '',
    type: value,
    nomenclature_groups: [],
    points: [],
  }));

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik<
    EmployeePercent[]
  >({
    initialValues: employee?.percents?.length ? employee.percents : INITIAL_VALUES,
    enableReinitialize: true,
    validationSchema: yup.array().of(
      yup.object().shape({
        percent: yup.string().required(messages.form.REQUIRED),
        type: yup.string().required(messages.form.REQUIRED),
        nomenclature_groups: yup.array().when('nomenclature_group_ids', {
          is: '*',
          otherwise: yup
            .array()
            .of(yup.object().nullable().required(messages.form.SELECT_FROM_LIST))
            .min(1, messages.form.SELECT_MIN_FROM_LIST(1)),
        }),
        points: yup.array().when('point_ids', {
          is: '*',
          otherwise: yup
            .array()
            .of(yup.object().nullable().required(messages.form.SELECT_FROM_LIST))
            .min(1, messages.form.SELECT_MIN_FROM_LIST(1)),
        }),
      }),
    ),
    async onSubmit(percents) {
      try {
        await updateEmployeeSalaryPercent({
          id: employee?.id,
          percents: percents.map(({ nomenclature_groups, nomenclature_group_ids, points, point_ids, ...percent }) => ({
            ...percent,
            nomenclature_group_ids:
              nomenclature_group_ids === ('*' as All)
                ? nomenclature_group_ids
                : nomenclature_groups?.map(({ id }) => id).join(','),
            point_ids: point_ids === ('*' as All) ? point_ids : points?.map(({ id }) => id).join(','),
          })),
        }).unwrap();

        onClose();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog maxWidth="lg" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Вид процента</b>
                  </TableCell>

                  <TableCell>
                    <b>%</b>
                  </TableCell>

                  <TableCell>
                    <b>Отбор по точкам</b>
                  </TableCell>

                  <TableCell>
                    <b>Все</b>
                  </TableCell>

                  <TableCell>
                    <b>Отбор по номенклатурным группам</b>
                  </TableCell>

                  <TableCell>
                    <b>Все</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {values.map((value, index) => {
                  const field = (name: string) => `[${index}].${name}`;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <b>{PERSENT_TYPES.find((type) => type.value === value.type)?.label}</b>
                      </TableCell>

                      <TableCell>
                        <NumberFormat
                          customInput={TextField}
                          name={field('percent')}
                          type="tel"
                          placeholder="Введите значение"
                          value={value.percent}
                          onChange={handleChange}
                          disabled={updateEmployeeSalaryPercentResponse.isLoading}
                          error={getIn(touched, field('percent')) && Boolean(getIn(errors, field('percent')))}
                          fullWidth
                          size="small"
                        />
                      </TableCell>

                      <TableCell>
                        <SearchPoints
                          multiple
                          value={value.points}
                          onChange={(event, option) => setFieldValue(field('points'), option)}
                          disabled={updateEmployeeSalaryPercentResponse.isLoading || value.point_ids === ('*' as All)}
                          getOptionLabel={(option) => option.name}
                          limitTags={1}
                          sx={{ minWidth: 320 }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              name={field('points')}
                              placeholder="Выберите из списка"
                              error={getIn(touched, field('points')) && Boolean(getIn(errors, field('points')))}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <Checkbox
                          name={field('point_ids')}
                          checked={value.point_ids === '*'}
                          value={field('point_ids')}
                          onChange={(event, checked) => setFieldValue(field('point_ids'), checked ? '*' : '')}
                        />
                      </TableCell>

                      <TableCell>
                        <SearchNomenclatureGroups
                          multiple
                          value={value.nomenclature_groups}
                          onChange={(event, option) => setFieldValue(field('nomenclature_groups'), option)}
                          disabled={
                            updateEmployeeSalaryPercentResponse.isLoading ||
                            value.nomenclature_group_ids === ('*' as All)
                          }
                          getOptionLabel={(option) => option.name}
                          limitTags={1}
                          sx={{ minWidth: 320 }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              name={field('nomenclature_groups')}
                              placeholder="Выберите из списка"
                              error={
                                getIn(touched, field('nomenclature_groups')) &&
                                Boolean(getIn(errors, field('nomenclature_groups')))
                              }
                              size="small"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <Checkbox
                          name={field('nomenclature_group_ids')}
                          checked={value.nomenclature_group_ids === '*'}
                          value={field('nomenclature_group_ids')}
                          onChange={(event, checked) =>
                            setFieldValue(field('nomenclature_group_ids'), checked ? '*' : '')
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateEmployeeSalaryPercentResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateEmployeeSalaryPercentResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateEmployeeSalaryPercent;
