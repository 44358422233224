import { useParams } from 'react-router-dom';

import { useGetCustomerQuery } from '@/services';
import { AppBar, Empty, Error, Loader } from '@/components';

import { CustomerInfo } from './components';

export interface CustomerProps {}

const Customer: React.FC<CustomerProps> = () => {
  const { customerId } = useParams<{ customerId: string }>();

  const { data: customer, isLoading, isSuccess, isError } = useGetCustomerQuery({ id: +customerId! });

  return (
    <>
      <AppBar title="Информация о клиенте" />

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error text="Произошла ошибка с загрузкой клиента! Пожалуйста, попробуйте позже" />
      ) : isSuccess && Boolean(customer?.data) ? (
        <CustomerInfo customer={customer.data} />
      ) : (
        <Empty text="Клиент не найден" />
      )}
    </>
  );
};

export default Customer;
