import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Constant, ListResponse } from '@/types';

const constantApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Constants'],
});

const constantApi = constantApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getConstants: builder.query<ListResponse<Constant>, AxiosRequestConfig<Constant>>({
      query: (config) => ({ url: '/admin/constant', ...config }),
      providesTags: ['Constants'],
    }),
    createConstant: builder.mutation<Constant, Constant>({
      query: (constant) => ({ url: '/admin/constant', method: 'POST', data: constant }),
      invalidatesTags: ['Constants'],
    }),
    updateConstant: builder.mutation<Constant, Constant>({
      query: (constant) => ({ url: '/admin/constant/' + constant.id, method: 'PUT', data: constant }),
      invalidatesTags: ['Constants'],
    }),
    deleteConstant: builder.mutation<unknown, Constant>({
      query: (constant) => ({ url: '/admin/constant/' + constant.id, method: 'DELETE' }),
      invalidatesTags: ['Constants'],
    }),
  }),
});

const { useGetConstantsQuery, useCreateConstantMutation, useUpdateConstantMutation, useDeleteConstantMutation } =
  constantApi;

export { useGetConstantsQuery, useCreateConstantMutation, useUpdateConstantMutation, useDeleteConstantMutation };
