import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Locality } from '@/types';
import { useCreateLocalityMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateLocalityProps extends DialogProps {
  onClose(): void;
}

const CreateLocality: React.FC<CreateLocalityProps> = ({ onClose, ...props }) => {
  const { districtId } = useParams<{ districtId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [createLocality, createLocalityResponse] = useCreateLocalityMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Locality>({
    initialValues: {
      name: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(locality, { resetForm }) {
      try {
        await createLocality({ ...locality, parent_id: Number(districtId) }).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить населенный пункт</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createLocalityResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createLocalityResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createLocalityResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateLocality;
