import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureGroupDiscount, ListResponse } from '@/types';

const nomenclatureGroupDiscountApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureGroupDiscounts'],
});

const nomenclatureGroupDiscountApi = nomenclatureGroupDiscountApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureGroupDiscounts: builder.query<
      ListResponse<NomenclatureGroupDiscount>,
      AxiosRequestConfig<NomenclatureGroupDiscount>
    >({
      query: (config) => ({ url: '/admin/nomenclature-group-discount', ...config }),
      providesTags: ['NomenclatureGroupDiscounts'],
    }),
    createNomenclatureGroupDiscount: builder.mutation<NomenclatureGroupDiscount, NomenclatureGroupDiscount>({
      query: (nomenclatureGroupDiscount) => ({
        url: '/admin/nomenclature-group-discount',
        method: 'POST',
        data: nomenclatureGroupDiscount,
      }),
      invalidatesTags: ['NomenclatureGroupDiscounts'],
    }),
    updateNomenclatureGroupDiscount: builder.mutation<NomenclatureGroupDiscount, NomenclatureGroupDiscount>({
      query: (nomenclatureGroupDiscount) => ({
        url: '/admin/nomenclature-group-discount/' + nomenclatureGroupDiscount.id,
        method: 'PUT',
        data: nomenclatureGroupDiscount,
      }),
      invalidatesTags: ['NomenclatureGroupDiscounts'],
    }),
    deleteNomenclatureGroupDiscount: builder.mutation<unknown, NomenclatureGroupDiscount>({
      query: (nomenclatureGroupDiscount) => ({
        url: '/admin/nomenclature-group-discount/' + nomenclatureGroupDiscount.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['NomenclatureGroupDiscounts'],
    }),
  }),
});

const {
  useGetNomenclatureGroupDiscountsQuery,
  useCreateNomenclatureGroupDiscountMutation,
  useUpdateNomenclatureGroupDiscountMutation,
  useDeleteNomenclatureGroupDiscountMutation,
} = nomenclatureGroupDiscountApi;

export {
  useGetNomenclatureGroupDiscountsQuery,
  useCreateNomenclatureGroupDiscountMutation,
  useUpdateNomenclatureGroupDiscountMutation,
  useDeleteNomenclatureGroupDiscountMutation,
};
