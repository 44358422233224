import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Realization } from '@/types';
import { useGetRealizationsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchRealizations<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Realization> } & Omit<
  AutocompleteProps<Realization, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetRealizationsQueryProps = useAsyncSearch<Realization, Multiple, DisableClearable, FreeSolo>(
    useGetRealizationsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetRealizationsQueryProps} />;
}

export default SearchRealizations;
