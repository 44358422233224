import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CashboxOperation, ListResponse } from '@/types';

const cashboxOperationApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CashboxOperations'],
});

const cashboxOperationApi = cashboxOperationApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCashboxOperations: builder.query<ListResponse<CashboxOperation>, AxiosRequestConfig<CashboxOperation>>({
      query: (config) => ({ url: '/admin/cashbox-operation', ...config }),
      providesTags: ['CashboxOperations'],
    }),
    createCashboxOperation: builder.mutation<CashboxOperation, CashboxOperation>({
      query: ({ customer, organization, point, ...cashboxOperation }) => ({
        url: '/admin/cashbox-operation',
        method: 'POST',
        data: {
          ...cashboxOperation,
          customer_id: customer?.id,
          organization_id: organization?.id,
          point_id: point?.id,
        },
      }),
      invalidatesTags: ['CashboxOperations'],
    }),
    updateCashboxOperation: builder.mutation<CashboxOperation, CashboxOperation>({
      query: ({ customer, organization, point, id, ...cashboxOperation }) => ({
        url: '/admin/cashbox-operation/' + id,
        method: 'PUT',
        data: {
          ...cashboxOperation,
          customer_id: customer?.id,
          organization_id: organization?.id,
          point_id: point?.id,
        },
      }),
      invalidatesTags: ['CashboxOperations'],
    }),
    deleteCashboxOperation: builder.mutation<unknown, CashboxOperation>({
      query: (cashboxOperation) => ({ url: '/admin/cashbox-operation/' + cashboxOperation.id, method: 'DELETE' }),
      invalidatesTags: ['CashboxOperations'],
    }),
  }),
});

const {
  useGetCashboxOperationsQuery,
  useCreateCashboxOperationMutation,
  useUpdateCashboxOperationMutation,
  useDeleteCashboxOperationMutation,
} = cashboxOperationApi;

export {
  useGetCashboxOperationsQuery,
  useCreateCashboxOperationMutation,
  useUpdateCashboxOperationMutation,
  useDeleteCashboxOperationMutation,
};
