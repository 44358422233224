import React, { useRef, useMemo } from 'react';
import JoditEditor, { JoditProps } from 'jodit-react';

export interface RichEditorProps extends JoditProps {
  value: string;
  disabled: boolean;
  onChange(value: string): void;
}

const RichEditor: React.FC<RichEditorProps> = ({ value, disabled, onChange, ...props }) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 650,
      placeholder: 'Начните печатать сюда...',
      disabled,
    }),
    [disabled],
  );

  return (
    <JoditEditor
      ref={editor}
      value={value}
      // @ts-ignore
      config={config}
      // @ts-ignore
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
      {...props}
    />
  );
};

export default RichEditor;
