import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { useGetNomenclatureGroupsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureGroups<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  getOptions,
  prefetch,
  ...props
}: {
  config?: AxiosRequestConfig<NomenclatureGroup>;
  getOptions?(options: readonly NomenclatureGroup[]): void;
  prefetch?: boolean;
} & Omit<AutocompleteProps<NomenclatureGroup, Multiple, DisableClearable, FreeSolo>, 'options'>) {
  const { options, ...asyncGetNomenclatureGroupsQueryProps } = useAsyncSearch<
    NomenclatureGroup,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureGroupsQuery, 'id', value, config, getOptions, prefetch);

  return <Autocomplete options={options} {...props} {...asyncGetNomenclatureGroupsQueryProps} />;
}

export default SearchNomenclatureGroups;
