import { Fragment } from 'react';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports, ListResponse } from '@/types';
import { Pagination } from '@/components';
import theme from '@/theme';

export interface PaymentsToExpertsTableProps {
  list?: ListResponse<Reports.PaymentsToExperts>;
  page: number;
  onChangePage(page: number): void;
}

const PaymentsToExpertsTable: React.FC<PaymentsToExpertsTableProps> = ({ list, page, onChangePage }) => {
  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Эксперт / Документ</TableCell>

                <TableCell colSpan={2} align="center">
                  Сумма залогов
                </TableCell>

                <TableCell colSpan={2} align="center">
                  Сумма перезалогов
                </TableCell>

                <TableCell colSpan={2} align="center">
                  Сумма выкупов
                </TableCell>

                <TableCell rowSpan={2} align="right">
                  Итого
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">%</TableCell>
                <TableCell align="center">% от залогов</TableCell>

                <TableCell align="center">%</TableCell>
                <TableCell align="center">% от перезалогов</TableCell>

                <TableCell align="center">%</TableCell>
                <TableCell align="center">% от выкупов</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((paymentsToExperts, index) => {
                const { employee, pledges, re_pledges, buyouts, total } = paymentsToExperts;

                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell rowSpan={2}>
                        <b>{employee}</b>
                      </TableCell>

                      <TableCell align="center" rowSpan={2}>
                        {pledges.percent ?? '—'}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        {pledges.percent_sum ?? '—'}
                      </TableCell>

                      <TableCell align="center" rowSpan={2}>
                        {re_pledges.percent ?? '—'}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        {re_pledges.percent_sum ?? '—'}
                      </TableCell>

                      <TableCell align="center" rowSpan={2}>
                        {buyouts.percent ?? '—'}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        {buyouts.percent_sum ?? '—'}
                      </TableCell>
                    </TableRow>

                    <TableRow key={index}>
                      <TableCell align="right" colSpan={8}>
                        {total}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>
    </>
  );
};

export default PaymentsToExpertsTable;
