export enum CustomerStatuses {
  VIP = 'VIP',
  COMMON = 'Обычный',
}

export interface CustomerStatus {
  id?: number;
  name: string;
  is_simple: boolean;
  is_name_editable: boolean;
  independent_from_depth: boolean;
  independent_from_interval: boolean;
}
