import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Point } from '@/types';
import { useGetPointsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchPoints<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Point> } & Omit<
  AutocompleteProps<Point, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetPointsQueryProps = useAsyncSearch<Point, Multiple, DisableClearable, FreeSolo>(
    useGetPointsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetPointsQueryProps} />;
}

export default SearchPoints;
