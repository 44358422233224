import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Shift, ShiftTypes } from '@/types';
import { useAuth, useShifts } from '@/hooks';
import { messages } from '@/utils/helpers';
import { Error, Loader } from '@/components';

export interface CloseShiftProps extends DialogProps {
  onClose(): void;
}

const CloseShift: React.FC<CloseShiftProps> = ({ onClose, ...props }) => {
  const { user } = useAuth();
  const { shift, closeShiftLoading, isShiftLoading, isError, handleCloseShift } = useShifts();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Shift>({
    initialValues: shift
      ? { ...shift, passing_employee: user?.employee!, receiving_employee: null }
      : {
          cash_amount: 0,
          keys_count: 0,
          organization: null,
          pledges_count: 0,
          point: null,
          type: ShiftTypes.CLOSE,
          passing_employee: null,
          receiving_employee: null,
        },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      cash_amount: yup.number().required(messages.form.REQUIRED),
      keys_count: yup.number().required(messages.form.REQUIRED),
      organization: yup.object().nullable().required(messages.form.REQUIRED),
      pledges_count: yup.number().required(messages.form.REQUIRED),
      point: yup.object().nullable().required(messages.form.REQUIRED),
      passing_employee: yup.object().nullable().required(messages.form.REQUIRED),
      receiving_employee: yup.object().nullable(),
    }),
    onSubmit: handleCloseShift,
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Закрыть смену</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          {isShiftLoading ? (
            <Loader />
          ) : isError ? (
            <Error
              text="Произошла ошибка с загрузкой данных по смене! Пожалуйста, попробуйте позже"
              variant="outlined"
            />
          ) : (
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="organization"
                  value={values.organization ? values.organization?.full_name : ''}
                  label="Организация"
                  fullWidth
                  disabled={isShiftLoading || closeShiftLoading}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="point"
                  value={values.point ? values.point?.name : ''}
                  label="Точка"
                  fullWidth
                  disabled={isShiftLoading || closeShiftLoading}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="passing_employee"
                  value={
                    values.passing_employee
                      ? `${values.passing_employee?.last_name} ${values.passing_employee?.name} ${values.passing_employee?.father_name}`
                      : ''
                  }
                  label="Закрывающий смену"
                  fullWidth
                  disabled={isShiftLoading || closeShiftLoading}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  name="pledges_count"
                  label="Кол-во активных залогов"
                  placeholder="Введите значение"
                  value={values.pledges_count}
                  onChange={handleChange}
                  disabled={isShiftLoading || closeShiftLoading}
                  error={touched.pledges_count && Boolean(errors.pledges_count)}
                  helperText={touched.pledges_count ? errors.pledges_count : ''}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  name="cash_amount"
                  label="Касса на конец смены"
                  placeholder="Введите значение"
                  value={values.cash_amount}
                  onChange={handleChange}
                  disabled={isShiftLoading || closeShiftLoading}
                  error={touched.cash_amount && Boolean(errors.cash_amount)}
                  helperText={touched.cash_amount ? errors.cash_amount : ''}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  name="keys_count"
                  label="Кол-во ключей"
                  placeholder="Введите значение"
                  value={values.keys_count}
                  onChange={handleChange}
                  disabled={isShiftLoading || closeShiftLoading}
                  error={touched.keys_count && Boolean(errors.keys_count)}
                  helperText={touched.keys_count ? errors.keys_count : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={isShiftLoading || closeShiftLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={isShiftLoading || closeShiftLoading}>
            Провести
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CloseShift;
