import { lighten, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports } from '@/types';
import theme from '@/theme';

export interface CashboxOperationsSummaryTableProps {
  list?: Reports.CashboxOperationsSummary[];
}

const CashboxOperationsSummaryTable: React.FC<CashboxOperationsSummaryTableProps> = ({ list }) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Точка</TableCell>
            <TableCell rowSpan={2}>Нач. остаток</TableCell>

            <TableCell colSpan={2} align="center">
              Приход
            </TableCell>

            <TableCell colSpan={2} align="center">
              Расход
            </TableCell>

            <TableCell rowSpan={2}>Кон. остаток</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Общий</TableCell>
            <TableCell>в т.ч. внеломбардный</TableCell>

            <TableCell>Общий</TableCell>
            <TableCell>в т.ч. внеломбардный</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {list?.map((summary, index) => {
            const { arrival, consumption, end_balance, point, start_balance } = summary;

            return (
              <TableRow sx={{ bgcolor: lighten(theme.palette.primary.light, 0.5) }} key={index}>
                <TableCell>{point}</TableCell>
                <TableCell>{Math.round(start_balance || 0)}</TableCell>

                <TableCell>{Math.round(arrival.common || 0)}</TableCell>
                <TableCell>{Math.round(arrival.outer || 0)}</TableCell>

                <TableCell>{Math.round(consumption.common || 0)}</TableCell>
                <TableCell>{Math.round(consumption.outer || 0)}</TableCell>

                <TableCell>{Math.round(end_balance || 0)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CashboxOperationsSummaryTable;
