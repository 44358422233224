import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CustomerStatus, ListResponse } from '@/types';

const customerStatusApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CustomerStatuses'],
});

const customerStatusApi = customerStatusApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerStatuses: builder.query<ListResponse<CustomerStatus>, AxiosRequestConfig<CustomerStatus>>({
      query: (config) => ({ url: '/admin/customer-status', ...config }),
      providesTags: ['CustomerStatuses'],
    }),
    createCustomerStatus: builder.mutation<CustomerStatus, CustomerStatus>({
      query: (customerStatus) => ({ url: '/admin/customer-status', method: 'POST', data: customerStatus }),
      invalidatesTags: ['CustomerStatuses'],
    }),
    updateCustomerStatus: builder.mutation<CustomerStatus, CustomerStatus>({
      query: (customerStatus) => ({
        url: '/admin/customer-status/' + customerStatus.id,
        method: 'PUT',
        data: customerStatus,
      }),
      invalidatesTags: ['CustomerStatuses'],
    }),
    deleteCustomerStatus: builder.mutation<unknown, CustomerStatus>({
      query: (customerStatus) => ({ url: '/admin/customer-status/' + customerStatus.id, method: 'DELETE' }),
      invalidatesTags: ['CustomerStatuses'],
    }),
  }),
});

const {
  useGetCustomerStatusesQuery,
  useCreateCustomerStatusMutation,
  useUpdateCustomerStatusMutation,
  useDeleteCustomerStatusMutation,
} = customerStatusApi;

export {
  useGetCustomerStatusesQuery,
  useCreateCustomerStatusMutation,
  useUpdateCustomerStatusMutation,
  useDeleteCustomerStatusMutation,
};
