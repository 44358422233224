import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { NomenclatureGroupChar } from '@/types';
import { useDeleteNomenclatureGroupCharMutation } from '@/services';

export interface DeleteNomenclatureGroupCharProps extends DialogProps {
  nomenclatureGroupChar: NomenclatureGroupChar;
  onClose(): void;
}

const DeleteNomenclatureGroupChar: React.FC<DeleteNomenclatureGroupCharProps> = ({
  nomenclatureGroupChar,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteNomenclatureGroupChar, deleteNomenclatureGroupCharResponse] = useDeleteNomenclatureGroupCharMutation();

  async function handleDeleteNomenclatureGroupChar() {
    try {
      await deleteNomenclatureGroupChar(nomenclatureGroupChar).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{nomenclatureGroupChar?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{nomenclatureGroupChar?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteNomenclatureGroupCharResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteNomenclatureGroupCharResponse.isLoading}
          onClick={handleDeleteNomenclatureGroupChar}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNomenclatureGroupChar;
