export enum PeriodTypes {
  IRRELEVANT = 1,
  DAYS_1_30 = 2,
  DAYS_31 = 3,
}

export const PERIOD_TYPES = [
  { label: 'Не имеет значения', value: PeriodTypes.IRRELEVANT },
  { label: 'От 1 до 30 дней', value: PeriodTypes.DAYS_1_30 },
  { label: 'От 31 дня', value: PeriodTypes.DAYS_31 },
];
