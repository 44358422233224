import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffac33',
      light: '#ffe45b',
      dark: '#f6ab2f',
      contrastText: '#fff',
    },

    secondary: {
      main: '#8a8a8a',
      light: '#a1a1a1',
      dark: '#606060',
      contrastText: '#fff',
    },

    background: {
      default: '#f5f5fa',
    },

    text: {
      primary: '#353535',
      secondary: '#606060',
    },

    error: {
      main: '#eb5757',
    },
  },

  typography: {
    fontFamily: 'Roboto',

    h1: {
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '40px',
    },

    h2: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '32px',
    },

    h3: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '31.5px',
    },

    h4: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '27px',
    },

    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },

    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '28px',
    },

    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '21px',
    },

    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },

    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },

    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '20px',
    },

    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '30px',
    },

    button: {
      fontSize: 16,
      fontWeight: 700,
    },
  },

  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: { textTransform: 'unset' },
        sizeLarge: { minWidth: 210, padding: '10.875px 25px' },
        sizeMedium: { minWidth: 150, padding: '7.5px 10px' },
        sizeSmall: { padding: '2.5px 7.5px' },
      },
    },

    MuiDialog: {
      defaultProps: { scroll: 'body' },
      styleOverrides: {
        root: {
          '& > .MuiBackdrop-root': { backgroundColor: 'rgba(33, 33, 33, 0.3)' },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: { textAlign: 'center', color: 'secondary.dark', variant: 'h5' },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: { padding: '34px 24px' },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: { padding: 16, backgroundColor: '#f5f5fa' },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          caption: { captionSide: 'top', padding: '12px 16px', borderBottom: '1px solid rgba(224, 224, 224, 1)' },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        stickyHeader: { backgroundColor: '#fff' },
        head: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: 16,
          fontWeight: 600,
          color: '#8a8a8a',
          padding: '12px 18px',
        },
        body: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: 16,
          fontWeight: 300,
          padding: '10px 18px',
        },
      },
    },

    MuiTextField: {
      defaultProps: { color: 'secondary' },
    },
  },

  breakpoints: {
    values: { xs: 0, sm: 630, md: 992, lg: 1536, xl: 1920 },
  },

  shape: {
    borderRadius: 4,
  },
});

export default theme;
