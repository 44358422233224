import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Cashbox, ListResponse } from '@/types';

const cashboxApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Cashdesks'],
});

const cashboxApi = cashboxApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCashdesks: builder.query<ListResponse<Cashbox>, AxiosRequestConfig<Cashbox>>({
      query: (config) => ({ url: '/admin/cashbox', ...config }),
      providesTags: ['Cashdesks'],
    }),
    createCashbox: builder.mutation<Cashbox, Cashbox>({
      query: (cashbox) => ({ url: '/admin/cashbox', method: 'POST', data: cashbox }),
      invalidatesTags: ['Cashdesks'],
    }),
    updateCashbox: builder.mutation<Cashbox, Cashbox>({
      query: (cashbox) => ({ url: '/admin/cashbox/' + cashbox.id, method: 'PUT', data: cashbox }),
      invalidatesTags: ['Cashdesks'],
    }),
    deleteCashbox: builder.mutation<unknown, Cashbox>({
      query: (cashbox) => ({ url: '/admin/cashbox/' + cashbox.id, method: 'DELETE' }),
      invalidatesTags: ['Cashdesks'],
    }),
  }),
});

const { useGetCashdesksQuery, useCreateCashboxMutation, useUpdateCashboxMutation, useDeleteCashboxMutation } =
  cashboxApi;

export { useGetCashdesksQuery, useCreateCashboxMutation, useUpdateCashboxMutation, useDeleteCashboxMutation };
