import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { FurCoatCategory, ListResponse } from '@/types';

const furCoatCategoryApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['FurCoatCategories'],
});

const furCoatCategoryApi = furCoatCategoryApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getFurCoatCategories: builder.query<ListResponse<FurCoatCategory>, AxiosRequestConfig<FurCoatCategory>>({
      query: (config) => ({ url: '/admin/fur-coat-price-category', ...config }),
      providesTags: ['FurCoatCategories'],
    }),
    createFurCoatCategory: builder.mutation<FurCoatCategory, FurCoatCategory>({
      query: (furCoatCategory) => ({ url: '/admin/fur-coat-price-category', method: 'POST', data: furCoatCategory }),
      invalidatesTags: ['FurCoatCategories'],
    }),
    updateFurCoatCategory: builder.mutation<FurCoatCategory, FurCoatCategory>({
      query: (furCoatCategory) => ({
        url: '/admin/fur-coat-price-category/' + furCoatCategory.id,
        method: 'PUT',
        data: furCoatCategory,
      }),
      invalidatesTags: ['FurCoatCategories'],
    }),
    deleteFurCoatCategory: builder.mutation<unknown, FurCoatCategory>({
      query: (furCoatCategory) => ({ url: '/admin/fur-coat-price-category/' + furCoatCategory.id, method: 'DELETE' }),
      invalidatesTags: ['FurCoatCategories'],
    }),
  }),
});

const {
  useGetFurCoatCategoriesQuery,
  useCreateFurCoatCategoryMutation,
  useUpdateFurCoatCategoryMutation,
  useDeleteFurCoatCategoryMutation,
} = furCoatCategoryApi;

export {
  useGetFurCoatCategoriesQuery,
  useCreateFurCoatCategoryMutation,
  useUpdateFurCoatCategoryMutation,
  useDeleteFurCoatCategoryMutation,
};
