import { Form, Formik, FormikHelpers, getIn } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { CollateralItem, CollateralItemCharTypes, Customer, NomenclatureGroup, NomenclatureGroupType } from '@/types';
import { useGetNomenclatureGroupCharsQuery } from '@/services';
import { NomenclatureGroups } from '@/utils/enums';
import { messages } from '@/utils/helpers';
import { SearchNomenclatures } from '@/pages/Nomenclatures/components';

import { NOMENCLATURE_GROUP_PROPERTIES } from '..';
import {
  FUR_COAT,
  FUR_COAT_SCHEMA,
  JEVELRY,
  JEWELRY_SCHEMA,
  OTHER,
  OTHER_SCHEMA,
  TECHNIQUE,
  TECHNIQUE_SCHEMA,
} from '../schemas';

export interface AddCollateralItemProps extends Omit<DialogProps, 'onSubmit'> {
  customer: Customer;
  collateralItem: CollateralItem | null;
  nomenclatureGroup: NomenclatureGroup | null;
  disabled: boolean;
  onSubmit(collateralItem: CollateralItem): void;
  onClose(): void;
}

const AddCollateralItem: React.FC<AddCollateralItemProps> = ({
  customer,
  collateralItem,
  nomenclatureGroup,
  disabled,
  onSubmit,
  onClose,
  ...props
}) => {
  const { data: nomenclatureGroupChars } = useGetNomenclatureGroupCharsQuery(
    { params: { nomenclature_group_id: nomenclatureGroup?.id } },
    { skip: !nomenclatureGroup },
  );

  const PROPERTIES = new Map<NomenclatureGroups, NomenclatureGroupType>([
    [NomenclatureGroups.FUR_COATS, FUR_COAT],
    [NomenclatureGroups.JEVELRY, JEVELRY],
    [NomenclatureGroups.TECHNIQUE, TECHNIQUE],
    [NomenclatureGroups.OTHER, OTHER],
  ]).get(nomenclatureGroup?.name!)!;

  const PROPERTIES_VALIADTION_SCHEMAS = new Map<NomenclatureGroups, yup.ObjectSchema<{}>>([
    [NomenclatureGroups.FUR_COATS, FUR_COAT_SCHEMA],
    [NomenclatureGroups.JEVELRY, JEWELRY_SCHEMA],
    [NomenclatureGroups.TECHNIQUE, TECHNIQUE_SCHEMA],
    [NomenclatureGroups.OTHER, OTHER_SCHEMA],
  ]).get(nomenclatureGroup?.name!)!;

  const initialValues: CollateralItem<typeof PROPERTIES> = {
    description: '',
    nomenclature: null,
    nomenclature_group: nomenclatureGroup,
    primary_price: 0,
    max_price: 0,
    properties: PROPERTIES ?? {},
    vendor_code: '',
    nomenclature_group_chars: nomenclatureGroupChars?.data ?? [],
    nomenclature_chars: [],
  };

  const validationSchema = yup.object().shape({
    description: yup.string().max(255, messages.form.LARGE_TEXT()),
    name: yup.string().required(messages.form.REQUIRED),
    nomenclature: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    primary_price: yup
      .number()
      .min(1, messages.form.REQUIRED)
      .when('nomenclature_group.name', {
        is: (value: NomenclatureGroups) => [NomenclatureGroups.JEVELRY, NomenclatureGroups.FUR_COATS].includes(value),
        then: yup.number().max(yup.ref('max_price'), messages.form.LESS_OR_EQUAL_THAN('Начальная стоимость')),
      })
      .required(messages.form.REQUIRED),
    properties: PROPERTIES_VALIADTION_SCHEMAS,
    vendor_code: yup.string(),
  });

  function handleSubmit(
    collateralItem: CollateralItem<typeof PROPERTIES>,
    { resetForm }: FormikHelpers<CollateralItem<typeof PROPERTIES>>,
  ) {
    onSubmit(collateralItem);
    resetForm();
    onClose();
  }

  const NomenclatureGroupProperties = NOMENCLATURE_GROUP_PROPERTIES.find(({ name }) => name === nomenclatureGroup?.name)
    ?.component!;

  return (
    <Dialog maxWidth="lg" fullWidth {...props}>
      <DialogTitle fontWeight="bold">{collateralItem ? 'Обновить' : 'Добавить'} залоговую вещь</DialogTitle>

      <Formik
        initialValues={(collateralItem as CollateralItem<typeof PROPERTIES>) ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setValues, setFieldValue, handleChange }) => {
          return (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item lg={9} md={8} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6} xs={12}>
                        <SearchNomenclatures
                          value={values.nomenclature}
                          config={{ params: { nomenclature_group_id: nomenclatureGroup?.id } }}
                          onChange={(event, nomenclature) =>
                            setValues((values) => ({
                              ...values,
                              name: nomenclature?.name ?? '',
                              nomenclature,
                              nomenclature_chars: nomenclature?.chars ?? [],
                            }))
                          }
                          disabled={disabled}
                          getOptionLabel={(option) => option.name}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              name="nomenclature"
                              label="Номенклатура"
                              placeholder="Выберите из списка"
                              error={Boolean(touched.nomenclature && errors.nomenclature)}
                              helperText={touched.nomenclature && errors.nomenclature}
                            />
                          )}
                        />
                      </Grid>

                      {NomenclatureGroupProperties && (
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <NomenclatureGroupProperties customer={customer} />
                          </Grid>
                        </Grid>
                      )}

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          name="primary_price"
                          type="number"
                          label="Начальная стоимость"
                          placeholder="Введите значение"
                          value={values.primary_price}
                          onChange={handleChange}
                          disabled={disabled}
                          error={touched.primary_price && Boolean(errors.primary_price)}
                          helperText={touched.primary_price ? errors.primary_price : ''}
                          fullWidth
                          InputProps={{
                            readOnly: !nomenclatureGroup?.is_price_editable,
                            inputProps: { min: 1, step: 'any' },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          multiline
                          name="description"
                          label="Примечание"
                          placeholder="Введите значение"
                          value={values.description}
                          onChange={handleChange}
                          disabled={disabled}
                          error={touched.description && Boolean(errors.description)}
                          helperText={touched.description ? errors.description : ''}
                          fullWidth
                          minRows={2}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {Boolean(values.nomenclature_group_chars.length || values.nomenclature_chars.length) && (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <b>Характеристики</b>
                          </Typography>
                        </Grid>
                      )}

                      {values.nomenclature_group_chars.map((collateralItemChar, index) => {
                        const { name, value, type, required, min_value } = collateralItemChar,
                          field = `nomenclature_group_chars[${index}]`;

                        return (
                          <Grid item lg={2} md={3} xs={6} key={index}>
                            {type === CollateralItemCharTypes.STRING && (
                              <TextField
                                name={field}
                                label={name}
                                placeholder="Введите значение"
                                value={value}
                                onChange={(event) =>
                                  setFieldValue(field, { ...collateralItemChar, value: event.target.value })
                                }
                                disabled={disabled}
                                error={getIn(touched, field) && Boolean(getIn(errors, field))}
                                helperText={getIn(touched, field) ? getIn(errors, field) : ''}
                                fullWidth
                                required={required}
                                size="small"
                              />
                            )}

                            {type === CollateralItemCharTypes.NUMERIC && (
                              <TextField
                                name={field}
                                label={name}
                                placeholder="Введите значение"
                                value={value}
                                onChange={(event) =>
                                  setFieldValue(field, { ...collateralItemChar, value: +event.target.value })
                                }
                                disabled={disabled}
                                error={getIn(touched, field) && Boolean(getIn(errors, field))}
                                helperText={getIn(touched, field) ? getIn(errors, field) : ''}
                                InputProps={{ inputProps: { min: min_value, type: 'number' } }}
                                fullWidth
                                required={required}
                                size="small"
                              />
                            )}

                            {type === CollateralItemCharTypes.BOOLEAN && (
                              <FormControlLabel
                                label={name}
                                control={
                                  <Checkbox
                                    name={field}
                                    value={value}
                                    checked={Boolean(value)}
                                    disabled={disabled}
                                    required={required}
                                    onChange={(event, checked) =>
                                      setFieldValue(field, { ...collateralItemChar, value: checked })
                                    }
                                  />
                                }
                              />
                            )}
                          </Grid>
                        );
                      })}

                      {values.nomenclature_chars.map((collateralItemChar, index) => {
                        const { name, value, type, min_value, required } = collateralItemChar,
                          field = `nomenclature_chars[${index}]`;

                        return (
                          <Grid item lg={2} md={3} xs={6} key={index}>
                            {type === CollateralItemCharTypes.STRING && (
                              <TextField
                                name={field}
                                label={name}
                                placeholder="Введите значение"
                                value={value}
                                onChange={(event) =>
                                  setFieldValue(field, { ...collateralItemChar, value: event.target.value })
                                }
                                disabled={disabled}
                                error={getIn(touched, field) && Boolean(getIn(errors, field))}
                                helperText={getIn(touched, field) ? getIn(errors, field) : ''}
                                fullWidth
                                required={required}
                                size="small"
                              />
                            )}

                            {type === CollateralItemCharTypes.NUMERIC && (
                              <TextField
                                name={field}
                                label={name}
                                placeholder="Введите значение"
                                value={value}
                                onChange={(event) =>
                                  setFieldValue(field, { ...collateralItemChar, value: +event.target.value })
                                }
                                disabled={disabled}
                                error={getIn(touched, field) && Boolean(getIn(errors, field))}
                                helperText={getIn(touched, field) ? getIn(errors, field) : ''}
                                InputProps={{ inputProps: { min: min_value, type: 'number' } }}
                                fullWidth
                                required={required}
                                size="small"
                              />
                            )}

                            {type === CollateralItemCharTypes.BOOLEAN && (
                              <FormControlLabel
                                label={name}
                                control={
                                  <Checkbox
                                    name={field}
                                    value={value}
                                    checked={Boolean(value)}
                                    onChange={(event, checked) =>
                                      setFieldValue(field, { ...collateralItemChar, value: checked })
                                    }
                                  />
                                }
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button type="button" color="secondary" variant="outlined" disabled={disabled} onClick={onClose}>
                  Закрыть
                </Button>

                <Button type="submit" variant="contained" disabled={disabled}>
                  {collateralItem ? 'Обновить' : 'Добавить'}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddCollateralItem;
