import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { FurCoatCategory } from '@/types';
import { useGetFurCoatCategoriesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchFurCoatCategories<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<FurCoatCategory> } & Omit<
  AutocompleteProps<FurCoatCategory, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetFurCoatCategoriesQueryProps = useAsyncSearch<FurCoatCategory, Multiple, DisableClearable, FreeSolo>(
    useGetFurCoatCategoriesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetFurCoatCategoriesQueryProps} />;
}

export default SearchFurCoatCategories;
