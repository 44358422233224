import { useState } from 'react';
import {
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Organization, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteOrganization, UpdateOrganization } from '../components';

export interface OrganizationListProps {
  list?: ListResponse<Organization>;
  page: number;
  onChangePage(page: number): void;
}

const OrganizationsTable: React.FC<OrganizationListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);

  function openUpdateModal(organization: Organization) {
    setCurrentOrganization(organization);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(organization: Organization) {
    setCurrentOrganization(organization);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Полное название</TableCell>
                <TableCell />
                <TableCell>Юридический адрес</TableCell>
                <TableCell>Физический адрес</TableCell>
                <TableCell>Директор</TableCell>
                <TableCell>Аббревиатура</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>БИН</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((organization) => {
                const {
                  bin,
                  director_name,
                  email,
                  full_name,
                  id,
                  phone,
                  short_name,

                  actual_region,
                  actual_area,
                  actual_district,
                  actual_locality,
                  actual_street,
                  actual_house_number,
                  actual_flat_number,

                  official_region,
                  official_area,
                  official_district,
                  official_locality,
                  official_street,
                  official_house_number,
                  official_flat_number,
                } = organization;

                const actual_address = [
                  actual_region?.name,
                  actual_area?.name,
                  actual_district?.name,
                  actual_locality?.name,
                  actual_street?.name,
                  actual_house_number ? 'Дом ' + actual_house_number : null,
                  actual_flat_number ? 'Квартира ' + actual_flat_number : null,
                ]
                  .filter(Boolean)
                  .join(', ');

                const official_address = [
                  official_region?.name,
                  official_area?.name,
                  official_district?.name,
                  official_locality?.name,
                  official_street?.name,
                  official_house_number ? 'Дом ' + official_house_number : null,
                  official_flat_number ? 'Квартира ' + official_flat_number : null,
                ]
                  .filter(Boolean)
                  .join(', ');

                return (
                  <TableRow key={id}>
                    <TableCell>{full_name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(organization)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(organization)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{official_address}</TableCell>
                    <TableCell>{actual_address}</TableCell>
                    <TableCell>{director_name}</TableCell>
                    <TableCell>{short_name}</TableCell>
                    <TableCell>
                      <Link href={`tel:${phone}`} color="secondary">
                        {phone}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link href={`mailto:${email}`} color="secondary">
                        {email}
                      </Link>
                    </TableCell>
                    <TableCell>{bin}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateOrganization
        organization={currentOrganization!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteOrganization
        organization={currentOrganization!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default OrganizationsTable;
