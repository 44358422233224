import { Button, Dialog, DialogActions, DialogContent, DrawerProps } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface PreviewDocumentProps extends Omit<DrawerProps, 'slots'> {
  documentUrl: string | null;
  slots?: {
    trigger?(state: [boolean, Dispatch<SetStateAction<boolean>>]): React.ReactNode;
  };
}

const PreviewDocumentDialog = ({ documentUrl, slots, onClose, open = false, ...props }: PreviewDocumentProps) => {
  const [opened, setOpened] = useState(open);

  function handleCloseDialog(event: React.MouseEvent<HTMLButtonElement>) {
    setOpened(false);
    onClose?.(event, 'backdropClick');
  }

  useEffect(() => {
    setOpened(open);
  }, [open]);

  return (
    <>
      {slots?.trigger && slots.trigger([opened, setOpened])}

      <Dialog {...props} fullScreen open={opened} onClose={handleCloseDialog}>
        <DialogContent sx={{ height: 'calc(100% - 75px)', p: 0 }}>
          {documentUrl && <iframe title="Документ" src={documentUrl} width="100%" height="100%" />}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleCloseDialog}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewDocumentDialog;
