import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Point } from '@/types';
import { useUpdatePointMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchAreas } from '@/pages/Areas/components';
import { SearchCashdesks } from '@/pages/Cashdesks/components';
import { SearchDistricts } from '@/pages/Districts/components';
import { SearchEmployees } from '@/pages/Employees/components';
import { SearchLocalities } from '@/pages/Localities/components';
import { SearchOrganizations } from '@/pages/Organizations/components';
import { SearchRegions } from '@/pages/Regions/components';
import { SearchStreets } from '@/pages/Streets/components';

export interface UpdatePointProps extends DialogProps {
  point: Point;
  onClose(): void;
}

const UpdatePoint: React.FC<UpdatePointProps> = ({ point, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updatePoint, updatePointResponse] = useUpdatePointMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange, setValues, resetForm } = useFormik<Point>(
    {
      initialValues: point ?? {
        name: '',
        prefix: '',
        region: null,
        area: null,
        district: null,
        locality: null,
        street: null,
        house_number: '',
        flat_number: '',
        organization: null,
        name_translit: '',
        additional_information: '',
        phone_numbers: '',
        address_for_sms: '',
        schedule: '',
        cashbox: null,
        lead_employee: null,
        responsible_employee: null,
        video_monitoring_url: '',
      },
      enableReinitialize: true,
      validationSchema: yup.object().shape({
        name: yup.string().required(messages.form.REQUIRED),
        prefix: yup.string().required(messages.form.REQUIRED),
        region: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        area: yup.object().nullable(),
        district: yup.object().nullable(),
        locality: yup.object().nullable(),
        street: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        house_number: yup.string().required(messages.form.REQUIRED),
        flat_number: yup.string(),
        organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        name_translit: yup.string(),
        additional_information: yup.string().max(255, messages.form.LARGE_TEXT()),
        phone_numbers: yup.string().required(messages.form.REQUIRED),
        address_for_sms: yup.string(),
        schedule: yup.string(),
        cashbox: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        lead_employee: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        responsible_employee: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        video_monitoring_url: yup.string(),
      }),
      async onSubmit(point) {
        try {
          await updatePoint(point).unwrap();

          handleClose();
        } catch (error) {
          enqueueSnackbar(error.data, { variant: 'error' });
        }
      },
    },
  );

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать точку</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchOrganizations
                value={values.organization}
                onChange={(event, option) => setFieldValue('organization', option)}
                disabled={updatePointResponse.isLoading}
                getOptionLabel={(option) => option.full_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="organization"
                    label="Организация"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="prefix"
                label="Префикс документов"
                placeholder="Введите значение"
                value={values.prefix}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.prefix && Boolean(errors.prefix)}
                helperText={touched.prefix ? errors.prefix : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={7} md={9} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchRegions
                value={values.region}
                onChange={(event, option) =>
                  setValues((values) => ({
                    ...values,
                    region: option,
                    area: null,
                    district: null,
                    locality: null,
                    street: null,
                  }))
                }
                disabled={updatePointResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="region"
                    label="Область"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.region && errors.region)}
                    helperText={touched.region && errors.region}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchAreas
                value={values.area}
                config={{ params: { parent_id: values.region?.id } }}
                onChange={(event, option) =>
                  setValues((values) => ({ ...values, area: option, district: null, locality: null, street: null }))
                }
                disabled={updatePointResponse.isLoading || Boolean(!values.region)}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="area"
                    label="Район"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.area && errors.area)}
                    helperText={touched.area && errors.area}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchDistricts
                value={values.district}
                config={{ params: { parent_id: values.area?.id } }}
                onChange={(event, option) =>
                  setValues((values) => ({ ...values, district: option, locality: null, street: null }))
                }
                disabled={updatePointResponse.isLoading || Boolean(!values.area)}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="district"
                    label="Округ"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.district && errors.district)}
                    helperText={touched.district && errors.district}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchLocalities
                value={values.locality}
                config={{ params: { parent_id: values.district?.id } }}
                onChange={(event, option) => setFieldValue('locality', option)}
                disabled={updatePointResponse.isLoading || Boolean(!values.district)}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="locality"
                    label="Населенный пункт"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.locality && errors.locality)}
                    helperText={touched.locality && errors.locality}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchStreets
                freeSolo
                value={values.street}
                config={{
                  params: {
                    district_id: values.district?.id,
                    locality_id: values.locality?.id,
                    parent_id: values.region?.id,
                  },
                }}
                onChange={(event, street) =>
                  setFieldValue(
                    'street',
                    typeof street === 'string'
                      ? { name: street }
                      : street && street.name
                      ? { name: street.name }
                      : street,
                  )
                }
                disabled={updatePointResponse.isLoading || Boolean(!values.region)}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="street"
                    label="Улица"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.street && errors.street)}
                    helperText={touched.street && errors.street}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="house_number"
                label="Дом"
                placeholder="Введите значение"
                value={values.house_number}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.house_number && Boolean(errors.house_number)}
                helperText={touched.house_number ? errors.house_number : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="flat_number"
                label="Квартира"
                placeholder="Введите значение"
                value={values.flat_number}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.flat_number && Boolean(errors.flat_number)}
                helperText={touched.flat_number ? errors.flat_number : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name_translit"
                label="Код точки траслит."
                placeholder="Введите значение"
                value={values.name_translit}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.name_translit && Boolean(errors.name_translit)}
                helperText={touched.name_translit ? errors.name_translit : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCashdesks
                value={values.cashbox}
                onChange={(event, option) => setFieldValue('cashbox', option)}
                disabled={updatePointResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="cashbox"
                    label="Касса предприятия"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.cashbox && errors.cashbox)}
                    helperText={touched.cashbox && errors.cashbox}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="phone_numbers"
                label="Номера телефонов"
                placeholder="Введите значение"
                value={values.phone_numbers}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.phone_numbers && Boolean(errors.phone_numbers)}
                helperText={touched.phone_numbers ? errors.phone_numbers : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="address_for_sms"
                label="СМС-адрес"
                placeholder="Введите значение"
                value={values.address_for_sms}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.address_for_sms && Boolean(errors.address_for_sms)}
                helperText={touched.address_for_sms ? errors.address_for_sms : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="schedule"
                label="Расписание"
                placeholder="Введите значение"
                value={values.schedule}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.schedule && Boolean(errors.schedule)}
                helperText={touched.schedule ? errors.schedule : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="video_monitoring_url"
                label="URL видеокамеры для подсчета посетителей"
                placeholder="Введите значение"
                value={values.video_monitoring_url}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.video_monitoring_url && Boolean(errors.video_monitoring_url)}
                helperText={touched.video_monitoring_url ? errors.video_monitoring_url : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchEmployees
                value={values.lead_employee}
                onChange={(event, option) => setFieldValue('lead_employee', option)}
                disabled={updatePointResponse.isLoading}
                getOptionLabel={({ name, last_name, father_name }) => `${last_name} ${name} ${father_name} `}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="lead_employee"
                    label="Руководитель филиала"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.lead_employee && errors.lead_employee)}
                    helperText={touched.lead_employee && errors.lead_employee}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchEmployees
                value={values.responsible_employee}
                onChange={(event, option) => setFieldValue('responsible_employee', option)}
                disabled={updatePointResponse.isLoading}
                getOptionLabel={({ name, last_name, father_name }) => `${last_name} ${name} ${father_name} `}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="responsible_employee"
                    label="Ответственный"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.responsible_employee && errors.responsible_employee)}
                    helperText={touched.responsible_employee && errors.responsible_employee}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                minRows={2}
                name="additional_information"
                label="Дополнительная информация"
                placeholder="Введите значение"
                value={values.additional_information}
                onChange={handleChange}
                disabled={updatePointResponse.isLoading}
                error={touched.additional_information && Boolean(errors.additional_information)}
                helperText={touched.additional_information ? errors.additional_information : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updatePointResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updatePointResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdatePoint;
