import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { Organization } from '@/types';
import { useUpdateOrganizationMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchAreas } from '@/pages/Areas/components';
import { SearchDistricts } from '@/pages/Districts/components';
import { SearchLocalities } from '@/pages/Localities/components';
import { SearchRegions } from '@/pages/Regions/components';
import { SearchStreets } from '@/pages/Streets/components';

export interface UpdateOrganizationProps extends DialogProps {
  organization: Organization;
  onClose(): void;
}

const UpdateOrganization: React.FC<UpdateOrganizationProps> = ({ organization, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateOrganization, updateOrganizationResponse] = useUpdateOrganizationMutation();

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, setValues, resetForm } =
    useFormik<Organization>({
      initialValues: organization ?? {
        actual_region: null,
        actual_area: null,
        actual_district: null,
        actual_locality: null,
        actual_house_number: '',
        actual_flat_number: '',

        bin: '',
        director_name: '',
        email: '',
        phone: '',
        full_name: '',
        short_name: '',

        official_region: null,
        official_area: null,
        official_district: null,
        official_locality: null,
        official_house_number: '',
        official_flat_number: '',

        is_matches_with_official_address: 1,
      },
      enableReinitialize: true,
      validationSchema: yup.object().shape({
        official_region: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        official_area: yup.object().nullable(),
        official_district: yup.object().nullable(),
        official_locality: yup.object().nullable(),
        official_street: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        official_house_number: yup.string().required(messages.form.REQUIRED),
        official_flat_number: yup.string(),

        actual_region: yup
          .object()
          .nullable()
          .when('is_matches_with_official_address', {
            is: 0,
            then: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
          }),
        actual_area: yup.object().nullable(),
        actual_district: yup.object().nullable(),
        actual_locality: yup.object().nullable(),
        actual_street: yup
          .object()
          .nullable()
          .when('is_matches_with_official_address', {
            is: 0,
            then: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
          }),
        actual_house_number: yup.string().when('is_matches_with_official_address', {
          is: 0,
          then: yup.string().required(messages.form.REQUIRED),
        }),
        actual_flat_number: yup.string(),

        director_name: yup.string().required(messages.form.REQUIRED),
        bin: yup.string().required(messages.form.REQUIRED).min(12, messages.form.MIN_LENGTH(12)),
        short_name: yup.string().required(messages.form.REQUIRED),
        full_name: yup.string().required(messages.form.REQUIRED),
        phone: yup.string().required(messages.form.REQUIRED).min(11, messages.form.PHONE),
        email: yup.string().email(messages.form.EMAIL).required(messages.form.REQUIRED),
      }),
      async onSubmit(organization) {
        try {
          await updateOrganization(organization).unwrap();

          handleClose();
        } catch (error) {
          enqueueSnackbar(error.data, { variant: 'error' });
        }
      },
    });

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать дочернюю организацию</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h5" variant="body2" gutterBottom>
                <b>Юридический адрес</b>
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <SearchRegions
                    value={values.official_region}
                    onChange={(event, option) =>
                      setValues((values) => ({
                        ...values,
                        official_region: option,
                        official_area: null,
                        official_district: null,
                        official_locality: null,
                        official_street: null,
                      }))
                    }
                    disabled={updateOrganizationResponse.isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_region"
                        label="Область"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_region && errors.official_region)}
                        helperText={touched.official_region && errors.official_region}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchAreas
                    value={values.official_area}
                    config={{ params: { parent_id: values.official_region?.id } }}
                    onChange={(event, option) =>
                      setValues((values) => ({
                        ...values,
                        official_area: option,
                        official_district: null,
                        official_locality: null,
                      }))
                    }
                    disabled={updateOrganizationResponse.isLoading || Boolean(!values.official_region)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_area"
                        label="Район"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_area && errors.official_area)}
                        helperText={touched.official_area && errors.official_area}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchDistricts
                    value={values.official_district}
                    config={{ params: { parent_id: values.official_area?.id } }}
                    onChange={(event, option) =>
                      setValues((values) => ({ ...values, official_district: option, official_locality: null }))
                    }
                    disabled={updateOrganizationResponse.isLoading || Boolean(!values.official_area)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_district"
                        label="Округ"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_district && errors.official_district)}
                        helperText={touched.official_district && errors.official_district}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchLocalities
                    value={values.official_locality}
                    config={{ params: { parent_id: values.official_district?.id } }}
                    onChange={(event, option) => setFieldValue('official_locality', option)}
                    disabled={updateOrganizationResponse.isLoading || Boolean(!values.official_district)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_locality"
                        label="Населенный пункт"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_locality && errors.official_locality)}
                        helperText={touched.official_locality && errors.official_locality}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchStreets
                    freeSolo
                    value={values.official_street}
                    config={{
                      params: {
                        district_id: values.official_district?.id,
                        locality_id: values.official_locality?.id,
                        parent_id: values.official_region?.id,
                      },
                    }}
                    onChange={(event, street) =>
                      setFieldValue(
                        'official_street',
                        typeof street === 'string'
                          ? { name: street }
                          : street && street.name
                          ? { name: street.name }
                          : street,
                      )
                    }
                    disabled={updateOrganizationResponse.isLoading || Boolean(!values.official_region)}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_street"
                        label="Улица"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_street && errors.official_street)}
                        helperText={touched.official_street && errors.official_street}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="official_house_number"
                    label="Дом"
                    placeholder="Введите значение"
                    value={values.official_house_number}
                    onChange={handleChange}
                    disabled={updateOrganizationResponse.isLoading}
                    error={touched.official_house_number && Boolean(errors.official_house_number)}
                    helperText={touched.official_house_number ? errors.official_house_number : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="official_flat_number"
                    label="Квартира"
                    placeholder="Введите значение"
                    value={values.official_flat_number}
                    onChange={handleChange}
                    disabled={updateOrganizationResponse.isLoading}
                    error={touched.official_flat_number && Boolean(errors.official_flat_number)}
                    helperText={touched.official_flat_number ? errors.official_flat_number : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                  <FormControlLabel
                    label="Совпадает с адресом прописки"
                    control={
                      <Switch
                        name="is_matches_with_official_address"
                        value={values.is_matches_with_official_address}
                        checked={Boolean(values.is_matches_with_official_address)}
                        disabled={updateOrganizationResponse.isLoading}
                        onChange={(event, checked) =>
                          setFieldValue('is_matches_with_official_address', checked ? 1 : 0)
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {Boolean(!values.is_matches_with_official_address) && (
              <Grid item xs={12}>
                <Typography component="h5" variant="body2" gutterBottom>
                  <b>Физический адрес</b>
                </Typography>

                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <SearchRegions
                      value={values.actual_region}
                      onChange={(event, option) =>
                        setValues((values) => ({
                          ...values,
                          actual_region: option,
                          actual_area: null,
                          actual_district: null,
                          actual_locality: null,
                          actual_street: null,
                        }))
                      }
                      disabled={updateOrganizationResponse.isLoading}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_region"
                          label="Область"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_region && errors.actual_region)}
                          helperText={touched.actual_region && errors.actual_region}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchAreas
                      value={values.actual_area}
                      config={{ params: { parent_id: values.actual_region?.id } }}
                      onChange={(event, option) =>
                        setValues((values) => ({
                          ...values,
                          actual_area: option,
                          actual_district: null,
                          actual_locality: null,
                        }))
                      }
                      disabled={updateOrganizationResponse.isLoading || Boolean(!values.actual_region)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_area"
                          label="Район"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_area && errors.actual_area)}
                          helperText={touched.actual_area && errors.actual_area}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchDistricts
                      value={values.actual_district}
                      config={{ params: { parent_id: values.actual_area?.id } }}
                      onChange={(event, option) =>
                        setValues((values) => ({ ...values, actual_district: option, actual_locality: null }))
                      }
                      disabled={updateOrganizationResponse.isLoading || Boolean(!values.actual_area)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_district"
                          label="Округ"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_district && errors.actual_district)}
                          helperText={touched.actual_district && errors.actual_district}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchLocalities
                      value={values.actual_locality}
                      config={{ params: { parent_id: values.actual_district?.id } }}
                      onChange={(event, option) => setFieldValue('actual_locality', option)}
                      disabled={updateOrganizationResponse.isLoading || Boolean(!values.actual_district)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_locality"
                          label="Населенный пункт"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_locality && errors.actual_locality)}
                          helperText={touched.actual_locality && errors.actual_locality}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchStreets
                      freeSolo
                      value={values.actual_street}
                      config={{
                        params: {
                          district_id: values.actual_district?.id,
                          locality_id: values.actual_locality?.id,
                          parent_id: values.actual_region?.id,
                        },
                      }}
                      onChange={(event, street) =>
                        setFieldValue(
                          'actual_street',
                          typeof street === 'string'
                            ? { name: street }
                            : street && street.name
                            ? { name: street.name }
                            : street,
                        )
                      }
                      disabled={updateOrganizationResponse.isLoading || Boolean(!values.actual_region)}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_street"
                          label="Улица"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_street && errors.actual_street)}
                          helperText={touched.actual_street && errors.actual_street}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="actual_house_number"
                      label="Дом"
                      placeholder="Введите значение"
                      value={values.actual_house_number}
                      onChange={handleChange}
                      disabled={updateOrganizationResponse.isLoading}
                      error={touched.actual_house_number && Boolean(errors.actual_house_number)}
                      helperText={touched.actual_house_number ? errors.actual_house_number : ''}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="actual_flat_number"
                      label="Квартира"
                      placeholder="Введите значение"
                      value={values.actual_flat_number}
                      onChange={handleChange}
                      disabled={updateOrganizationResponse.isLoading}
                      error={touched.actual_flat_number && Boolean(errors.actual_flat_number)}
                      helperText={touched.actual_flat_number ? errors.actual_flat_number : ''}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="director_name"
                label="Директор"
                placeholder="Введите значение"
                value={values.director_name}
                onChange={handleChange}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.director_name && Boolean(errors.director_name)}
                helperText={touched.director_name ? errors.director_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                format="############"
                name="bin"
                label="БИН"
                placeholder="Введите значение"
                value={values.bin}
                onChange={handleChange}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.bin && Boolean(errors.bin)}
                helperText={touched.bin ? errors.bin : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="short_name"
                label="Аббревиатура"
                placeholder="Введите значение"
                value={values.short_name}
                onChange={handleChange}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.short_name && Boolean(errors.short_name)}
                helperText={touched.short_name ? errors.short_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="full_name"
                label="Полное название"
                placeholder="Введите значение"
                value={values.full_name}
                onChange={handleChange}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.full_name && Boolean(errors.full_name)}
                helperText={touched.full_name ? errors.full_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                format="+# (###) ###-##-##"
                name="phone"
                label="Номер телефона"
                placeholder="Введите значение"
                value={values.phone}
                onValueChange={({ value }) => setFieldValue('phone', value)}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone ? errors.phone : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="email"
                label="E-mail"
                placeholder="Введите значение"
                value={values.email}
                onChange={handleChange}
                disabled={updateOrganizationResponse.isLoading}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email ? errors.email : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateOrganizationResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateOrganizationResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateOrganization;
