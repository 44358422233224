import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps, createFilterOptions } from '@mui/material';

import { Customer } from '@/types';
import { useGetCustomersQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

const filterOptions = createFilterOptions({
  stringify: ({ name, last_name, father_name, iin }: Customer) => `${last_name} ${name} ${father_name} (${iin})`,
});

function SearchCustomers<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Customer> } & Omit<
  AutocompleteProps<Customer, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCustomersQueryProps = useAsyncSearch<Customer, Multiple, DisableClearable, FreeSolo>(
    useGetCustomersQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete filterOptions={filterOptions} {...props} {...asyncGetCustomersQueryProps} />;
}

export default SearchCustomers;
