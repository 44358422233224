import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CustomerStatus } from '@/types';
import { useGetCustomerStatusesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCustomerStatuses<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  getOptions,
  prefetch,
  ...props
}: {
  config?: AxiosRequestConfig<CustomerStatus>;
  getOptions?(options: readonly CustomerStatus[]): void;
  prefetch?: boolean;
} & Omit<AutocompleteProps<CustomerStatus, Multiple, DisableClearable, FreeSolo>, 'options'>) {
  const asyncGetCustomerStatusesQueryProps = useAsyncSearch<CustomerStatus, Multiple, DisableClearable, FreeSolo>(
    useGetCustomerStatusesQuery,
    'id',
    value,
    config,
    getOptions,
    prefetch,
  );

  return <Autocomplete {...props} {...asyncGetCustomerStatusesQueryProps} />;
}

export default SearchCustomerStatuses;
