import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CustomerCategory } from '@/types';
import { useGetCustomerCategoriesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCustomerCategories<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<CustomerCategory> } & Omit<
  AutocompleteProps<CustomerCategory, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCustomerCategoriesQueryProps = useAsyncSearch<CustomerCategory, Multiple, DisableClearable, FreeSolo>(
    useGetCustomerCategoriesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetCustomerCategoriesQueryProps} />;
}

export default SearchCustomerCategories;
