import { Employee, Organization, Point } from '@/types';

export enum ShiftTypes {
  OPEN = 1,
  CLOSE = 2,
}

export interface Shift {
  id?: number;
  name?: string;
  organization: Organization | null;
  organization_id?: Organization['id'];
  point: Point | null;
  point_id?: Point['id'];
  previous_shift_id?: Shift['id'];
  passing_employee: Employee | null;
  passing_employee_id?: Employee['id'];
  receiving_employee: Employee | null;
  receiving_employee_id?: Employee['id'];
  cash_amount: number;
  pledges_count: number;
  keys_count: number;
  type: ShiftTypes;
  created_at?: Date;
}
