import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Gem } from '@/types';
import { useGetGemsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchGems<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Gem> } & Omit<
  AutocompleteProps<Gem, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetGemsQueryProps = useAsyncSearch<Gem, Multiple, DisableClearable, FreeSolo>(
    useGetGemsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetGemsQueryProps} />;
}

export default SearchGems;
