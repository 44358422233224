import NumberFormat from 'react-number-format';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { CustomerStatusPriceForPoints, Sample } from '@/types';
import { useCreateSampleMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SAMPLES } from '@/utils/samples';
import { SearchPoints } from '@/pages/Points/components';
import { PlusFlat, TrashFlat } from '@/assets/icons';

export interface CreateSampleProps extends DialogProps {
  onClose(): void;
}

const CreateSample: React.FC<CreateSampleProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createSample, createSampleResponse] = useCreateSampleMutation();

  const CUSTOMER_STATUS_PRICES_FOR_POINTS: CustomerStatusPriceForPoints = {
    point: null,
    common_price: 0,
    vip_price: 0,
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить пробу</DialogTitle>
      <Formik<Sample>
        initialValues={{
          auto_calculation: false,
          customer_status_prices_for_points: [CUSTOMER_STATUS_PRICES_FOR_POINTS],
          dont_calculate_from_999: false,
          name: '',
          max_limit: '',
          min_limit: '',
          metal_type: '',
          price: 0,
          vip_price: 0,
          prices_configurable_for_point_and_statuses: false,
          show: false,
          show_in_jewelries: false,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required(messages.form.REQUIRED),
          min_limit: yup.string().required(messages.form.REQUIRED),
          max_limit: yup.string().required(messages.form.REQUIRED),
          metal_type: yup.string().required(messages.form.SELECT_FROM_LIST),
          price: yup.number().required(messages.form.REQUIRED),
          vip_price: yup.number().required(messages.form.REQUIRED),
          customer_status_prices_for_points: yup
            .array()
            .nullable()
            .when('prices_configurable_for_point_and_statuses', {
              is: true,
              then: yup.array().of(
                yup.object().shape({
                  point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
                  common_price: yup.number().required(messages.form.REQUIRED),
                  vip_price: yup.number().required(messages.form.REQUIRED),
                }),
              ),
            }),
        })}
        onSubmit={async (sample, { resetForm }) => {
          try {
            await createSample(sample).unwrap();

            onClose();
            resetForm();
          } catch (error) {
            enqueueSnackbar(error.data, { variant: 'error' });
          }
        }}
      >
        {({ values, touched, errors, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    select
                    name="metal_type"
                    label="Металл"
                    value={values.metal_type}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.metal_type && Boolean(errors.metal_type)}
                    helperText={touched.metal_type ? errors.metal_type : ''}
                    fullWidth
                  >
                    {SAMPLES.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="name"
                    label="Наименование"
                    placeholder="Введите значение"
                    value={values.name}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="min_limit"
                    label="Нижний предел"
                    placeholder="Введите значение"
                    value={values.min_limit}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.min_limit && Boolean(errors.min_limit)}
                    helperText={touched.min_limit ? errors.min_limit : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="max_limit"
                    label="Верхний предел"
                    placeholder="Введите значение"
                    value={values.max_limit}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.max_limit && Boolean(errors.max_limit)}
                    helperText={touched.max_limit ? errors.max_limit : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <FormControlLabel
                      label='Не показывать закладку "Драгоценные камни" при оформлении залогового билета'
                      control={
                        <Switch
                          name="show_in_jewelries"
                          value={values.show_in_jewelries}
                          disabled={createSampleResponse.isLoading}
                          onChange={(event, checked) => setFieldValue('show_in_jewelries', checked)}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Не рассчитывать цену автоматически от цены 999 пробы"
                      control={
                        <Switch
                          name="dont_calculate_from_999"
                          value={values.dont_calculate_from_999}
                          disabled={createSampleResponse.isLoading}
                          onChange={(event, checked) => setFieldValue('dont_calculate_from_999', checked)}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Для точек и статусов цена настраивается индивидуально"
                      control={
                        <Switch
                          name="prices_configurable_for_point_and_statuses"
                          value={values.prices_configurable_for_point_and_statuses}
                          disabled={createSampleResponse.isLoading}
                          onChange={(event, checked) => {
                            setFieldValue(
                              'customer_status_prices_for_points',
                              checked ? [CUSTOMER_STATUS_PRICES_FOR_POINTS] : [],
                            );
                            setFieldValue('prices_configurable_for_point_and_statuses', checked);
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="price"
                    label="Общая цена"
                    placeholder="Введите значение"
                    value={values.price}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price ? errors.price : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="vip_price"
                    label="Общая цена для VIP"
                    placeholder="Введите значение"
                    value={values.vip_price}
                    onChange={handleChange}
                    disabled={createSampleResponse.isLoading}
                    error={touched.vip_price && Boolean(errors.vip_price)}
                    helperText={touched.vip_price ? errors.vip_price : ''}
                    fullWidth
                  />
                </Grid>

                {Boolean(values.prices_configurable_for_point_and_statuses) && (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Точка</TableCell>
                            <TableCell>Общая цена</TableCell>
                            <TableCell>Цена (Статус: VIP)</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>

                        <FieldArray name="customer_status_prices_for_points">
                          {({ push, remove }) => (
                            <>
                              <TableBody>
                                {values.customer_status_prices_for_points.map((status_price, index) => {
                                  const field = (name: string) => `customer_status_prices_for_points[${index}].${name}`;

                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <SearchPoints
                                          value={status_price.point}
                                          onChange={(event, option) => setFieldValue(field('point'), option)}
                                          disabled={createSampleResponse.isLoading}
                                          getOptionLabel={(option) => option.name}
                                          sx={{ minWidth: 300 }}
                                          renderInput={(props) => (
                                            <TextField
                                              {...props}
                                              name={field('point')}
                                              label="Точка"
                                              placeholder="Выберите из списка"
                                              error={
                                                getIn(touched, field('point')) && Boolean(getIn(errors, field('point')))
                                              }
                                              size="small"
                                            />
                                          )}
                                        />
                                      </TableCell>

                                      <TableCell>
                                        <NumberFormat
                                          customInput={TextField}
                                          name={field('common_price')}
                                          label="Общая цена"
                                          placeholder="Введите значение"
                                          value={status_price.common_price}
                                          onChange={handleChange}
                                          disabled={createSampleResponse.isLoading}
                                          error={
                                            getIn(touched, field('common_price')) &&
                                            Boolean(getIn(errors, field('common_price')))
                                          }
                                          fullWidth
                                          size="small"
                                        />
                                      </TableCell>

                                      <TableCell>
                                        <NumberFormat
                                          customInput={TextField}
                                          name={field('vip_price')}
                                          label="Цена (Статус: VIP)"
                                          placeholder="Введите значение"
                                          value={status_price.vip_price}
                                          onChange={handleChange}
                                          disabled={createSampleResponse.isLoading}
                                          error={
                                            getIn(touched, field('vip_price')) &&
                                            Boolean(getIn(errors, field('vip_price')))
                                          }
                                          fullWidth
                                          size="small"
                                        />
                                      </TableCell>

                                      <TableCell>
                                        {Boolean(index) &&
                                          values.customer_status_prices_for_points.length === index + 1 && (
                                            <Tooltip title="Добавить строку">
                                              <IconButton
                                                aria-label="Добавить строку"
                                                disabled={createSampleResponse.isLoading}
                                                onClick={() => remove(index)}
                                              >
                                                <TrashFlat sx={{ fontSize: 16 }} />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>

                              <TableFooter>
                                <TableRow>
                                  <TableCell align="right" colSpan={9}>
                                    <Tooltip title="Добавить новую строку">
                                      <IconButton
                                        aria-label="Добавить новую строку"
                                        disabled={createSampleResponse.isLoading}
                                        onClick={() => push(CUSTOMER_STATUS_PRICES_FOR_POINTS)}
                                      >
                                        <PlusFlat sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              </TableFooter>
                            </>
                          )}
                        </FieldArray>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    label="Отображать"
                    control={
                      <Switch
                        name="show"
                        value={values.show}
                        disabled={createSampleResponse.isLoading}
                        onChange={(event, checked) => setFieldValue('show', checked)}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                size="large"
                disabled={createSampleResponse.isLoading}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Button
                component="button"
                type="submit"
                variant="contained"
                size="large"
                disabled={createSampleResponse.isLoading}
              >
                Добавить
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateSample;
