import { useCallback, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { AutocompleteInputChangeReason, AutocompleteProps, AutocompleteValue } from '@mui/material';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';

import { ListResponse } from '@/types';
import { useDebounce } from '@/hooks';

const ensuredOptions = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  options: readonly T[],
  value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
  excludeBy?: keyof T,
): readonly T[] => {
  const spliceOption = (value: T) => {
    if (!value) return options;
    const newOptions = [...options];

    const isEqualOptionToValue = (option: T, value: T) =>
      excludeBy ? option[excludeBy] === value[excludeBy] : option === value;

    if (!newOptions.find((option) => isEqualOptionToValue(option, value))) newOptions.push(value);
    return newOptions;
  };

  if (Array.isArray(value)) {
    value.map((option) => spliceOption(option)).flat();
    return options;
  } else return spliceOption(value as T);
};

export interface AsyncSearchProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {}

enum NoOptionTextVariants {
  LOADING = 'Загрузка...',
  ENTER_VALUE = 'Введите значение для поиска...',
  NO_OPTIONS = 'Ничего не найдено',
  CATCH_ERROR = 'Произошла ошибка с загрузкой данных',
}

function useAsyncSearch<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  useQuery: UseQuery<
    QueryDefinition<
      AxiosRequestConfig<T>,
      BaseQueryFn<AxiosRequestConfig<T>, unknown, unknown, {}, {}>,
      string,
      ListResponse<T>,
      'api'
    >
  >,
  uniqeBy: keyof T,
  value?: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
  config?: AxiosRequestConfig<T>,
  getOptions?: (options: T[]) => void,
  prefetch?: boolean,
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void,
): AsyncSearchProps<T, Multiple, DisableClearable, FreeSolo> {
  const [open, setOpen] = useState(prefetch);
  const [query, setQuery] = useState<string | null>(null);
  const debouncedValue = useDebounce(query, 1000);
  const {
    data: options,
    isLoading,
    isFetching,
    isError,
  } = useQuery({ ...config, params: { search: debouncedValue, ...config?.params } }, { skip: !open });

  const handleSearch = useCallback((value: string) => {
    if (value) return setQuery(value);
    setQuery(null);
  }, []);

  const noOptionsText = isError
    ? NoOptionTextVariants.CATCH_ERROR
    : !options?.data.length
    ? NoOptionTextVariants.NO_OPTIONS
    : NoOptionTextVariants.ENTER_VALUE;

  useEffect(() => {
    if (!open || !options?.data.length) return;
    getOptions && getOptions(options.data);
  }, [options?.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    value,
    // @ts-ignore
    options: value ? ensuredOptions(options?.data ?? [], value, uniqeBy) : options?.data ?? [],
    isOptionEqualToValue: (option, value) => option[uniqeBy] === value[uniqeBy],
    noOptionsText,
    loadingText: NoOptionTextVariants.LOADING,
    loading: isLoading || isFetching,
    onOpen: () => setOpen(true),
    onClose: () => {
      setQuery(null);
      setOpen(false);
    },
    onInputChange: (event, value, reason) => {
      reason === 'input' && handleSearch(value);
      onInputChange && onInputChange(event, value, reason);
    },
  };
}

export default useAsyncSearch;
