import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { CashboxOperation, CashboxOperations } from '@/types';
import { useCreateCashboxOperationMutation } from '@/services';
import { useAuth } from '@/hooks';
import { messages } from '@/utils/helpers';
import { CASHBOX_OPERATIONS } from '@/utils/cashbox-operations';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchOrganizations } from '@/pages/Organizations/components';
import { CheckEmployeeAdditionalPassword } from '@/pages/Employees/components';

export interface CreateCashboxOperationProps extends DialogProps {
  onClose(): void;
}

const CreateCashboxOperation: React.FC<CreateCashboxOperationProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const [createCashboxOperation, createCashboxOperationResponse] = useCreateCashboxOperationMutation();

  const [checkAdditionalPasswordModalOpened, setOpenedCheckAdditionalPasswordModal] = useState(false);

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<CashboxOperation>({
    initialValues: {
      addition: '',
      customer: null,
      organization: user?.point?.organization,
      point: user?.point,
      reason: '',
      status: CashboxOperations.INCOME,
      sum: 0,
    },
    validationSchema: yup.object().shape({
      addition: yup.string(),
      customer: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      reason: yup.string(),
      status: yup.string().required(messages.form.REQUIRED),
      sum: yup.number().required(messages.form.REQUIRED),
    }),
    async onSubmit(cashboxOperation, { resetForm }) {
      if (values.status === CashboxOperations.OUTCOME) return setOpenedCheckAdditionalPasswordModal(true);

      await handleSubmitForm(cashboxOperation);
      resetForm();
    },
  });

  async function handleSubmitForm(cashboxOperation: CashboxOperation) {
    try {
      await createCashboxOperation(cashboxOperation).unwrap();

      onClose();
      setOpenedCheckAdditionalPasswordModal(false);
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <>
      <Dialog fullWidth {...props}>
        <DialogTitle fontWeight="bold">Добавить запись в кассу</DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <SearchOrganizations
                  value={values.organization}
                  onChange={(event, option) => setFieldValue('organization', option)}
                  disabled={createCashboxOperationResponse.isLoading}
                  getOptionLabel={(option) => option.full_name}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="organization"
                      label="Организация"
                      placeholder="Выберите из списка"
                      error={Boolean(touched.organization && errors.organization)}
                      helperText={touched.organization && errors.organization}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <SearchPoints
                  value={values.point}
                  onChange={(event, option) => setFieldValue('point', option)}
                  disabled={createCashboxOperationResponse.isLoading}
                  getOptionLabel={(option) => option.name}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="point"
                      label="Точка"
                      placeholder="Выберите из списка"
                      error={Boolean(touched.point && errors.point)}
                      helperText={touched.point && errors.point}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <SearchCustomers
                  value={values.customer}
                  onChange={(event, option) => setFieldValue('customer', option)}
                  disabled={createCashboxOperationResponse.isLoading}
                  getOptionLabel={({ name, last_name, father_name }) =>
                    `${last_name ?? ''} ${name} ${father_name ?? ''} `
                  }
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="customer"
                      label="Клиент"
                      placeholder="Выберите из списка"
                      error={Boolean(touched.customer && errors.customer)}
                      helperText={touched.customer && errors.customer}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  name="sum"
                  label="Сумма"
                  placeholder="Введите значение"
                  value={values.sum}
                  onChange={handleChange}
                  disabled={createCashboxOperationResponse.isLoading}
                  error={touched.sum && Boolean(errors.sum)}
                  helperText={touched.sum ? errors.sum : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="reason"
                  label="Основание"
                  placeholder="Введите значение"
                  value={values.reason}
                  onChange={handleChange}
                  disabled={createCashboxOperationResponse.isLoading}
                  error={touched.reason && Boolean(errors.reason)}
                  helperText={touched.reason ? errors.reason : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  select
                  name="status"
                  label="Вид операции"
                  value={values.status}
                  onChange={handleChange}
                  disabled={createCashboxOperationResponse.isLoading}
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status ? errors.status : ''}
                  fullWidth
                >
                  {CASHBOX_OPERATIONS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="addition"
                  label="Дополнительно"
                  placeholder="Введите значение"
                  value={values.addition}
                  onChange={handleChange}
                  disabled={createCashboxOperationResponse.isLoading}
                  error={touched.addition && Boolean(errors.addition)}
                  helperText={touched.addition ? errors.addition : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              disabled={createCashboxOperationResponse.isLoading}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Button type="submit" variant="contained" disabled={createCashboxOperationResponse.isLoading}>
              Добавить
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <CheckEmployeeAdditionalPassword
        open={checkAdditionalPasswordModalOpened}
        onCheck={() => handleSubmitForm(values)}
        onClose={() => setOpenedCheckAdditionalPasswordModal(false)}
      />
    </>
  );
};

export default CreateCashboxOperation;
