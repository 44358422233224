import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Color, ListResponse } from '@/types';

const colorApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Colors'],
});

const colorApi = colorApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getColors: builder.query<ListResponse<Color>, AxiosRequestConfig<Color>>({
      query: (config) => ({ url: '/admin/color', ...config }),
      providesTags: ['Colors'],
    }),
    createColor: builder.mutation<Color, Color>({
      query: (color) => ({ url: '/admin/color', method: 'POST', data: color }),
      invalidatesTags: ['Colors'],
    }),
    updateColor: builder.mutation<Color, Color>({
      query: (color) => ({ url: '/admin/color/' + color.id, method: 'PUT', data: color }),
      invalidatesTags: ['Colors'],
    }),
    deleteColor: builder.mutation<unknown, Color>({
      query: (color) => ({ url: '/admin/color/' + color.id, method: 'DELETE' }),
      invalidatesTags: ['Colors'],
    }),
  }),
});

const { useGetColorsQuery, useCreateColorMutation, useUpdateColorMutation, useDeleteColorMutation } = colorApi;

export { useGetColorsQuery, useCreateColorMutation, useUpdateColorMutation, useDeleteColorMutation };
