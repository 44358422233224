import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { MobileOperator, ListResponse } from '@/types';
import { Pagination } from '@/components';
import theme from '@/theme';
import { EditFlat, TrashFlat } from '@/assets/icons';

import { DeleteMobileOperator, UpdateMobileOperator } from '../components';

export interface MobileOperatorListProps {
  list?: ListResponse<MobileOperator>;
  page: number;
  onChangePage(page: number): void;
}

const MobileOperatorsTable: React.FC<MobileOperatorListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentMobileOperator, setCurrentMobileOperator] = useState<MobileOperator | null>(null);

  function openUpdateModal(mobileOperator: MobileOperator) {
    setCurrentMobileOperator(mobileOperator);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(mobileOperator: MobileOperator) {
    setCurrentMobileOperator(mobileOperator);
    setOpenedDeleteModal(true);
  }
  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Код оператора</TableCell>
                <TableCell>Зарезервированные коды номеров</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((mobileOperator) => {
                const { name, reserved_codes, site_code, id } = mobileOperator;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{site_code}</TableCell>
                    <TableCell>{reserved_codes}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(mobileOperator)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(mobileOperator)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateMobileOperator
        mobileOperator={currentMobileOperator!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteMobileOperator
        mobileOperator={currentMobileOperator!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default MobileOperatorsTable;
