import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Employee } from '@/types';
import { useChangeEmployeePasswordMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface ChangeEmployeePasswordProps extends DialogProps {
  employee?: Employee;
  onClose(): void;
}

const ChangeEmployeePassword: React.FC<ChangeEmployeePasswordProps> = ({ employee, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [changeEmployeePassword, changeEmployeePasswordResponse] = useChangeEmployeePasswordMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: { password: '' },
    validationSchema: yup.object().shape({
      password: yup.string().required(messages.form.REQUIRED).min(8, messages.form.MIN_LENGTH()),
    }),
    async onSubmit({ password }, { resetForm }) {
      try {
        await changeEmployeePassword({ id: employee?.id, password }).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Сменить пароль сотрудника</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid item xs={12}>
            <TextField
              name="password"
              autoComplete="off"
              label="Пароль"
              placeholder="Введите значение"
              value={values.password}
              onChange={handleChange}
              disabled={changeEmployeePasswordResponse.isLoading}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password ? errors.password : ''}
              fullWidth
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={changeEmployeePasswordResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={changeEmployeePasswordResponse.isLoading}>
            Сменить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeEmployeePassword;
