import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CarBrand } from '@/types';
import { useGetCarBrandsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCarBrands<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<CarBrand> } & Omit<
  AutocompleteProps<CarBrand, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCarBrandsQueryProps = useAsyncSearch<CarBrand, Multiple, DisableClearable, FreeSolo>(
    useGetCarBrandsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetCarBrandsQueryProps} />;
}

export default SearchCarBrands;
