import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Country } from '@/types';
import { useDeleteCountryMutation } from '@/services';

export interface DeleteCountryProps extends DialogProps {
  country: Country;
  onClose(): void;
}

const DeleteCountry: React.FC<DeleteCountryProps> = ({ country, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCountry, deleteCountryResponse] = useDeleteCountryMutation();

  async function handleDeleteCountry() {
    try {
      await deleteCountry(country).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{country?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{country?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteCountryResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteCountryResponse.isLoading} onClick={handleDeleteCountry}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCountry;
