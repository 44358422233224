import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Attribute, ListResponse } from '@/types';

const attributeApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Attributes'],
});

const attributeApi = attributeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<ListResponse<Attribute>, AxiosRequestConfig<Attribute>>({
      query: (config) => ({ url: '/admin/attribute', ...config }),
      providesTags: ['Attributes'],
    }),
    createAttribute: builder.mutation<Attribute, Attribute>({
      query: (attribute) => ({ url: '/admin/attribute', method: 'POST', data: attribute }),
      invalidatesTags: ['Attributes'],
    }),
    updateAttribute: builder.mutation<Attribute, Attribute>({
      query: (attribute) => ({ url: '/admin/attribute/' + attribute.id, method: 'PUT', data: attribute }),
      invalidatesTags: ['Attributes'],
    }),
    deleteAttribute: builder.mutation<unknown, Attribute>({
      query: (attribute) => ({ url: '/admin/attribute/' + attribute.id, method: 'DELETE' }),
      invalidatesTags: ['Attributes'],
    }),
  }),
});

const { useGetAttributesQuery, useCreateAttributeMutation, useUpdateAttributeMutation, useDeleteAttributeMutation } =
  attributeApi;

export { useGetAttributesQuery, useCreateAttributeMutation, useUpdateAttributeMutation, useDeleteAttributeMutation };
