import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Menu, MenuItem, MenuProps } from '@mui/material';

import { DocumentTypes, Realization } from '@/types';
import { getDocument } from '@/services';
import { PreviewDocument } from '@/components';
import { Langs, LANGS } from '@/utils/langs';
import { REALIZATION_DOCUMENTS } from '@/utils/documents';
import { catchErrorForBlob } from '@/utils/helpers';

export interface GenerateRealizationDocumentProps extends MenuProps {
  realization: Realization;
}

const GenerateRealizationDocument: React.FC<GenerateRealizationDocumentProps> = ({ realization, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [lan, setLang] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);
  const [previewDocumentOpened, setOpenedPreviewDocument] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);

  async function handleSelectDocument(type: DocumentTypes) {
    try {
      setLoading(true);

      const document = (await getDocument({
        params: { realization_id: realization?.id, lan, type },
        responseType: 'blob',
      })) as Blob;

      setOpenedPreviewDocument(true);
      setDocumentUrl(URL.createObjectURL(document));
    } catch (error) {
      enqueueSnackbar((await catchErrorForBlob(error)).error.data, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function handleSelectLang(value: Langs) {
    return (event: React.MouseEvent<HTMLLIElement>) => {
      setLang(value);
      setAnchorEl(event.currentTarget);
    };
  }

  function handleCloseDialog() {
    if (documentUrl) URL.revokeObjectURL(documentUrl);
    setDocumentUrl(null);
    setOpenedPreviewDocument(false);
  }

  return (
    <>
      <Menu {...props}>
        {LANGS.map(({ label, value }) => (
          <MenuItem key={value} value={value} disabled={loading} onClick={handleSelectLang(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>

      <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {REALIZATION_DOCUMENTS.map(({ label, value }) => (
          <MenuItem key={value} value={value} disabled={loading} onClick={() => handleSelectDocument(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>

      <PreviewDocument onClose={handleCloseDialog} documentUrl={documentUrl} open={previewDocumentOpened} />
    </>
  );
};

export default GenerateRealizationDocument;
