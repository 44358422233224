import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Sample, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteSample, UpdateSample } from '../components';

export interface SampleListProps {
  list?: ListResponse<Sample>;
  page: number;
  onChangePage(page: number): void;
}

const SamplesTable: React.FC<SampleListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentSample, setCurrentSample] = useState<Sample | null>(null);

  function openUpdateModal(sample: Sample) {
    setCurrentSample(sample);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(sample: Sample) {
    setCurrentSample(sample);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Ниж. предел</TableCell>
                <TableCell>Верх. предел</TableCell>
                <TableCell>Метал</TableCell>
                <TableCell>Цена</TableCell>
                <TableCell>АвтоРасчет</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((sample) => {
                const { name, min_limit, max_limit, metal, price, auto_calculation, id } = sample;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(sample)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(sample)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{min_limit}</TableCell>
                    <TableCell>{max_limit}</TableCell>
                    <TableCell>{metal}</TableCell>
                    <TableCell>{price}</TableCell>
                    <TableCell>
                      <Switch checked={Boolean(auto_calculation)} readOnly />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateSample sample={currentSample!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeleteSample sample={currentSample!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default SamplesTable;
