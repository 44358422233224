import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Locality } from '@/types';
import { useGetLocalitiesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchLocalities<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Locality> } & Omit<
  AutocompleteProps<Locality, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetLocalitiesQueryProps = useAsyncSearch<Locality, Multiple, DisableClearable, FreeSolo>(
    useGetLocalitiesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetLocalitiesQueryProps} />;
}

export default SearchLocalities;
