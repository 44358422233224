import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Holiday } from '@/types';
import { useCreateHolidayMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateHolidayProps extends DialogProps {
  onClose(): void;
}

const CreateHoliday: React.FC<CreateHolidayProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createHoliday, createHolidayResponse] = useCreateHolidayMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Holiday>({
    initialValues: { name: '', date: null },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      date: yup.date().required(messages.form.SELECT_DATE).typeError(messages.form.SELECT_CORRECT_DATE),
    }),
    async onSubmit(holiday, { resetForm }) {
      try {
        await createHoliday(holiday).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить праздничный день</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createHolidayResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Срок годности УЛ (паспорта)"
                value={values.date}
                onChange={(value) => setFieldValue('date', moment(value).toDate())}
                disabled={createHolidayResponse.isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="date"
                    label="Дата"
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createHolidayResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createHolidayResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateHoliday;
