import baseApi from '@/api';
import { Attachment } from '@/types';

const fileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<Attachment, Attachment>({
      query: ({ file, folder_name }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder_name', folder_name);

        return { url: '/admin/upload/file', method: 'POST', data: formData };
      },
    }),

    uploadFiles: builder.mutation<Attachment, (File | null)[]>({
      query: (files) => {
        const formData = new FormData();
        for (const file of files) file && formData.append('files[]', file);

        return { url: '/admin/upload/files', method: 'POST', data: formData };
      },
    }),
  }),
});

const { useUploadFileMutation, useUploadFilesMutation } = fileApi;

export { useUploadFileMutation, useUploadFilesMutation };
