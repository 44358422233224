import { useState } from 'react';
import moment from 'moment';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Shift, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { InfoOutlined, TrashFlat } from '@/assets/icons';
import { SHIFT_TYPES } from '@/utils/shift-types';
import theme from '@/theme';

import { DeleteShift, ViewShift } from '../components';

export interface ShiftListProps {
  list?: ListResponse<Shift>;
  page: number;
  onChangePage(page: number): void;
}

const ShiftsTable: React.FC<ShiftListProps> = ({ list, page, onChangePage }) => {
  const [viewModalOpened, setOpenedViewModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentShift, setCurrentShift] = useState<Shift | null>(null);

  function openViewModal(shift: Shift) {
    setCurrentShift(shift);
    setOpenedViewModal(true);
  }

  function openDeleteModal(shift: Shift) {
    setCurrentShift(shift);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>№ Документа</TableCell>
                <TableCell>Вид документа</TableCell>
                <TableCell>Точка</TableCell>
                <TableCell>Автор</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((shift) => {
                const { created_at, name, passing_employee, point, type, id } = shift;

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{SHIFT_TYPES.find((shiftType) => shiftType.value === type)?.label}</TableCell>
                    <TableCell>{point?.name}</TableCell>
                    <TableCell>
                      {passing_employee?.last_name ?? ''} {passing_employee?.name ?? ''}{' '}
                      {passing_employee?.father_name ?? ''}
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Посмотреть">
                          <IconButton aria-label="Посмотреть" onClick={() => openViewModal(shift)}>
                            <InfoOutlined sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        {false && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(shift)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <ViewShift shift={currentShift} open={viewModalOpened} onClose={() => setOpenedViewModal(false)} />
      <DeleteShift shift={currentShift!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default ShiftsTable;
