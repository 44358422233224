import { useEffect, useState } from 'react';
import { CheckboxProps, Checkbox } from '@mui/material';

import { useDebounceFn } from '@/hooks';

export type DebouncedCheckboxProps = CheckboxProps & {};

const DebouncedCheckbox: React.FC<DebouncedCheckboxProps> = ({ checked, value, onChange, ...props }) => {
  const [_value, setValue] = useState(value);
  const [_checked, setChecked] = useState(checked);
  const debouncedOnChange = useDebounceFn(onChange!, 1000);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setValue(checked);
    setChecked(checked);
    debouncedOnChange(event, checked);
  }

  useEffect(() => setChecked(checked), [checked]);

  return <Checkbox {...props} checked={_checked} value={_value} onChange={handleChange} />;
};

export default DebouncedCheckbox;
