import { Employee, Pledge } from '@/types';

export enum RealizationStatuses {
  CARRIED_OUT = 'carried_out',
  CANCELLED = 'cancelled',
}

export interface Realization {
  id?: number;
  name?: string;
  pledges: Pledge[];
  pledge_ids?: Pledge['id'][];
  status?: RealizationStatuses;
  employee?: Employee;
  carried_by?: Employee;
  updated_by?: Employee;
  created_at?: Date | null;
}
