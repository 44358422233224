import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Nomenclature } from '@/types';
import { useDeleteNomenclatureMutation } from '@/services';

export interface DeleteNomenclatureProps extends DialogProps {
  nomenclature: Nomenclature;
  onClose(): void;
}

const DeleteNomenclature: React.FC<DeleteNomenclatureProps> = ({ nomenclature, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteNomenclature, deleteNomenclatureResponse] = useDeleteNomenclatureMutation();

  async function handleDeleteNomenclature() {
    try {
      await deleteNomenclature(nomenclature).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{nomenclature?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{nomenclature?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteNomenclatureResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          size="small"
          disabled={deleteNomenclatureResponse.isLoading}
          onClick={handleDeleteNomenclature}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNomenclature;
