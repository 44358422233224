import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureGroupDiscount } from '@/types';
import { useGetNomenclatureGroupDiscountsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureGroupDiscounts<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<NomenclatureGroupDiscount> } & Omit<
  AutocompleteProps<NomenclatureGroupDiscount, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclatureGroupDiscountsQueryProps = useAsyncSearch<
    NomenclatureGroupDiscount,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureGroupDiscountsQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetNomenclatureGroupDiscountsQueryProps} />;
}

export default SearchNomenclatureGroupDiscounts;
