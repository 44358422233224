import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports } from '@/types';

export interface WebkassaXReportTableProps {
  data: Reports.WebkassaX;
}

const WebkassaXReportTable: React.FC<WebkassaXReportTableProps> = ({ data }) => {
  const {
    CashboxIN,
    CashboxRN,
    CashboxSN,
    CashierCode,
    ControlSum,
    DocumentCount,
    EndNonNullable,
    Ofd,
    ShiftNumber,
    StartNonNullable,
    SumInCashbox,
    TaxPayerIN,
    TaxPayerName,
  } = data;

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">
                  <b>Дочерняя компания</b>
                </TableCell>

                <TableCell>{TaxPayerName}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>БИН</b>
                </TableCell>

                <TableCell>{TaxPayerIN}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>Код кассира</b>
                </TableCell>

                <TableCell>{CashierCode}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>Смена номер</b>
                </TableCell>

                <TableCell>{ShiftNumber}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <caption>
              <b>Необнуляемая сумма на начало смены</b>
            </caption>

            <TableHead>
              <TableRow>
                <TableCell>Продаж</TableCell>
                <TableCell>Покупок</TableCell>
                <TableCell>Возвратов продаж</TableCell>
                <TableCell>Возвратов покупок</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>{StartNonNullable.Sell}</TableCell>
                <TableCell>{StartNonNullable.Buy}</TableCell>
                <TableCell>{StartNonNullable.ReturnSell}</TableCell>
                <TableCell>{StartNonNullable.ReturnBuy}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <caption>
              <b>Необнуляемая сумма на текущую смену</b>
            </caption>

            <TableHead>
              <TableRow>
                <TableCell>Продаж</TableCell>
                <TableCell>Покупок</TableCell>
                <TableCell>Возвратов продаж</TableCell>
                <TableCell>Возвратов покупок</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>{EndNonNullable.Sell}</TableCell>
                <TableCell>{EndNonNullable.Buy}</TableCell>
                <TableCell>{EndNonNullable.ReturnSell}</TableCell>
                <TableCell>{EndNonNullable.ReturnBuy}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">
                  <b>Контрольное значение</b>
                </TableCell>

                <TableCell>{ControlSum}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>Кол-во документов сформированных за смену</b>
                </TableCell>

                <TableCell>{DocumentCount}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>Наличных в кассе</b>
                </TableCell>

                <TableCell>{SumInCashbox}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <caption>
              <b>Сформировано оператором фискальных данных:</b> {Ofd.Name}
            </caption>

            <TableBody>
              <TableRow>
                <TableCell component="th">
                  <b>ИНК</b>
                </TableCell>

                <TableCell>{CashboxIN}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>РНК</b>
                </TableCell>

                <TableCell>{CashboxRN}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">
                  <b>ЗНК</b>
                </TableCell>

                <TableCell>{CashboxSN}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default WebkassaXReportTable;
