import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIconProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { Roles } from '@/types';
import { useAuth, useRoles, useShifts } from '@/hooks';
import { AppsFlat, DollarFlat, FileFlat, LogoutFlat, SuperLobmardPrimary, UserFlat, UsersFlat } from '@/assets/icons';

interface MenuItemProps {
  divider?: boolean;
  Icon?: React.FC<SvgIconProps>;
  list?: MenuItemProps[];
  to: string;
  text: string;
  roles: (keyof typeof Roles)[];
}

const MENU_LIST: MenuItemProps[] = [
  {
    to: '/customers',
    Icon: UsersFlat,
    text: 'Клиенты',
    roles: ['ADMIN', 'CASHIER', 'REVISOR'],
  },
  {
    to: '/dictionaries',
    Icon: AppsFlat,
    text: 'Справочники',
    list: [
      { to: '/organizations', text: 'Дочерние организации', roles: ['ADMIN'] },
      { to: '/points', text: 'Точки', roles: ['ADMIN'] },
      { to: '/accounts', text: 'Банковские счета', roles: ['ADMIN'] },
      { to: '/cashdesks', text: 'Кассы предприятия', divider: true, roles: ['ADMIN'] },
      { to: '/customer-statuses', text: 'Статусы клиентов', roles: ['ADMIN'] },
      { to: '/customer-categories', text: 'Категории клиентов', roles: ['ADMIN'] },
      { to: '/customer-document-types', text: 'Виды документов, удостоверяющих личность', roles: ['ADMIN'] },
      {
        to: '/terrorist-organizations',
        text: 'Перечень организаций и лиц, связанных с финансированием терроризма и экстремизма',
        roles: ['ADMIN'],
      },
      { to: '/mobile-operators', text: 'Мобильные операторы', roles: ['ADMIN'] },
      { to: '/countries', text: 'Страны', divider: true, roles: ['ADMIN'] },
      { to: '/nomenclature-groups', text: 'Номенклатурные группы', roles: ['ADMIN'] },
      { to: '/pledge-documents', text: 'Шаблоны документов', roles: ['ADMIN'] },
      { to: '/nomenclatures', text: 'Типовые изделия (Номенклатура)', roles: ['ADMIN'] },
      { to: '/units', text: 'Единицы измерения', roles: ['ADMIN'] },
      { to: '/samples', text: 'Пробы', divider: true, roles: ['ADMIN'] },
      {
        to: '/collateral-chars',
        text: 'Характеристики залоговых вещей',
        list: [
          { to: '/fur-coat/categories', text: 'Категории расценок норковых шуб', divider: true, roles: ['ADMIN'] },
          { to: '/gems', text: 'Драгоценные камни', roles: ['ADMIN'] },
          { to: '/gem-cut-shapes', text: 'Формы огранок драгоценных камней', divider: true, roles: ['ADMIN'] },
          { to: '/cars', text: 'Модели автомобилей', roles: ['ADMIN'] },
          { to: '/car-brands', text: 'Бренды автомобилей', divider: true, roles: ['ADMIN'] },
          { to: '/electronic-models', text: 'Модели электроники', roles: ['ADMIN'] },
          { to: '/electronic-brands', text: 'Бренды электроники', roles: ['ADMIN'] },
        ],
        divider: true,
        roles: ['ADMIN'],
      },
      { to: '/currencies', text: 'Валюты', roles: ['ADMIN'] },
      { to: '/employees', text: 'Сотрудники', roles: ['ADMIN'] },
      { to: '/positions', text: 'Должности', roles: ['ADMIN'] },
      { to: '/roles', text: 'Роли', divider: true, roles: ['ADMIN'] },
      { to: '/regions', text: 'Адресный класификатор', roles: ['ADMIN'] },
      { to: '/computers', text: 'Компьютеры', roles: ['ADMIN'] },
      { to: '/constants', text: 'Константы', roles: ['ADMIN'] },
      { to: '/holidays', text: 'Праздничные дни', roles: ['ADMIN'] },
      { to: '/colors', text: 'Палитра цветов', roles: ['ADMIN'] },
      { to: '/bonuses', text: 'Бонусные программы', roles: ['ADMIN'] },
    ],
    roles: ['ADMIN'],
  },
  {
    to: '/operations',
    Icon: DollarFlat,
    text: 'Журналы операций',
    list: [
      { to: '/pledges', text: 'Залоги', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/prolongations', text: 'Пролонгации', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/realizations', text: 'Реализации', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/buyouts', text: 'Выкупы', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      {
        to: '/cashbox-operations',
        text: 'Касса (Внеломбардный приход/расход)',
        roles: ['ADMIN', 'CASHIER', 'REVISOR'],
      },
      { to: '/technical-requests', text: 'Технические заявки', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/checks', text: 'Чеки', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/shifts', text: 'Смены', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
    ],
    roles: ['ADMIN', 'CASHIER', 'REVISOR'],
  },
  {
    to: '/reports',
    Icon: FileFlat,
    text: 'Отчёты',
    list: [
      { to: '/payments-to-experts', text: 'Выплаты экспертам', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/active-pledges', text: 'Активные залоги', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/cashbox-operations', text: 'Движение по кассе', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
      { to: '/webkassa-x', text: 'Движение по веб-кассе', roles: ['ADMIN', 'CASHIER', 'REVISOR'] },
    ],
    roles: ['ADMIN', 'CASHIER', 'REVISOR'],
  },
];

const Sidebar: React.FC = () => {
  const { user, handleLogout } = useAuth();
  const { isShiftOpened, isShiftLoading, handleLoadShift } = useShifts();
  const { hasRoles } = useRoles();

  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [submenuAnchorEl, setSubMenuAnchorEl] = useState<HTMLElement | null>(null);

  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemProps | null>(null);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState<MenuItemProps | null>(null);

  function handleClickMenu(menuItem: MenuItemProps, index?: number, event?: React.MouseEvent<HTMLDivElement>) {
    const { to, list } = menuItem;

    if (to && !list) navigate(to);
    event && list ? setMenuAnchorEl(event.currentTarget) : setMenuAnchorEl(null);
    if ((index !== null && event) || list) setActiveMenuItem(menuItem);
  }

  function handleClickSubMenu(menuItem: MenuItemProps, index?: number, event?: React.MouseEvent<HTMLLIElement>) {
    const { to, list } = menuItem;

    if (to && !list) navigate(to);

    if (event && list) setSubMenuAnchorEl(event.currentTarget);
    else {
      setSubMenuAnchorEl(null);
      setMenuAnchorEl(null);
    }

    if ((index !== null && event) || list) setActiveSubMenuItem(menuItem);
  }

  return (
    <>
      <Drawer
        anchor="left"
        variant="permanent"
        sx={{ width: 90, flexShrink: 1 }}
        className="no-print"
        PaperProps={{ sx: { width: 90 } }}
      >
        <Stack spacing={8} sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
            <SuperLobmardPrimary width={58} />
          </Box>

          <Stack justifyContent="space-between" spacing={3} sx={{ flex: 1 }}>
            <List>
              {MENU_LIST.map((menuItem, index) => {
                const { to, Icon, text, roles } = menuItem,
                  selected = (to: string) => new RegExp(to).test(location.pathname);

                return (
                  hasRoles(roles) && (
                    <Tooltip title={text} placement="right" key={index}>
                      <ListItemButton
                        id={to}
                        selected={selected(to)}
                        onClick={(event) => handleClickMenu(menuItem, index, event)}
                        sx={{ p: 0 }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            p: 3,
                            boxShadow: selected(to) ? 'inset 3px 0 0 0 ' + theme.palette.primary.main : 'none',
                          }}
                        >
                          {Icon && <Icon color={selected(to) ? 'primary' : 'secondary'} />}
                        </Box>
                      </ListItemButton>
                    </Tooltip>
                  )
                );
              })}
            </List>

            <List>
              <ListItemButton disabled={isShiftLoading} onClick={() => navigate('/personal-area')} sx={{ p: 0 }}>
                <Tooltip title="Личный кабинет" placement="right">
                  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', p: 3 }}>
                    <UserFlat color="primary" />
                  </Box>
                </Tooltip>
              </ListItemButton>

              <ListItemButton
                disabled={isShiftLoading}
                onClick={() => (isShiftOpened ? user && handleLoadShift(user, 'close') : handleLogout())}
                sx={{ p: 0 }}
              >
                <Tooltip title="Выход" placement="right">
                  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', p: 3 }}>
                    <LogoutFlat color="error" />
                  </Box>
                </Tooltip>
              </ListItemButton>
            </List>
          </Stack>
        </Stack>
      </Drawer>

      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        PaperProps={{ sx: { bgcolor: theme.palette.secondary.light } }}
        MenuListProps={{ 'aria-labelledby': activeMenuItem?.to, sx: { color: 'secondary', maxWidth: 360 } }}
      >
        {activeMenuItem?.list?.map((menuItem, index) => {
          const { divider, text, to, roles } = menuItem,
            selected = (to: string) => new RegExp(to).test(location.pathname);

          return (
            hasRoles(roles) && (
              <MenuItem
                key={index}
                onClick={(event) =>
                  handleClickSubMenu({ ...menuItem, to: activeMenuItem.to + menuItem.to }, index, event)
                }
                divider={divider}
                selected={selected(to)}
              >
                <ListItemText sx={{ color: theme.palette.secondary.contrastText }}>
                  <Typography noWrap>{text}</Typography>
                </ListItemText>
              </MenuItem>
            )
          );
        })}
      </Menu>

      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        PaperProps={{ sx: { bgcolor: theme.palette.secondary.light } }}
        MenuListProps={{ 'aria-labelledby': activeSubMenuItem?.to, sx: { color: 'secondary', maxWidth: 360 } }}
      >
        {activeSubMenuItem?.list?.map((menuItem, index) => {
          const { divider, text, to, roles } = menuItem,
            selected = (to: string) => new RegExp(to).test(location.pathname);

          return (
            hasRoles(roles) && (
              <MenuItem
                key={index}
                onClick={() => handleClickSubMenu({ ...menuItem, to: activeSubMenuItem.to + menuItem.to }, index)}
                divider={divider}
                selected={selected(to)}
              >
                <ListItemText sx={{ color: theme.palette.secondary.contrastText }}>
                  <Typography noWrap>{text}</Typography>
                </ListItemText>
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default Sidebar;
