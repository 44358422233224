import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';

import { Cashbox } from '@/types';
import { useCreateCashboxMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateCashboxProps extends DialogProps {
  onClose(): void;
}

const CreateCashbox: React.FC<CreateCashboxProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createCashbox, createCashboxResponse] = useCreateCashboxMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Cashbox>({
    initialValues: {
      code: '',
      name: '',
      url: '',
      factory_number: '',
      operator_token: '',
      original_cashbox_id: '',
      registration_number: '',
      is_webkassa_enabled: 0,
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
      url: yup.string().required(messages.form.REQUIRED),
      factory_number: yup.string().required(messages.form.REQUIRED),
      operator_token: yup.string().required(messages.form.REQUIRED),
      original_cashbox_id: yup.string().required(messages.form.REQUIRED),
      registration_number: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(cashbox, { resetForm }) {
      try {
        await createCashbox(cashbox).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить кассу</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="url"
                label="URL"
                placeholder="Введите значение"
                value={values.url}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url ? errors.url : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="factory_number"
                label="Заводской номер"
                placeholder="Введите значение"
                value={values.factory_number}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.factory_number && Boolean(errors.factory_number)}
                helperText={touched.factory_number ? errors.factory_number : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="operator_token"
                label="Токен оператора"
                placeholder="Введите значение"
                value={values.operator_token}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.operator_token && Boolean(errors.operator_token)}
                helperText={touched.operator_token ? errors.operator_token : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="original_cashbox_id"
                label="ID кассы"
                placeholder="Введите значение"
                value={values.original_cashbox_id}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.original_cashbox_id && Boolean(errors.original_cashbox_id)}
                helperText={touched.original_cashbox_id ? errors.original_cashbox_id : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="registration_number"
                label="Рег. номер кассы"
                placeholder="Введите значение"
                value={values.registration_number}
                onChange={handleChange}
                disabled={createCashboxResponse.isLoading}
                error={touched.registration_number && Boolean(errors.registration_number)}
                helperText={touched.registration_number ? errors.registration_number : ''}
                fullWidth
              />
            </Grid>

            <Grid item alignSelf="center" lg={6} md={6} xs={12}>
              <FormControlLabel
                label="Интеграция с веб-кассой"
                control={
                  <Switch
                    name="is_webkassa_enabled"
                    disabled={createCashboxResponse.isLoading}
                    value={values.is_webkassa_enabled}
                    onChange={(event, checked) => setFieldValue('is_webkassa_enabled', checked ? 1 : 0)}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createCashboxResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createCashboxResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCashbox;
