import { PropsWithChildren, createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cookies from 'react-cookies';
import { useSnackbar } from 'notistack';

import { Cridentials, Employee, Roles, User } from '@/types';
import baseApi from '@/api';
import { useLoginMutation } from '@/services';
import { useActions } from '@/hooks';
import { Cookies } from '@/utils/enums';

export interface AuthProviderProps {
  token: string | null;
  user: User | null;
  employee: Employee | null;

  isAdmin: boolean;
  isRevisor: boolean;
  isCashier: boolean;

  isLoading: boolean;
  handleLogin(values: Cridentials): void;
  handleLogout(): void;
}

const initialAuthProviderValues: AuthProviderProps = {
  token: null,
  user: null,
  employee: null,
  isAdmin: false,
  isRevisor: false,
  isCashier: false,
  isLoading: false,
  handleLogin() {},
  handleLogout() {},
};

export const AuthContext = createContext<AuthProviderProps>(initialAuthProviderValues);

const AuthProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useActions();

  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(cookies.load(Cookies.USER));
  const [token, setToken] = useState<string | null>(cookies.load(Cookies.TOKEN));

  const { enqueueSnackbar } = useSnackbar();

  const [login] = useLoginMutation();

  async function handleLogin(values: Cridentials) {
    try {
      setLoading(true);
      await fetch('/sanctum/csrf-cookie');
      const user = await login(values).unwrap();

      const { employee, point, id } = user;

      cookies.save(Cookies.USER, { employee, point, id }, { path: '/' });
      cookies.save(Cookies.TOKEN, user.auth_token.plainTextToken, { path: '/' });
      setToken(user.auth_token.plainTextToken);
      setUser(user);

      navigate((location.state as { from: { pathname: string } })?.from.pathname ?? '/');
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function handleLogout() {
    setUser(null);
    setToken(null);
    cookies.remove(Cookies.USER, { path: '/' });
    cookies.remove(Cookies.TOKEN, { path: '/' });
    navigate('/auth/login', { state: { from: location } });

    setTimeout(() => dispatch(baseApi.util.resetApiState()), 0);
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        employee: user?.employee!,
        isAdmin: user?.employee?.role?.code === Roles.ADMIN,
        isRevisor: user?.employee?.role?.code === Roles.REVISOR,
        isCashier: user?.employee?.role?.code === Roles.CASHIER,
        isLoading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
