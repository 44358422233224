export enum Roles {
  ADMIN = 'admin',
  REVISOR = 'revisor',
  CASHIER = 'cashier',
}

export interface Role {
  id?: number;
  name: string;
  code: Roles;
}
