import { Employee, Pledge } from '@/types';

export enum BuyoutStatuses {
  CARRIED_OUT = 'carried_out',
  CANCELLED = 'cancelled',
}

export interface Buyout {
  id?: number;
  name?: string;
  pledge?: Pledge | null;
  pledge_id?: Pledge['id'];
  re_pledge?: Pledge | null;
  status?: BuyoutStatuses;
  employee?: Employee;
  carried_by?: Employee;
  updated_by?: Employee;
  created_at?: Date;
}
