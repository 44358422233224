import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Sample } from '@/types';
import { useDeleteSampleMutation } from '@/services';

export interface DeleteSampleProps extends DialogProps {
  sample: Sample;
  onClose(): void;
}

const DeleteSample: React.FC<DeleteSampleProps> = ({ sample, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteSample, deleteSampleResponse] = useDeleteSampleMutation();

  async function handleDeleteSample() {
    try {
      await deleteSample(sample).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{sample?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{sample?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteSampleResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteSampleResponse.isLoading} onClick={handleDeleteSample}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSample;
