import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Area } from '@/types';
import { useGetAreasQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchAreas<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Area> } & Omit<
  AutocompleteProps<Area, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetAreasQueryProps = useAsyncSearch<Area, Multiple, DisableClearable, FreeSolo>(
    useGetAreasQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetAreasQueryProps} />;
}

export default SearchAreas;
