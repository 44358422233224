import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Account, ListResponse } from '@/types';

const accountApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Accounts'],
});

const accountApi = accountApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<ListResponse<Account>, AxiosRequestConfig<Account>>({
      query: (config) => ({ url: '/admin/bank-requisite', ...config }),
      providesTags: ['Accounts'],
    }),
    createAccount: builder.mutation<Account, Account>({
      query: ({ currency, organization, ...account }) => ({
        url: '/admin/bank-requisite',
        method: 'POST',
        data: { ...account, organization_id: organization?.id, currency_id: currency?.id },
      }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation<Account, Account>({
      query: ({ currency, organization, ...account }) => ({
        url: '/admin/bank-requisite/' + account.id,
        method: 'PUT',
        data: { ...account, organization_id: organization?.id, currency_id: currency?.id },
      }),
      invalidatesTags: ['Accounts'],
    }),
    deleteAccount: builder.mutation<unknown, Account>({
      query: (account) => ({ url: '/admin/bank-requisite/' + account.id, method: 'DELETE' }),
      invalidatesTags: ['Accounts'],
    }),
  }),
});

const { useGetAccountsQuery, useCreateAccountMutation, useUpdateAccountMutation, useDeleteAccountMutation } =
  accountApi;

export { useGetAccountsQuery, useCreateAccountMutation, useUpdateAccountMutation, useDeleteAccountMutation };
