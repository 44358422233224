import { useGetEmployeeQuery } from '@/services';
import { useAuth } from '@/hooks';
import { AppBar, Empty, Error, Loader } from '@/components';

import { EmployeeInfo } from './components';

export interface PersonalAreaProps {}

const PersonalArea: React.FC<PersonalAreaProps> = () => {
  const { user } = useAuth();

  const { data: employee, isLoading, isSuccess, isError } = useGetEmployeeQuery({ id: +user?.employee?.id! });

  return (
    <>
      <AppBar title="Личный кабинет" />

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error text="Произошла ошибка с загрузкой сотрудника! Пожалуйста, попробуйте позже" />
      ) : isSuccess && Boolean(employee?.data) ? (
        <EmployeeInfo employee={employee.data} />
      ) : (
        <Empty text="Сотрудник не найден" />
      )}
    </>
  );
};

export default PersonalArea;
