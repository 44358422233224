import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Buyout } from '@/types';
import { useDeleteBuyoutMutation } from '@/services';

export interface DeleteBuyoutProps extends DialogProps {
  buyout: Buyout;
  onClose(): void;
}

const DeleteBuyout: React.FC<DeleteBuyoutProps> = ({ buyout, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteBuyout, deleteBuyoutResponse] = useDeleteBuyoutMutation();

  async function handleDeleteBuyout() {
    try {
      await deleteBuyout(buyout).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{buyout?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{buyout?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteBuyoutResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteBuyoutResponse.isLoading} size="small" onClick={handleDeleteBuyout}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBuyout;
