import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { ElectronicBrand } from '@/types';
import { useGetElectronicBrandsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchElectronicBrands<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<ElectronicBrand> } & Omit<
  AutocompleteProps<ElectronicBrand, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetElectronicBrandsQueryProps = useAsyncSearch<ElectronicBrand, Multiple, DisableClearable, FreeSolo>(
    useGetElectronicBrandsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetElectronicBrandsQueryProps} />;
}

export default SearchElectronicBrands;
