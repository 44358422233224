import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Cashbox, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteCashbox, UpdateCashbox } from '../components';

export interface CashboxListProps {
  list?: ListResponse<Cashbox>;
  page: number;
  onChangePage(page: number): void;
}

const CashdesksTable: React.FC<CashboxListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentCashbox, setCurrentCashbox] = useState<Cashbox | null>(null);

  function openUpdateModal(cashbox: Cashbox) {
    setCurrentCashbox(cashbox);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(cashbox: Cashbox) {
    setCurrentCashbox(cashbox);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Код</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Заводской номер</TableCell>
                <TableCell>Токен оператора</TableCell>
                <TableCell>ID кассы</TableCell>
                <TableCell>Рег. номер кассы</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((cashbox) => {
                const {
                  code,
                  factory_number,
                  name,
                  operator_token,
                  original_cashbox_id,
                  registration_number,
                  url,
                  id,
                } = cashbox;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(cashbox)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(cashbox)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{code}</TableCell>
                    <TableCell>{url}</TableCell>
                    <TableCell>{factory_number}</TableCell>
                    <TableCell>{operator_token}</TableCell>
                    <TableCell>{original_cashbox_id}</TableCell>
                    <TableCell>{registration_number}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateCashbox cashbox={currentCashbox!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeleteCashbox cashbox={currentCashbox!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default CashdesksTable;
