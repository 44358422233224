import { useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { api } from '@/api';
import { ServerError, ServerResponseState } from '@/types';
import { downloadFile, getFilenameFromContentDisposition } from '@/utils/helpers';

export interface DownloadFileProps<T> extends AxiosRequestConfig<T> {
  fileName?: string;
  onDownload?(): void;
  children(onClick: () => void, state: ServerResponseState): React.ReactNode;
}

const DownloadFile = <T,>({ fileName, children, onDownload, ...config }: DownloadFileProps<T>) => {
  const [downloadFileState, setDownloadFileState] = useState<ServerResponseState>({
    isError: false,
    isLoading: false,
    isFetching: false,
    isSuccess: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    try {
      setDownloadFileState((state) => ({ ...state, isLoading: true, isFetching: true }));

      const response = (await api({
        ...config,
        responseType: 'blob',
        method: config.method || 'POST',
      })) as AxiosResponse<T>;

      downloadFile(
        response.data as Blob,
        fileName ? fileName : getFilenameFromContentDisposition(response.headers?.['content-disposition'])!,
        response.headers?.['content-type'],
      );

      setDownloadFileState({ isLoading: false, isFetching: false, isError: false, isSuccess: true });

      if (onDownload) onDownload();
    } catch (error) {
      setDownloadFileState({ isLoading: false, isFetching: false, isError: true, isSuccess: false });
      enqueueSnackbar((error as ServerError).data, { variant: 'error' });
    }
  }

  return <>{children(handleSubmit, downloadFileState)}</>;
};

export default DownloadFile;
