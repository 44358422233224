import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { MobileOperator } from '@/types';
import { useCreateMobileOperatorMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateMobileOperatorProps extends DialogProps {
  onClose(): void;
}

const CreateMobileOperator: React.FC<CreateMobileOperatorProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createMobileOperator, createMobileOperatorResponse] = useCreateMobileOperatorMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<MobileOperator>({
    initialValues: {
      name: '',
      reserved_codes: '',
      site_code: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      reserved_codes: yup.string().required(messages.form.REQUIRED),
      site_code: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(mobileOperator, { resetForm }) {
      try {
        await createMobileOperator(mobileOperator).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить мобильного оператора</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createMobileOperatorResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="site_code"
                label="Код оператора"
                placeholder="Введите значение"
                value={values.site_code}
                onChange={handleChange}
                disabled={createMobileOperatorResponse.isLoading}
                error={touched.site_code && Boolean(errors.site_code)}
                helperText={touched.site_code ? errors.site_code : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="reserved_codes"
                label="Зарезервированные коды номеров"
                placeholder="Введите значение"
                value={values.reserved_codes}
                onChange={handleChange}
                disabled={createMobileOperatorResponse.isLoading}
                error={touched.reserved_codes && Boolean(errors.reserved_codes)}
                helperText={
                  touched.reserved_codes
                    ? errors.reserved_codes
                    : 'ВАЖНО: Введите коды через запятую (###, ###, ###, ...)'
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createMobileOperatorResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createMobileOperatorResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateMobileOperator;
