import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { CollateralItemCharTypes, NomenclatureGroupChar } from '@/types';
import { useCreateNomenclatureGroupCharMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { COLLATERAL_ITEM_CHARS } from '@/utils/collateral-item-chars';

export interface CreateNomenclatureGroupCharProps extends DialogProps {
  onClose(): void;
}

const CreateNomenclatureGroupChar: React.FC<CreateNomenclatureGroupCharProps> = ({ onClose, ...props }) => {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const [createNomenclatureGroupChar, createNomenclatureGroupCharResponse] = useCreateNomenclatureGroupCharMutation();

  const { values, errors, touched, setFieldValue, setValues, handleSubmit, handleChange } =
    useFormik<NomenclatureGroupChar>({
      initialValues: {
        name: '',
        nomenclature_group_id: +id!,
        type: '',
        value: undefined,
        required: false,
        min_value: 0,
      },
      validationSchema: yup.object().shape({
        name: yup.string().required(messages.form.REQUIRED),
        type: yup.string().required(messages.form.SELECT_FROM_LIST),
      }),
      async onSubmit(nomenclatureGroupChar, { resetForm }) {
        try {
          await createNomenclatureGroupChar(nomenclatureGroupChar).unwrap();

          onClose();
          resetForm();
        } catch (error) {
          enqueueSnackbar(error.data, { variant: 'error' });
        }
      },
    });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить характеристику</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createNomenclatureGroupCharResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="type"
                label="Тип поля"
                value={values.type}
                onChange={(event) =>
                  setValues((values) => ({
                    ...values,
                    type: event.target.value as CollateralItemCharTypes,
                    min_value: 0,
                    value: COLLATERAL_ITEM_CHARS.find(({ value }) => value === event.target.value)?.defaultValue,
                  }))
                }
                disabled={createNomenclatureGroupCharResponse.isLoading}
                error={touched.type && Boolean(errors.type)}
                helperText={touched.type ? errors.type : ''}
                fullWidth
              >
                {COLLATERAL_ITEM_CHARS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {values.type === CollateralItemCharTypes.STRING && (
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="value"
                  label="Значение по умолчанию"
                  placeholder="Введите значение"
                  value={values.value}
                  onChange={handleChange}
                  disabled={createNomenclatureGroupCharResponse.isLoading}
                  error={touched.value && Boolean(errors.value)}
                  helperText={touched.value ? errors.value : ''}
                  fullWidth
                />
              </Grid>
            )}

            {values.type === CollateralItemCharTypes.NUMERIC && (
              <>
                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="value"
                    label="Значение по умолчанию"
                    placeholder="Введите значение"
                    value={Number(values.value)}
                    onValueChange={({ value }) => setFieldValue('value', value)}
                    disabled={createNomenclatureGroupCharResponse.isLoading}
                    error={touched.value && Boolean(errors.value)}
                    helperText={touched.value ? errors.value : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    name="min_value"
                    label="Минимальное значение"
                    placeholder="Введите значение"
                    value={Number(values.min_value)}
                    onValueChange={({ value }) => setFieldValue('min_value', value)}
                    disabled={createNomenclatureGroupCharResponse.isLoading}
                    error={touched.min_value && Boolean(errors.min_value)}
                    helperText={touched.min_value ? errors.min_value : ''}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {values.type === CollateralItemCharTypes.BOOLEAN && (
              <Grid item lg={6} md={6} xs={12}>
                <FormControlLabel
                  label="Отмечено"
                  control={
                    <Checkbox
                      name="value"
                      value={values.value}
                      checked={Boolean(values.value)}
                      disabled={createNomenclatureGroupCharResponse.isLoading}
                      onChange={(event, checked) => setFieldValue('value', checked)}
                    />
                  }
                />
              </Grid>
            )}

            <Grid item alignSelf="center" lg={6} md={6} xs={12}>
              <FormControlLabel
                label="Обязательное поле"
                control={
                  <Checkbox
                    name="required"
                    value={values.required}
                    checked={Boolean(values.required)}
                    disabled={createNomenclatureGroupCharResponse.isLoading}
                    onChange={(event, checked) => setFieldValue('required', checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createNomenclatureGroupCharResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createNomenclatureGroupCharResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNomenclatureGroupChar;
