import { combineReducers, configureStore } from '@reduxjs/toolkit';

import baseApi from '@/api';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
});

const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
  });

export const store = setupStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
