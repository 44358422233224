import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik';

import { Pledge } from '@/types';
import { useConstant } from '@/hooks';
import { useCreatePledgeMutation } from '@/services';

function useCreatePledge(onCreate?: (pledge: Pledge, helpers: FormikHelpers<Pledge>) => void) {
  const { enqueueSnackbar } = useSnackbar();

  const [createPledge, createPledgeResponse] = useCreatePledgeMutation();

  const max_mrp_constraints = useConstant('MAX_MRP_CONSTRAINTS');

  async function handleCreatePledge(pledge: Pledge, helpers: FormikHelpers<Pledge>) {
    const MAKE_PLEDGE_CONSTRAINTS = pledge.return_sum_to_hand > +max_mrp_constraints.value,
      MAX_COLLATERAL_ITEMS_CONSTRAINTS = pledge.collateral_items.length > 5;

    if (MAX_COLLATERAL_ITEMS_CONSTRAINTS) return enqueueSnackbar('Нельзя добавить в ЗБ более 5-ти изделий');

    if (MAKE_PLEDGE_CONSTRAINTS && pledge.collateral_items.length > 1)
      return enqueueSnackbar(
        `Оценка залоговых вещей превышает ${+max_mrp_constraints.value}. В списке больше, чем 1 залоговая вещь. Необходимо разбить залог на несколько`,
        { variant: 'warning' },
      );

    if (MAKE_PLEDGE_CONSTRAINTS)
      enqueueSnackbar(`Залоговая вещь оценена свыше ${+max_mrp_constraints.value}. Будет создан составной залог`, {
        variant: 'info',
      });

    try {
      await createPledge(pledge).unwrap();

      onCreate?.(pledge, helpers);
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return { handleCreatePledge, createPledgeResponse };
}

export default useCreatePledge;
