export enum Cookies {
  USER = 'USER',
  TOKEN = 'TOKEN',
}

export enum Genders {
  MALE = 1,
  FEMALE = 2,
}

export enum CollateralTypes {
  JEVELRY = 1,
  BIJOUTERIE = 2,
  VEHICLES = 3,
  ELECTRICAL_EQUIPMENT = 4,
  CELLULAR_DEVICES = 5,
  COLLECTIBLES = 6,
  OTHER = 7,
}

export enum NomenclatureGroups {
  JEVELRY = 'Ювелирные изделия',
  CARS = 'Автомобили',
  FUR_COATS = 'Шубы',
  REAL_ESTATES = 'Недвижимость',
  TECHNIQUE = 'Техника',
  OTHER = 'Прочие',
}

export enum PaymentMethods {
  CASH = 1,
  BANK = 2,
  KASPI_GOLD = 3,
  POS_TERMINAL = 4,
  MOBILE_APP = 5,
}

export enum StoneTypes {
  PEARL = 1,
  OPAQUE = 2,
  TRANSPARENT = 3,
  AMBER = 4,
}

export enum StoneForms {
  CABOCHON = 1,
  UNEXTENDED = 2,
  ELONGATED = 3,
  BALL = 4,
}

export enum PercentTypes {
  PIECE_RATE_FOR_COLLATERAL = 1,
  PIECE_RATE_FOR_REMORTGAGES = 2,
  PIECE_RATE_FOR_PROLONGATIONS = 3,
  ADD_PIECE_RATE_FOR_PROLONGATIONS = 4,
  PIECE_RATE_FOR_BUYOUTS = 5,
}

export enum Samples {
  'S_500' = '500',
  'S_999' = '999',
  'S_385' = '385',
  'S_750' = '750',
  'S_585' = '585',
}

export enum Constants {
  MRP = 'mrp',
  MAX_MRP_CONSTRAINTS = 'max_mrp_constraints',
  PLEDGE_DAYS_COUNT = 'pledge_days_count',
}
