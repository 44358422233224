import { useParams } from 'react-router';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { IntervalCollateralRate } from '@/types';
import { useCreateIntervalCollateralRateMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { PERIOD_TYPES } from '@/utils/period-types';
import { SearchPoints } from '@/pages/Points/components';
import { SearchCustomerStatuses } from '@/pages/CustomerStatuses/components';

export interface CreateIntervalCollateralRateProps extends DialogProps {
  onClose(): void;
}

const CreateIntervalCollateralRate: React.FC<CreateIntervalCollateralRateProps> = ({ onClose, ...props }) => {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const [createIntervalCollateralRate, createIntervalCollateralRateResponse] =
    useCreateIntervalCollateralRateMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<IntervalCollateralRate>({
    initialValues: {
      customer_status: null,
      nomenclature_group_interval_id: +id!,
      percent: '',
      period_type: '',
      point: null,
    },
    validationSchema: yup.object().shape({
      customer_status: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      percent: yup.string().required(messages.form.REQUIRED),
      period_type: yup.string().required(messages.form.SELECT_FROM_LIST),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(intervalCollateralRate, { resetForm }) {
      try {
        await createIntervalCollateralRate(intervalCollateralRate).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить ставку</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={createIntervalCollateralRateResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCustomerStatuses
                value={values.customer_status}
                onChange={(event, option) => setFieldValue('customer_status', option)}
                disabled={createIntervalCollateralRateResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customer_status"
                    label="Статус клиента"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.customer_status && errors.customer_status)}
                    helperText={touched.customer_status && errors.customer_status}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="period_type"
                label="Период глубины залога"
                value={values.period_type}
                onChange={handleChange}
                disabled={createIntervalCollateralRateResponse.isLoading}
                error={touched.period_type && Boolean(errors.period_type)}
                helperText={touched.period_type ? errors.period_type : ''}
                fullWidth
              >
                {PERIOD_TYPES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="percent"
                label="Процент"
                placeholder="Введите значение"
                value={values.percent}
                onChange={handleChange}
                disabled={createIntervalCollateralRateResponse.isLoading}
                error={touched.percent && Boolean(errors.percent)}
                helperText={touched.percent ? errors.percent : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createIntervalCollateralRateResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createIntervalCollateralRateResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateIntervalCollateralRate;
