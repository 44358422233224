import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Position, ListResponse } from '@/types';

const positionApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Positions'],
});

const positionApi = positionApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPositions: builder.query<ListResponse<Position>, AxiosRequestConfig<Position>>({
      query: (config) => ({ url: '/admin/position', ...config }),
      providesTags: ['Positions'],
    }),
    createPosition: builder.mutation<Position, Position>({
      query: (position) => ({ url: '/admin/position', method: 'POST', data: position }),
      invalidatesTags: ['Positions'],
    }),
    updatePosition: builder.mutation<Position, Position>({
      query: (position) => ({ url: '/admin/position/' + position.id, method: 'PUT', data: position }),
      invalidatesTags: ['Positions'],
    }),
    deletePosition: builder.mutation<unknown, Position>({
      query: (position) => ({ url: '/admin/position/' + position.id, method: 'DELETE' }),
      invalidatesTags: ['Positions'],
    }),
  }),
});

const { useGetPositionsQuery, useCreatePositionMutation, useUpdatePositionMutation, useDeletePositionMutation } =
  positionApi;

export { useGetPositionsQuery, useCreatePositionMutation, useUpdatePositionMutation, useDeletePositionMutation };
