import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureRateCategory } from '@/types';
import { useGetNomenclatureRateCategoriesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureRateCategories<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<NomenclatureRateCategory> } & Omit<
  AutocompleteProps<NomenclatureRateCategory, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclatureRateCategoriesQueryProps = useAsyncSearch<
    NomenclatureRateCategory,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureRateCategoriesQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetNomenclatureRateCategoriesQueryProps} />;
}

export default SearchNomenclatureRateCategories;
