import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Region, ListResponse } from '@/types';

const regionApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Regions'],
});

const regionApi = regionApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query<ListResponse<Region>, AxiosRequestConfig<Region>>({
      query: (config) => ({ url: '/admin/region', ...config }),
      providesTags: ['Regions'],
    }),
    createRegion: builder.mutation<Region, Region>({
      query: (region) => ({ url: '/admin/region', method: 'POST', data: region }),
      invalidatesTags: ['Regions'],
    }),
    updateRegion: builder.mutation<Region, Region>({
      query: (region) => ({ url: '/admin/region/' + region.id, method: 'PUT', data: region }),
      invalidatesTags: ['Regions'],
    }),
    deleteRegion: builder.mutation<unknown, Region>({
      query: (region) => ({ url: '/admin/region/' + region.id, method: 'DELETE' }),
      invalidatesTags: ['Regions'],
    }),
  }),
});

const { useGetRegionsQuery, useCreateRegionMutation, useUpdateRegionMutation, useDeleteRegionMutation } = regionApi;

export { useGetRegionsQuery, useCreateRegionMutation, useUpdateRegionMutation, useDeleteRegionMutation };
