import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { FolderNames, Gem } from '@/types';
import { useUpdateGemMutation, useUploadFileMutation } from '@/services';
import { FILE_SIZE, messages, SUPPORTED_FORMATS } from '@/utils/helpers';
import { FileInput } from '@/components';

export interface UpdateGemProps extends DialogProps {
  gem: Gem;
  onClose(): void;
}

const UpdateGem: React.FC<UpdateGemProps> = ({ gem, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [uploadFile] = useUploadFileMutation();
  const [updateGem] = useUpdateGemMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Gem>({
    initialValues: gem ?? {
      name: '',
      density: 0,
      file: null,
      web_slug: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      density: yup.number().required(messages.form.REQUIRED),
      file: yup
        .mixed()
        .test('fileSize', messages.form.LARGE_FILE, (file) => (!file ? true : file.size <= FILE_SIZE))
        .test('fileFormat', messages.form.UNSUPPORTED_FILE_TYPE, (file) =>
          !file ? true : file && SUPPORTED_FORMATS.includes(file.type),
        ),
      web_slug: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit({ file, ...gem }, { resetForm }) {
      try {
        setLoading(true);

        await updateGem({
          ...gem,
          file: file && (await uploadFile({ file: file as File, folder_name: FolderNames.GEMS }).unwrap()),
        }).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать драгоценный камень</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={loading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="density"
                label="Плотность"
                placeholder="Введите значение"
                value={values.density}
                onValueChange={({ value }) => setFieldValue('density', value)}
                disabled={loading}
                error={touched.density && Boolean(errors.density)}
                helperText={touched.density ? errors.density : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="web_slug"
                label="web_slug"
                placeholder="Введите значение"
                value={values.web_slug}
                onChange={handleChange}
                disabled={loading}
                error={touched.web_slug && Boolean(errors.web_slug)}
                helperText={touched.web_slug ? errors.web_slug : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FileInput
                name="file"
                label="Изображение"
                onChange={([file]) => setFieldValue('file', file)}
                disabled={loading}
                error={touched.file && Boolean(errors.file)}
                helperText={touched.file ? errors.file : ''}
                DropzoneProps={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png'] } }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={loading} onClick={onClose}>
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={loading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateGem;
