import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Sample, ListResponse } from '@/types';

const sampleApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Samples'],
});

const sampleApi = sampleApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSamples: builder.query<ListResponse<Sample>, AxiosRequestConfig<Sample>>({
      query: (config) => ({ url: '/admin/sample', ...config }),
      providesTags: ['Samples'],
    }),
    createSample: builder.mutation<Sample, Sample>({
      query: ({ customer_status_prices_for_points, ...sample }) => ({
        url: '/admin/sample',
        method: 'POST',
        data: {
          ...sample,
          customer_status_prices_for_points: customer_status_prices_for_points.map(({ point, ...price }) => ({
            ...price,
            point_id: point?.id,
          })),
        },
      }),
      invalidatesTags: ['Samples'],
    }),
    updateSample: builder.mutation<Sample, Sample>({
      query: ({ customer_status_prices_for_points, ...sample }) => ({
        url: '/admin/sample/' + sample.id,
        method: 'PUT',
        data: {
          ...sample,
          customer_status_prices_for_points: customer_status_prices_for_points.map(({ point, ...price }) => ({
            ...price,
            point_id: point?.id,
          })),
        },
      }),
      invalidatesTags: ['Samples'],
    }),
    deleteSample: builder.mutation<unknown, Sample>({
      query: (sample) => ({ url: '/admin/sample/' + sample.id, method: 'DELETE' }),
      invalidatesTags: ['Samples'],
    }),
  }),
});

const { useGetSamplesQuery, useCreateSampleMutation, useUpdateSampleMutation, useDeleteSampleMutation } = sampleApi;

export { useGetSamplesQuery, useCreateSampleMutation, useUpdateSampleMutation, useDeleteSampleMutation };
