import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { NomenclatureGroupFine } from '@/types';
import { useUpdateNomenclatureGroupFineMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchPoints } from '@/pages/Points/components';

export interface UpdateNomenclatureGroupFineProps extends DialogProps {
  nomenclatureGroupFine: NomenclatureGroupFine;
  onClose(): void;
}

const UpdateNomenclatureGroupFine: React.FC<UpdateNomenclatureGroupFineProps> = ({
  nomenclatureGroupFine,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateNomenclatureGroupFine, updateNomenclatureGroupFineResponse] = useUpdateNomenclatureGroupFineMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<NomenclatureGroupFine>({
    initialValues: nomenclatureGroupFine ?? {
      gt_30_days: 0,
      lt_30_days: 0,
      point: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      gt_30_days: yup.number().required(messages.form.REQUIRED),
      lt_30_days: yup.number().required(messages.form.REQUIRED),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(nomenclatureGroupFine, { resetForm }) {
      try {
        await updateNomenclatureGroupFine(nomenclatureGroupFine).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать штраф</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="gt_30_days"
                type="tel"
                label="Общая пени (>= 30)"
                placeholder="Введите значение"
                value={values.gt_30_days}
                onChange={handleChange}
                disabled={updateNomenclatureGroupFineResponse.isLoading}
                error={touched.gt_30_days && Boolean(errors.gt_30_days)}
                helperText={touched.gt_30_days ? errors.gt_30_days : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="lt_30_days"
                type="tel"
                label="Общая пени (<= 30)"
                placeholder="Введите значение"
                value={values.lt_30_days}
                onChange={handleChange}
                disabled={updateNomenclatureGroupFineResponse.isLoading}
                error={touched.lt_30_days && Boolean(errors.lt_30_days)}
                helperText={touched.lt_30_days ? errors.lt_30_days : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={updateNomenclatureGroupFineResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateNomenclatureGroupFineResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateNomenclatureGroupFineResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateNomenclatureGroupFine;
