import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { TechnicalRequest, ListResponse } from '@/types';

const technicalRequestApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['TechnicalRequests'],
});

const technicalRequestApi = technicalRequestApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTechnicalRequests: builder.query<ListResponse<TechnicalRequest>, AxiosRequestConfig<TechnicalRequest>>({
      query: (config) => ({ url: '/admin/technical-request', ...config }),
      providesTags: ['TechnicalRequests'],
    }),
    createTechnicalRequest: builder.mutation<TechnicalRequest, TechnicalRequest>({
      query: ({ organization, point, position, ...technicalRequest }) => ({
        url: '/admin/technical-request',
        method: 'POST',
        data: {
          ...technicalRequest,
          organization_id: organization?.id,
          point_id: point?.id,
          position_id: position?.id,
        },
      }),
      invalidatesTags: ['TechnicalRequests'],
    }),
    updateTechnicalRequest: builder.mutation<TechnicalRequest, TechnicalRequest>({
      query: ({ executor, organization, point, position, id, ...technicalRequest }) => ({
        url: '/admin/technical-request/' + id,
        method: 'PUT',
        data: {
          ...technicalRequest,
          executor_id: executor?.id,
          organization_id: organization?.id,
          point_id: point?.id,
          position_id: position?.id,
        },
      }),
      invalidatesTags: ['TechnicalRequests'],
    }),
    deleteTechnicalRequest: builder.mutation<unknown, TechnicalRequest>({
      query: (technicalRequest) => ({ url: '/admin/technical-request/' + technicalRequest.id, method: 'DELETE' }),
      invalidatesTags: ['TechnicalRequests'],
    }),
  }),
});

const {
  useGetTechnicalRequestsQuery,
  useCreateTechnicalRequestMutation,
  useUpdateTechnicalRequestMutation,
  useDeleteTechnicalRequestMutation,
} = technicalRequestApi;

export {
  useGetTechnicalRequestsQuery,
  useCreateTechnicalRequestMutation,
  useUpdateTechnicalRequestMutation,
  useDeleteTechnicalRequestMutation,
};
