import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Nomenclature, ListResponse } from '@/types';
import { COLLATERAL_TYPES } from '@/utils/collateral-types';
import { OrderDirections } from '@/utils/order-directions';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteNomenclature, UpdateNomenclature } from '../components';

export interface NomenclatureListProps {
  list?: ListResponse<Nomenclature>;
  page: number;
  onChangePage(page: number): void;
}

const NomenclaturesTable: React.FC<NomenclatureListProps> = ({ list, page, onChangePage }) => {
  const [{ order_direction }] = useQueryParams({
    order_direction: withDefault(StringParam, OrderDirections.DESC),
  });

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentNomenclature, setCurrentNomenclature] = useState<Nomenclature | null>(null);

  function openUpdateModal(nomenclature: Nomenclature) {
    setCurrentNomenclature(nomenclature);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(nomenclature: Nomenclature) {
    setCurrentNomenclature(nomenclature);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Код</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Номенклатурная группа</TableCell>
                <TableCell>Ед. измерения длины</TableCell>
                <TableCell>Ед. измерения массы</TableCell>
                <TableCell>Тип залога</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((nomenclature, index) => {
                const { name, nomenclature_group, length_unit, weight_unit, collateral_type, id } = nomenclature,
                  COLLATERAL_TYPE = COLLATERAL_TYPES.find(({ value }) => value === collateral_type);

                const nomenclatureIndex =
                  order_direction === OrderDirections.ASC
                    ? (list?.meta.total || 1) - index - (page - 1) * (list.meta.per_page || 1)
                    : index + 1 + (page - 1) * (list.meta.per_page || 1);

                return (
                  <TableRow key={id}>
                    <TableCell>{nomenclatureIndex}</TableCell>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(nomenclature)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(nomenclature)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Категории тарифов">
                          <IconButton
                            component={RouterLink}
                            to={`/dictionaries/nomenclatures/${id}/rate-categories`}
                            aria-label="Интервалы"
                          >
                            <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{nomenclature_group?.name ?? '—'}</TableCell>
                    <TableCell>{length_unit?.name ?? '—'}</TableCell>
                    <TableCell>{weight_unit?.name ?? '—'}</TableCell>
                    <TableCell title={COLLATERAL_TYPE?.label}>{COLLATERAL_TYPE?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateNomenclature
        nomenclature={currentNomenclature!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteNomenclature
        nomenclature={currentNomenclature!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default NomenclaturesTable;
