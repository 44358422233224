import { useFormikContext } from 'formik';
import { Box, Grid, TextField } from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { SearchColors } from '@/pages/Colors/components';
import { SearchFurCoatCategories } from '@/pages/FurCoatCategories/components';

export interface FurCoatProps {}

const FurCoat: React.FC<FurCoatProps> = () => {
  const { values, touched, errors, setValues, setFieldValue } = useFormikContext<{
    properties: NomenclatureGroup.FurCoat;
    primary_price: number;
    max_price: number;
  }>();

  return (
    <>
      <Grid item lg={6} md={6} xs={12}>
        <SearchFurCoatCategories
          value={values.properties.category}
          onChange={(event, category) =>
            setValues((values) => ({
              ...values,
              properties: { ...values.properties, category },
              primary_price: category?.price ?? 0,
              max_price: category?.price ?? 0,
            }))
          }
          getOptionLabel={(option) => option.name}
          renderInput={(props) => (
            <TextField
              {...props}
              name="properties.category"
              label="Категория вещи (расценка)"
              placeholder="Выберите из списка"
              error={Boolean(touched.properties?.category && errors.properties?.category)}
              helperText={touched.properties?.category && errors.properties?.category}
            />
          )}
        />
      </Grid>

      <Grid item lg={6} md={6} xs={12}>
        <SearchColors
          value={values.properties.color}
          onChange={(event, option) => setFieldValue('properties.color', option)}
          getOptionLabel={(option) => option.name}
          renderOption={(props, { name, hex }) => (
            <li {...props}>
              <Box sx={{ width: 32, height: 32, mr: 1, bgcolor: hex, borderRadius: '10em' }} />
              {name}
            </li>
          )}
          renderInput={(props) => (
            <TextField
              {...props}
              name="properties.color"
              label="Цвет"
              placeholder="Выберите из списка"
              error={Boolean(touched.properties?.color && errors.properties?.color)}
              helperText={touched.properties?.color && errors.properties?.color}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default FurCoat;
