import moment from 'moment';
import {
  ArrayParam,
  BooleanParam,
  DateParam,
  JsonParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import {
  Button,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Customer, Employee, NomenclatureGroup, Point } from '@/types';
import { useGetActivePledgesReportQuery, useGetActivePledgesSummaryReportQuery } from '@/services';
import { useAuth, useDebounce } from '@/hooks';
import { AppBar, DebouncedCheckbox, DebouncedTextField, DownloadFile, Empty, Error, Loader } from '@/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchNomenclatureGroups } from '@/pages/NomenclatureGroups/components';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchEmployees } from '@/pages/Employees/components';
import { PLEDGE_STATUSES } from '@/pages/Pledges';

import { ActivePledgesTable, ActivePledgesSummaryTable } from './components';
import { ChartGrowthFlat } from '@/assets/icons';

const ActivePledgesPage: React.FC = () => {
  const [
    {
      page,
      with_prolongations,
      with_collateral_items,
      points,
      nomenclature_groups,
      customers,
      employees,
      active_pledges,
      pledge_statuses,
      date_from,
      date_to,
      sum_from,
      sum_to,
    },
    setQuery,
  ] = useQueryParams({
    page: withDefault(NumberParam, 1),
    with_prolongations: withDefault(BooleanParam, false),
    with_collateral_items: withDefault(BooleanParam, false),
    points: withDefault<Partial<Point>[], Point[]>(JsonParam, []),
    nomenclature_groups: withDefault<Partial<NomenclatureGroup>[], NomenclatureGroup[]>(JsonParam, []),
    customers: withDefault<Partial<Customer>[], Customer[]>(JsonParam, []),
    employees: withDefault<Partial<Employee>[], Employee[]>(JsonParam, []),
    active_pledges: withDefault(NumberParam, 1),
    pledge_statuses: withDefault(ArrayParam, []),
    date_from: withDefault(DateParam, null),
    date_to: withDefault(DateParam, moment().toDate()),
    sum_from: withDefault(NumberParam, 0),
    sum_to: withDefault(NumberParam, 0),
  });

  const { user, isAdmin, isRevisor, isCashier } = useAuth();

  const point_ids = useDebounce(points.map(({ id }) => id)),
    nomenclature_group_ids = useDebounce(nomenclature_groups.map(({ id }) => id)),
    customer_ids = useDebounce(customers.map(({ id }) => id)),
    employee_ids = useDebounce(employees.map(({ id }) => id)),
    statuses = useDebounce(pledge_statuses),
    active = useDebounce(active_pledges);

  const params = {
    point_ids: isCashier ? [user?.point?.id] : point_ids,
    nomenclature_group_ids,
    customer_ids,
    employee_ids,
    active,
    statuses,
    date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
    date_to: moment(date_to).format('YYYY-MM-DD'),
    with_prolongations: with_prolongations ? 1 : 0,
    with_collateral_items: with_collateral_items ? 1 : 0,
  };

  const emptyFilters =
    isAdmin || isRevisor ? ![point_ids.length, customer_ids.length, employee_ids.length].some(Boolean) : false;

  const {
    data: activePledges,
    isLoading: activePledgesLoading,
    isFetching: activePledgesFetching,
    isSuccess: activePledgesSuccess,
    isError: activePledgesError,
  } = useGetActivePledgesReportQuery({ params: { ...params, page } }, { skip: emptyFilters });

  const {
    data: activePledgesSummary,
    isLoading: activePledgesSummaryLoading,
    isFetching: activePledgesSummaryFetching,
    isSuccess: activePledgesSummarySuccess,
    isError: activePledgesSummaryError,
  } = useGetActivePledgesSummaryReportQuery({ params }, { skip: emptyFilters });

  const isLoading = activePledgesLoading || activePledgesSummaryLoading;
  const isFetching = activePledgesFetching || activePledgesSummaryFetching;

  return (
    <>
      <AppBar title="Активные залоги" />

      <Grid container spacing={3}>
        <Grid item xs={12} className="no-print">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <FormGroup sx={{ flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <DebouncedCheckbox
                        value={with_prolongations}
                        checked={Boolean(with_prolongations)}
                        onChange={(event, checked) => setQuery({ with_prolongations: checked })}
                        disabled={isLoading || isFetching}
                      />
                    }
                    label="Вывести сведения о пролонгации"
                  />

                  <FormControlLabel
                    control={
                      <DebouncedCheckbox
                        value={with_collateral_items}
                        checked={Boolean(with_collateral_items)}
                        onChange={(event, checked) => setQuery({ with_collateral_items: checked })}
                        disabled={isLoading || isFetching}
                      />
                    }
                    label="Детализация по строкам документа"
                  />
                </FormGroup>

                {!emptyFilters && (
                  <Grid item>
                    <DownloadFile
                      url="/admin/reports/active-pledges/excel"
                      fileName="active-pledge.xlsx"
                      params={params}
                    >
                      {(onDownload, state) => (
                        <Button
                          variant="contained"
                          startIcon={<ChartGrowthFlat />}
                          size="large"
                          disabled={isFetching || isLoading || state.isLoading}
                          onClick={onDownload}
                        >
                          Сформировать отчёт
                        </Button>
                      )}
                    </DownloadFile>
                  </Grid>
                )}
              </Stack>
            </Grid>

            {(isAdmin || isRevisor) && (
              <>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <SearchPoints
                    multiple
                    value={points as Point[]}
                    disableCloseOnSelect
                    onChange={(event, options) => setQuery({ points: options.map(({ id, name }) => ({ id, name })) })}
                    disabled={isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="points"
                        label="Точки"
                        placeholder="Выберите из списка"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <SearchEmployees
                    multiple
                    value={employees as Employee[]}
                    limitTags={1}
                    disableCloseOnSelect
                    onChange={(event, options) =>
                      setQuery({
                        employees: options.map(({ id, name, last_name, father_name }) => ({
                          id,
                          name,
                          last_name,
                          father_name,
                        })),
                      })
                    }
                    disabled={isLoading}
                    getOptionLabel={({ name, last_name, father_name }) => `${last_name} ${name} ${father_name} `}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="employees"
                        label="Сотрудники"
                        placeholder="Выберите из списка"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <SearchNomenclatureGroups
                multiple
                value={nomenclature_groups as NomenclatureGroup[]}
                limitTags={1}
                disableCloseOnSelect
                onChange={(event, options) =>
                  setQuery({ nomenclature_groups: options.map(({ id, name }) => ({ id, name })) })
                }
                disabled={isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="nomenclature_groups"
                    label="Номенклатурные группы"
                    placeholder="Выберите из списка"
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <SearchCustomers
                multiple
                value={customers as Customer[]}
                limitTags={1}
                disableCloseOnSelect
                onChange={(event, options) =>
                  setQuery({
                    customers: options.map(({ id, name, last_name, father_name }) => ({
                      id,
                      name,
                      last_name,
                      father_name,
                    })),
                  })
                }
                disabled={isLoading}
                getOptionLabel={({ name, last_name, father_name }) =>
                  `${last_name ?? ''} ${name} ${father_name ?? ''} `
                }
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customers"
                    label="Клиенты"
                    placeholder="Выберите из списка"
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel>Статусы залогов</InputLabel>

                <Select
                  multiple
                  value={pledge_statuses}
                  onChange={(event) => setQuery({ pledge_statuses: event.target.value as string[] })}
                  input={<FilledInput />}
                  disabled={isLoading}
                >
                  {PLEDGE_STATUSES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextField
                select
                value={active}
                label="Активные залоги"
                disabled={isLoading}
                onChange={(event) => setQuery({ active_pledges: +event.target.value })}
                fullWidth
                variant="filled"
                size="small"
              >
                {[
                  { label: 'Активные', value: 1 },
                  { label: 'Неактивные', value: 0 },
                ].map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата от"
                value={date_from ? date_from : null}
                maxDate={moment(date_to)}
                onChange={(value) => setQuery({ date_from: moment(value).toDate() })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_from" fullWidth variant="filled" size="small" />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата до"
                value={date_to}
                maxDate={moment()}
                onChange={(value) => setQuery({ date_to: moment(value).toDate() })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_to" fullWidth variant="filled" size="small" />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DebouncedTextField
                type="number"
                name="sum_from"
                label="Сумма от"
                placeholder="Введите значение"
                value={sum_from}
                onChange={(event) => setQuery({ sum_from: +event.target.value })}
                disabled={isLoading}
                fullWidth
                variant="filled"
                size="small"
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DebouncedTextField
                type="number"
                name="sum_to"
                label="Сумма до"
                placeholder="Введите значение"
                value={sum_to}
                onChange={(event) => setQuery({ sum_to: +event.target.value })}
                disabled={isLoading}
                fullWidth
                variant="filled"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>

        {emptyFilters ? (
          <Grid item xs={12}>
            <Empty text="Выберите фильтр по точкам, сотрудникам или клиентам" />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {activePledgesSummaryLoading || activePledgesSummaryFetching ? (
                <Loader />
              ) : activePledgesSummaryError ? (
                <Error text="Произошла ошибка с загрузкой данных по общей информации! Пожалуйста, попробуйте позже" />
              ) : activePledgesSummarySuccess && Boolean(activePledgesSummary?.length) ? (
                <ActivePledgesSummaryTable list={activePledgesSummary} />
              ) : (
                <Empty text="Данные отсутствуют" />
              )}
            </Grid>

            <Grid item xs={12}>
              {activePledgesLoading || activePledgesFetching ? (
                <Loader />
              ) : activePledgesError ? (
                <Error text="Произошла ошибка с загрузкой данных по активным залогам! Пожалуйста, попробуйте позже" />
              ) : activePledgesSuccess && Boolean(activePledges?.data?.length) ? (
                <ActivePledgesTable list={activePledges} page={page} onChangePage={(page) => setQuery({ page })} />
              ) : (
                <Empty text="Данные отсутствуют" />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ActivePledgesPage;
