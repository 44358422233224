import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Currency, ListResponse } from '@/types';

const currencyApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Currencies'],
});

const currencyApi = currencyApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<ListResponse<Currency>, AxiosRequestConfig<Currency>>({
      query: (config) => ({ url: '/admin/currency', ...config }),
      providesTags: ['Currencies'],
    }),
    createCurrency: builder.mutation<Currency, Currency>({
      query: (currency) => ({ url: '/admin/currency', method: 'POST', data: currency }),
      invalidatesTags: ['Currencies'],
    }),
    updateCurrency: builder.mutation<Currency, Currency>({
      query: (currency) => ({ url: '/admin/currency/' + currency.id, method: 'PUT', data: currency }),
      invalidatesTags: ['Currencies'],
    }),
    deleteCurrency: builder.mutation<unknown, Currency>({
      query: (currency) => ({ url: '/admin/currency/' + currency.id, method: 'DELETE' }),
      invalidatesTags: ['Currencies'],
    }),
  }),
});

const { useGetCurrenciesQuery, useCreateCurrencyMutation, useUpdateCurrencyMutation, useDeleteCurrencyMutation } =
  currencyApi;

export { useGetCurrenciesQuery, useCreateCurrencyMutation, useUpdateCurrencyMutation, useDeleteCurrencyMutation };
