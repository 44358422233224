import { useState } from 'react';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { Buyout, Pledge, RePledgeCalculation } from '@/types';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { numbersDeclension } from '@/utils/numbers-declension';
import { SearchPledges } from '@/pages/Pledges/components';

import { RePledgeCalculations } from '../components';

export interface CreateBuyoutProps extends Omit<DialogProps, 'onSubmit'> {
  pledge: Pledge | null;
  disabled: boolean;
  onClose(): void;
  onSubmit(calculcations: Buyout & RePledgeCalculation): void;
}

const CreateRePledge: React.FC<CreateBuyoutProps> = ({ pledge, disabled, onClose, onSubmit, ...props }) => {
  const [rePledgeCalculationsModalOpened, setOpenedRePledgeCalculationsModal] = useState(false);

  const DAYS_FROM_PLEDGE_WAS_CREATED = Math.ceil(moment().endOf('day').diff(moment(pledge?.updated_at), 'days', true)),
    SUM_FOR_DAY = Math.round(+pledge?.return_sum_to_hand! * +pledge?.rate_for_day!) / 100,
    OVERDUE_DAYS_COUNT =
      DAYS_FROM_PLEDGE_WAS_CREATED > +pledge?.days_count! ? DAYS_FROM_PLEDGE_WAS_CREATED - +pledge?.days_count! : 0,
    FINE = Math.round((+pledge?.return_sum! * OVERDUE_DAYS_COUNT * +pledge?.nomenclature_group?.gt_30_days!) / 100),
    TOTAL_SUM_FOR_BUYOUT = Math.round(
      +pledge?.return_sum_to_hand! + SUM_FOR_DAY * (DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT) + FINE,
    );

  function handleSubmit(calculcations: Buyout & RePledgeCalculation) {
    onSubmit(calculcations);
    onClose();
    setOpenedRePledgeCalculationsModal(false);
  }

  return (
    <>
      <Dialog fullWidth {...props}>
        <DialogTitle fontWeight="bold">Провести выкуп</DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={8} md={10} xs={12}>
              <SearchPledges
                value={pledge}
                readOnly
                getOptionLabel={(option) => String(option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="pledge"
                    label="Залог"
                    placeholder="Выберите из списка"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>

            {pledge && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        label="Дата создания"
                        placeholder="Введите значение"
                        value={moment().format('DD.MM.YYYY')}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        name="pledge.employee.name"
                        label="Автор"
                        placeholder="Введите значение"
                        value={pledge.employee?.name}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        name="pledge.point.name"
                        label="Точка (Ломбард)"
                        placeholder="Введите значение"
                        value={pledge.point?.name}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        name="pledge.customer.name"
                        label="Залогодатель"
                        placeholder="Введите значение"
                        value={`${pledge.customer?.last_name} ${pledge.customer?.name} ${
                          pledge.customer?.father_name ?? ''
                        }`}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6.5} md={6.5} xs={12}>
                      <TextField
                        name="pledge.rate_for_day"
                        label="Ставка за кредит (1 день)"
                        placeholder="Введите значение"
                        value={pledge.rate_for_day}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        name="pledge.return_sum_to_hand"
                        label="На руки"
                        placeholder="Введите значение"
                        value={pledge.return_sum_to_hand}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        name="pledge.return_sum"
                        label="К возврату"
                        placeholder="Введите значение"
                        value={pledge.return_sum}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        label="Просрочено (дней)"
                        placeholder="Введите значение"
                        value={OVERDUE_DAYS_COUNT}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>
                        <b>
                          % за {DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT}{' '}
                          {numbersDeclension(DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT, [
                            'день',
                            'дня',
                            'дней',
                          ])}{' '}
                          под {pledge.rate_for_day}%:{' '}
                        </b>
                        {Math.round(SUM_FOR_DAY * (DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT))} (тг)
                      </Typography>

                      {Boolean(FINE) && (
                        <Typography>
                          <b>
                            Пеня за {OVERDUE_DAYS_COUNT}{' '}
                            {numbersDeclension(OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}:{' '}
                          </b>
                          {FINE} (тг)
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        label="Сумма пени"
                        placeholder="Введите значение"
                        value={FINE}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>
                        <b>Итого: </b>
                        {TOTAL_SUM_FOR_BUYOUT} (тг)
                      </Typography>

                      <Typography>
                        <b>Общее кол-во дней залога: </b>
                        {DAYS_FROM_PLEDGE_WAS_CREATED}{' '}
                        {numbersDeclension(DAYS_FROM_PLEDGE_WAS_CREATED, ['день', 'дня', 'дней'])}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        select
                        name="pledge.payment_method"
                        label="Способ оплаты"
                        value={pledge.payment_method}
                        fullWidth
                        InputProps={{ readOnly: true }}
                      >
                        {PAYMENT_METHODS.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" onClick={onClose}>
            Закрыть
          </Button>

          <Button variant="contained" onClick={() => setOpenedRePledgeCalculationsModal(true)}>
            Предварительный рассчёт
          </Button>
        </DialogActions>
      </Dialog>

      <RePledgeCalculations
        open={rePledgeCalculationsModalOpened}
        pledge={pledge}
        fine={FINE}
        sumForDay={SUM_FOR_DAY}
        totalSumForBuyout={TOTAL_SUM_FOR_BUYOUT}
        daysFromPledgeWasCreated={DAYS_FROM_PLEDGE_WAS_CREATED}
        overdueDaysCount={OVERDUE_DAYS_COUNT}
        disabled={disabled}
        onClose={() => setOpenedRePledgeCalculationsModal(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateRePledge;
