import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureGroupFine } from '@/types';
import { useGetNomenclatureGroupFinesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureGroupFines<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<NomenclatureGroupFine> } & Omit<
  AutocompleteProps<NomenclatureGroupFine, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclatureGroupFinesQueryProps = useAsyncSearch<
    NomenclatureGroupFine,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureGroupFinesQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetNomenclatureGroupFinesQueryProps} />;
}

export default SearchNomenclatureGroupFines;
