import { useState } from 'react';
import moment from 'moment';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Prolongation, ListResponse, ProlongationStatuses } from '@/types';
import { useRoles } from '@/hooks';
import { Pagination } from '@/components';
import { EditFlat, FileFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { PROLONGATION_STATUSES } from '..';
import { DeleteProlongation, GenerateProlongationDocument, UpdateProlongation } from '../components';

export interface ProlongationListProps {
  list?: ListResponse<Prolongation>;
  page: number;
  onChangePage(page: number): void;
}

const ProlongationsTable: React.FC<ProlongationListProps> = ({ list, page, onChangePage }) => {
  const { hasRoles } = useRoles();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentProlongation, setCurrentProlongation] = useState<Prolongation | null>(null);

  function openUpdateModal(prolongation: Prolongation) {
    setCurrentProlongation(prolongation);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(prolongation: Prolongation) {
    setCurrentProlongation(prolongation);
    setOpenedDeleteModal(true);
  }

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, prolongation: Prolongation) {
    setCurrentProlongation(prolongation);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Автор</TableCell>
                <TableCell>Проведён</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((prolongation) => {
                const { name, created_at, employee, carried_by, status, id } = prolongation,
                  prolongationStatus = PROLONGATION_STATUSES.find(({ value }) => value === status);

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={prolongationStatus?.label}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 32,
                              height: 32,
                            }}
                          >
                            {prolongationStatus?.icon}
                          </Box>
                        </Tooltip>

                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(prolongation)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        {hasRoles(['ADMIN']) && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(prolongation)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {![ProlongationStatuses.CANCELLED].includes(status!) && (
                          <Tooltip title="Печать">
                            <IconButton
                              aria-label="Печать"
                              onClick={(event) => openGenerateDocumentModal(event, prolongation)}
                            >
                              <FileFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {employee?.last_name ?? ''} {employee?.name ?? ''} {employee?.father_name ?? ''}
                    </TableCell>

                    <TableCell>
                      {carried_by?.last_name ?? ''} {carried_by?.name ?? ''} {carried_by?.father_name ?? ''}
                    </TableCell>
                    <TableCell>{prolongationStatus?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateProlongation
        prolongation={currentProlongation!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteProlongation
        prolongation={currentProlongation!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
      <GenerateProlongationDocument
        prolongation={currentProlongation!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default ProlongationsTable;
