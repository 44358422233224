import { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

import { useDebounceFn } from '@/hooks';

export type DebouncedTextFieldProps = TextFieldProps & {};

const DebouncedTextField: React.FC<DebouncedTextFieldProps> = ({ value, onChange, ...props }) => {
  const [data, setValue] = useState(value);
  const debouncedOnChange = useDebounceFn(onChange!, 1000);

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setValue(event.target.value);
    debouncedOnChange(event);
  }

  return <TextField value={data} onChange={handleChange} {...props} />;
};

export default DebouncedTextField;
