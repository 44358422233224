import { useParams } from 'react-router';
import NumberFormat from 'react-number-format';
import { FieldArray, Form, Formik, FormikHelpers, getIn } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { GemCategory, GemCategoryPrice } from '@/types';
import { useUpdateGemCategoryMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { TrashFlat } from '@/assets/icons';

export interface UpdateGemCategoryProps extends DialogProps {
  gemCategory: GemCategory;
  onClose(): void;
}

const UpdateGemCategory: React.FC<UpdateGemCategoryProps> = ({ gemCategory, onClose, ...props }) => {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const [updateGemCategory, updateGemCategoryResponse] = useUpdateGemCategoryMutation();

  const CATEGORY_PRICE: GemCategoryPrice = { from: 1, price: 0 };

  const initialValues: GemCategory = { name: '', gem_id: +id!, prices: [CATEGORY_PRICE] };

  const validationSchema = yup.object().shape({
    name: yup.string().required(messages.form.REQUIRED),
    prices: yup.array().of(
      yup.object().shape({
        from: yup.number().required(messages.form.REQUIRED),
        to: yup.number(),
        price: yup.number().required(messages.form.REQUIRED),
      }),
    ),
  });

  async function handleSubmit(gemCategory: GemCategory, { resetForm }: FormikHelpers<GemCategory>) {
    try {
      await updateGemCategory(gemCategory).unwrap();

      onClose();
      resetForm();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog onClose={onClose} fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать категорию драгоценных камней</DialogTitle>

      <Formik
        initialValues={gemCategory ?? initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="name"
                    label="Наименование"
                    placeholder="Введите значение"
                    value={values.name}
                    onChange={handleChange}
                    disabled={updateGemCategoryResponse.isLoading}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldArray name="prices">
                    {({ push, remove }) => (
                      <>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Кол-во от</TableCell>
                                <TableCell>Кол-во по</TableCell>
                                <TableCell>Цена</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {values.prices.map((price, index) => {
                                const field = (name: string) => `prices[${index}].${name}`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <NumberFormat
                                        customInput={TextField}
                                        name={field('from')}
                                        placeholder="Введите значение"
                                        value={price.from}
                                        onValueChange={({ value }) => setFieldValue(field('from'), +value)}
                                        disabled={updateGemCategoryResponse.isLoading}
                                        error={getIn(touched, field('from')) && Boolean(getIn(errors, field('from')))}
                                        fullWidth
                                        size="small"
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <NumberFormat
                                        customInput={TextField}
                                        name={field('to')}
                                        value={price.to}
                                        onValueChange={({ value }) => setFieldValue(field('to'), +value)}
                                        disabled={updateGemCategoryResponse.isLoading}
                                        error={getIn(touched, field('to')) && Boolean(getIn(errors, field('to')))}
                                        fullWidth
                                        size="small"
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <NumberFormat
                                        customInput={TextField}
                                        name={field('price')}
                                        placeholder="Введите значение"
                                        value={price.price}
                                        onValueChange={({ value }) => setFieldValue(field('price'), +value)}
                                        disabled={updateGemCategoryResponse.isLoading}
                                        error={getIn(touched, field('price')) && Boolean(getIn(errors, field('price')))}
                                        fullWidth
                                        size="small"
                                      />
                                    </TableCell>

                                    <TableCell>
                                      {Boolean(index) && values.prices.length === index + 1 && (
                                        <Tooltip title="Удалить">
                                          <IconButton
                                            aria-label="Удалить"
                                            disabled={updateGemCategoryResponse.isLoading}
                                            onClick={() => remove(index)}
                                          >
                                            <TrashFlat sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                          <Button
                            variant="contained"
                            disabled={updateGemCategoryResponse.isLoading}
                            size="small"
                            onClick={() => push(CATEGORY_PRICE)}
                          >
                            Добавить
                          </Button>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                size="large"
                disabled={updateGemCategoryResponse.isLoading}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Button type="submit" variant="contained" size="large" disabled={updateGemCategoryResponse.isLoading}>
                Добавить
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateGemCategory;
