import { Langs } from '@/utils/langs';

export enum DocumentTypes {
  TITLE = 'title',
  NOTIFICATION = 'notification',
  STATEMENT = 'statement',
  SCHEDULE = 'schedule',
  PACKING_SLIP = 'packing_slip',
  ORGANIZATION_CAR_STATEMENT = 'organization_car_statement',
  CLIENT_CAR_STATEMENT = 'client_car_statement',
  ENCUMBRANCE_REMOVAL = 'encumbrance_removal',
  ENCUMBRANCE = 'encumbrance',
  SHORT_TERM_LOAN = 'short_term_loan',
  PLEDGE_SHORT_TERM_LOAN = 'pledge_short_term_loan',
  CAR_SHORT_TERM_LOAN = 'car_short_term_loan',
  WHOLE_DOCUMENT = 'whole_document',
  RKO = 'rko',
  ON_PARKING = 'on_parking',
  BUYOUT_MARK = 'buyout_mark',
  PKO = 'pko',
  PROLONGATION_MARK = 'prolongation_mark',
  IMPLEMENTATION_ACT = 'implementation_act',
  INVOICE = 'invoice',
  KKM_CHECK = 'kkm_cheque',
}

export interface Document {
  id?: number;
  html_content: string;
  type: DocumentTypes | '';
  lan: Langs | '';
}
