import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Region } from '@/types';
import { useDeleteRegionMutation } from '@/services';

export interface DeleteRegionProps extends DialogProps {
  region: Region;
  onClose(): void;
}

const DeleteRegion: React.FC<DeleteRegionProps> = ({ region, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteRegion, deleteRegionResponse] = useDeleteRegionMutation();

  async function handleDeleteRegion() {
    try {
      await deleteRegion(region).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{region?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{region?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteRegionResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteRegionResponse.isLoading} onClick={handleDeleteRegion}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRegion;
