import { CollateralItem, Customer, CustomerStatus, Employee, NomenclatureGroup, Point } from '@/types';
import { PaymentMethods } from '@/utils/enums';

export enum PledgeStatuses {
  CARRIED_OUT = 'carried_out',
  CANCELLED = 'cancelled',
  FINISHED = 'finished',
  REALIZED = 'realized',
  PROLONGED = 'prolonged',
  OVERDUE = 'overdue',
}

export enum RePledgeOperationTypes {
  DECREASE = 'decrease',
  INCREASE = 'increase',
}

export interface RePledgeCalculation {
  days_count: number;
  pledge: Pledge | null;
  pledge_id?: Pledge['id'];
  type: RePledgeOperationTypes;
  sum: number;
  partial_buyout_items: CollateralItem[];
  partial_buyout_item_ids?: CollateralItem['id'][];
}

export interface Pledge {
  id?: number;
  customer?: Customer | null;
  customer_id?: Customer['id'];
  customer_status?: CustomerStatus | null;
  customer_status_id?: CustomerStatus['id'];
  collateral_items: CollateralItem[];
  carried_by?: Employee;
  employee?: Employee;
  point: Point | null;
  point_id?: Point['id'];
  prolongated?: Date;
  is_composite?: boolean;
  is_trusted_person: boolean;
  full_pledge?: Pledge | null;
  first_pledge?: Pledge | null;
  first_pledge_id?: Pledge['id'] | null;
  trusted_person: Customer | null;
  trusted_person_id?: Customer['id'];
  is_without_buyout: boolean;
  name?: string;
  days_count: number;
  re_mortgage?: boolean;
  re_pledge?: Pledge | null;
  return_date: Date | null;
  rate_for_day: number;
  return_sum: number;
  return_sum_to_hand: number;
  prolongated_until_date?: Date | null;
  payment_method: PaymentMethods | '';
  comment: string;
  status: PledgeStatuses;
  nomenclature_group: NomenclatureGroup | null;
  nomenclature_group_id?: NomenclatureGroup['id'];
  created_at?: Date;
  prolongated_at?: Date | null;
  buyout_at?: Date | null;
  updated_at?: Date | null;
  updated_by?: Employee;
}
