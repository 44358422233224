import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { NomenclatureGroupInterval } from '@/types';
import { useGetNomenclatureGroupIntervalsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchNomenclatureGroupIntervals<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<NomenclatureGroupInterval> } & Omit<
  AutocompleteProps<NomenclatureGroupInterval, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetNomenclatureGroupIntervalsQueryProps = useAsyncSearch<
    NomenclatureGroupInterval,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetNomenclatureGroupIntervalsQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetNomenclatureGroupIntervalsQueryProps} />;
}

export default SearchNomenclatureGroupIntervals;
