import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Account } from '@/types';
import { useGetAccountsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchAccounts<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Account> } & Omit<
  AutocompleteProps<Account, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetAccountsQueryProps = useAsyncSearch<Account, Multiple, DisableClearable, FreeSolo>(
    useGetAccountsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetAccountsQueryProps} />;
}

export default SearchAccounts;
