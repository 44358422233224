import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Container, Typography, Stack } from '@mui/material';

import { useAuth, useTitle } from '@/hooks';

export interface AppBarProps extends MuiAppBarProps {
  title: string;
}

const AppBar: React.FC<AppBarProps> = ({ title, ...props }) => {
  const { user } = useAuth();
  useTitle(`${title} | Super Lobmard`);

  return (
    <MuiAppBar
      variant="outlined"
      elevation={0}
      color="inherit"
      sx={{ p: 2.5, left: 90, width: 'calc(100% - 90px)' }}
      className="no-print"
      {...props}
    >
      <Container>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography component="h1" variant="h2" color="primary.dark">
            {title}
          </Typography>

          <Typography variant="h2" color="secondary.dark">
            {user?.point?.name}
          </Typography>
        </Stack>
      </Container>
    </MuiAppBar>
  );
};

export default AppBar;
