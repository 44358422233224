import { useState } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Customer, FolderNames, MobileOperator } from '@/types';
import { useUpdateCustomerMutation, useUploadFileMutation } from '@/services';
import { useRoles } from '@/hooks';
import { FILE_SIZE, identifyByIIN, identifyOperatorByPhoneNumber, messages, SUPPORTED_FORMATS } from '@/utils/helpers';
import { GENDERS } from '@/utils/genders';
import { FileInput } from '@/components';
import { SearchRegions } from '@/pages/Regions/components';
import { SearchCustomerDocumentTypes } from '@/pages/CustomerDocumentTypes/components';
import { SearchCountries } from '@/pages/Countries/components';
import { SearchAreas } from '@/pages/Areas/components';
import { SearchDistricts } from '@/pages/Districts/components';
import { SearchLocalities } from '@/pages/Localities/components';
import { SearchMobileOperators } from '@/pages/MobileOperators/components';
import { SearchCustomerStatuses } from '@/pages/CustomerStatuses/components';
import { SearchCustomerCategories } from '@/pages/CustomerCategories/components';
import { SearchStreets } from '@/pages/Streets/components';

import { ADDITIONAL, COMMON, IDENTITY, PASSPORT_DATA, RESIDENS_PLACE } from './helpers';

export interface UpdateCustomerProps extends DialogProps {
  customer: Customer;
  onClose(): void;
}

const MAX_BORN_AT_DATE = moment().subtract(18, 'year');

const UpdateCustomer: React.FC<UpdateCustomerProps> = ({ customer, onClose, ...props }) => {
  const { isAdmin } = useRoles();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [uploadFile] = useUploadFileMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const { values, errors, touched, resetForm, setValues, setFieldValue, handleChange, handleSubmit } =
    useFormik<Customer>({
      initialValues: customer ?? { ...COMMON, ...PASSPORT_DATA, ...RESIDENS_PLACE, ...IDENTITY, ...ADDITIONAL },
      validationSchema: yup.object().shape({
        name: yup.string().required(messages.form.REQUIRED),
        father_name: yup.string().nullable(),
        last_name: yup.string(),
        gender: yup.string().required(messages.form.SELECT_FROM_LIST),
        born_at: yup
          .date()
          .max(MAX_BORN_AT_DATE, 'Клиент должен быть совершеннолетним')
          .required(messages.form.SELECT_DATE)
          .typeError(messages.form.SELECT_CORRECT_DATE),
        document_type: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        document_series: yup.string(),
        document_number: yup.string().required(messages.form.REQUIRED),
        document_issued_by: yup.string().required(messages.form.REQUIRED),
        document_issued_date: yup
          .date()
          .required(messages.form.SELECT_DATE)
          .typeError(messages.form.SELECT_CORRECT_DATE),
        document_expire_date: yup
          .date()
          .required(messages.form.SELECT_DATE)
          .typeError(messages.form.SELECT_CORRECT_DATE),
        country: yup
          .object()
          .nullable()
          .when('is_foreign_citizen', {
            is: 1,
            then: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
          }),

        iin: yup
          .string()
          .nullable()
          .when('is_foreign_citizen', {
            is: 0,
            then: yup.string().required(messages.form.REQUIRED).min(12, messages.form.MIN_LENGTH(12)),
          }),

        official_region: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        official_area: yup.object().nullable(),
        official_district: yup.object().nullable(),
        official_locality: yup.object().nullable(),
        official_street: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
        official_house_number: yup.string().required(messages.form.REQUIRED),
        official_flat_number: yup.string(),

        actual_region: yup
          .object()
          .nullable()
          .when('is_matches_with_official_address', {
            is: 0,
            then: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
          }),
        actual_area: yup.object().nullable(),
        actual_district: yup.object().nullable(),
        actual_locality: yup.object().nullable(),
        actual_street: yup
          .object()
          .nullable()
          .when('is_matches_with_official_address', {
            is: 0,
            then: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
          }),
        actual_house_number: yup.string().when('is_matches_with_official_address', {
          is: 0,
          then: yup.string().required(messages.form.REQUIRED),
        }),
        actual_flat_number: yup.string(),

        identity_front_side: yup.lazy((value) =>
          value instanceof File
            ? yup
                .mixed()
                .test('fileSize', messages.form.LARGE_FILE, (file) => (!file ? true : file.size <= FILE_SIZE))
                .test('fileFormat', messages.form.UNSUPPORTED_FILE_TYPE, (file) =>
                  !file ? true : file && SUPPORTED_FORMATS.includes(file.type),
                )
            : yup.string().nullable(),
        ),
        identity_back_side: yup.lazy((value) =>
          value instanceof File
            ? yup
                .mixed()
                .test('fileSize', messages.form.LARGE_FILE, (file) => (!file ? true : file.size <= FILE_SIZE))
                .test('fileFormat', messages.form.UNSUPPORTED_FILE_TYPE, (file) =>
                  !file ? true : file && SUPPORTED_FORMATS.includes(file.type),
                )
            : yup.string().nullable(),
        ),
        customer_category: yup.object().nullable(),
        customer_status: yup.object().nullable(),
        operator: yup.object().nullable(),
        phone_number: yup.string().required(messages.form.REQUIRED).min(11, messages.form.PHONE),
        avatar: yup.lazy((value) =>
          value instanceof File
            ? yup
                .mixed()
                .test('fileSize', messages.form.LARGE_FILE, (file) => (!file ? true : file.size <= FILE_SIZE))
                .test('fileFormat', messages.form.UNSUPPORTED_FILE_TYPE, (file) =>
                  !file ? true : file && SUPPORTED_FORMATS.includes(file.type),
                )
            : yup.string().nullable(),
        ),
      }),
      enableReinitialize: true,
      async onSubmit({ avatar, identity_front_side, identity_back_side, ...customer }) {
        try {
          setLoading(true);

          if (![customer.last_name, customer.father_name].every(Boolean))
            enqueueSnackbar('Внимание, клиент был обновлен без фамилии и отчества', { variant: 'info' });

          await updateCustomer({
            ...customer,
            avatar:
              avatar instanceof File
                ? await uploadFile({ file: avatar, folder_name: FolderNames.AVATARS }).unwrap()
                : avatar,

            identity_front_side:
              identity_front_side instanceof File
                ? await uploadFile({
                    file: identity_front_side,
                    folder_name: FolderNames.IDENTITY_FRONT_SIDES,
                  }).unwrap()
                : identity_front_side,

            identity_back_side:
              identity_back_side instanceof File
                ? await uploadFile({
                    file: identity_back_side as File,
                    folder_name: FolderNames.IDENTITY_BACK_SIDES,
                  }).unwrap()
                : identity_back_side,
          }).unwrap();

          handleClose();
        } catch (error) {
          enqueueSnackbar(error.data, { variant: 'error' });
        } finally {
          setLoading(false);
        }
      },
    });

  const [mobileOperators, setMobileOperators] = useState<readonly MobileOperator[]>([]);

  function handleClose() {
    onClose();
    resetForm();
  }

  function handleChangeIIN(value: string) {
    setFieldValue('iin', value);
    const identity = identifyByIIN(value);

    if (!identity) return;
    const { born_at, gender } = identity;
    setFieldValue('gender', gender);
    setFieldValue('born_at', born_at);
  }

  function handleChangePhoneNumber(value: string) {
    setFieldValue('phone_number', value);
    const operator = identifyOperatorByPhoneNumber(value, mobileOperators);
    setFieldValue('operator', operator);
  }

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать клиента</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {Boolean(!values.is_foreign_citizen) && (
              <Grid item lg={7} md={7} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  format="############"
                  name="iin"
                  type="tel"
                  label="ИИН"
                  placeholder="Введите значение"
                  value={values.iin ?? ''}
                  onValueChange={({ value }) => handleChangeIIN(value)}
                  disabled={loading}
                  error={touched.iin && Boolean(errors.iin)}
                  helperText={touched.iin ? errors.iin : ''}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="last_name"
                label="Фамилия"
                placeholder="Введите значение"
                value={values.last_name}
                onChange={handleChange}
                disabled={loading}
                error={touched.last_name && Boolean(errors.last_name)}
                helperText={touched.last_name ? errors.last_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={4} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата рождения"
                value={values.born_at}
                maxDate={MAX_BORN_AT_DATE}
                onChange={(value) => setFieldValue('born_at', moment(value).toDate())}
                disabled={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="born_at"
                    error={Boolean(touched.born_at && errors.born_at)}
                    helperText={touched.born_at && errors.born_at}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Имя"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={loading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item alignSelf="center" lg={6} md={6} xs={12}>
              <FormControlLabel
                label="Иностранец"
                control={
                  <Switch
                    name="is_foreign_citizen"
                    value={values.is_foreign_citizen}
                    checked={Boolean(values.is_foreign_citizen)}
                    disabled={loading}
                    onChange={(event, checked) =>
                      setValues((values) => ({ ...values, is_foreign_citizen: checked ? 1 : 0, iin: '' }))
                    }
                  />
                }
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="father_name"
                label="Отчество"
                placeholder="Введите значение"
                value={values.father_name ?? ''}
                onChange={handleChange}
                disabled={loading}
                error={touched.father_name && Boolean(errors.father_name)}
                helperText={touched.father_name ? errors.father_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCountries
                value={values.country}
                onChange={(event, option) => setFieldValue('country', option)}
                disabled={loading || Boolean(!values.is_foreign_citizen)}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="country"
                    label="Страна"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={4} xs={12}>
              <TextField
                select
                name="gender"
                label="Пол"
                value={values.gender ?? ''}
                onChange={handleChange}
                disabled={loading}
                error={touched.gender && Boolean(errors.gender)}
                helperText={touched.gender ? errors.gender : ''}
                fullWidth
              >
                {GENDERS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item alignSelf="center" lg={5} md={3} xs={12}>
              <Typography component="h5" variant="body2" gutterBottom>
                <b>Создан: </b>
                {values.created_by?.last_name ?? ''} {values.created_by?.name ?? ''}{' '}
                {values.created_by?.father_name ?? ''}
              </Typography>

              <Typography component="h5" variant="body2">
                <b>Обновлён: </b>
                {values.updated_by?.last_name ?? ''} {values.updated_by?.name ?? ''}{' '}
                {values.updated_by?.father_name ?? ''}
              </Typography>
            </Grid>

            <Grid item lg={7} md={9} xs={12}>
              <TextField
                label="ФИО"
                placeholder="Введите значение"
                value={
                  [values.last_name, values.name, values.father_name].some(Boolean)
                    ? `${values.last_name} ${values.name} ${values.father_name ?? ''}`
                    : ''
                }
                onChange={handleChange}
                disabled={loading}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="h5" variant="body2" gutterBottom>
                <b>Данные документа</b>
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <SearchCustomerDocumentTypes
                    value={values.document_type}
                    onChange={(event, option) => setFieldValue('document_type', option)}
                    disabled={loading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="document_type"
                        label="Вид документа удостоверяющего личность"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.document_type && errors.document_type)}
                        helperText={touched.document_type && errors.document_type}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="document_series"
                    label="Серия"
                    placeholder="Введите значение"
                    value={values.document_series}
                    onChange={handleChange}
                    disabled={loading}
                    error={touched.document_series && Boolean(errors.document_series)}
                    helperText={touched.document_series ? errors.document_series : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="document_number"
                    label="Номер документа"
                    placeholder="Введите значение"
                    value={values.document_number}
                    onChange={handleChange}
                    disabled={loading}
                    error={touched.document_number && Boolean(errors.document_number)}
                    helperText={touched.document_number ? errors.document_number : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <DatePicker
                    mask="__.__.____"
                    label="Срок годности УЛ (паспорта)"
                    value={values.document_expire_date}
                    onChange={(value) => setFieldValue('document_expire_date', moment(value).toDate())}
                    disabled={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="document_expire_date"
                        error={Boolean(touched.document_expire_date && errors.document_expire_date)}
                        helperText={touched.document_expire_date && errors.document_expire_date}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="document_issued_by"
                    label="Кем выдан"
                    placeholder="Введите значение"
                    value={values.document_issued_by}
                    onChange={handleChange}
                    disabled={loading}
                    error={touched.document_issued_by && Boolean(errors.document_issued_by)}
                    helperText={touched.document_issued_by ? errors.document_issued_by : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <DatePicker
                    mask="__.__.____"
                    label="Когда выдан"
                    value={values.document_issued_date}
                    onChange={(value) => setFieldValue('document_issued_date', moment(value).toDate())}
                    disabled={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="document_issued_date"
                        error={Boolean(touched.document_issued_date && errors.document_issued_date)}
                        helperText={touched.document_issued_date && errors.document_issued_date}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h5" variant="body2" gutterBottom>
                <b>Адрес проживания</b>
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <SearchRegions
                    value={values.official_region}
                    onChange={(event, option) =>
                      setValues((values) => ({
                        ...values,
                        official_region: option,
                        official_area: null,
                        official_district: null,
                        official_locality: null,
                        official_street: null,
                      }))
                    }
                    disabled={loading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_region"
                        label="Область"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_region && errors.official_region)}
                        helperText={touched.official_region && errors.official_region}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchAreas
                    value={values.official_area}
                    config={{ params: { parent_id: values.official_region?.id } }}
                    onChange={(event, option) =>
                      setValues((values) => ({
                        ...values,
                        official_area: option,
                        official_district: null,
                        official_locality: null,
                      }))
                    }
                    disabled={loading || Boolean(!values.official_region)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_area"
                        label="Район"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_area && errors.official_area)}
                        helperText={touched.official_area && errors.official_area}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchDistricts
                    value={values.official_district}
                    config={{ params: { parent_id: values.official_area?.id } }}
                    onChange={(event, option) =>
                      setValues((values) => ({ ...values, official_district: option, official_locality: null }))
                    }
                    disabled={loading || Boolean(!values.official_area)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_district"
                        label="Округ"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_district && errors.official_district)}
                        helperText={touched.official_district && errors.official_district}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchLocalities
                    value={values.official_locality}
                    config={{ params: { parent_id: values.official_district?.id } }}
                    onChange={(event, option) => setFieldValue('official_locality', option)}
                    disabled={loading || Boolean(!values.official_district)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_locality"
                        label="Населенный пункт"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_locality && errors.official_locality)}
                        helperText={touched.official_locality && errors.official_locality}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <SearchStreets
                    freeSolo
                    value={values.official_street}
                    config={{
                      params: {
                        district_id: values.official_district?.id,
                        locality_id: values.official_locality?.id,
                        parent_id: values.official_region?.id,
                      },
                    }}
                    onChange={(event, street) =>
                      setFieldValue(
                        'official_street',
                        typeof street === 'string'
                          ? { name: street }
                          : street && street.name
                          ? { name: street.name }
                          : street,
                      )
                    }
                    disabled={loading || Boolean(!values.official_region)}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="official_street"
                        label="Улица"
                        placeholder="Выберите из списка"
                        error={Boolean(touched.official_street && errors.official_street)}
                        helperText={touched.official_street && errors.official_street}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="official_house_number"
                    label="Дом"
                    placeholder="Введите значение"
                    value={values.official_house_number}
                    onChange={handleChange}
                    disabled={loading}
                    error={touched.official_house_number && Boolean(errors.official_house_number)}
                    helperText={touched.official_house_number ? errors.official_house_number : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    name="official_flat_number"
                    label="Квартира"
                    placeholder="Введите значение"
                    value={values.official_flat_number}
                    onChange={handleChange}
                    disabled={loading}
                    error={touched.official_flat_number && Boolean(errors.official_flat_number)}
                    helperText={touched.official_flat_number ? errors.official_flat_number : ''}
                    fullWidth
                  />
                </Grid>

                <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                  <FormControlLabel
                    label="Совпадает с адресом прописки"
                    control={
                      <Switch
                        name="is_matches_with_official_address"
                        value={values.is_matches_with_official_address}
                        checked={Boolean(values.is_matches_with_official_address)}
                        disabled={loading}
                        onChange={(event, checked) =>
                          setFieldValue('is_matches_with_official_address', checked ? 1 : 0)
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {Boolean(!values.is_matches_with_official_address) && (
              <Grid item xs={12}>
                <Typography component="h5" variant="body2" gutterBottom>
                  <b>Адрес прописки</b>
                </Typography>

                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <SearchRegions
                      value={values.actual_region}
                      onChange={(event, option) =>
                        setValues((values) => ({
                          ...values,
                          actual_region: option,
                          actual_area: null,
                          actual_district: null,
                          actual_locality: null,
                          actual_street: null,
                        }))
                      }
                      disabled={loading}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_region"
                          label="Область"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_region && errors.actual_region)}
                          helperText={touched.actual_region && errors.actual_region}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchAreas
                      value={values.actual_area}
                      config={{ params: { parent_id: values.actual_region?.id } }}
                      onChange={(event, option) =>
                        setValues((values) => ({
                          ...values,
                          actual_area: option,
                          actual_district: null,
                          actual_locality: null,
                        }))
                      }
                      disabled={loading || Boolean(!values.actual_region)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_area"
                          label="Район"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_area && errors.actual_area)}
                          helperText={touched.actual_area && errors.actual_area}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchDistricts
                      value={values.actual_district}
                      config={{ params: { parent_id: values.actual_area?.id } }}
                      onChange={(event, option) =>
                        setValues((values) => ({ ...values, actual_district: option, actual_locality: null }))
                      }
                      disabled={loading || Boolean(!values.actual_area)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_district"
                          label="Округ"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_district && errors.actual_district)}
                          helperText={touched.actual_district && errors.actual_district}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchLocalities
                      value={values.actual_locality}
                      config={{ params: { parent_id: values.actual_district?.id } }}
                      onChange={(event, option) => setFieldValue('actual_locality', option)}
                      disabled={loading || Boolean(!values.actual_district)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_locality"
                          label="Населенный пункт"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_locality && errors.actual_locality)}
                          helperText={touched.actual_locality && errors.actual_locality}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <SearchStreets
                      freeSolo
                      value={values.actual_street}
                      config={{
                        params: {
                          district_id: values.actual_district?.id,
                          locality_id: values.actual_locality?.id,
                          parent_id: values.actual_region?.id,
                        },
                      }}
                      onChange={(event, street) =>
                        setFieldValue(
                          'actual_street',
                          typeof street === 'string'
                            ? { name: street }
                            : street && street.name
                            ? { name: street.name }
                            : street,
                        )
                      }
                      disabled={loading || Boolean(!values.actual_region)}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="actual_street"
                          label="Улица"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.actual_street && errors.actual_street)}
                          helperText={touched.actual_street && errors.actual_street}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="actual_house_number"
                      label="Дом"
                      placeholder="Введите значение"
                      value={values.actual_house_number}
                      onChange={handleChange}
                      disabled={loading}
                      error={touched.actual_house_number && Boolean(errors.actual_house_number)}
                      helperText={touched.actual_house_number ? errors.actual_house_number : ''}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="actual_flat_number"
                      label="Квартира"
                      placeholder="Введите значение"
                      value={values.actual_flat_number}
                      onChange={handleChange}
                      disabled={loading}
                      error={touched.actual_flat_number && Boolean(errors.actual_flat_number)}
                      helperText={touched.actual_flat_number ? errors.actual_flat_number : ''}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item lg={6} md={6} xs={12}>
              <FileInput
                name="identity_front_side"
                label="Лицевая сторона удостоверения личности"
                onChange={([file]) => setFieldValue('identity_front_side', file)}
                disabled={loading}
                error={touched.identity_front_side && Boolean(errors.identity_front_side)}
                helperText={touched.identity_front_side ? errors.identity_front_side : ''}
                DropzoneProps={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png'] } }}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FileInput
                name="identity_back_side"
                label="Обратная сторона удостоверения личности"
                onChange={([file]) => setFieldValue('identity_back_side', file)}
                disabled={loading}
                error={touched.identity_back_side && Boolean(errors.identity_back_side)}
                helperText={touched.identity_back_side ? errors.identity_back_side : ''}
                DropzoneProps={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png'] } }}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCustomerCategories
                value={values.customer_category}
                onChange={(event, option) => setFieldValue('customer_category', option)}
                disabled={loading || !isAdmin}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customer_category"
                    label="Категория клиента"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.customer_category && errors.customer_category)}
                    helperText={touched.customer_category && errors.customer_category}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCustomerStatuses
                value={values.customer_status}
                onChange={(event, option) => setFieldValue('customer_status', option)}
                disabled={loading || !isAdmin}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customer_status"
                    label="Статус клиента"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.customer_status && errors.customer_status)}
                    helperText={touched.customer_status && errors.customer_status}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchMobileOperators
                prefetch
                value={values.operator}
                onChange={(event, option) => setFieldValue('operator', option)}
                getOptions={(options) => setMobileOperators(options)}
                disabled={loading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="operator"
                    label="Мобильный оператор"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.operator && errors.operator)}
                    helperText={touched.operator && errors.operator}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                format="+# (###) ###-##-##"
                name="phone_number"
                label="Номер телефона"
                placeholder="Введите значение"
                value={values.phone_number}
                onValueChange={({ value }) => handleChangePhoneNumber(value)}
                disabled={loading}
                error={touched.phone_number && Boolean(errors.phone_number)}
                helperText={touched.phone_number ? errors.phone_number : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FileInput
                name="avatar"
                label="Аватар"
                onChange={([file]) => setFieldValue('avatar', file)}
                disabled={loading}
                error={touched.avatar && Boolean(errors.avatar)}
                helperText={touched.avatar ? errors.avatar : ''}
                DropzoneProps={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png'] } }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={loading} onClick={handleClose}>
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={loading}>
            Сохранить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCustomer;
