import moment from 'moment';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Prolongation, Pledge } from '@/types';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { numbersDeclension } from '@/utils/numbers-declension';
import { SearchPledges } from '@/pages/Pledges/components';

export interface CreateProlongationProps extends DialogProps {
  pledge: Pledge | null;
  disabled: boolean;
  onClose(): void;
  onSubmit(): void;
}

const CreateProlongation: React.FC<CreateProlongationProps> = ({ pledge, disabled, onClose, onSubmit, ...props }) => {
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik<Prolongation>({
    initialValues: { pledge },
    enableReinitialize: true,
    onSubmit,
  });

  const DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED = Math.ceil(
      moment()
        .endOf('day')
        .diff(moment(values.pledge?.prolongated_at || values.pledge?.created_at), 'days', true),
    ),
    OVERDUE_DAYS_COUNT =
      DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED > +values.pledge?.days_count!
        ? DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED - +values.pledge?.days_count!
        : 0,
    FINE = Math.round(
      (+values.pledge?.return_sum! * OVERDUE_DAYS_COUNT * +values.pledge?.nomenclature_group?.gt_30_days!) / 100,
    ),
    TOTAL_SUM_FOR_PROLONGATION = Math.round(
      (+values.pledge?.return_sum_to_hand! *
        (DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED - OVERDUE_DAYS_COUNT) *
        +values.pledge?.rate_for_day!) /
        100,
    );

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Провести пролонгацию</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={8} md={10} xs={12}>
              <SearchPledges
                value={values.pledge}
                onChange={(event, option) => setFieldValue('pledge', option)}
                disabled={disabled}
                readOnly
                getOptionLabel={(option) => String(option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="pledge"
                    label="Залог"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.pledge && errors.pledge)}
                    helperText={touched.pledge && errors.pledge}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>

            {values.pledge && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Дата создания"
                      value={moment().format('DD.MM.YYYY')}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.employee.name"
                      label="Автор"
                      placeholder="Введите значение"
                      value={values.pledge?.employee?.name}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.point.name"
                      label="Точка (Ломбард)"
                      placeholder="Введите значение"
                      value={values.pledge?.point?.name}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.customer.name"
                      label="Залогодатель"
                      placeholder="Введите значение"
                      value={`${values.pledge.customer?.last_name} ${values.pledge.customer?.name} ${
                        values.pledge.customer?.father_name ?? ''
                      }`}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.rate_for_day"
                      label="Ставка за кредит (1 день)"
                      placeholder="Введите значение"
                      value={values.pledge?.rate_for_day}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.return_sum"
                      label="К возврату"
                      placeholder="Введите значение"
                      value={values.pledge?.return_sum}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.return_sum_to_hand"
                      label="На руки"
                      placeholder="Введите значение"
                      value={values.pledge?.return_sum_to_hand}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <DatePicker
                      mask="__.__.____"
                      label="Дата возврата"
                      value={moment().add(values.pledge.days_count, 'day')}
                      onChange={() => {}}
                      disabled={disabled}
                      readOnly
                      renderInput={(params) => <TextField {...params} fullWidth InputProps={{ readOnly: true }} />}
                    />
                  </Grid>

                  <Grid item lg={5} md={5} xs={12}>
                    <TextField
                      label="Глубина"
                      placeholder="Введите значение"
                      value={`${values.pledge.days_count} ${numbersDeclension(values.pledge.days_count, [
                        'день',
                        'дня',
                        'дней',
                      ])}`}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={7} md={7} xs={12}>
                    <Typography>
                      <b>
                        Сумма % за {DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED - OVERDUE_DAYS_COUNT}{' '}
                        {numbersDeclension(DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED_OR_CREATED - OVERDUE_DAYS_COUNT, [
                          'день',
                          'дня',
                          'дней',
                        ])}{' '}
                        под {values.pledge.rate_for_day}%:{' '}
                      </b>
                      {TOTAL_SUM_FOR_PROLONGATION} (тг)
                    </Typography>

                    {Boolean(FINE) && (
                      <Typography>
                        <b>
                          Пеня за {OVERDUE_DAYS_COUNT} {numbersDeclension(OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}:{' '}
                        </b>
                        {FINE} (тг)
                      </Typography>
                    )}

                    <Typography>
                      <b>Итого: </b>
                      {TOTAL_SUM_FOR_PROLONGATION + FINE} (тг)
                    </Typography>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      select
                      name="pledge.payment_method"
                      label="Способ оплаты"
                      value={values.pledge?.payment_method}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    >
                      {PAYMENT_METHODS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={disabled} onClick={onClose}>
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={disabled}>
            Провести
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateProlongation;
