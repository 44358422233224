import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Gem, ListResponse } from '@/types';

const gemApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Gems'],
});

const gemApi = gemApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getGems: builder.query<ListResponse<Gem>, AxiosRequestConfig<Gem>>({
      query: (config) => ({ url: '/admin/gem', ...config }),
      providesTags: ['Gems'],
    }),
    createGem: builder.mutation<Gem, Gem>({
      query: (gem) => ({ url: '/admin/gem', method: 'POST', data: gem }),
      invalidatesTags: ['Gems'],
    }),
    updateGem: builder.mutation<Gem, Gem>({
      query: (gem) => ({ url: '/admin/gem/' + gem.id, method: 'PUT', data: gem }),
      invalidatesTags: ['Gems'],
    }),
    deleteGem: builder.mutation<unknown, Gem>({
      query: ({ id }) => ({ url: '/admin/gem/' + id, method: 'DELETE' }),
      invalidatesTags: ['Gems'],
    }),
  }),
});

const { useGetGemsQuery, useCreateGemMutation, useUpdateGemMutation, useDeleteGemMutation } = gemApi;

export { useGetGemsQuery, useCreateGemMutation, useUpdateGemMutation, useDeleteGemMutation };
