import React, { PropsWithChildren, useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

export interface RouteAdapterProps {}

const RouteAdapter: React.FC<PropsWithChildren<RouteAdapterProps>> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );

  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

export default RouteAdapter;
