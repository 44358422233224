import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { IntervalCollateralRate, ListResponse } from '@/types';
import { PERIOD_TYPES } from '@/utils/period-types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteIntervalCollateralRate, UpdateIntervalCollateralRate } from '../components';

export interface IntervalCollateralRateListProps {
  list?: ListResponse<IntervalCollateralRate>;
  page: number;
  onChangePage(page: number): void;
}

const IntervalCollateralRatesTable: React.FC<IntervalCollateralRateListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentIntervalCollateralRate, setCurrentIntervalCollateralRate] = useState<IntervalCollateralRate | null>(
    null,
  );

  function openUpdateModal(intervalCollateralRate: IntervalCollateralRate) {
    setCurrentIntervalCollateralRate(intervalCollateralRate);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(intervalCollateralRate: IntervalCollateralRate) {
    setCurrentIntervalCollateralRate(intervalCollateralRate);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Интервал</TableCell>
                <TableCell />
                <TableCell>Статус клиента</TableCell>
                <TableCell>Точка</TableCell>
                <TableCell>Период глубины залога</TableCell>
                <TableCell>Процент</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((intervalCollateralRate) => {
                const { nomenclature_group_interval, customer_status, point, period_type, percent, id } =
                    intervalCollateralRate,
                  periodType = PERIOD_TYPES.find((periodType) => periodType.value === period_type);

                return (
                  <TableRow key={id}>
                    <TableCell>{nomenclature_group_interval?.name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(intervalCollateralRate)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(intervalCollateralRate)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{customer_status?.name}</TableCell>
                    <TableCell>{point?.name}</TableCell>
                    <TableCell>{periodType?.label}</TableCell>
                    <TableCell>{percent}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateIntervalCollateralRate
        intervalCollateralRate={currentIntervalCollateralRate!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteIntervalCollateralRate
        intervalCollateralRate={currentIntervalCollateralRate!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default IntervalCollateralRatesTable;
