import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { TechnicalRequest } from '@/types';
import { TECH_REQUEST_STATUSES } from '@/utils/tech-request-statuses';

export interface ViewTechnicalRequestProps extends DialogProps {
  technicalRequest: TechnicalRequest | null;
  onClose(): void;
}

const ViewTechnicalRequest: React.FC<ViewTechnicalRequestProps> = ({ technicalRequest, onClose, ...props }) => {
  if (!technicalRequest) return null;

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Информация о заявке</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              value={technicalRequest.organization?.full_name}
              name="organization"
              label="Организация"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              value={technicalRequest.point?.name}
              name="point"
              label="Точка"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              value={technicalRequest.position?.name}
              name="position"
              label="Кому адресована заявка"
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              name="short_description"
              label="Краткое описание заявки"
              value={technicalRequest.short_description}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              name="description"
              label="Описание"
              placeholder="Введите значение"
              value={technicalRequest.description}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              name="executor"
              label="Исполнитель"
              value={
                technicalRequest.executor
                  ? `${technicalRequest.executor?.last_name} ${technicalRequest.executor?.name} ${technicalRequest.executor?.father_name}`
                  : ''
              }
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              multiline
              name="executor_text"
              label="Текст исполнителя"
              value={technicalRequest.executor_text}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              name="execution_date"
              label="Дата исполнения"
              value={technicalRequest.execution_date}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              name="execution_time"
              label="Время на исполнение"
              value={technicalRequest.execution_time}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              name="status"
              label="Статус заявки"
              value={TECH_REQUEST_STATUSES.find((status) => status.value === technicalRequest.status)?.label}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TextField
              name="author"
              label="Автор заявки"
              value={`${technicalRequest.created_by?.last_name} ${technicalRequest.created_by?.name} ${technicalRequest.created_by?.father_name}`}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button type="button" color="secondary" variant="outlined" onClick={onClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTechnicalRequest;
