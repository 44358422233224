import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';

import { IntervalCollateralRate } from '@/types';
import { useDeleteIntervalCollateralRateMutation } from '@/services';

export interface DeleteIntervalCollateralRateProps extends DialogProps {
  intervalCollateralRate: IntervalCollateralRate;
  onClose(): void;
}

const DeleteIntervalCollateralRate: React.FC<DeleteIntervalCollateralRateProps> = ({
  intervalCollateralRate,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteIntervalCollateralRate, deleteIntervalCollateralRateResponse] =
    useDeleteIntervalCollateralRateMutation();

  async function handleDeleteIntervalCollateralRate() {
    try {
      await deleteIntervalCollateralRate(intervalCollateralRate).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteIntervalCollateralRateResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteIntervalCollateralRateResponse.isLoading}
          size="small"
          onClick={handleDeleteIntervalCollateralRate}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIntervalCollateralRate;
