import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Nomenclature, ListResponse } from '@/types';

const nomenclatureApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Nomenclatures'],
});

const nomenclatureApi = nomenclatureApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatures: builder.query<ListResponse<Nomenclature>, AxiosRequestConfig<Nomenclature>>({
      query: (config) => ({ url: '/admin/nomenclature', ...config }),
      providesTags: ['Nomenclatures'],
    }),
    createNomenclature: builder.mutation<Nomenclature, Nomenclature>({
      query: ({ length_unit, nomenclature_group, weight_unit, ...nomenclature }) => ({
        url: '/admin/nomenclature',
        method: 'POST',
        data: {
          ...nomenclature,
          length_unit_id: length_unit?.id,
          nomenclature_group_id: nomenclature_group?.id,
          weight_unit_id: weight_unit?.id,
        },
      }),
      invalidatesTags: ['Nomenclatures'],
    }),
    updateNomenclature: builder.mutation<Nomenclature, Nomenclature>({
      query: ({ length_unit, nomenclature_group, weight_unit, ...nomenclature }) => ({
        url: '/admin/nomenclature/' + nomenclature.id,
        method: 'PUT',
        data: {
          ...nomenclature,
          length_unit_id: length_unit?.id,
          nomenclature_group_id: nomenclature_group?.id,
          weight_unit_id: weight_unit?.id,
        },
      }),
      invalidatesTags: ['Nomenclatures'],
    }),
    deleteNomenclature: builder.mutation<unknown, Nomenclature>({
      query: (nomenclature) => ({ url: '/admin/nomenclature/' + nomenclature.id, method: 'DELETE' }),
      invalidatesTags: ['Nomenclatures'],
    }),
  }),
});

const {
  useGetNomenclaturesQuery,
  useCreateNomenclatureMutation,
  useUpdateNomenclatureMutation,
  useDeleteNomenclatureMutation,
} = nomenclatureApi;

export {
  useGetNomenclaturesQuery,
  useCreateNomenclatureMutation,
  useUpdateNomenclatureMutation,
  useDeleteNomenclatureMutation,
};
