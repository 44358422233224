import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CollateralItemState, ListResponse } from '@/types';

const collateralItemStateApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CollateralItemStates'],
});

const collateralItemStateApi = collateralItemStateApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCollateralItemStates: builder.query<ListResponse<CollateralItemState>, AxiosRequestConfig<CollateralItemState>>({
      query: (config) => ({ url: '/admin/collateral-item-state', ...config }),
      providesTags: ['CollateralItemStates'],
    }),
    createCollateralItemState: builder.mutation<CollateralItemState, CollateralItemState>({
      query: (collateralItemState) => ({
        url: '/admin/collateral-item-state',
        method: 'POST',
        data: collateralItemState,
      }),
      invalidatesTags: ['CollateralItemStates'],
    }),
    updateCollateralItemState: builder.mutation<CollateralItemState, CollateralItemState>({
      query: (collateralItemState) => ({
        url: '/admin/collateral-item-state/' + collateralItemState.id,
        method: 'PUT',
        data: collateralItemState,
      }),
      invalidatesTags: ['CollateralItemStates'],
    }),
    deleteCollateralItemState: builder.mutation<unknown, CollateralItemState>({
      query: (collateralItemState) => ({
        url: '/admin/collateral-item-state/' + collateralItemState.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['CollateralItemStates'],
    }),
  }),
});

const {
  useGetCollateralItemStatesQuery,
  useCreateCollateralItemStateMutation,
  useUpdateCollateralItemStateMutation,
  useDeleteCollateralItemStateMutation,
} = collateralItemStateApi;

export {
  useGetCollateralItemStatesQuery,
  useCreateCollateralItemStateMutation,
  useUpdateCollateralItemStateMutation,
  useDeleteCollateralItemStateMutation,
};
