import { Employee, Pledge } from '@/types';

export enum ProlongationStatuses {
  CARRIED_OUT = 'carried_out',
  CANCELLED = 'cancelled',
}

export interface Prolongation {
  id?: number;
  name?: string;
  pledge?: Pledge | null;
  pledge_id?: Pledge['id'];
  status?: ProlongationStatuses;
  employee?: Employee;
  carried_by?: Employee;
  updated_by?: Employee;
  previous_prolongation_date?: Date | null;
  created_at?: Date | null;
  updated_at?: Date | null;
}
