import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureRate, ListResponse } from '@/types';

const nomenclatureRateApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureRates'],
});

const nomenclatureRateApi = nomenclatureRateApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureRates: builder.query<ListResponse<NomenclatureRate>, AxiosRequestConfig<NomenclatureRate>>({
      query: (config) => ({ url: '/admin/nomenclature-rate', ...config }),
      providesTags: ['NomenclatureRates'],
    }),
    createNomenclatureRate: builder.mutation<NomenclatureRate, NomenclatureRate>({
      query: (nomenclatureRate) => ({ url: '/admin/nomenclature-rate', method: 'POST', data: nomenclatureRate }),
      invalidatesTags: ['NomenclatureRates'],
    }),
    updateNomenclatureRate: builder.mutation<NomenclatureRate, NomenclatureRate>({
      query: (nomenclatureRate) => ({
        url: '/admin/nomenclature-rate/' + nomenclatureRate.id,
        method: 'PUT',
        data: nomenclatureRate,
      }),
      invalidatesTags: ['NomenclatureRates'],
    }),
    deleteNomenclatureRate: builder.mutation<unknown, NomenclatureRate>({
      query: (nomenclatureRate) => ({ url: '/admin/nomenclature-rate/' + nomenclatureRate.id, method: 'DELETE' }),
      invalidatesTags: ['NomenclatureRates'],
    }),
  }),
});

const {
  useGetNomenclatureRatesQuery,
  useCreateNomenclatureRateMutation,
  useUpdateNomenclatureRateMutation,
  useDeleteNomenclatureRateMutation,
} = nomenclatureRateApi;

export {
  useGetNomenclatureRatesQuery,
  useCreateNomenclatureRateMutation,
  useUpdateNomenclatureRateMutation,
  useDeleteNomenclatureRateMutation,
};
