import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { ListResponse, Reports } from '@/types';

const reportsApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: [],
});

const reportsApi = reportsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentsToExpertsReport: builder.query<
      ListResponse<Reports.PaymentsToExperts>,
      AxiosRequestConfig<Reports.PaymentsToExperts>
    >({
      query: (config) => ({ url: '/admin/reports/payments-to-experts', ...config }),
    }),

    getActivePledgesReport: builder.query<
      ListResponse<Reports.ActivePledges>,
      AxiosRequestConfig<Reports.ActivePledges>
    >({
      query: (config) => ({ url: '/admin/reports/active-pledges', ...config }),
    }),
    getActivePledgesSummaryReport: builder.query<
      Reports.ActivePledgesSummary[],
      AxiosRequestConfig<Reports.ActivePledgesSummary>
    >({
      query: (config) => ({ url: '/admin/reports/summary-active-pledges', ...config }),
    }),

    getCashboxOperationsReport: builder.query<
      ListResponse<Reports.CashboxOperations>,
      AxiosRequestConfig<Reports.CashboxOperations>
    >({
      query: (config) => ({ url: '/admin/reports/cashbox-operations', ...config }),
    }),

    getCashboxOperationsSummaryReport: builder.query<
      Reports.CashboxOperationsSummary[],
      AxiosRequestConfig<Reports.CashboxOperationsSummary>
    >({
      query: (config) => ({ url: '/admin/reports/summary-cashbox-operations', ...config }),
    }),

    getWebkassaXReport: builder.query<Reports.WebkassaX, AxiosRequestConfig<Reports.WebkassaX>>({
      query: (config) => ({ url: '/admin/webkassa/x-report', ...config }),
      transformResponse: (report: { Data: Reports.WebkassaX }) => report.Data,
    }),
  }),
});

const {
  useGetPaymentsToExpertsReportQuery,
  useGetActivePledgesReportQuery,
  useGetActivePledgesSummaryReportQuery,
  useGetCashboxOperationsReportQuery,
  useGetCashboxOperationsSummaryReportQuery,
  useGetWebkassaXReportQuery,
} = reportsApi;

export {
  useGetPaymentsToExpertsReportQuery,
  useGetActivePledgesReportQuery,
  useGetActivePledgesSummaryReportQuery,
  useGetCashboxOperationsReportQuery,
  useGetCashboxOperationsSummaryReportQuery,
  useGetWebkassaXReportQuery,
};
