import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Bonus } from '@/types';
import { useGetBonusesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchBonuses<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Bonus> } & Omit<
  AutocompleteProps<Bonus, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetBonusesQueryProps = useAsyncSearch<Bonus, Multiple, DisableClearable, FreeSolo>(
    useGetBonusesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetBonusesQueryProps} />;
}

export default SearchBonuses;
