import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Check } from '@/types';
import { useGetChecksQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchChecks<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Check> } & Omit<
  AutocompleteProps<Check, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetChecksQueryProps = useAsyncSearch<Check, Multiple, DisableClearable, FreeSolo>(
    useGetChecksQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetChecksQueryProps} />;
}

export default SearchChecks;
