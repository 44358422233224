import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { NomenclatureGroupInterval } from '@/types';
import { useDeleteNomenclatureGroupIntervalMutation } from '@/services';

export interface DeleteNomenclatureGroupIntervalProps extends DialogProps {
  nomenclatureGroupInterval: NomenclatureGroupInterval;
  onClose(): void;
}

const DeleteNomenclatureGroupInterval: React.FC<DeleteNomenclatureGroupIntervalProps> = ({
  nomenclatureGroupInterval,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteNomenclatureGroupInterval, deleteNomenclatureGroupIntervalResponse] =
    useDeleteNomenclatureGroupIntervalMutation();

  async function handleDeleteNomenclatureGroupInterval() {
    try {
      await deleteNomenclatureGroupInterval(nomenclatureGroupInterval).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{nomenclatureGroupInterval?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{nomenclatureGroupInterval?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteNomenclatureGroupIntervalResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteNomenclatureGroupIntervalResponse.isLoading}
          onClick={handleDeleteNomenclatureGroupInterval}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNomenclatureGroupInterval;
