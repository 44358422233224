import { InputHTMLAttributes, useCallback, useState } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import {
  Box,
  ButtonBase,
  capitalize,
  Card,
  CardActions,
  CardMedia,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  lighten,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';

import { FileFlat } from '@/assets/icons';
import theme from '@/theme';

export interface FileInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
  DropzoneProps?: DropzoneOptions;
  error?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  helperText?: React.ReactNode;
  label?: string;
  multiple?: boolean;
  onChange<T extends File>(acceptedFiles: T[] | [null], fileRejections?: FileRejection[], event?: DropEvent): void;
}

const FileInput: React.FC<FileInputProps> = ({
  disabled = false,
  error = false,
  fullWidth = true,
  size = 'medium',
  helperText,
  label,
  multiple = false,
  onChange,
  DropzoneProps,
  ...props
}) => {
  const [dropzone, setDropzone] = useState<{
    acceptedFiles: File[];
    fileRejections?: FileRejection[];
    event?: DropEvent;
  }>({ acceptedFiles: [] });

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (multiple) {
        const filteredFiles = acceptedFiles.filter(
          (file) => !dropzone.acceptedFiles.find(({ name }) => file.name === name),
        );

        if (filteredFiles.length) {
          setDropzone({ acceptedFiles: filteredFiles, fileRejections, event });
          onChange(filteredFiles, fileRejections, event);
        }
        return;
      }

      if (acceptedFiles[0].name !== dropzone.acceptedFiles[0]?.name) {
        setDropzone({ acceptedFiles, fileRejections, event });
        onChange(acceptedFiles, fileRejections, event);
      }
    },
    [dropzone.acceptedFiles, multiple, onChange],
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    multiple,
    ...DropzoneProps,
  });

  const removeFile = (file: File) => {
    const newFiles = [...dropzone.acceptedFiles!];

    newFiles.splice(newFiles.indexOf(file), 1);
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setDropzone({ acceptedFiles: newFiles });
    onChange(newFiles.length ? newFiles : [null]);
  };

  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: isDragActive,
        [classes.error]: error,
        [classes.fullWidth]: fullWidth,
        [classes[`size${capitalize(size)}` as keyof typeof classes]]: size,
      })}
    >
      <label>
        {label && (
          <Typography color="text.secondary" gutterBottom sx={{ mx: '14px' }}>
            {label}
          </Typography>
        )}

        <ButtonBase disabled={disabled} {...getRootProps()}>
          <FileFlat color="action" sx={{ fontSize: 16 }} />
          &nbsp;
          <Typography component="span">Перетащите или&nbsp;</Typography>
          <Typography component="span" sx={{ textDecoration: 'underline' }}>
            выберите файл
          </Typography>
        </ButtonBase>

        <input disabled={disabled} type="file" {...getInputProps()} {...props} />
      </label>

      {helperText && (
        <FormHelperText error={error} sx={{ mx: '14px' }}>
          {helperText}
        </FormHelperText>
      )}

      {Boolean(dropzone.acceptedFiles.length) && (
        <Grid container spacing={1} className={classes.grid}>
          {dropzone.acceptedFiles.map((file, index) => {
            const preview = URL.createObjectURL(file),
              [type, extension] = file.type.split('/');

            return (
              <Grid item key={index}>
                <Card variant="outlined" sx={{ maxWidth: 300 }}>
                  {['image'].includes(type) ? (
                    <CardMedia
                      component="img"
                      image={preview}
                      height="160"
                      onLoad={() => URL.revokeObjectURL(preview)}
                      alt={file.name}
                    />
                  ) : (
                    <Box sx={{ height: 100, px: 2, textAlign: 'center' }}>
                      <Tooltip
                        title={
                          <Typography component="p" variant="caption" align="center">
                            {type} <br />
                            {extension}
                          </Typography>
                        }
                      >
                        <Typography
                          sx={{
                            maxWidth: 266,
                            lineHeight: '100px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            fontSize: 32,
                            color: red[300],
                          }}
                        >
                          {extension}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}

                  <Divider />

                  <CardActions>
                    <Chip label={file.name} variant="outlined" disabled={disabled} onDelete={() => removeFile(file)} />
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

const backgroundImage = (color: string) => `
      url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${color.slice(
        1,
      )}FF' stroke-width='2.5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;

export const useStyles = makeStyles(() => ({
  root: {
    '& label .MuiButtonBase-root': {
      backgroundImage: backgroundImage(theme.palette.text.secondary),
      borderRadius: 8,
      overflow: 'hidden',
      transition: 'background-color 200ms',
    },

    '& label .MuiButtonBase-root:not(.Mui-disabled)': {
      '&:hover, &:focus': {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      },
    },

    '& label .MuiTypography-root': {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
    },
  },

  active: {
    color: theme.palette.primary.main,

    '& label .MuiButtonBase-root:not(.Mui-disabled)': {
      backgroundImage: backgroundImage(theme.palette.primary.main),

      '&:hover, &:focus': {
        backgroundColor: lighten(theme.palette.primary.light, 0.75),
      },
    },

    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },

  error: {
    color: theme.palette.error.main,

    '& label .MuiButtonBase-root:not(.Mui-disabled)': {
      backgroundImage: backgroundImage(theme.palette.error.main),

      '&:hover, &:focus': {
        backgroundColor: lighten(theme.palette.error.main, 0.85),
      },
    },

    '& label .MuiTypography-root, & label .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },

  fullWidth: {
    '& label .MuiButtonBase-root': { width: '100%' },
  },

  sizeSmall: {
    '& label .MuiButtonBase-root': {
      padding: '8px 10px',
    },

    '& label .MuiTypography-root': {
      fontSize: 14,
    },
  },

  sizeMedium: {
    '& label .MuiButtonBase-root': {
      padding: '16px 30px',
    },
  },

  sizeLarge: {
    '& label .MuiButtonBase-root': {
      padding: '20px 36px',
    },
  },

  grid: { padding: '10px 0 0 0' },
}));

export default FileInput;
