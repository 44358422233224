import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Unit } from '@/types';
import { useDeleteUnitMutation } from '@/services';

export interface DeleteUnitProps extends DialogProps {
  unit: Unit;
  onClose(): void;
}

const DeleteUnit: React.FC<DeleteUnitProps> = ({ unit, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteUnit, deleteUnitResponse] = useDeleteUnitMutation();

  async function handleDeleteUnit() {
    try {
      await deleteUnit(unit).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{unit?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{unit?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteUnitResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteUnitResponse.isLoading} onClick={handleDeleteUnit}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUnit;
