import { PropsWithChildren, createContext } from 'react';

import { Roles } from '@/types';
import { useAuth } from '@/hooks';

export interface RolesProviderProps {
  isAdmin: boolean;
  isRevisor: boolean;
  isCachier: boolean;
  hasRoles(roles: (keyof typeof Roles)[]): boolean;
}

const initialRolesProviderValues: RolesProviderProps = {
  isAdmin: false,
  isRevisor: false,
  isCachier: false,
  hasRoles() {
    return false;
  },
};

export const RolesContext = createContext<RolesProviderProps>(initialRolesProviderValues);

const RolesProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { employee } = useAuth();

  function hasRole(role: Roles) {
    if (employee?.role?.code) return Boolean([role].includes(employee?.role?.code));
    return false;
  }

  function hasRoles(roles: (keyof typeof Roles)[]) {
    return roles.map((role) => [Roles[role]].includes(employee?.role?.code!)).some(Boolean);
  }

  return (
    <RolesContext.Provider
      value={{
        isAdmin: hasRole(Roles.ADMIN),
        isRevisor: hasRole(Roles.REVISOR),
        isCachier: hasRole(Roles.CASHIER),
        hasRoles,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export default RolesProvider;
