import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Employee, EmployeePercent, ListResponse, StandardResponse } from '@/types';

const employeeApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Employee', 'Employees'],
});

const employeeApi = employeeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<ListResponse<Employee>, AxiosRequestConfig<Employee>>({
      query: (config) => ({ url: '/admin/employee', ...config }),
      providesTags: ['Employees'],
    }),
    getEmployee: builder.query<
      StandardResponse<Employee>,
      { id: Employee['id']; config?: AxiosRequestConfig<Employee> }
    >({ query: ({ id, config }) => ({ url: '/admin/employee/' + id, ...config }), providesTags: ['Employee'] }),
    createEmployee: builder.mutation<Employee, Employee>({
      query: ({ role, ...employee }) => ({
        url: '/admin/employee',
        method: 'POST',
        data: { ...employee, role_id: role?.id },
      }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployee: builder.mutation<Employee, Employee>({
      query: ({ role, ...employee }) => ({
        url: '/admin/employee/' + employee.id,
        method: 'PUT',
        data: { ...employee, role_id: role?.id },
      }),
      invalidatesTags: ['Employees'],
    }),
    deleteEmployee: builder.mutation<unknown, Employee>({
      query: (employee) => ({ url: '/admin/employee/' + employee.id, method: 'DELETE' }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployeeSalaryPercent: builder.mutation<Employee, { id: Employee['id']; percents: EmployeePercent[] }>({
      query: ({ id, percents }) => ({ url: `/admin/employee/${id}/percents`, method: 'PUT', data: { percents } }),
      invalidatesTags: ['Employees'],
    }),
    changeEmployeePassword: builder.mutation<Employee, { id: Employee['id']; password: string }>({
      query: ({ id, password }) => ({
        url: `/admin/employee/${id}/change-password`,
        method: 'PUT',
        data: { password },
      }),
      invalidatesTags: ['Employees'],
    }),
    changeEmployeeAdditionalPassword: builder.mutation<
      string,
      { id: Employee['id']; additional_password: Employee['additional_password'] }
    >({
      query: ({ id, additional_password }) => ({
        url: `/admin/employee/${id}/change-additional-password`,
        method: 'PUT',
        data: { additional_password },
      }),
      invalidatesTags: ['Employee'],
    }),
    checkEmployeeAdditionalPassword: builder.mutation<
      boolean,
      { id: Employee['id']; additional_password: Employee['additional_password'] }
    >({
      query: ({ id, additional_password }) => ({
        url: `/admin/employee/${id}/check-additional-password`,
        method: 'POST',
        data: { additional_password },
      }),
    }),
  }),
});

const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeSalaryPercentMutation,
  useChangeEmployeePasswordMutation,
  useChangeEmployeeAdditionalPasswordMutation,
  useCheckEmployeeAdditionalPasswordMutation,
} = employeeApi;

export {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeSalaryPercentMutation,
  useChangeEmployeePasswordMutation,
  useChangeEmployeeAdditionalPasswordMutation,
  useCheckEmployeeAdditionalPasswordMutation,
};
