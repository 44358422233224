import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureGroupInterval, ListResponse } from '@/types';

const nomenclatureGroupIntervalApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureGroupIntervals'],
});

const nomenclatureGroupIntervalApi = nomenclatureGroupIntervalApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureGroupIntervals: builder.query<
      ListResponse<NomenclatureGroupInterval>,
      AxiosRequestConfig<NomenclatureGroupInterval>
    >({
      query: (config) => ({ url: '/admin/nomenclature-group-interval', ...config }),
      providesTags: ['NomenclatureGroupIntervals'],
    }),
    createNomenclatureGroupInterval: builder.mutation<NomenclatureGroupInterval, NomenclatureGroupInterval>({
      query: (nomenclatureGroupInterval) => ({
        url: '/admin/nomenclature-group-interval',
        method: 'POST',
        data: nomenclatureGroupInterval,
      }),
      invalidatesTags: ['NomenclatureGroupIntervals'],
    }),
    updateNomenclatureGroupInterval: builder.mutation<NomenclatureGroupInterval, NomenclatureGroupInterval>({
      query: (nomenclatureGroupInterval) => ({
        url: '/admin/nomenclature-group-interval/' + nomenclatureGroupInterval.id,
        method: 'PUT',
        data: nomenclatureGroupInterval,
      }),
      invalidatesTags: ['NomenclatureGroupIntervals'],
    }),
    deleteNomenclatureGroupInterval: builder.mutation<unknown, NomenclatureGroupInterval>({
      query: (nomenclatureGroupInterval) => ({
        url: '/admin/nomenclature-group-interval/' + nomenclatureGroupInterval.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['NomenclatureGroupIntervals'],
    }),
    getNomenclatureGroupIntervalPercent: builder.query<number, AxiosRequestConfig<NomenclatureGroupInterval>>({
      query: (config) => ({ url: '/admin/nomenclature-group-interval/get-percent', ...config }),
      providesTags: ['NomenclatureGroupIntervals'],
    }),
  }),
});

const {
  useGetNomenclatureGroupIntervalsQuery,
  useCreateNomenclatureGroupIntervalMutation,
  useUpdateNomenclatureGroupIntervalMutation,
  useDeleteNomenclatureGroupIntervalMutation,
  useGetNomenclatureGroupIntervalPercentQuery,
  useLazyGetNomenclatureGroupIntervalPercentQuery,
} = nomenclatureGroupIntervalApi;

export {
  useGetNomenclatureGroupIntervalsQuery,
  useCreateNomenclatureGroupIntervalMutation,
  useUpdateNomenclatureGroupIntervalMutation,
  useDeleteNomenclatureGroupIntervalMutation,
  useGetNomenclatureGroupIntervalPercentQuery,
  useLazyGetNomenclatureGroupIntervalPercentQuery,
};
