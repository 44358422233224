import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { NomenclatureGroupChar, ListResponse } from '@/types';
import { COLLATERAL_ITEM_CHARS } from '@/utils/collateral-item-chars';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteNomenclatureGroupChar, UpdateNomenclatureGroupChar } from '../components';

export interface NomenclatureGroupCharListProps {
  list?: ListResponse<NomenclatureGroupChar>;
  page: number;
  onChangePage(page: number): void;
}

const NomenclatureGroupCharsTable: React.FC<NomenclatureGroupCharListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentNomenclatureGroupChar, setCurrentNomenclatureGroupChar] = useState<NomenclatureGroupChar | null>(null);

  function openUpdateModal(nomenclatureGroupChar: NomenclatureGroupChar) {
    setCurrentNomenclatureGroupChar(nomenclatureGroupChar);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(nomenclatureGroupChar: NomenclatureGroupChar) {
    setCurrentNomenclatureGroupChar(nomenclatureGroupChar);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Тип поля</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((nomenclatureGroupChar) => {
                const { name, type, id } = nomenclatureGroupChar,
                  charType = COLLATERAL_ITEM_CHARS.find((charType) => charType.value === type);

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{charType?.label}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(nomenclatureGroupChar)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(nomenclatureGroupChar)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateNomenclatureGroupChar
        nomenclatureGroupChar={currentNomenclatureGroupChar!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteNomenclatureGroupChar
        nomenclatureGroupChar={currentNomenclatureGroupChar!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default NomenclatureGroupCharsTable;
