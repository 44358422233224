import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { ElectronicBrand, ListResponse } from '@/types';

const electronicBrandApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['ElectronicBrands'],
});

const electronicBrandApi = electronicBrandApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getElectronicBrands: builder.query<ListResponse<ElectronicBrand>, AxiosRequestConfig<ElectronicBrand>>({
      query: (config) => ({ url: '/admin/electronic-brand', ...config }),
      providesTags: ['ElectronicBrands'],
    }),
    createElectronicBrand: builder.mutation<ElectronicBrand, ElectronicBrand>({
      query: (electronicBrand) => ({ url: '/admin/electronic-brand', method: 'POST', data: electronicBrand }),
      invalidatesTags: ['ElectronicBrands'],
    }),
    updateElectronicBrand: builder.mutation<ElectronicBrand, ElectronicBrand>({
      query: (electronicBrand) => ({
        url: '/admin/electronic-brand/' + electronicBrand.id,
        method: 'PUT',
        data: electronicBrand,
      }),
      invalidatesTags: ['ElectronicBrands'],
    }),
    deleteElectronicBrand: builder.mutation<unknown, ElectronicBrand>({
      query: (electronicBrand) => ({ url: '/admin/electronic-brand/' + electronicBrand.id, method: 'DELETE' }),
      invalidatesTags: ['ElectronicBrands'],
    }),
  }),
});

const {
  useGetElectronicBrandsQuery,
  useCreateElectronicBrandMutation,
  useUpdateElectronicBrandMutation,
  useDeleteElectronicBrandMutation,
} = electronicBrandApi;

export {
  useGetElectronicBrandsQuery,
  useCreateElectronicBrandMutation,
  useUpdateElectronicBrandMutation,
  useDeleteElectronicBrandMutation,
};
