import { CssBaseline } from '@mui/material';

import Router from '@/Router';
import GlobalStyles from '@/styles';

// [ ] TODO: Удалить prop fullwidth. Сделать его по умолчанию true
// [ ] TODO: Сбрасывать форму редактирования при закрытии и вешать функцию закрытия на кнопку закрытия
// [ ] TODO: Добавить prop type=tel ко всем полям с вводом цифр
// [ ] TODO: number => boolean, где это необходимо

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <CssBaseline />

      <Router />
    </>
  );
};

export default App;
