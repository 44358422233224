import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Employee } from '@/types';
import { useDeleteEmployeeMutation } from '@/services';

export interface DeleteEmployeeProps extends DialogProps {
  employee: Employee;
  onClose(): void;
}

const DeleteEmployee: React.FC<DeleteEmployeeProps> = ({ employee, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEmployee, deleteEmployeeResponse] = useDeleteEmployeeMutation();

  async function handleDeleteEmployee() {
    try {
      await deleteEmployee(employee).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{employee?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{employee?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteEmployeeResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteEmployeeResponse.isLoading} onClick={handleDeleteEmployee}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEmployee;
