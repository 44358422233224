import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureRateCategory, ListResponse } from '@/types';

const nomenclatureRateCategoryApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureRateCategories'],
});

const nomenclatureRateCategoryApi = nomenclatureRateCategoryApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureRateCategories: builder.query<
      ListResponse<NomenclatureRateCategory>,
      AxiosRequestConfig<NomenclatureRateCategory>
    >({
      query: (config) => ({ url: '/admin/nomenclature-rate-category', ...config }),
      providesTags: ['NomenclatureRateCategories'],
    }),
    createNomenclatureRateCategory: builder.mutation<NomenclatureRateCategory, NomenclatureRateCategory>({
      query: (nomenclatureRateCategory) => ({
        url: '/admin/nomenclature-rate-category',
        method: 'POST',
        data: nomenclatureRateCategory,
      }),
      invalidatesTags: ['NomenclatureRateCategories'],
    }),
    updateNomenclatureRateCategory: builder.mutation<NomenclatureRateCategory, NomenclatureRateCategory>({
      query: (nomenclatureRateCategory) => ({
        url: '/admin/nomenclature-rate-category/' + nomenclatureRateCategory.id,
        method: 'PUT',
        data: nomenclatureRateCategory,
      }),
      invalidatesTags: ['NomenclatureRateCategories'],
    }),
    deleteNomenclatureRateCategory: builder.mutation<unknown, NomenclatureRateCategory>({
      query: (nomenclatureRateCategory) => ({
        url: '/admin/nomenclature-rate-category/' + nomenclatureRateCategory.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['NomenclatureRateCategories'],
    }),
  }),
});

const {
  useGetNomenclatureRateCategoriesQuery,
  useCreateNomenclatureRateCategoryMutation,
  useUpdateNomenclatureRateCategoryMutation,
  useDeleteNomenclatureRateCategoryMutation,
} = nomenclatureRateCategoryApi;

export {
  useGetNomenclatureRateCategoriesQuery,
  useCreateNomenclatureRateCategoryMutation,
  useUpdateNomenclatureRateCategoryMutation,
  useDeleteNomenclatureRateCategoryMutation,
};
