import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { CustomerDocumentType } from '@/types';
import { useDeleteCustomerDocumentTypeMutation } from '@/services';

export interface DeleteCustomerDocumentTypeProps extends DialogProps {
  customerDocumentType: CustomerDocumentType;
  onClose(): void;
}

const DeleteCustomerDocumentType: React.FC<DeleteCustomerDocumentTypeProps> = ({
  customerDocumentType,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCustomerDocumentType, deleteCustomerDocumentTypeResponse] = useDeleteCustomerDocumentTypeMutation();

  async function handleDeleteCustomerDocumentType() {
    try {
      await deleteCustomerDocumentType(customerDocumentType).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{customerDocumentType?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{customerDocumentType?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteCustomerDocumentTypeResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteCustomerDocumentTypeResponse.isLoading}
          onClick={handleDeleteCustomerDocumentType}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomerDocumentType;
