import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { NomenclatureGroupDiscount } from '@/types';
import { useDeleteNomenclatureGroupDiscountMutation } from '@/services';

export interface DeleteNomenclatureGroupDiscountProps extends DialogProps {
  nomenclatureGroupDiscount: NomenclatureGroupDiscount;
  onClose(): void;
}

const DeleteNomenclatureGroupDiscount: React.FC<DeleteNomenclatureGroupDiscountProps> = ({
  nomenclatureGroupDiscount,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteNomenclatureGroupDiscount, deleteNomenclatureGroupDiscountResponse] =
    useDeleteNomenclatureGroupDiscountMutation();

  async function handleDeleteNomenclatureGroupDiscount() {
    try {
      await deleteNomenclatureGroupDiscount(nomenclatureGroupDiscount).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{nomenclatureGroupDiscount?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{nomenclatureGroupDiscount?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteNomenclatureGroupDiscountResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteNomenclatureGroupDiscountResponse.isLoading}
          onClick={handleDeleteNomenclatureGroupDiscount}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNomenclatureGroupDiscount;
