import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Buyout } from '@/types';
import { useGetBuyoutsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchBuyouts<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Buyout> } & Omit<
  AutocompleteProps<Buyout, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetBuyoutsQueryProps = useAsyncSearch<Buyout, Multiple, DisableClearable, FreeSolo>(
    useGetBuyoutsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetBuyoutsQueryProps} />;
}

export default SearchBuyouts;
