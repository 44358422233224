import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Locality } from '@/types';
import { useDeleteLocalityMutation } from '@/services';

export interface DeleteLocalityProps extends DialogProps {
  locality: Locality;
  onClose(): void;
}

const DeleteLocality: React.FC<DeleteLocalityProps> = ({ locality, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLocality, deleteLocalityResponse] = useDeleteLocalityMutation();

  async function handleDeleteLocality() {
    try {
      await deleteLocality(locality).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{locality?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{locality?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteLocalityResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteLocalityResponse.isLoading} onClick={handleDeleteLocality}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLocality;
