import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { TerroristOrganization, ListResponse } from '@/types';

const terroristOrganizationApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['TerroristOrganizations'],
});

const terroristOrganizationApi = terroristOrganizationApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTerroristOrganizations: builder.query<
      ListResponse<TerroristOrganization>,
      AxiosRequestConfig<TerroristOrganization>
    >({
      query: (config) => ({ url: '/admin/terrorist-organization', ...config }),
      providesTags: ['TerroristOrganizations'],
    }),
    createTerroristOrganization: builder.mutation<TerroristOrganization, TerroristOrganization>({
      query: (terroristOrganization) => ({
        url: '/admin/terrorist-organization',
        method: 'POST',
        data: terroristOrganization,
      }),
      invalidatesTags: ['TerroristOrganizations'],
    }),
    updateTerroristOrganization: builder.mutation<TerroristOrganization, TerroristOrganization>({
      query: (terroristOrganization) => ({
        url: '/admin/terrorist-organization/' + terroristOrganization.id,
        method: 'PUT',
        data: terroristOrganization,
      }),
      invalidatesTags: ['TerroristOrganizations'],
    }),
    deleteTerroristOrganization: builder.mutation<unknown, TerroristOrganization>({
      query: (terroristOrganization) => ({
        url: '/admin/terrorist-organization/' + terroristOrganization.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['TerroristOrganizations'],
    }),
    refresh: builder.query<ListResponse<TerroristOrganization>, AxiosRequestConfig<TerroristOrganization>>({
      query: (config) => ({ url: '/admin/terrorist-organization/refresh', ...config }),
    }),
  }),
});

const {
  useGetTerroristOrganizationsQuery,
  useCreateTerroristOrganizationMutation,
  useUpdateTerroristOrganizationMutation,
  useDeleteTerroristOrganizationMutation,
} = terroristOrganizationApi;

export {
  useGetTerroristOrganizationsQuery,
  useCreateTerroristOrganizationMutation,
  useUpdateTerroristOrganizationMutation,
  useDeleteTerroristOrganizationMutation,
};
