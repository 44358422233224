import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Pledge, ListResponse, StandardResponse, RePledgeCalculation } from '@/types';

const pledgeApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Pledges'],
});

const pledgeApi = pledgeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPledges: builder.query<ListResponse<Pledge>, AxiosRequestConfig<Pledge>>({
      query: (config) => ({ url: '/admin/pledge', ...config }),
      providesTags: ['Pledges'],
    }),
    getPledge: builder.query<StandardResponse<Pledge>, { id: Pledge['id']; config?: AxiosRequestConfig<Pledge> }>({
      query: ({ id, config }) => ({ url: '/admin/pledge/' + id, ...config }),
    }),
    createPledge: builder.mutation<Pledge, Pledge>({
      query: ({ customer, trusted_person, nomenclature_group, point, ...pledge }) => ({
        url: '/admin/pledge',
        method: 'POST',
        data: {
          ...pledge,
          customer_id: customer?.id,
          trusted_person_id: trusted_person?.id,
          nomenclature_group_id: nomenclature_group?.id,
          point_id: point?.id,
        },
      }),
      invalidatesTags: ['Pledges'],
    }),
    updatePledge: builder.mutation<Pledge, Pledge>({
      query: ({ customer, trusted_person, nomenclature_group, point, ...pledge }) => ({
        url: '/admin/pledge/' + pledge.id,
        method: 'PUT',
        data: {
          ...pledge,
          customer_id: customer?.id,
          trusted_person_id: trusted_person?.id,
          nomenclature_group_id: nomenclature_group?.id,
          point_id: point?.id,
        },
      }),
      invalidatesTags: ['Pledges'],
    }),
    calculateRePledge: builder.mutation<StandardResponse<Pledge>, RePledgeCalculation>({
      query: ({ pledge, partial_buyout_items, ...calculations }) => ({
        url: '/admin/pledge/calculate-re-pledge',
        method: 'POST',
        data: {
          ...calculations,
          pledge_id: pledge?.id,
          partial_buyout_item_ids: partial_buyout_items.map(({ id }) => id),
        },
      }),
    }),
    deletePledge: builder.mutation<unknown, Pledge>({
      query: (pledge) => ({ url: '/admin/pledge/' + pledge.id, method: 'DELETE' }),
      invalidatesTags: ['Pledges'],
    }),
  }),
});

const {
  useGetPledgesQuery,
  useGetPledgeQuery,
  useCreatePledgeMutation,
  useUpdatePledgeMutation,
  useCalculateRePledgeMutation,
  useDeletePledgeMutation,
} = pledgeApi;

export {
  useGetPledgesQuery,
  useGetPledgeQuery,
  useCreatePledgeMutation,
  useUpdatePledgeMutation,
  useCalculateRePledgeMutation,
  useDeletePledgeMutation,
};
