import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { NomenclatureGroupDiscount } from '@/types';
import { useCreateNomenclatureGroupDiscountMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchPoints } from '@/pages/Points/components';

export interface CreateNomenclatureGroupDiscountProps extends DialogProps {
  onClose(): void;
}

const CreateNomenclatureGroupDiscount: React.FC<CreateNomenclatureGroupDiscountProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createNomenclatureGroupDiscount, createNomenclatureGroupDiscountResponse] =
    useCreateNomenclatureGroupDiscountMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<NomenclatureGroupDiscount>({
    initialValues: {
      name: '',
      percent: '',
      point: null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      percent: yup.string().required(messages.form.REQUIRED),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(nomenclatureGroupDiscount, { resetForm }) {
      try {
        await createNomenclatureGroupDiscount(nomenclatureGroupDiscount).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить скидку</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createNomenclatureGroupDiscountResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="percent"
                label="Скидка"
                placeholder="Введите значение"
                value={values.percent}
                onChange={handleChange}
                disabled={createNomenclatureGroupDiscountResponse.isLoading}
                error={touched.percent && Boolean(errors.percent)}
                helperText={touched.percent ? errors.percent : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={createNomenclatureGroupDiscountResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={createNomenclatureGroupDiscountResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={createNomenclatureGroupDiscountResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNomenclatureGroupDiscount;
