import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Computer, ListResponse } from '@/types';

const computerApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Computers'],
});

const computerApi = computerApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getComputers: builder.query<ListResponse<Computer>, AxiosRequestConfig<Computer>>({
      query: (config) => ({ url: '/admin/computer', ...config }),
      providesTags: ['Computers'],
    }),
    createComputer: builder.mutation<Computer, Computer>({
      query: ({ point, ...computer }) => ({
        url: '/admin/computer',
        method: 'POST',
        data: { point_id: point?.id, ...computer },
      }),
      invalidatesTags: ['Computers'],
    }),
    updateComputer: builder.mutation<Computer, Computer>({
      query: ({ point, ...computer }) => ({
        url: '/admin/computer/' + computer.id,
        method: 'PUT',
        data: { point_id: point?.id, ...computer },
      }),
      invalidatesTags: ['Computers'],
    }),
    deleteComputer: builder.mutation<unknown, Computer>({
      query: (computer) => ({ url: '/admin/computer/' + computer.id, method: 'DELETE' }),
      invalidatesTags: ['Computers'],
    }),
  }),
});

const { useGetComputersQuery, useCreateComputerMutation, useUpdateComputerMutation, useDeleteComputerMutation } =
  computerApi;

export { useGetComputersQuery, useCreateComputerMutation, useUpdateComputerMutation, useDeleteComputerMutation };
