import moment from 'moment';
import { DateParam, JsonParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { Button, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Customer, Employee, Point } from '@/types';
import { useGetCashboxOperationsReportQuery, useGetCashboxOperationsSummaryReportQuery } from '@/services';
import { useAuth, useDebounce } from '@/hooks';
import { AppBar, DownloadFile, Empty, Error, Loader } from '@/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchEmployees } from '@/pages/Employees/components';
import { ChartGrowthFlat } from '@/assets/icons';

import { CashboxOperationsSummaryTable, CashboxOperationsTable } from './components';

const CashboxOperationsReportPage: React.FC = () => {
  const [{ page, points, customers, employees, date_from, date_to }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    points: withDefault<Partial<Point>[], Point[]>(JsonParam, []),
    customers: withDefault<Partial<Customer>[], Customer[]>(JsonParam, []),
    employees: withDefault<Partial<Employee>[], Employee[]>(JsonParam, []),
    date_from: withDefault(DateParam, null),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const { user, isAdmin, isRevisor, isCashier } = useAuth();

  const point_ids = useDebounce(points.map(({ id }) => id)),
    customer_ids = useDebounce(customers.map(({ id }) => id)),
    employee_ids = useDebounce(employees.map(({ id }) => id));

  const params = {
    point_ids: isCashier ? [user?.point?.id] : point_ids,
    customer_ids,
    employee_ids,
    date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
    date_to: moment(date_to).format('YYYY-MM-DD'),
  };

  const emptyFilters =
    isAdmin || isRevisor ? ![point_ids.length, customer_ids.length, employee_ids.length].some(Boolean) : false;

  const {
    data: cashboxOperations,
    isLoading: cashboxOperationsLoading,
    isFetching: cashboxOperationsFetching,
    isSuccess: cashboxOperationsSuccess,
    isError: cashboxOperationsError,
  } = useGetCashboxOperationsReportQuery({ params: { ...params, page } }, { skip: emptyFilters });

  const {
    data: cashboxOperationsSummary,
    isLoading: cashboxOperationsSummaryLoading,
    isFetching: cashboxOperationsSummaryFetching,
    isSuccess: cashboxOperationsSummarySuccess,
    isError: cashboxOperationsSummaryError,
  } = useGetCashboxOperationsSummaryReportQuery({ params }, { skip: emptyFilters });

  const isLoading = cashboxOperationsLoading || cashboxOperationsSummaryLoading;
  const isFetching = cashboxOperationsFetching || cashboxOperationsSummaryFetching;

  return (
    <>
      <AppBar title="Движение по кассе" />

      <Grid container spacing={3}>
        <Grid item xs={12} className="no-print">
          <Grid container alignItems="center" spacing={2}>
            {(isAdmin || isRevisor) && (
              <>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <SearchPoints
                    multiple
                    value={points as Point[]}
                    disableCloseOnSelect
                    onChange={(event, options) => setQuery({ points: options.map(({ id, name }) => ({ id, name })) })}
                    disabled={isLoading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="points"
                        label="Точки"
                        placeholder="Выберите из списка"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <SearchEmployees
                    multiple
                    value={employees as Employee[]}
                    limitTags={1}
                    disableCloseOnSelect
                    onChange={(event, options) =>
                      setQuery({
                        employees: options.map(({ id, name, last_name, father_name }) => ({
                          id,
                          name,
                          last_name,
                          father_name,
                        })),
                      })
                    }
                    disabled={isLoading}
                    getOptionLabel={({ name, last_name, father_name }) => `${last_name} ${name} ${father_name} `}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        name="employees"
                        label="Сотрудники"
                        placeholder="Выберите из списка"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <SearchCustomers
                multiple
                value={customers as Customer[]}
                limitTags={1}
                disableCloseOnSelect
                onChange={(event, options) =>
                  setQuery({
                    customers: options.map(({ id, name, last_name, father_name }) => ({
                      id,
                      name,
                      last_name,
                      father_name,
                    })),
                  })
                }
                disabled={isLoading}
                getOptionLabel={({ name, last_name, father_name }) =>
                  `${last_name ?? ''} ${name} ${father_name ?? ''} `
                }
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="customers"
                    label="Клиенты"
                    placeholder="Выберите из списка"
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата от"
                value={date_from ? date_from : null}
                maxDate={moment(date_to)}
                onChange={(value) => setQuery({ date_from: moment(value).toDate() })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_from" fullWidth variant="filled" size="small" />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата до"
                value={date_to}
                maxDate={moment()}
                onChange={(value) => setQuery({ date_to: moment(value).toDate() })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_to" fullWidth variant="filled" size="small" />
                )}
              />
            </Grid>

            {!emptyFilters && (
              <Grid item>
                <DownloadFile
                  url="/admin/reports/cashbox-operations/excel"
                  fileName="cashbox-operation.xlsx"
                  params={params}
                >
                  {(onDownload, state) => (
                    <Button
                      variant="contained"
                      startIcon={<ChartGrowthFlat />}
                      size="large"
                      disabled={isFetching || isLoading || state.isLoading}
                      onClick={onDownload}
                    >
                      Сформировать отчёт
                    </Button>
                  )}
                </DownloadFile>
              </Grid>
            )}
          </Grid>
        </Grid>

        {emptyFilters ? (
          <Grid item xs={12}>
            <Empty text="Выберите фильтр по точкам, сотрудникам или клиентам" />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {cashboxOperationsSummaryLoading || cashboxOperationsSummaryFetching ? (
                <Loader />
              ) : cashboxOperationsSummaryError ? (
                <Error text="Произошла ошибка с загрузкой данных по общей информации! Пожалуйста, попробуйте позже" />
              ) : cashboxOperationsSummarySuccess && Boolean(cashboxOperationsSummary?.length) ? (
                <CashboxOperationsSummaryTable list={cashboxOperationsSummary} />
              ) : (
                <Empty text="Данные отсутствуют" />
              )}
            </Grid>

            <Grid item xs={12}>
              {cashboxOperationsLoading || cashboxOperationsFetching ? (
                <Loader />
              ) : cashboxOperationsError ? (
                <Error text="Произошла ошибка с загрузкой данных по активным залогам! Пожалуйста, попробуйте позже" />
              ) : cashboxOperationsSuccess && Boolean(cashboxOperations?.data?.length) ? (
                <CashboxOperationsTable
                  list={cashboxOperations}
                  page={page}
                  onChangePage={(page) => setQuery({ page })}
                />
              ) : (
                <Empty text="Данные отсутствуют" />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CashboxOperationsReportPage;
