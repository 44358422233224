import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Position } from '@/types';
import { useDeletePositionMutation } from '@/services';

export interface DeletePositionProps extends DialogProps {
  position: Position;
  onClose(): void;
}

const DeletePosition: React.FC<DeletePositionProps> = ({ position, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deletePosition, deletePositionResponse] = useDeletePositionMutation();

  async function handleDeletePosition() {
    try {
      await deletePosition(position).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{position?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{position?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deletePositionResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deletePositionResponse.isLoading} onClick={handleDeletePosition}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePosition;
