import { Point } from '@/types';

export interface CustomerStatusPriceForPoints {
  point: Point | null;
  point_id?: Point['id'];
  common_price: number;
  vip_price: number;
}

export enum Samples {
  GOLD = 1,
  PLATINUM = 2,
  SILVER = 3,
  OTHER = 4,
}

export interface Sample {
  id?: number;
  name: string;
  metal?: string;
  metal_type: Samples | '';
  min_limit: string;
  max_limit: string;
  price: number;
  vip_price: number;
  auto_calculation: boolean;
  show_in_jewelries: boolean;
  dont_calculate_from_999: boolean;
  prices_configurable_for_point_and_statuses: boolean;
  show: boolean;
  customer_status_prices_for_points: CustomerStatusPriceForPoints[];
}
