import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { GemCutShape } from '@/types';
import { useDeleteGemCutShapeMutation } from '@/services';

export interface DeleteGemCutShapeProps extends DialogProps {
  gemCutShape: GemCutShape;
  onClose(): void;
}

const DeleteGemCutShape: React.FC<DeleteGemCutShapeProps> = ({ gemCutShape, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteGemCutShape, deleteGemCutShapeResponse] = useDeleteGemCutShapeMutation();

  async function handleDeleteGemCutShape() {
    try {
      await deleteGemCutShape(gemCutShape).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{gemCutShape?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{gemCutShape?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteGemCutShapeResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteGemCutShapeResponse.isLoading} onClick={handleDeleteGemCutShape}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGemCutShape;
