import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Defect, ListResponse } from '@/types';

const defectApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Defects'],
});

const defectApi = defectApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDefects: builder.query<ListResponse<Defect>, AxiosRequestConfig<Defect>>({
      query: (config) => ({ url: '/admin/defect', ...config }),
      providesTags: ['Defects'],
    }),
    createDefect: builder.mutation<Defect, Defect>({
      query: (defect) => ({ url: '/admin/defect', method: 'POST', data: defect }),
      invalidatesTags: ['Defects'],
    }),
    updateDefect: builder.mutation<Defect, Defect>({
      query: (defect) => ({ url: '/admin/defect/' + defect.id, method: 'PUT', data: defect }),
      invalidatesTags: ['Defects'],
    }),
    deleteDefect: builder.mutation<unknown, Defect>({
      query: (defect) => ({ url: '/admin/defect/' + defect.id, method: 'DELETE' }),
      invalidatesTags: ['Defects'],
    }),
  }),
});

const { useGetDefectsQuery, useCreateDefectMutation, useUpdateDefectMutation, useDeleteDefectMutation } = defectApi;

export { useGetDefectsQuery, useCreateDefectMutation, useUpdateDefectMutation, useDeleteDefectMutation };
