import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { CollateralItemState } from '@/types';
import { useUpdateCollateralItemStateMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateCollateralItemStateProps extends DialogProps {
  сollateralItemState: CollateralItemState;
  onClose(): void;
}

const UpdateCollateralItemState: React.FC<UpdateCollateralItemStateProps> = ({
  сollateralItemState,
  onClose,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateCollateralItemState, updateCollateralItemStateResponse] = useUpdateCollateralItemStateMutation();

  const { values, touched, errors, handleChange, handleSubmit, resetForm } = useFormik<CollateralItemState>({
    initialValues: сollateralItemState ?? {
      name: '',
      gkd_code: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      gkd_code: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(сollateralItemState) {
      try {
        await updateCollateralItemState(сollateralItemState).unwrap();

        handleClose();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog onClose={handleClose} fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать характеристику</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateCollateralItemStateResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="gkd_code"
                label="Код"
                placeholder="Введите значение"
                value={values.gkd_code}
                onChange={handleChange}
                disabled={updateCollateralItemStateResponse.isLoading}
                error={touched.gkd_code && Boolean(errors.gkd_code)}
                helperText={touched.gkd_code ? errors.gkd_code : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={updateCollateralItemStateResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={updateCollateralItemStateResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCollateralItemState;
