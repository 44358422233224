import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { ElectronicBrand } from '@/types';
import { useDeleteElectronicBrandMutation } from '@/services';

export interface DeleteElectronicBrandProps extends DialogProps {
  electronicBrand: ElectronicBrand;
  onClose(): void;
}

const DeleteElectronicBrand: React.FC<DeleteElectronicBrandProps> = ({ electronicBrand, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteElectronicBrand, deleteElectronicBrandResponse] = useDeleteElectronicBrandMutation();

  async function handleDeleteElectronicBrand() {
    try {
      await deleteElectronicBrand(electronicBrand).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{electronicBrand?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{electronicBrand?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          disabled={deleteElectronicBrandResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteElectronicBrandResponse.isLoading}
          onClick={handleDeleteElectronicBrand}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteElectronicBrand;
