import { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Area, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteArea, UpdateArea } from '../components';

export interface AreaListProps {
  list?: ListResponse<Area>;
  page: number;
  onChangePage(page: number): void;
}

const AreasTable: React.FC<AreaListProps> = ({ list, page, onChangePage }) => {
  const { regionId } = useParams<{ regionId: string }>();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentArea, setCurrentArea] = useState<Area | null>(null);

  function openUpdateModal(area: Area) {
    setCurrentArea(area);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(area: Area) {
    setCurrentArea(area);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Округи</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((area) => {
                const { name, id } = area;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Tooltip title="Округи">
                        <IconButton
                          component={RouterLink}
                          to={`/dictionaries/regions/${regionId}/areas/${id}/districts`}
                          aria-label="Округи"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(area)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(area)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateArea area={currentArea!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeleteArea area={currentArea!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default AreasTable;
