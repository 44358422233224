import { useState } from 'react';
import moment from 'moment';
import {
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Employee, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteEmployee, UpdateEmployee } from '../components';

export interface EmployeeListProps {
  list?: ListResponse<Employee>;
  page: number;
  onChangePage(page: number): void;
}

const EmployeesTable: React.FC<EmployeeListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<Employee | null>(null);

  function openUpdateModal(employee: Employee) {
    setCurrentEmployee(employee);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(employee: Employee) {
    setCurrentEmployee(employee);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell />
                <TableCell>Дата рождения</TableCell>
                <TableCell>Номер телефона</TableCell>
                <TableCell>Роль</TableCell>
                <TableCell>Должность</TableCell>
                <TableCell>Нанят</TableCell>
                <TableCell>Уволен</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((employee) => {
                const {
                  name,
                  born_at,
                  father_name,
                  dismissed_at,
                  hired_at,
                  last_name,
                  phone_number,
                  role,
                  position,
                  id,
                } = employee;

                return (
                  <TableRow key={id}>
                    <TableCell>{`${last_name} ${name} ${father_name}`}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(employee)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(employee)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{born_at ? moment(born_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    <TableCell>
                      {phone_number ? (
                        <Link href={`tel:${phone_number}`} color="secondary">
                          {phone_number}
                        </Link>
                      ) : (
                        '—'
                      )}
                    </TableCell>
                    <TableCell>{role?.name ?? '—'}</TableCell>
                    <TableCell>{position?.name ?? '—'}</TableCell>
                    <TableCell>{hired_at ? moment(hired_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    <TableCell>{dismissed_at ? moment(dismissed_at).format('DD.MM.YYYY') : '—'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateEmployee
        employee={currentEmployee!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteEmployee
        employee={currentEmployee!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default EmployeesTable;
