import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CashboxOperation } from '@/types';
import { useGetCashboxOperationsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCashboxOperations<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<CashboxOperation> } & Omit<
  AutocompleteProps<CashboxOperation, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCashboxOperationsQueryProps = useAsyncSearch<CashboxOperation, Multiple, DisableClearable, FreeSolo>(
    useGetCashboxOperationsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetCashboxOperationsQueryProps} />;
}

export default SearchCashboxOperations;
