import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Prolongation, ProlongationStatuses } from '@/types';
import { useUpdateProlongationMutation } from '@/services';
import { useRoles } from '@/hooks';
import { messages } from '@/utils/helpers';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { numbersDeclension } from '@/utils/numbers-declension';

export interface UpdateProlongationProps extends DialogProps {
  prolongation: Prolongation;
  onClose(): void;
}

const UpdateProlongation: React.FC<UpdateProlongationProps> = ({ prolongation, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { isAdmin } = useRoles();

  const [updateProlongation, updateProlongationResponse] = useUpdateProlongationMutation();

  const { values, setFieldValue, handleChange, handleSubmit, submitForm } = useFormik<Prolongation>({
    initialValues: prolongation ?? {
      pledge: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      pledge: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(prolongation, { resetForm }) {
      try {
        await updateProlongation(prolongation).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleSubmitForm(status: keyof typeof ProlongationStatuses) {
    setFieldValue('status', ProlongationStatuses[status]);
    submitForm();
  }

  const DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED = Math.ceil(
      moment(values.created_at)
        .endOf('day')
        .diff(moment(values.previous_prolongation_date || values.pledge?.created_at), 'days', true),
    ),
    OVERDUE_DAYS_COUNT =
      DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED > +values.pledge?.days_count!
        ? DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED - +values.pledge?.days_count!
        : 0,
    FINE = Math.round(
      (+values.pledge?.return_sum! * OVERDUE_DAYS_COUNT * +values.pledge?.nomenclature_group?.gt_30_days!) / 100,
    ),
    TOTAL_SUM_FOR_PROLONGATION = Math.round(
      (+values.pledge?.return_sum_to_hand! *
        (DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED - OVERDUE_DAYS_COUNT) *
        +values.pledge?.rate_for_day!) /
        100,
    );

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Пролонгация {values.name}</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          {values.pledge && (
            <Grid container spacing={3}>
              <Grid item lg={8} md={10} xs={12}>
                <TextField
                  label="Залог"
                  value={values.pledge?.name}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Дата создания"
                  value={moment(values.created_at).format('DD.MM.YYYY')}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Автор"
                  value={values.pledge?.employee?.name}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Точка (Ломбард)"
                  value={values.pledge?.point?.name}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Залогодатель"
                  value={`${values.pledge.customer?.last_name} ${values.pledge.customer?.name} ${
                    values.pledge.customer?.father_name ?? ''
                  }`}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Ставка за кредит (1 день)"
                  value={values.pledge?.rate_for_day}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="К возврату"
                  value={values.pledge?.return_sum}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="На руки"
                  value={values.pledge?.return_sum_to_hand}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата возврата"
                  value={moment().add(values.pledge.days_count, 'day')}
                  onChange={() => {}}
                  disabled={updateProlongationResponse.isLoading}
                  readOnly
                  renderInput={(params) => <TextField {...params} fullWidth InputProps={{ readOnly: true }} />}
                />
              </Grid>

              <Grid item lg={5} md={5} xs={12}>
                <TextField
                  label="Глубина"
                  placeholder="Введите значение"
                  value={`${values.pledge.days_count} ${numbersDeclension(values.pledge.days_count, [
                    'день',
                    'дня',
                    'дней',
                  ])}`}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item lg={7} md={7} xs={12}>
                <Typography>
                  <b>
                    Сумма % за {DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED - OVERDUE_DAYS_COUNT}{' '}
                    {numbersDeclension(DAYS_FROM_PLEDGE_HAS_BEEN_PROLONGED - OVERDUE_DAYS_COUNT, [
                      'день',
                      'дня',
                      'дней',
                    ])}{' '}
                    под {values.pledge.rate_for_day}%:{' '}
                  </b>
                  {TOTAL_SUM_FOR_PROLONGATION} (тг)
                </Typography>

                {Boolean(FINE) && (
                  <Typography>
                    <b>
                      Пеня за {OVERDUE_DAYS_COUNT} {numbersDeclension(OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}:{' '}
                    </b>
                    {FINE} (тг)
                  </Typography>
                )}

                <Typography>
                  <b>Итого: </b>
                  {TOTAL_SUM_FOR_PROLONGATION + FINE} (тг)
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  select
                  label="Способ оплаты"
                  value={values.pledge?.payment_method}
                  disabled={updateProlongationResponse.isLoading}
                  fullWidth
                  onChange={handleChange}
                  InputProps={{ readOnly: true }}
                >
                  {PAYMENT_METHODS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateProlongationResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          {![ProlongationStatuses.CANCELLED].includes(values.status!) && isAdmin && (
            <>
              <Button
                type="submit"
                variant="contained"
                disabled={updateProlongationResponse.isLoading}
                onClick={() => handleSubmitForm('CANCELLED')}
              >
                Отменить
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateProlongation;
