import * as yup from 'yup';

import { NomenclatureGroup } from '@/types';
import { messages } from '@/utils/helpers';

export const GEM_RESET: NomenclatureGroup.GemReset = {
  count: 0,
  height: 0,
  length: 0,
  cut_shape: null,
  gem: null,
  weight: 0,
  width: 0,
};

export const DIAMOND_VALUATION: NomenclatureGroup.DiamondValuation = {
  gem: null,
  category: null,
  count: 0,
  sum: 0,
};

export const FUR_COAT: NomenclatureGroup.FurCoat = { category: null, color: null };
export const JEVELRY: NomenclatureGroup.Jewelry = {
  metal_type: 1,
  sample: null,
  jewelry_weight: 0,
  initial_metal_weight: 0,
  metal_weight: 0,
  gems_reset: [],
  diamond_valuation: [],
};
export const TECHNIQUE: NomenclatureGroup.Technique = {
  electronic_brand: null,
  electronic_model: null,
};
export const OTHER: NomenclatureGroup.Other = {};

export const PLEDGE_SCHEMA = yup.object().shape({
  customer: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  collateral_items: yup
    .array()
    .of(yup.object().shape({ name: yup.string() }))
    .min(1, messages.form.MIN_FROM_LIST(1, ['залоговой вещи', 'залоговых вещей'])),
  days_count: yup.number().required(messages.form.REQUIRED),
  nomenclature_group: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  payment_method: yup.string().required(messages.form.SELECT_FROM_LIST),
  point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  rate_for_day: yup.number().required(messages.form.REQUIRED),
  return_date: yup.date().required(messages.form.SELECT_DATE).typeError(messages.form.SELECT_CORRECT_DATE),
  return_sum: yup.number().required(messages.form.REQUIRED),
  return_sum_to_hand: yup.number().required(messages.form.REQUIRED),
  comment: yup.string().max(255, messages.form.LARGE_TEXT()),
});

export const FUR_COAT_SCHEMA = yup.object().shape({
  category: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  color: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
});

export const GEM_RESET_SCHEMA = yup.object().shape({
  count: yup.number().required(messages.form.REQUIRED),
  cut_shape: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  gem: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  height: yup.number().required(messages.form.REQUIRED),
  length: yup.number().required(messages.form.REQUIRED),
  weight: yup.number().required(messages.form.REQUIRED),
  width: yup.number().required(messages.form.REQUIRED),
});
export const DIAMOND_VALUATION_SCHEMA = yup.object().shape({
  gem: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  category: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  count: yup.number().required(messages.form.REQUIRED),
  sum: yup.number().required(messages.form.REQUIRED),
});
export const JEWELRY_SCHEMA = yup.object().shape({
  metal_type: yup.string().required(messages.form.SELECT_FROM_LIST),
  sample: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  jewelry_weight: yup.number().required(messages.form.REQUIRED),
  metal_weight: yup
    .number()
    .max(yup.ref('jewelry_weight'), messages.form.LESS_OR_EQUAL_THAN('Вес изделия'))
    .required(messages.form.REQUIRED),
  gems_reset: yup.array().of(GEM_RESET_SCHEMA),
  diamond_valuation: yup.array().of(DIAMOND_VALUATION_SCHEMA),
});

export const TECHNIQUE_SCHEMA = yup.object().shape({
  electronic_brand: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
  electronic_model: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
});

export const OTHER_SCHEMA = yup.object().shape({});
