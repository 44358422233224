import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import { TechnicalRequest, TechRequestStatuses } from '@/types';
import { useUpdateTechnicalRequestMutation } from '@/services';
import { useAuth } from '@/hooks';
import { messages } from '@/utils/helpers';
import { TECH_REQUEST_STATUSES } from '@/utils/tech-request-statuses';
import { SearchOrganizations } from '@/pages/Organizations/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchEmployees } from '@/pages/Employees/components';
import { SearchPositions } from '@/pages/Positions/components';

export interface UpdateTechnicalRequestProps extends Omit<DialogProps, 'technicalRequest'> {
  technicalRequest: TechnicalRequest;
  onClose(): void;
}

const UpdateTechnicalRequest: React.FC<UpdateTechnicalRequestProps> = ({ technicalRequest, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { user, employee } = useAuth();

  const [updateTechnicalRequest, updateTechnicalRequestResponse] = useUpdateTechnicalRequestMutation();

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik<TechnicalRequest>({
    initialValues: technicalRequest ?? {
      description: '',
      execution_date: '',
      execution_time: '',
      executor: null,
      executor_text: '',
      position: null,
      organization: user!.point?.organization,
      point: user!.point,
      short_description: '',
      status: TechRequestStatuses.COMPLETED,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string().required(messages.form.REQUIRED),
      execution_date: yup.string().required(messages.form.REQUIRED),
      execution_time: yup.string().required(messages.form.REQUIRED),
      executor: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      executor_text: yup.string().required(messages.form.REQUIRED),
      position: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      organization: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      short_description: yup.string().required(messages.form.REQUIRED),
      status: yup.string().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(technicalRequest, { resetForm }) {
      try {
        await updateTechnicalRequest(technicalRequest).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать техническую заявку</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchOrganizations
                value={values.organization}
                onChange={(event, option) => setFieldValue('organization', option)}
                disabled={updateTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.full_name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="organization"
                    label="Организация"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={updateTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchPositions
                value={values.position}
                onChange={(event, option) => setFieldValue('position', option)}
                disabled={updateTechnicalRequestResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="position"
                    label="Кому адресована заявка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="short_description"
                label="Краткое описание заявки"
                placeholder="Введите значение"
                value={values.short_description}
                onChange={handleChange}
                disabled={updateTechnicalRequestResponse.isLoading}
                error={touched.short_description && Boolean(errors.short_description)}
                helperText={touched.short_description ? errors.short_description : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                multiline
                name="description"
                label="Описание"
                placeholder="Введите значение"
                value={values.description}
                onChange={handleChange}
                disabled={updateTechnicalRequestResponse.isLoading}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description ? errors.description : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchEmployees
                value={values.executor}
                config={{ params: { position_id: values.position?.id } }}
                onChange={(event, option) => setFieldValue('executor', option)}
                disabled={!values.position || updateTechnicalRequestResponse.isLoading}
                getOptionLabel={({ name, last_name, father_name }) => `${last_name} ${name} ${father_name} `}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="executor"
                    label="Исполнитель"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.executor && errors.executor)}
                    helperText={touched.executor && errors.executor}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                multiline
                name="executor_text"
                label="Текст исполнителя"
                placeholder="Введите значение"
                value={values.executor_text}
                onChange={handleChange}
                disabled={updateTechnicalRequestResponse.isLoading}
                error={touched.executor_text && Boolean(errors.executor_text)}
                helperText={touched.executor_text ? errors.executor_text : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата исполнения"
                value={values.execution_date}
                onChange={(value) => setFieldValue('execution_date', moment(value).toDate())}
                disabled={updateTechnicalRequestResponse.isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="execution_date"
                    error={Boolean(touched.execution_date && errors.execution_date)}
                    helperText={touched.execution_date && errors.execution_date}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TimePicker
                mask="__.__.____"
                label="Время на исполнение"
                value={values.execution_time}
                onChange={(value) => setFieldValue('execution_time', moment(value).toDate())}
                disabled={updateTechnicalRequestResponse.isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="execution_time"
                    error={Boolean(touched.execution_time && errors.execution_time)}
                    helperText={touched.execution_time && errors.execution_time}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="status"
                label="Статус заявки"
                value={values.status}
                onChange={handleChange}
                disabled={updateTechnicalRequestResponse.isLoading}
                error={touched.status && Boolean(errors.status)}
                helperText={touched.status ? errors.status : ''}
                fullWidth
              >
                {TECH_REQUEST_STATUSES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="author"
                label="Автор заявки"
                placeholder="Введите значение"
                value={`${employee?.last_name} ${employee?.name} ${employee?.father_name}`}
                onChange={handleChange}
                disabled={updateTechnicalRequestResponse.isLoading}
                error={touched.short_description && Boolean(errors.short_description)}
                helperText={touched.short_description ? errors.short_description : ''}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateTechnicalRequestResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateTechnicalRequestResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateTechnicalRequest;
