import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { IntervalCollateralRate, ListResponse } from '@/types';

const intervalCollateralRateApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['IntervalCollateralRates'],
});

const intervalCollateralRateApi = intervalCollateralRateApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getIntervalCollateralRates: builder.query<
      ListResponse<IntervalCollateralRate>,
      AxiosRequestConfig<IntervalCollateralRate>
    >({
      query: (config) => ({
        url: '/admin/collateral-rate',
        ...config,
      }),
      providesTags: ['IntervalCollateralRates'],
    }),
    createIntervalCollateralRate: builder.mutation<IntervalCollateralRate, IntervalCollateralRate>({
      query: ({ customer_status, point, ...intervalCollateralRate }) => ({
        url: '/admin/collateral-rate',
        method: 'POST',
        data: { ...intervalCollateralRate, customer_status_id: customer_status?.id, point_id: point?.id },
      }),
      invalidatesTags: ['IntervalCollateralRates'],
    }),
    updateIntervalCollateralRate: builder.mutation<IntervalCollateralRate, IntervalCollateralRate>({
      query: ({ customer_status, point, ...intervalCollateralRate }) => ({
        url: '/admin/collateral-rate/' + intervalCollateralRate.id,
        method: 'PUT',
        data: { ...intervalCollateralRate, customer_status_id: customer_status?.id, point_id: point?.id },
      }),
      invalidatesTags: ['IntervalCollateralRates'],
    }),
    deleteIntervalCollateralRate: builder.mutation<unknown, IntervalCollateralRate>({
      query: (intervalCollateralRate) => ({
        url: '/admin/collateral-rate/' + intervalCollateralRate.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['IntervalCollateralRates'],
    }),
  }),
});

const {
  useGetIntervalCollateralRatesQuery,
  useCreateIntervalCollateralRateMutation,
  useUpdateIntervalCollateralRateMutation,
  useDeleteIntervalCollateralRateMutation,
} = intervalCollateralRateApi;

export {
  useGetIntervalCollateralRatesQuery,
  useCreateIntervalCollateralRateMutation,
  useUpdateIntervalCollateralRateMutation,
  useDeleteIntervalCollateralRateMutation,
};
