import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureGroup, ListResponse } from '@/types';

const nomenclatureGroupApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureGroups'],
});

const nomenclatureGroupApi = nomenclatureGroupApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureGroups: builder.query<ListResponse<NomenclatureGroup>, AxiosRequestConfig<NomenclatureGroup>>({
      query: (config) => ({ url: '/admin/nomenclature-group', ...config }),
      providesTags: ['NomenclatureGroups'],
    }),
    createNomenclatureGroup: builder.mutation<NomenclatureGroup, NomenclatureGroup>({
      query: (nomenclatureGroup) => ({ url: '/admin/nomenclature-group', method: 'POST', data: nomenclatureGroup }),
      invalidatesTags: ['NomenclatureGroups'],
    }),
    updateNomenclatureGroup: builder.mutation<NomenclatureGroup, NomenclatureGroup>({
      query: (nomenclatureGroup) => ({
        url: '/admin/nomenclature-group/' + nomenclatureGroup.id,
        method: 'PUT',
        data: nomenclatureGroup,
      }),
      invalidatesTags: ['NomenclatureGroups'],
    }),
    deleteNomenclatureGroup: builder.mutation<unknown, NomenclatureGroup>({
      query: (nomenclatureGroup) => ({ url: '/admin/nomenclature-group/' + nomenclatureGroup.id, method: 'DELETE' }),
      invalidatesTags: ['NomenclatureGroups'],
    }),
  }),
});

const {
  useGetNomenclatureGroupsQuery,
  useCreateNomenclatureGroupMutation,
  useUpdateNomenclatureGroupMutation,
  useDeleteNomenclatureGroupMutation,
} = nomenclatureGroupApi;

export {
  useGetNomenclatureGroupsQuery,
  useCreateNomenclatureGroupMutation,
  useUpdateNomenclatureGroupMutation,
  useDeleteNomenclatureGroupMutation,
};
