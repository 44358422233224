import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { NomenclatureRateCategory, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteNomenclatureRateCategory, UpdateNomenclatureRateCategory } from '../components';

export interface NomenclatureRateCategoryListProps {
  list?: ListResponse<NomenclatureRateCategory>;
  page: number;
  onChangePage(page: number): void;
}

const NomenclatureRateCategoriesTable: React.FC<NomenclatureRateCategoryListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentNomenclatureRateCategory, setCurrentNomenclatureRateCategory] =
    useState<NomenclatureRateCategory | null>(null);

  function openUpdateModal(nomenclatureRateCategory: NomenclatureRateCategory) {
    setCurrentNomenclatureRateCategory(nomenclatureRateCategory);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(nomenclatureRateCategory: NomenclatureRateCategory) {
    setCurrentNomenclatureRateCategory(nomenclatureRateCategory);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Код</TableCell>
                <TableCell>Наименование</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((nomenclatureRateCategory) => {
                const { name, code, id } = nomenclatureRateCategory;

                return (
                  <TableRow key={id}>
                    <TableCell>{code}</TableCell>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(nomenclatureRateCategory)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(nomenclatureRateCategory)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateNomenclatureRateCategory
        nomenclatureRateCategory={currentNomenclatureRateCategory!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteNomenclatureRateCategory
        nomenclatureRateCategory={currentNomenclatureRateCategory!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default NomenclatureRateCategoriesTable;
