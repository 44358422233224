import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Unit } from '@/types';
import { useGetUnitsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchUnits<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Unit> } & Omit<
  AutocompleteProps<Unit, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetUnitsQueryProps = useAsyncSearch<Unit, Multiple, DisableClearable, FreeSolo>(
    useGetUnitsQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetUnitsQueryProps} />;
}

export default SearchUnits;
