import { useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Buyout, BuyoutStatuses } from '@/types';
import { useUpdateBuyoutMutation } from '@/services';
import { useRoles } from '@/hooks';
import { messages } from '@/utils/helpers';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { numbersDeclension } from '@/utils/numbers-declension';
import { NomenclatureGroups } from '@/utils/enums';

enum BuyoutTabs {
  COMMON = 'common',
  COLLATERAL_ITEMS = 'collateralItems',
}
export interface UpdateBuyoutProps extends DialogProps {
  buyout: Buyout;
  onClose(): void;
}

const UpdateBuyout: React.FC<UpdateBuyoutProps> = ({ buyout, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { isAdmin } = useRoles();

  const [activeTab, setActiveTab] = useState(BuyoutTabs.COMMON);

  const [updateBuyout, updateBuyoutResponse] = useUpdateBuyoutMutation();

  const { values, setFieldValue, handleChange, handleSubmit, submitForm } = useFormik<Buyout>({
    initialValues: buyout ?? {
      pledge: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      pledge: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(buyout, { resetForm }) {
      try {
        await updateBuyout(buyout).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleSubmitForm(status: keyof typeof BuyoutStatuses) {
    setFieldValue('status', BuyoutStatuses[status]);
    submitForm();
  }

  const DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT = Math.ceil(
      moment(values.created_at).endOf('day').diff(moment(values.pledge?.buyout_at), 'days', true),
    ),
    SUM_FOR_DAY = Math.round(+values.pledge?.return_sum_to_hand! * +values.pledge?.rate_for_day!) / 100,
    OVERDUE_DAYS_COUNT =
      DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT > +values.pledge?.days_count!
        ? DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT - +values.pledge?.days_count!
        : 0,
    FINE = Math.round(
      (+values.pledge?.return_sum! * OVERDUE_DAYS_COUNT * +values.pledge?.nomenclature_group?.gt_30_days!) / 100,
    ),
    TOTAL_SUM_FOR_BUYOUT = Math.round(
      +values.pledge?.return_sum_to_hand! +
        SUM_FOR_DAY * (DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT - OVERDUE_DAYS_COUNT) +
        FINE,
    );

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle fontWeight="bold">Выкуп {values.name}</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          {values.pledge && (
            <Grid container spacing={3}>
              <Grid item lg={8} md={10} xs={12}>
                <TextField
                  label="Залог"
                  value={values.pledge?.name}
                  disabled={updateBuyoutResponse.isLoading}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <TabContext value={activeTab}>
                <Grid item xs={12}>
                  <TabList onChange={(event, newValue) => setActiveTab(newValue)}>
                    <Tab label="Основная информация" value={BuyoutTabs.COMMON} />
                    <Tab label="Залоговые вещи" value={BuyoutTabs.COLLATERAL_ITEMS} />
                  </TabList>

                  <TabPanel value={BuyoutTabs.COMMON} sx={{ mx: -3 }}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Дата создания"
                          value={moment(values.created_at).format('DD.MM.YYYY')}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Автор"
                          value={`${values.pledge.employee?.last_name} ${values.pledge.employee?.name} ${values.pledge.employee?.father_name}`}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Точка (Ломбард)"
                          value={values.pledge?.point?.name}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Залогодатель"
                          value={`${values.pledge.customer?.last_name} ${values.pledge.customer?.name} ${
                            values.pledge.customer?.father_name ?? ''
                          }`}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6.5} md={6.5} xs={12}>
                        <TextField
                          label="Ставка за кредит (1 день)"
                          value={values.pledge?.rate_for_day}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="На руки"
                          value={values.pledge?.return_sum_to_hand}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="К возврату"
                          value={values.pledge?.return_sum}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Просрочено (дней)"
                          placeholder="Введите значение"
                          value={OVERDUE_DAYS_COUNT}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <Typography>
                          <b>
                            % за {DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT - OVERDUE_DAYS_COUNT}{' '}
                            {numbersDeclension(DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT - OVERDUE_DAYS_COUNT, [
                              'день',
                              'дня',
                              'дней',
                            ])}{' '}
                            под {values.pledge.rate_for_day}%:{' '}
                          </b>
                          {Math.round(SUM_FOR_DAY * (DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT - OVERDUE_DAYS_COUNT))} (тг)
                        </Typography>

                        {Boolean(FINE) && (
                          <Typography>
                            <b>
                              Пеня за {OVERDUE_DAYS_COUNT}{' '}
                              {numbersDeclension(OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}:{' '}
                            </b>
                            {FINE} (тг)
                          </Typography>
                        )}
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          label="Сумма пени"
                          placeholder="Введите значение"
                          value={FINE}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <Typography>
                          <b>Итого: </b>
                          {TOTAL_SUM_FOR_BUYOUT} (тг)
                        </Typography>

                        <Typography>
                          <b>Общее кол-во дней залога: </b>
                          {DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT}{' '}
                          {numbersDeclension(DAYS_FROM_PLEDGE_HAS_BEEN_BOUGHTOUT, ['день', 'дня', 'дней'])}
                        </Typography>
                      </Grid>

                      <Grid item lg={6} md={6} xs={12}>
                        <TextField
                          select
                          label="Способ оплаты"
                          value={values.pledge?.payment_method}
                          disabled={updateBuyoutResponse.isLoading}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{ readOnly: true }}
                        >
                          {PAYMENT_METHODS.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={BuyoutTabs.COLLATERAL_ITEMS} sx={{ mx: -3 }}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Номенклатура</TableCell>
                            <TableCell>Начальная стоимость</TableCell>

                            {values.pledge.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                              <>
                                <TableCell>Проба</TableCell>
                                <TableCell>Вес изделия</TableCell>
                              </>
                            )}

                            <TableCell>Примечание</TableCell>
                            <TableCell>Характеристики</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.pledge.collateral_items.map((collateralItem, index) => {
                            const {
                                description,
                                nomenclature,
                                primary_price,
                                nomenclature_chars,
                                nomenclature_group_chars,
                                properties,
                              } = collateralItem,
                              chars = [...nomenclature_chars, ...nomenclature_group_chars]
                                .map((char) => {
                                  const hasValue =
                                      ['string', 'number'].includes(typeof char.value) && Boolean(char.value),
                                    hasBooleanValue = ['boolean'].includes(typeof char.value) && Boolean(char.value);
                                  return hasValue ? `${char.name}: ${char.value}` : hasBooleanValue && char.name;
                                })
                                .filter(Boolean)
                                .join(', ');

                            return (
                              <TableRow key={index}>
                                <TableCell>{nomenclature?.name || '—'}</TableCell>
                                <TableCell>{primary_price}</TableCell>

                                {values.pledge?.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                                  <>
                                    <TableCell>{properties?.sample?.name}</TableCell>
                                    <TableCell>{properties?.jewelry_weight}</TableCell>
                                  </>
                                )}

                                <TableCell>{description || '—'}</TableCell>
                                <TableCell title={chars || '—'}>{chars || '—'}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </Grid>
              </TabContext>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateBuyoutResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          {![BuyoutStatuses.CANCELLED].includes(values.status!) && isAdmin && (
            <>
              <Button
                type="submit"
                variant="contained"
                disabled={updateBuyoutResponse.isLoading}
                onClick={() => handleSubmitForm('CANCELLED')}
              >
                Отменить
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateBuyout;
