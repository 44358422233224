import { useState } from 'react';
import moment from 'moment';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { TechnicalRequest, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { useAuth } from '@/hooks';
import { TECH_REQUEST_STATUSES } from '@/utils/tech-request-statuses';
import { EditFlat, InfoOutlined, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteTechnicalRequest, UpdateTechnicalRequest, ViewTechnicalRequest } from '../components';

export interface TechnicalRequestListProps {
  list?: ListResponse<TechnicalRequest>;
  page: number;
  onChangePage(page: number): void;
}

const TechnicalRequestsTable: React.FC<TechnicalRequestListProps> = ({ list, page, onChangePage }) => {
  const { user } = useAuth();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [viewModalOpened, setOpenedViewModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentTechnicalRequest, setCurrentTechnicalRequest] = useState<TechnicalRequest | null>(null);

  function openUpdateModal(technicalRequest: TechnicalRequest) {
    setCurrentTechnicalRequest(technicalRequest);
    setOpenedUpdateModal(true);
  }

  function openViewModal(technicalRequest: TechnicalRequest) {
    setCurrentTechnicalRequest(technicalRequest);
    setOpenedViewModal(true);
  }

  function openDeleteModal(technicalRequest: TechnicalRequest) {
    setCurrentTechnicalRequest(technicalRequest);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Точка (Ломбард)</TableCell>
                <TableCell>Краткий текст заявки</TableCell>
                <TableCell>Адресат</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Исполнитель</TableCell>
                <TableCell>Автор</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((technicalRequest) => {
                const { created_at, created_by, executor, point, position, short_description, name, status, id } =
                    technicalRequest,
                  techRequestStatus = TECH_REQUEST_STATUSES.find(({ value }) => value === status)!;

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name ?? '—'}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {created_by?.id === user?.id && (
                          <Tooltip title="Обновить">
                            <IconButton aria-label="Обновить" onClick={() => openUpdateModal(technicalRequest)}>
                              <EditFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Посмотреть">
                          <IconButton aria-label="Посмотреть" onClick={() => openViewModal(technicalRequest)}>
                            <InfoOutlined sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        {false && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(technicalRequest)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>

                    <TableCell>{point?.name ?? '—'}</TableCell>
                    <TableCell>{short_description ?? '—'}</TableCell>
                    <TableCell>{position?.name ?? '—'}</TableCell>
                    <TableCell>{techRequestStatus.label}</TableCell>
                    <TableCell>
                      {executor ? `${executor?.last_name} ${executor?.name} ${executor?.father_name}` : '—'}
                    </TableCell>
                    <TableCell>
                      {created_by ? `${created_by?.last_name} ${created_by?.name} ${created_by?.father_name}` : '—'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateTechnicalRequest
        technicalRequest={currentTechnicalRequest!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <ViewTechnicalRequest
        technicalRequest={currentTechnicalRequest!}
        open={viewModalOpened}
        onClose={() => setOpenedViewModal(false)}
      />
      <DeleteTechnicalRequest
        technicalRequest={currentTechnicalRequest!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default TechnicalRequestsTable;
