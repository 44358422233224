import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { GemCategory } from '@/types';
import { useDeleteGemCategoryMutation } from '@/services';

export interface DeleteGemCategoryProps extends DialogProps {
  gemCategory: GemCategory;
  onClose(): void;
}

const DeleteGemCategory: React.FC<DeleteGemCategoryProps> = ({ gemCategory, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteGemCategory, deleteGemCategoryResponse] = useDeleteGemCategoryMutation();

  async function handleDeleteGemCategory() {
    try {
      await deleteGemCategory(gemCategory).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{gemCategory?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{gemCategory?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" disabled={deleteGemCategoryResponse.isLoading} onClick={onClose}>
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteGemCategoryResponse.isLoading} onClick={handleDeleteGemCategory}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGemCategory;
