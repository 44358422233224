import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { CashboxOperation } from '@/types';
import { useDeleteCashboxOperationMutation } from '@/services';

export interface DeleteCashboxOperationProps extends Omit<DialogProps, 'cashboxOperations'> {
  cashboxOperations: CashboxOperation;
  onClose(): void;
}

const DeleteCashboxOperation: React.FC<DeleteCashboxOperationProps> = ({ cashboxOperations, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCashboxOperation, deleteCashboxOperationResponse] = useDeleteCashboxOperationMutation();

  async function handleDeleteCashboxOperation() {
    try {
      await deleteCashboxOperation(cashboxOperations).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{cashboxOperations?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{cashboxOperations?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteCashboxOperationResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          size="small"
          disabled={deleteCashboxOperationResponse.isLoading}
          onClick={handleDeleteCashboxOperation}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCashboxOperation;
