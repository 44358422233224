import { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Customer, NomenclatureGroup } from '@/types';
import { useAuth } from '@/hooks';
import { Samples } from '@/utils/enums';
import { SAMPLES } from '@/utils/samples';
import { getSamplePriceByPoint } from '@/utils/get-sample-price';
import { EditFlat, TrashFlat } from '@/assets/icons';
import { SearchSamples } from '@/pages/Samples/components';

import { DiamondValuation, GemReset } from '../CollateralItemByNomenclatureGroup';

enum JewelryTabs {
  DIAMOND_VALUATION = 'diamondValuation',
  GEMS_RESET = 'gemsReset',
}

function floorToHundredths(number: number) {
  return Math.floor(number / 100) * 100;
}

export interface JewelryProps {
  customer: Customer;
}

const Jewelry: React.FC<JewelryProps> = ({ customer }) => {
  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState(JewelryTabs.DIAMOND_VALUATION);
  const [gemResetDialogOpened, setOpenedGemResetDialog] = useState(false);
  const [diamondValuationDialogOpened, setOpenedDiamondValuationDialog] = useState(false);
  const [currentGemReset, setCurrentGemReset] = useState<NomenclatureGroup.GemReset | null>(null);
  const [currentDiamondValuation, setCurrentDiamondValuation] = useState<NomenclatureGroup.DiamondValuation | null>(
    null,
  );

  const { values, touched, errors, setValues, setFieldValue, handleChange } = useFormikContext<{
    properties: NomenclatureGroup.Jewelry;
    primary_price: number;
    max_price: number;
  }>();

  function openGemResetDialog(gemRest: NomenclatureGroup.GemReset | null = null) {
    setCurrentGemReset(gemRest);
    setOpenedGemResetDialog(true);
  }

  function openDiamondValuationDialog(gemRest: NomenclatureGroup.DiamondValuation | null = null) {
    setCurrentDiamondValuation(gemRest);
    setOpenedDiamondValuationDialog(true);
  }

  const gems_reset = values.properties.gems_reset.reduce((acc, curr) => {
      acc += curr.weight;
      return acc;
    }, 0),
    diamond_valuation = values.properties.diamond_valuation.reduce((acc, curr) => {
      acc += curr.sum;
      return acc;
    }, 0),
    sample_price_for_point = +getSamplePriceByPoint(
      values.properties.sample,
      user?.point!,
      customer?.customer_status!,
    )!;

  return (
    <>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          select
          name="properties.metal_type"
          label="Металл"
          value={values.properties.metal_type}
          onChange={handleChange}
          error={touched.properties?.metal_type && Boolean(errors.properties?.metal_type)}
          helperText={touched.properties?.metal_type ? errors.properties?.metal_type : ''}
          fullWidth
          InputProps={{ readOnly: true }}
        >
          {SAMPLES.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item lg={6} md={6} xs={12}>
        <SearchSamples
          prefetch={Boolean(values.properties.metal_type)}
          value={values.properties.sample}
          config={{ params: { metal_type: values.properties.metal_type } }}
          onChange={(event, sample) =>
            setValues((values) => {
              const metal_weight = +(+values.properties.metal_weight - gems_reset).toFixed(4),
                primary_price = floorToHundredths(
                  +getSamplePriceByPoint(sample, user?.point!, customer?.customer_status!)! * metal_weight +
                    diamond_valuation,
                );

              return {
                ...values,
                properties: { ...values.properties, sample },
                primary_price,
                max_price: primary_price,
              };
            })
          }
          getOptions={(options) =>
            setFieldValue(
              'properties.sample',
              options.find(({ name }) => name === Samples['S_585']),
            )
          }
          disabled={!values.properties.metal_type}
          getOptionLabel={(option) => `${option.name} — ${option.metal}`}
          renderInput={(props) => (
            <TextField
              {...props}
              name="properties.sample"
              label="Проба"
              placeholder="Выберите из списка"
              error={Boolean(touched.properties?.sample && errors.properties?.sample)}
              helperText={
                (touched.properties?.sample && errors.properties?.sample) ??
                (values.properties.sample && `Цена металла данной пробы ${sample_price_for_point}`)
              }
            />
          )}
        />
      </Grid>

      <Grid item lg={6} md={6} xs={12}>
        <TextField
          type="number"
          name="properties.jewelry_weight"
          label="Вес изделия"
          placeholder="Введите значение"
          value={values.properties.jewelry_weight}
          onChange={({ target: { value } }) =>
            setValues((values) => {
              const metal_weight = +(+value - gems_reset).toFixed(4),
                primary_price = floorToHundredths(sample_price_for_point * metal_weight + diamond_valuation);

              return {
                ...values,
                properties: {
                  ...values.properties,
                  jewelry_weight: +value,
                  initial_metal_weight: +value,
                  metal_weight,
                },
                primary_price,
                max_price: primary_price,
              };
            })
          }
          disabled={!values.properties.sample}
          error={touched.properties?.jewelry_weight && Boolean(errors.properties?.jewelry_weight)}
          helperText={touched.properties?.jewelry_weight ? errors.properties?.jewelry_weight : ''}
          fullWidth
          InputProps={{ inputProps: { min: 0, step: 'any' } }}
        />
      </Grid>

      <Grid item lg={6} md={6} xs={12}>
        <TextField
          type="number"
          name="properties.metal_weight"
          label="Вес металла"
          placeholder="Введите значение"
          value={values.properties.metal_weight}
          onChange={({ target: { value } }) =>
            setValues((values) => {
              const metal_weight = +(+value - gems_reset).toFixed(4),
                primary_price = floorToHundredths(sample_price_for_point * metal_weight + diamond_valuation);

              return {
                ...values,
                properties: { ...values.properties, initial_metal_weight: +value, metal_weight: +value },
                primary_price,
                max_price: primary_price,
              };
            })
          }
          disabled={!values.properties.sample}
          error={touched.properties?.metal_weight && Boolean(errors.properties?.metal_weight)}
          helperText={touched.properties?.metal_weight ? errors.properties?.metal_weight : ''}
          fullWidth
          InputProps={{ inputProps: { min: 0, step: 'any' } }}
        />
      </Grid>

      <TabContext value={activeTab}>
        <Grid item xs={12}>
          <TabList onChange={(event, newValue) => setActiveTab(newValue)}>
            <Tab label="Драгоценные камни" value={JewelryTabs.DIAMOND_VALUATION} />
            <Tab
              label="Сброс веса на камни"
              value={JewelryTabs.GEMS_RESET}
              disabled={!Boolean(values.properties.jewelry_weight)}
            />
          </TabList>

          <TabPanel value={JewelryTabs.DIAMOND_VALUATION} sx={{ mx: -3 }}>
            <Grid item xs={12}>
              <FieldArray name="properties.diamond_valuation">
                {({ remove }) => (
                  <>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Категория вещи</TableCell>
                            <TableCell />
                            <TableCell>Кол-во</TableCell>
                            <TableCell>Цена</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.properties.diamond_valuation.map((diamondValuation, index) => {
                            const { category, count, sum, id } = diamondValuation;

                            return (
                              <TableRow key={id}>
                                <TableCell>{category?.name}</TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={1}>
                                    <Tooltip title="Обновить">
                                      <IconButton
                                        aria-label="Обновить"
                                        onClick={() => openDiamondValuationDialog(diamondValuation)}
                                      >
                                        <EditFlat sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Удалить">
                                      <IconButton
                                        aria-label="Удалить"
                                        onClick={() => {
                                          setValues((values) => {
                                            const metal_weight = +(
                                                values.properties.initial_metal_weight - gems_reset
                                              ).toFixed(4),
                                              primary_price = floorToHundredths(
                                                sample_price_for_point * metal_weight + diamond_valuation - sum,
                                              );

                                            return { ...values, primary_price, max_price: primary_price };
                                          });

                                          remove(index);
                                        }}
                                      >
                                        <TrashFlat sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                </TableCell>
                                <TableCell>{count}</TableCell>
                                <TableCell>{sum}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                      <Button variant="contained" size="small" onClick={() => openDiamondValuationDialog(null)}>
                        Добавить
                      </Button>
                    </Box>

                    <DiamondValuation
                      open={diamondValuationDialogOpened}
                      diamondValuation={currentDiamondValuation}
                      onSubmit={(diamondValuation) => {
                        const diamond_valuation = currentDiamondValuation
                          ? values.properties.diamond_valuation.map((currentDiamondValuation) => {
                              if (currentDiamondValuation.id === diamondValuation.id)
                                currentDiamondValuation = diamondValuation;
                              return currentDiamondValuation;
                            })
                          : [...values.properties.diamond_valuation, diamondValuation];

                        const metal_weight = +(values.properties.initial_metal_weight - gems_reset).toFixed(4),
                          primary_price = floorToHundredths(
                            sample_price_for_point * metal_weight +
                              diamond_valuation.reduce((acc, curr) => {
                                acc += curr.sum;
                                return acc;
                              }, 0),
                          );

                        setValues((values) => ({
                          ...values,
                          properties: { ...values.properties, diamond_valuation },
                          primary_price,
                          max_price: primary_price,
                        }));
                      }}
                      onClose={() => setOpenedDiamondValuationDialog(false)}
                    />
                  </>
                )}
              </FieldArray>
            </Grid>
          </TabPanel>

          <TabPanel value={JewelryTabs.GEMS_RESET} sx={{ mx: -3 }}>
            <Grid item xs={12}>
              <FieldArray name="properties.gems_reset">
                {({ remove }) => (
                  <>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Камень</TableCell>
                            <TableCell>Огранка</TableCell>
                            <TableCell />
                            <TableCell>Длина</TableCell>
                            <TableCell>Ширина</TableCell>
                            <TableCell>Высота</TableCell>
                            <TableCell>Кол-во</TableCell>
                            <TableCell>Вес</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.properties.gems_reset.map((gemReset, index) => {
                            const { cut_shape, gem, length, count, height, weight, width, id } = gemReset;

                            return (
                              <TableRow key={id}>
                                <TableCell>{gem?.name}</TableCell>
                                <TableCell>{cut_shape?.name}</TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={1}>
                                    <Tooltip title="Обновить">
                                      <IconButton aria-label="Обновить" onClick={() => openGemResetDialog(gemReset)}>
                                        <EditFlat sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Удалить">
                                      <IconButton
                                        aria-label="Удалить"
                                        onClick={() => {
                                          setValues((values) => {
                                            const metal_weight = +(values.properties.metal_weight + weight).toFixed(4),
                                              primary_price = floorToHundredths(
                                                sample_price_for_point * metal_weight + diamond_valuation,
                                              );

                                            return {
                                              ...values,
                                              properties: { ...values.properties, metal_weight },
                                              primary_price,
                                              max_price: primary_price,
                                            };
                                          });

                                          remove(index);
                                        }}
                                      >
                                        <TrashFlat sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                </TableCell>
                                <TableCell>{length}</TableCell>
                                <TableCell>{width}</TableCell>
                                <TableCell>{height}</TableCell>
                                <TableCell>{count}</TableCell>
                                <TableCell>{weight}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                      <Button variant="contained" size="small" onClick={() => openGemResetDialog(null)}>
                        Добавить
                      </Button>
                    </Box>

                    <GemReset
                      open={gemResetDialogOpened}
                      gemReset={currentGemReset}
                      onSubmit={(gemReset) => {
                        const gems_reset = currentGemReset
                          ? values.properties.gems_reset.map((currentGemReset) => {
                              if (currentGemReset.id === gemReset.id) currentGemReset = gemReset;
                              return currentGemReset;
                            })
                          : [...values.properties.gems_reset, gemReset];

                        const metal_weight = +(
                            values.properties.initial_metal_weight -
                            gems_reset.reduce((acc, curr) => {
                              acc += curr.weight;
                              return acc;
                            }, 0)
                          ).toFixed(4),
                          primary_price = floorToHundredths(sample_price_for_point * metal_weight + diamond_valuation);

                        setValues((values) => ({
                          ...values,
                          properties: { ...values.properties, gems_reset, metal_weight },
                          primary_price,
                          max_price: primary_price,
                        }));
                      }}
                      onClose={() => setOpenedGemResetDialog(false)}
                    />
                  </>
                )}
              </FieldArray>
            </Grid>
          </TabPanel>
        </Grid>
      </TabContext>
    </>
  );
};

export default Jewelry;
