import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { TechnicalRequest } from '@/types';
import { useDeleteTechnicalRequestMutation } from '@/services';

export interface DeleteTechnicalRequestProps extends Omit<DialogProps, 'technicalRequest'> {
  technicalRequest: TechnicalRequest;
  onClose(): void;
}

const DeleteTechnicalRequest: React.FC<DeleteTechnicalRequestProps> = ({ technicalRequest, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteTechnicalRequest, deleteTechnicalRequestResponse] = useDeleteTechnicalRequestMutation();

  async function handleDeleteTechnicalRequest() {
    try {
      await deleteTechnicalRequest(technicalRequest).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{technicalRequest?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{technicalRequest?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteTechnicalRequestResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          size="small"
          disabled={deleteTechnicalRequestResponse.isLoading}
          onClick={handleDeleteTechnicalRequest}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTechnicalRequest;
