import { CollateralTypes } from '@/utils/enums';

export const COLLATERAL_TYPES = [
  {
    label: 'Ювелирные изделия и другие изделия из драгоценных металлов и драгоценных камней',
    value: CollateralTypes.JEVELRY,
  },
  { label: 'Бижутерия, монеты, недрагоценные металлы и изделия из них', value: CollateralTypes.BIJOUTERIE },
  { label: 'Транспортные средства', value: CollateralTypes.VEHICLES },
  { label: 'Электротехническое оборудование', value: CollateralTypes.ELECTRICAL_EQUIPMENT },
  { label: 'Устройства сотовой связи', value: CollateralTypes.CELLULAR_DEVICES },
  { label: 'Произведения искусства, предметы коллекционирования и антиквариат', value: CollateralTypes.COLLECTIBLES },
  { label: 'Иные вещи', value: CollateralTypes.OTHER },
];
