import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CustomerCategory, ListResponse } from '@/types';

const customerCategoryApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CustomerCategories'],
});

const customerCategoryApi = customerCategoryApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerCategories: builder.query<ListResponse<CustomerCategory>, AxiosRequestConfig<CustomerCategory>>({
      query: (config) => ({ url: '/admin/customer-category', ...config }),
      providesTags: ['CustomerCategories'],
    }),
    createCustomerCategory: builder.mutation<CustomerCategory, CustomerCategory>({
      query: ({ color, ...customerCategory }) => ({
        url: '/admin/customer-category',
        method: 'POST',
        data: { ...customerCategory, color_id: color?.id },
      }),
      invalidatesTags: ['CustomerCategories'],
    }),
    updateCustomerCategory: builder.mutation<CustomerCategory, CustomerCategory>({
      query: ({ color, id, ...customerCategory }) => ({
        url: '/admin/customer-category/' + id,
        method: 'PUT',
        data: { ...customerCategory, color_id: color?.id },
      }),
      invalidatesTags: ['CustomerCategories'],
    }),
    deleteCustomerCategory: builder.mutation<unknown, CustomerCategory>({
      query: (customerCategory) => ({ url: '/admin/customer-category/' + customerCategory.id, method: 'DELETE' }),
      invalidatesTags: ['CustomerCategories'],
    }),
  }),
});

const {
  useGetCustomerCategoriesQuery,
  useCreateCustomerCategoryMutation,
  useUpdateCustomerCategoryMutation,
  useDeleteCustomerCategoryMutation,
} = customerCategoryApi;

export {
  useGetCustomerCategoriesQuery,
  useCreateCustomerCategoryMutation,
  useUpdateCustomerCategoryMutation,
  useDeleteCustomerCategoryMutation,
};
