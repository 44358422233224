import { DocumentTypes } from '@/types/document';

export const PLEDGE_DOCUMENTS = [
  { label: 'Весь документ', value: DocumentTypes.WHOLE_DOCUMENT, divider: true },
  { label: 'Заявление', value: DocumentTypes.STATEMENT },
  { label: 'Уведомление', value: DocumentTypes.NOTIFICATION },
  { label: 'Титульный лист', value: DocumentTypes.TITLE },
  { label: 'Упаковочный талон', value: DocumentTypes.PACKING_SLIP },
  { label: 'График', value: DocumentTypes.SCHEDULE },
  { label: 'РКО', value: DocumentTypes.RKO },
];
export const PLEDGE_AGREEMENTS = [
  { label: 'Договор о предоставлении краткосрочного займа', value: DocumentTypes.SHORT_TERM_LOAN },
  {
    label: 'Договор Залога к договору О Предоставлении Краткосрочного Займа',
    value: DocumentTypes.PLEDGE_SHORT_TERM_LOAN,
  },
  {
    label: 'Договор Закладка к договору О Предоставлении Краткосрочного Займа (автомобиль у нас)',
    value: DocumentTypes.CAR_SHORT_TERM_LOAN,
  },
  { label: 'Заявление на займ (автомобиль у нас)', value: DocumentTypes.ORGANIZATION_CAR_STATEMENT },
  { label: 'Заявление на займ (автомобиль у клиента)', value: DocumentTypes.CLIENT_CAR_STATEMENT },
  { label: 'Постановка на обременение (КАЗ)', value: DocumentTypes.ENCUMBRANCE },
  { label: 'Снятие с обременения (КАЗ)', value: DocumentTypes.ENCUMBRANCE_REMOVAL },
  { label: 'На стоянку', value: DocumentTypes.ON_PARKING },
];

export const BUYOUT_DOCUMENTS = [
  { label: 'Отметка о возврате', value: DocumentTypes.BUYOUT_MARK },
  { label: 'ПКО', value: DocumentTypes.PKO },
];

export const PROLONGATION_DOCUMENTS = [{ label: 'Отметка о пролонгации', value: DocumentTypes.PROLONGATION_MARK }];

export const REALIZATION_DOCUMENTS = [
  { label: 'Акт реализации', value: DocumentTypes.IMPLEMENTATION_ACT },
  { label: 'Накладная', value: DocumentTypes.INVOICE },
];

export const CHECK_DOCUMENTS = [{ label: 'Чек ККМ', value: DocumentTypes.KKM_CHECK }];

export const DOCUMENTS = [
  ...PLEDGE_DOCUMENTS,
  ...PLEDGE_AGREEMENTS,
  ...BUYOUT_DOCUMENTS,
  ...PROLONGATION_DOCUMENTS,
  ...REALIZATION_DOCUMENTS,
  ...CHECK_DOCUMENTS,
];
