import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Role } from '@/types';
import { useGetRolesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchRoles<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Role> } & Omit<
  AutocompleteProps<Role, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetRolesQueryProps = useAsyncSearch<Role, Multiple, DisableClearable, FreeSolo>(
    useGetRolesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetRolesQueryProps} />;
}

export default SearchRoles;
