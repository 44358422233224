import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Check, ListResponse } from '@/types';

const checkApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Checks'],
});

const checkApi = checkApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getChecks: builder.query<ListResponse<Check>, AxiosRequestConfig<Check>>({
      query: (config) => ({ url: '/admin/cheque', ...config }),
      providesTags: ['Checks'],
    }),
  }),
});

const { useGetChecksQuery } = checkApi;

export { useGetChecksQuery };
