import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { GemCategory } from '@/types';
import { useGetGemCategoriesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchGemCategories<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<GemCategory> } & Omit<
  AutocompleteProps<GemCategory, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetGemCategoriesQueryProps = useAsyncSearch<GemCategory, Multiple, DisableClearable, FreeSolo>(
    useGetGemCategoriesQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetGemCategoriesQueryProps} />;
}

export default SearchGemCategories;
