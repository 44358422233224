import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Area, ListResponse } from '@/types';

const areaApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Areas'],
});

const areaApi = areaApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAreas: builder.query<ListResponse<Area>, AxiosRequestConfig<Area>>({
      query: (config) => ({ url: '/admin/area', ...config }),
      providesTags: ['Areas'],
    }),
    createArea: builder.mutation<Area, Area>({
      query: (area) => ({ url: '/admin/area', method: 'POST', data: area }),
      invalidatesTags: ['Areas'],
    }),
    updateArea: builder.mutation<Area, Area>({
      query: (area) => ({ url: '/admin/area/' + area.id, method: 'PUT', data: area }),
      invalidatesTags: ['Areas'],
    }),
    deleteArea: builder.mutation<unknown, Area>({
      query: (area) => ({ url: '/admin/area/' + area.id, method: 'DELETE' }),
      invalidatesTags: ['Areas'],
    }),
  }),
});

const { useGetAreasQuery, useCreateAreaMutation, useUpdateAreaMutation, useDeleteAreaMutation } = areaApi;

export { useGetAreasQuery, useCreateAreaMutation, useUpdateAreaMutation, useDeleteAreaMutation };
