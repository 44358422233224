export enum FolderNames {
  AVATARS = 'avatars',
  IDENTITY_FRONT_SIDES = 'identity_front_sides',
  IDENTITY_BACK_SIDES = 'identity_back_sides',
  GEMS = 'gems',
}

export interface Attachment {
  file: Blob;
  folder_name: FolderNames;
}
