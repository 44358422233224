import moment from 'moment';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { Buyout, Pledge } from '@/types';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { numbersDeclension } from '@/utils/numbers-declension';
import { SearchPledges } from '@/pages/Pledges/components';

export interface CreateBuyoutProps extends DialogProps {
  pledge: Pledge | null;
  disabled: boolean;
  onClose(): void;
  onSubmit(): void;
}

const CreateBuyout: React.FC<CreateBuyoutProps> = ({ pledge, disabled, onClose, onSubmit, ...props }) => {
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik<Buyout>({
    initialValues: { pledge },
    enableReinitialize: true,
    onSubmit,
  });

  const DAYS_FROM_PLEDGE_WAS_CREATED = Math.ceil(moment().endOf('day').diff(moment(pledge?.updated_at), 'days', true)),
    SUM_FOR_DAY = Math.round(+values.pledge?.return_sum_to_hand! * +values.pledge?.rate_for_day!) / 100,
    OVERDUE_DAYS_COUNT =
      DAYS_FROM_PLEDGE_WAS_CREATED > +values.pledge?.days_count!
        ? DAYS_FROM_PLEDGE_WAS_CREATED - +values.pledge?.days_count!
        : 0,
    FINE = Math.round(
      (+values.pledge?.return_sum! * OVERDUE_DAYS_COUNT * +values.pledge?.nomenclature_group?.gt_30_days!) / 100,
    ),
    TOTAL_SUM_FOR_BUYOUT = Math.round(
      +values.pledge?.return_sum_to_hand! + SUM_FOR_DAY * (DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT) + FINE,
    );

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Провести выкуп</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={8} md={10} xs={12}>
              <SearchPledges
                value={values.pledge}
                onChange={(event, option) => setFieldValue('pledge', option)}
                disabled={disabled}
                readOnly
                getOptionLabel={(option) => String(option.name)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="pledge"
                    label="Залог"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.pledge && errors.pledge)}
                    helperText={touched.pledge && errors.pledge}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>

            {values.pledge && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Дата создания"
                      placeholder="Введите значение"
                      value={moment().format('DD.MM.YYYY')}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.employee.name"
                      label="Автор"
                      placeholder="Введите значение"
                      value={`${values.pledge.employee?.last_name} ${values.pledge.employee?.name} ${values.pledge.employee?.father_name}`}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.point.name"
                      label="Точка (Лобмард)"
                      placeholder="Введите значение"
                      value={values.pledge.point?.name}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.customer.name"
                      label="Залогодатель"
                      placeholder="Введите значение"
                      value={`${values.pledge.customer?.last_name} ${values.pledge.customer?.name} ${
                        values.pledge.customer?.father_name ?? ''
                      }`}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6.5} md={6.5} xs={12}>
                    <TextField
                      name="pledge.rate_for_day"
                      label="Ставка за кредит (1 день)"
                      placeholder="Введите значение"
                      value={values.pledge.rate_for_day}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.return_sum_to_hand"
                      label="На руки"
                      placeholder="Введите значение"
                      value={values.pledge.return_sum_to_hand}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      name="pledge.return_sum"
                      label="К возврату"
                      placeholder="Введите значение"
                      value={values.pledge.return_sum}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Просрочено (дней)"
                      placeholder="Введите значение"
                      value={OVERDUE_DAYS_COUNT}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>
                      <b>
                        % за {DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT}{' '}
                        {numbersDeclension(DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}{' '}
                        под {values.pledge.rate_for_day}%:{' '}
                      </b>
                      {Math.round(SUM_FOR_DAY * (DAYS_FROM_PLEDGE_WAS_CREATED - OVERDUE_DAYS_COUNT))} (тг)
                    </Typography>

                    {Boolean(FINE) && (
                      <Typography>
                        <b>
                          Пеня за {OVERDUE_DAYS_COUNT} {numbersDeclension(OVERDUE_DAYS_COUNT, ['день', 'дня', 'дней'])}:{' '}
                        </b>
                        {FINE} (тг)
                      </Typography>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Сумма пени"
                      placeholder="Введите значение"
                      value={FINE}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>
                      <b>Итого: </b>
                      {TOTAL_SUM_FOR_BUYOUT} (тг)
                    </Typography>

                    <Typography>
                      <b>Общее кол-во дней залога: </b>
                      {DAYS_FROM_PLEDGE_WAS_CREATED}{' '}
                      {numbersDeclension(DAYS_FROM_PLEDGE_WAS_CREATED, ['день', 'дня', 'дней'])}
                    </Typography>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      select
                      name="pledge.payment_method"
                      label="Способ оплаты"
                      value={values.pledge.payment_method}
                      disabled={disabled}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    >
                      {PAYMENT_METHODS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="button" color="secondary" variant="outlined" disabled={disabled} onClick={onClose}>
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={disabled}>
            Провести
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateBuyout;
