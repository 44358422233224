import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps, createFilterOptions } from '@mui/material';

import { Street } from '@/types';
import { useGetStreetsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchStreets<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  getOptions,
  prefetch,
  onInputChange,
  ...props
}: {
  config?: AxiosRequestConfig<Street>;
  getOptions?(options: readonly Street[]): void;
  prefetch?: boolean;
} & Omit<AutocompleteProps<Street, Multiple, DisableClearable, FreeSolo>, 'options'>) {
  const asyncGetStreetsQueryProps = useAsyncSearch<Street, Multiple, DisableClearable, FreeSolo>(
    useGetStreetsQuery,
    'id',
    value,
    config,
    getOptions,
    prefetch,
    onInputChange,
  );

  return (
    <Autocomplete
      {...props}
      {...asyncGetStreetsQueryProps}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<Street>()(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue && !isExisting) filtered.push({ name: inputValue });

        return filtered;
      }}
    />
  );
}

export default SearchStreets;
