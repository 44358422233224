import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Street } from '@/types';
import { useDeleteStreetMutation } from '@/services';

export interface DeleteStreetProps extends DialogProps {
  street: Street;
  onClose(): void;
}

const DeleteStreet: React.FC<DeleteStreetProps> = ({ street, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteStreet, deleteStreetResponse] = useDeleteStreetMutation();

  async function handleDeleteStreet() {
    try {
      await deleteStreet(street).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{street?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{street?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteStreetResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button variant="contained" disabled={deleteStreetResponse.isLoading} size="small" onClick={handleDeleteStreet}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStreet;
