import { useEffect, useRef } from 'react';

function useTitle(title: string) {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : null);

  useEffect(() => {
    if (!documentDefined) return;
    const currentTitle = originalTitle.current;

    if (document.title !== title) document.title = title;

    return () => {
      document.title = currentTitle ?? '';
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useTitle;
