import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { GemCutShape, ListResponse } from '@/types';

const gemCutShapeApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['GemCutShapes'],
});

const gemCutShapeApi = gemCutShapeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getGemCutShapes: builder.query<ListResponse<GemCutShape>, AxiosRequestConfig<GemCutShape>>({
      query: (config) => ({ url: '/admin/gem-cut-shape', ...config }),
      providesTags: ['GemCutShapes'],
    }),
    createGemCutShape: builder.mutation<GemCutShape, GemCutShape>({
      query: (gemCutShape) => ({ url: '/admin/gem-cut-shape', method: 'POST', data: gemCutShape }),
      invalidatesTags: ['GemCutShapes'],
    }),
    updateGemCutShape: builder.mutation<GemCutShape, GemCutShape>({
      query: (gemCutShape) => ({
        url: '/admin/gem-cut-shape/' + gemCutShape.id,
        method: 'PUT',
        data: gemCutShape,
      }),
      invalidatesTags: ['GemCutShapes'],
    }),
    deleteGemCutShape: builder.mutation<unknown, GemCutShape>({
      query: (gemCutShape) => ({ url: '/admin/gem-cut-shape/' + gemCutShape.id, method: 'DELETE' }),
      invalidatesTags: ['GemCutShapes'],
    }),
  }),
});

const {
  useGetGemCutShapesQuery,
  useCreateGemCutShapeMutation,
  useUpdateGemCutShapeMutation,
  useDeleteGemCutShapeMutation,
} = gemCutShapeApi;

export {
  useGetGemCutShapesQuery,
  useCreateGemCutShapeMutation,
  useUpdateGemCutShapeMutation,
  useDeleteGemCutShapeMutation,
};
