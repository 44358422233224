import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { GemCategory, ListResponse } from '@/types';

const gemCategoryApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['GemCategories'],
});

const gemCategoryApi = gemCategoryApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getGemCategories: builder.query<ListResponse<GemCategory>, AxiosRequestConfig<GemCategory>>({
      query: (config) => ({ url: '/admin/gem-category', ...config }),
      providesTags: ['GemCategories'],
    }),
    createGemCategory: builder.mutation<GemCategory, GemCategory>({
      query: (gemCategory) => ({ url: '/admin/gem-category', method: 'POST', data: gemCategory }),
      invalidatesTags: ['GemCategories'],
    }),
    updateGemCategory: builder.mutation<GemCategory, GemCategory>({
      query: (gemCategory) => ({ url: '/admin/gem-category/' + gemCategory.id, method: 'PUT', data: gemCategory }),
      invalidatesTags: ['GemCategories'],
    }),
    deleteGemCategory: builder.mutation<unknown, GemCategory>({
      query: (gemCategory) => ({ url: '/admin/gem-category/' + gemCategory.id, method: 'DELETE' }),
      invalidatesTags: ['GemCategories'],
    }),
  }),
});

const {
  useGetGemCategoriesQuery,
  useCreateGemCategoryMutation,
  useUpdateGemCategoryMutation,
  useDeleteGemCategoryMutation,
} = gemCategoryApi;

export {
  useGetGemCategoriesQuery,
  useCreateGemCategoryMutation,
  useUpdateGemCategoryMutation,
  useDeleteGemCategoryMutation,
};
