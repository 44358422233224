import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { Computer } from '@/types';
import { useUpdateComputerMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchPoints } from '@/pages/Points/components';
import { SCANNER_PORT_TYPES } from '@/utils/scanner-port-types';

export interface UpdateComputerProps extends DialogProps {
  computer: Computer;
  onClose(): void;
}

const UpdateComputer: React.FC<UpdateComputerProps> = ({ computer, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateComputer, updateComputerResponse] = useUpdateComputerMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Computer>({
    initialValues: computer ?? {
      point: null,
      code: '',
      vpn_ip: '',
      vpn_username: '',
      scaner_port_type: '',
      sensitivity: 0,
      comment: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      point: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      code: yup.string().required(messages.form.REQUIRED),
      vpn_ip: yup
        .string()
        .required(messages.form.REQUIRED)
        .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, { message: messages.form.INVALID_IP, excludeEmptyString: true })
        .test('vpn_ip', messages.form.INCORRECT_IP, (value) => {
          if (value === undefined || value.trim() === '') return true;
          return value.split('.').find((i) => parseInt(i) > 255) === undefined;
        }),
      vpn_username: yup.string().required(messages.form.REQUIRED),
      scaner_port_type: yup.string().required(messages.form.SELECT_FROM_LIST),
      comment: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(computer, { resetForm }) {
      try {
        await updateComputer(computer).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать компьютер</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <SearchPoints
                value={values.point}
                onChange={(event, option) => setFieldValue('point', option)}
                disabled={updateComputerResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="point"
                    label="Точка"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Наименование"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={updateComputerResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="vpn_ip"
                label="VPN IP"
                placeholder="Введите значение"
                value={values.vpn_ip}
                onChange={handleChange}
                disabled={updateComputerResponse.isLoading}
                error={touched.vpn_ip && Boolean(errors.vpn_ip)}
                helperText={touched.vpn_ip ? errors.vpn_ip : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="vpn_username"
                label="vpn username"
                placeholder="Введите значение"
                value={values.vpn_username}
                onChange={handleChange}
                disabled={updateComputerResponse.isLoading}
                error={touched.vpn_username && Boolean(errors.vpn_username)}
                helperText={touched.vpn_username ? errors.vpn_username : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                select
                name="scaner_port_type"
                label="Укажите scaner port type"
                value={values.scaner_port_type}
                onChange={handleChange}
                disabled={updateComputerResponse.isLoading}
                error={touched.scaner_port_type && Boolean(errors.scaner_port_type)}
                helperText={touched.scaner_port_type ? errors.scaner_port_type : ''}
                fullWidth
              >
                {SCANNER_PORT_TYPES.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                multiline
                name="comment"
                label="Комментарий"
                placeholder="Введите значение"
                value={values.comment}
                onChange={handleChange}
                disabled={updateComputerResponse.isLoading}
                error={touched.comment && Boolean(errors.comment)}
                helperText={touched.comment ? errors.comment : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FormControlLabel
                label="sensitivity"
                control={
                  <Switch
                    name="sensitivity"
                    value={values.sensitivity}
                    disabled={updateComputerResponse.isLoading}
                    onChange={(event, checked) => setFieldValue('sensitivity', checked ? 1 : 0)}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateComputerResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateComputerResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateComputer;
