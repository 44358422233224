import { Grid } from '@mui/material';

import { useGetWebkassaXReportQuery } from '@/services';
import { AppBar, Empty, Error, Loader } from '@/components';

import { WebkassaXReportTable } from './components';

const WebkassaXReportPage: React.FC = () => {
  const { data: webkassaXReport, isLoading, isSuccess, isError } = useGetWebkassaXReportQuery({});

  return (
    <>
      <AppBar title="Движение по веб-кассе" />

      <Grid item xs={12}>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Error text="Произошла ошибка с загрузкой отчёта по веб-кассе! Пожалуйста, попробуйте позже" />
        ) : isSuccess && Boolean(webkassaXReport) ? (
          <WebkassaXReportTable data={webkassaXReport} />
        ) : (
          <Empty text="Данные отсутствуют" />
        )}
      </Grid>
    </>
  );
};

export default WebkassaXReportPage;
