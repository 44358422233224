import { Employee, Organization, Position, Point } from '@/types';

export enum TechRequestStatuses {
  NOT_COMPLETED = 1,
  PROCESSING = 2,
  COMPLETED = 3,
  REJECTED_BY_EXECUTOR = 4,
}

export interface TechnicalRequest {
  id?: number;
  name?: string;
  organization?: Organization | null;
  organization_id?: Organization['id'];
  point?: Point | null;
  point_id?: Point['id'];
  created_by?: Employee;
  short_description: string;
  description: string;
  executor_text?: string;
  executor?: Employee | null;
  executor_id?: Employee['id'];
  execution_date?: string;
  execution_time?: string;
  position: Position | null;
  position_id?: Position['id'];
  status?: TechRequestStatuses;
  created_at?: Date;
}
