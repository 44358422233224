import { Box, Paper, PaperProps, Typography } from '@mui/material';

export interface ErrorProps extends PaperProps {
  text: string;
}

const Error: React.FC<ErrorProps> = ({ text, ...props }) => {
  return (
    <Box component={Paper} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }} {...props}>
      <Typography component="h2" variant="h4" align="center">
        {text}
      </Typography>
    </Box>
  );
};

export default Error;
