import { Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import { Box, Container } from '@mui/material';

import {
  Accounts,
  ActivePledgesReport,
  Areas,
  Bonuses,
  Buyouts,
  CarBrands,
  Cars,
  CashboxOperations,
  CashboxOperationsReport,
  Cashdesks,
  Checks,
  CollateralItemStates,
  Colors,
  Computers,
  Constants,
  Countries,
  Currencies,
  Customer,
  CustomerCategories,
  CustomerDocumentTypes,
  Customers,
  CustomerStatuses,
  Defects,
  Districts,
  Documents,
  ElectronicBrands,
  ElectronicModels,
  Employees,
  FurCoatCategories,
  GemCategories,
  GemCutShapes,
  Gems,
  Holidays,
  IntervalCollateralRates,
  Localities,
  MobileOperators,
  NomenclatureGroupChars,
  NomenclatureGroupDiscounts,
  NomenclatureGroupFines,
  NomenclatureGroupIntervals,
  NomenclatureGroups,
  NomenclatureRateCategories,
  Nomenclatures,
  Organizations,
  PaymentsToExpertsReport,
  PersonalArea,
  Pledges,
  Points,
  Positions,
  Prolongations,
  Realizations,
  Regions,
  Roles,
  Samples,
  Shifts,
  Streets,
  TechnicalRequests,
  TerroristOrganizations,
  Units,
  WebkassaXReport,
} from '@/pages';
import { ShiftProvider } from '@/providers';
import { useRoles } from '@/hooks';
import { ProtectedRoute, Sidebar } from '@/components';

const Cabinet: React.FC = () => {
  const { isAdmin } = useRoles();

  return (
    <ShiftProvider>
      <Sidebar />

      <Box
        component="main"
        sx={{
          position: 'relative',
          top: 72,
          left: 90,
          display: 'flex',
          maxWidth: 'calc(100% - 90px)',
          minHeight: 'calc(100% - 72px)',
          flex: 1,
          '@media print': { top: 0, left: 0, maxWidth: '100%' },
        }}
      >
        <Container sx={{ p: 3 }}>
          <Routes>
            <Route path="customers">
              <Route index element={<Customers />} />
              <Route path=":customerId" element={<Customer />} />

              <Route path=":customerId/pledges">
                <Route index element={<Pledges />} />

                <Route path=":pledgeId/buyouts">
                  <Route index element={<Buyouts />} />
                </Route>

                <Route path=":pledgeId/prolongations">
                  <Route index element={<Prolongations />} />
                </Route>

                <Route path=":pledgeId/realizations">
                  <Route index element={<Realizations />} />
                </Route>
              </Route>
            </Route>

            <Route element={<ProtectedRoute isAllowed={isAdmin} redirectTo="/customers" />}>
              <Route path="dictionaries">
                <Route path="accounts" element={<Accounts />} />
                <Route path="bonuses" element={<Bonuses />} />
                <Route path="cashdesks" element={<Cashdesks />} />
                <Route path="colors" element={<Colors />} />
                <Route path="computers" element={<Computers />} />
                <Route path="constants" element={<Constants />} />
                <Route path="countries" element={<Countries />} />
                <Route path="currencies" element={<Currencies />} />
                <Route path="customer-categories" element={<CustomerCategories />} />
                <Route path="customer-document-types" element={<CustomerDocumentTypes />} />
                <Route path="customer-statuses" element={<CustomerStatuses />} />
                <Route path="employees" element={<Employees />} />
                <Route path="holidays" element={<Holidays />} />
                <Route path="intervals/:id/rates" element={<IntervalCollateralRates />} />
                <Route path="nomenclature-groups/:id/chars" element={<NomenclatureGroupChars />} />
                <Route path="nomenclature-groups/:id/discounts" element={<NomenclatureGroupDiscounts />} />
                <Route path="nomenclature-groups/:id/fines" element={<NomenclatureGroupFines />} />
                <Route path="nomenclature-groups/:id/intervals" element={<NomenclatureGroupIntervals />} />
                <Route path="nomenclature-groups" element={<NomenclatureGroups />} />
                <Route path="nomenclatures/:id/rate-categories" element={<NomenclatureRateCategories />} />
                <Route path="nomenclatures" element={<Nomenclatures />} />
                <Route path="mobile-operators" element={<MobileOperators />} />
                <Route path="organizations" element={<Organizations />} />
                <Route path="pledge-documents" element={<Documents />} />
                <Route path="points" element={<Points />} />
                <Route path="positions" element={<Positions />} />

                <Route path="regions">
                  <Route index element={<Regions />} />

                  <Route path=":regionId/areas">
                    <Route index element={<Areas />} />

                    <Route path=":areaId/districts">
                      <Route index element={<Districts />} />

                      <Route path=":districtId/localities">
                        <Route index element={<Localities />} />

                        <Route path=":localityId/streets">
                          <Route index element={<Streets />} />
                        </Route>
                      </Route>

                      <Route path=":districtId/streets">
                        <Route index element={<Streets />} />
                      </Route>
                    </Route>
                  </Route>

                  <Route path=":regionId/streets">
                    <Route index element={<Streets />} />
                  </Route>
                </Route>

                <Route path="roles" element={<Roles />} />
                <Route path="samples" element={<Samples />} />
                <Route path="terrorist-organizations" element={<TerroristOrganizations />} />
                <Route path="units" element={<Units />} />

                <Route path="collateral-chars">
                  <Route path="car-brands" element={<CarBrands />} />
                  <Route path="cars" element={<Cars />} />
                  <Route path="defects" element={<Defects />} />
                  <Route path="fur-coat/categories" element={<FurCoatCategories />} />
                  <Route path="gems/:id/categories" element={<GemCategories />} />
                  <Route path="gems" element={<Gems />} />
                  <Route path="gem-cut-shapes" element={<GemCutShapes />} />
                  <Route path="electronic-brands" element={<ElectronicBrands />} />
                  <Route path="electronic-models" element={<ElectronicModels />} />
                  <Route path="states" element={<CollateralItemStates />} />

                  <Route path="/dictionaries/collateral-chars" element={<Navigate to="car-brands" />} />
                </Route>

                <Route path="/dictionaries" element={<Navigate to="accounts" />} />
              </Route>
            </Route>

            <Route path="operations">
              <Route path="buyouts" element={<Buyouts />} />

              <Route path="pledges">
                <Route index element={<Pledges />} />

                <Route path=":pledgeId/buyouts">
                  <Route index element={<Buyouts />} />
                </Route>

                <Route path=":pledgeId/prolongations">
                  <Route index element={<Prolongations />} />
                </Route>

                <Route path=":pledgeId/realizations">
                  <Route index element={<Realizations />} />
                </Route>
              </Route>

              <Route path="prolongations" element={<Prolongations />} />

              <Route path="realizations">
                <Route index element={<Realizations />} />

                <Route path=":realizationId/pledges">
                  <Route index element={<Pledges />} />
                </Route>
              </Route>

              <Route path="cashbox-operations" element={<CashboxOperations />} />
              <Route path="technical-requests" element={<TechnicalRequests />} />
              <Route path="checks" element={<Checks />} />
              <Route path="shifts" element={<Shifts />} />

              <Route path="/operations" element={<Navigate to="shifts" />} />
            </Route>

            <Route path="reports">
              <Route path="payments-to-experts" element={<PaymentsToExpertsReport />} />
              <Route path="active-pledges" element={<ActivePledgesReport />} />
              <Route path="cashbox-operations" element={<CashboxOperationsReport />} />
              <Route path="webkassa-x" element={<WebkassaXReport />} />

              <Route path="/reports" element={<Navigate to="payments-to-experts" />} />
            </Route>

            <Route path="personal-area">
              <Route index element={<PersonalArea />} />
            </Route>

            <Route path="*" element={<Navigate to="/customers" />} />
          </Routes>
        </Container>
      </Box>
    </ShiftProvider>
  );
};

export default Cabinet;
