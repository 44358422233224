import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import { CustomerCategory } from '@/types';
import { useCreateCustomerCategoryMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchColors } from '@/pages/Colors/components';

export interface CreateCustomerCategoryProps extends DialogProps {
  onClose(): void;
}

const CreateCustomerCategory: React.FC<CreateCustomerCategoryProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createCustomerCategory, createCustomerCategoryResponse] = useCreateCustomerCategoryMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<CustomerCategory>({
    initialValues: { name: '', color: null },
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      color: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(customerCategory, { resetForm }) {
      try {
        await createCustomerCategory(customerCategory).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить категорию клиента</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={createCustomerCategoryResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchColors
                value={values.color}
                onChange={(event, option) => setFieldValue('color', option)}
                getOptionLabel={(option) => option.name}
                disabled={createCustomerCategoryResponse.isLoading}
                renderOption={(props, { name, hex }) => (
                  <li {...props}>
                    <Box sx={{ width: 32, height: 32, mr: 1, bgcolor: hex, borderRadius: '10em' }} />
                    {name}
                  </li>
                )}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="color"
                    label="Цвет"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.color && errors.color)}
                    helperText={touched.color && errors.color}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={createCustomerCategoryResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={createCustomerCategoryResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCustomerCategory;
