import { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Employee } from '@/types';
import { useRoles } from '@/hooks';
import { PERSENT_TYPES } from '@/utils/percent-types';
import { ChangeEmployeeAdditionalPassword, ChangeEmployeePassword } from '@/pages/Employees/components';

export interface EmployeeInfoProps {
  employee: Employee;
}

const EmployeeInfo: React.FC<EmployeeInfoProps> = ({ employee }) => {
  const { isAdmin, isRevisor } = useRoles();

  const [changePasswordModalOpened, setOpenedChangePasswordModal] = useState(false);
  const [changeAdditionalPasswordModalOpened, setOpenedChangeAdditionalPasswordModal] = useState(false);

  const {
    last_name,
    name,
    father_name,
    login,
    phone_number,
    position,
    role,
    born_at,
    dismissed_at,
    hired_at,
    percents,
    percent_salary,
  } = employee;
  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography component="h2" variant="h1">
            {last_name} {name} {father_name}
          </Typography>

          {isAdmin && (
            <Button
              type="button"
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => setOpenedChangePasswordModal(true)}
            >
              Обновить пароль
            </Button>
          )}

          {(isAdmin || isRevisor) && (
            <Button
              type="button"
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => setOpenedChangeAdditionalPasswordModal(true)}
            >
              Обновить пароль для проведения операций
            </Button>
          )}
        </Stack>

        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th">
                        <b>Должность</b>
                      </TableCell>

                      <TableCell>{position?.name ?? '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Роль</b>
                      </TableCell>

                      <TableCell>{role?.name ?? '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Номер телефона</b>
                      </TableCell>

                      <TableCell>{phone_number ?? '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>E-mail</b>
                      </TableCell>

                      <TableCell>{login ?? '—'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item lg={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th">
                        <b>Дата рождения</b>
                      </TableCell>

                      <TableCell>{born_at ? moment(born_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Нанят</b>
                      </TableCell>

                      <TableCell>{hired_at ? moment(hired_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Уволен</b>
                      </TableCell>

                      <TableCell>{dismissed_at ? moment(dismissed_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th">
                        <b>Процент</b>
                      </TableCell>

                      <TableCell>{percent_salary}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {Boolean(percents?.length) && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Вид процента</b>
                        </TableCell>

                        <TableCell>
                          <b>%</b>
                        </TableCell>

                        <TableCell>
                          <b>Отбор по точкам</b>
                        </TableCell>

                        <TableCell>
                          <b>Отбор по номенклатурным группам</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(percents || []).map((percent, index) => {
                        const { type, points, nomenclature_groups } = percent;

                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <b>{PERSENT_TYPES.find(({ value }) => value === type)?.label}</b>
                            </TableCell>

                            <TableCell>{percent.percent}</TableCell>

                            <TableCell>
                              <Stack direction="row" sx={{ gap: 0.5, maxWidth: 300, flexWrap: 'wrap' }}>
                                {(points || []).map(({ name }) => (
                                  <Chip label={name} key={name} variant="outlined" size="small" />
                                ))}
                              </Stack>
                            </TableCell>

                            <TableCell>{(nomenclature_groups || []).map(({ name }) => name).join(', ')}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>

      <ChangeEmployeePassword
        employee={employee}
        open={changePasswordModalOpened}
        onClose={() => setOpenedChangePasswordModal(false)}
      />

      <ChangeEmployeeAdditionalPassword
        employee={employee}
        open={changeAdditionalPasswordModalOpened}
        onClose={() => setOpenedChangeAdditionalPasswordModal(false)}
      />
    </>
  );
};

export default EmployeeInfo;
