import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Gem } from '@/types';
import { useDeleteGemMutation } from '@/services';

export interface DeleteGemProps extends DialogProps {
  gem: Gem;
  onClose(): void;
}

const DeleteGem: React.FC<DeleteGemProps> = ({ gem, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteGem, deleteGemResponse] = useDeleteGemMutation();

  async function handleDeleteGem() {
    try {
      await deleteGem(gem).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{gem?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{gem?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteGemResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button variant="contained" size="small" disabled={deleteGemResponse.isLoading} onClick={handleDeleteGem}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGem;
