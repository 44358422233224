import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { CustomerDocumentType } from '@/types';
import { useGetCustomerDocumentTypesQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCustomerDocumentTypes<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<CustomerDocumentType> } & Omit<
  AutocompleteProps<CustomerDocumentType, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCustomerDocumentTypesQueryProps = useAsyncSearch<
    CustomerDocumentType,
    Multiple,
    DisableClearable,
    FreeSolo
  >(useGetCustomerDocumentTypesQuery, 'id', value, config);

  return <Autocomplete {...props} {...asyncGetCustomerDocumentTypesQueryProps} />;
}

export default SearchCustomerDocumentTypes;
