import { ScannerPortTypes } from '@/types';

export const SCANNER_PORT_TYPES = [
  { label: 'none', value: ScannerPortTypes.NONE },
  { label: 'keyboard', value: ScannerPortTypes.KEYBOARD },
  { label: 'COM1', value: ScannerPortTypes.COM1 },
  { label: 'COM2', value: ScannerPortTypes.COM2 },
  { label: 'COM3', value: ScannerPortTypes.COM3 },
  { label: 'COM4', value: ScannerPortTypes.COM4 },
  { label: 'COM5', value: ScannerPortTypes.COM5 },
  { label: 'COM6', value: ScannerPortTypes.COM6 },
  { label: 'COM7', value: ScannerPortTypes.COM7 },
  { label: 'COM8', value: ScannerPortTypes.COM8 },
  { label: 'COM9', value: ScannerPortTypes.COM9 },
];
