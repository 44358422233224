import { Fragment } from 'react';
import { lighten, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Reports } from '@/types';
import theme from '@/theme';

export interface ActivePledgesSummaryTableProps {
  list?: Reports.ActivePledgesSummary[];
}

const ActivePledgesSummaryTable: React.FC<ActivePledgesSummaryTableProps> = ({ list }) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Точка</TableCell>
            <TableCell rowSpan={2}>Масса металла</TableCell>
            <TableCell colSpan={3} align="center">
              Сумма оценки
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Залог</TableCell>
            <TableCell>Перезалог</TableCell>
            <TableCell>Общая (Залог + перезалог)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {list?.map((summary, index) => {
            const { point, metal_mass, pledge_sum, repledge_sum, total_sum, nomenclature_groups } = summary;

            return (
              <Fragment key={index}>
                <TableRow sx={{ bgcolor: lighten(theme.palette.primary.light, 0.5) }}>
                  <TableCell>
                    <b>{point}</b>
                  </TableCell>

                  <TableCell>
                    <b>{Math.round(metal_mass)}</b>
                  </TableCell>

                  <TableCell>
                    <b>{Math.round(pledge_sum)}</b>
                  </TableCell>

                  <TableCell>
                    <b>{Math.round(repledge_sum)}</b>
                  </TableCell>

                  <TableCell>
                    <b>{Math.round(total_sum)}</b>
                  </TableCell>
                </TableRow>

                {nomenclature_groups.map((summary, index) => {
                  const { nomenclature_group, metal_mass, pledge_sum, repledge_sum, total_sum } = summary;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <b>{nomenclature_group}</b>
                      </TableCell>
                      <TableCell>{Math.round(metal_mass)}</TableCell>
                      <TableCell>{Math.round(pledge_sum)}</TableCell>
                      <TableCell>{Math.round(repledge_sum)}</TableCell>
                      <TableCell>{Math.round(total_sum)}</TableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActivePledgesSummaryTable;
