import { Gem, GemCutShape } from '@/types';

export type GemResetOperand = 'length' | 'height' | 'width' | 'density' | 'coefficient';

export const OPERANDS: GemResetOperand[] = ['length', 'height', 'width', 'density', 'coefficient'];

export function getWeightByChars(length: number, height: number, width: number, gem: Gem, gemCutShape: GemCutShape) {
  const TEMPLATE_KEYS = new Map<GemResetOperand, number>([
    ['length', length],
    ['height', height],
    ['width', width],
    ['density', +gem?.density],
    ['coefficient', +gemCutShape?.coefficient],
  ]);

  return +gemCutShape?.formula
    .replaceAll(/\+|-|\*|\//g, ' ')
    .split(' ')
    .reduce((acc, curr) => {
      const value = TEMPLATE_KEYS.get(curr as GemResetOperand);
      acc *= value ? value : +curr || 1;

      return acc;
    }, 1)
    .toFixed(3);
}
