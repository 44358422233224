import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { MobileOperator } from '@/types';
import { useGetMobileOperatorsQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchMobileOperators<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  getOptions,
  prefetch,
  ...props
}: {
  config?: AxiosRequestConfig<MobileOperator>;
  getOptions?(options: readonly MobileOperator[]): void;
  prefetch?: boolean;
} & Omit<AutocompleteProps<MobileOperator, Multiple, DisableClearable, FreeSolo>, 'options'>) {
  const asyncGetMobileOperatorsQueryProps = useAsyncSearch<MobileOperator, Multiple, DisableClearable, FreeSolo>(
    useGetMobileOperatorsQuery,
    'id',
    value,
    config,
    getOptions,
    prefetch,
  );

  return <Autocomplete {...props} {...asyncGetMobileOperatorsQueryProps} />;
}

export default SearchMobileOperators;
