import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Constant } from '@/types';
import { useDeleteConstantMutation } from '@/services';

export interface DeleteConstantProps extends Omit<DialogProps, 'constant'> {
  constant: Constant;
  onClose(): void;
}

const DeleteConstant: React.FC<DeleteConstantProps> = ({ constant, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteConstant, deleteConstantResponse] = useDeleteConstantMutation();

  async function handleDeleteConstant() {
    try {
      await deleteConstant(constant).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{constant?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{constant?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteConstantResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          size="small"
          disabled={deleteConstantResponse.isLoading}
          onClick={handleDeleteConstant}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConstant;
