import { useState } from 'react';
import moment from 'moment';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { CashboxOperation, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, FileFlat, TrashFlat } from '@/assets/icons';
import { CASHBOX_OPERATIONS } from '@/utils/cashbox-operations';
import theme from '@/theme';

import { DeleteCashboxOperation, GenerateCashboxOperationDocument, UpdateCashboxOperation } from '../components';

export interface CashboxOperationListProps {
  list?: ListResponse<CashboxOperation>;
  page: number;
  onChangePage(page: number): void;
}

const CashboxOperationsTable: React.FC<CashboxOperationListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentCashboxOperation, setCurrentCashboxOperation] = useState<CashboxOperation | null>(null);

  function openUpdateModal(cashboxOperations: CashboxOperation) {
    setCurrentCashboxOperation(cashboxOperations);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(cashboxOperations: CashboxOperation) {
    setCurrentCashboxOperation(cashboxOperations);
    setOpenedDeleteModal(true);
  }

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, cashboxOperation: CashboxOperation) {
    setCurrentCashboxOperation(cashboxOperation);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Организация</TableCell>
                <TableCell>Точка</TableCell>
                <TableCell>Клиент</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Причина</TableCell>
                <TableCell>Дополнительно</TableCell>
                <TableCell>Вид операции</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((cashboxOperation) => {
                const { created_at, name, organization, point, customer, sum, reason, addition, status, id } =
                  cashboxOperation;

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name}</TableCell>

                    <TableCell padding="checkbox">
                      <Tooltip title="Печать">
                        <IconButton
                          aria-label="Печать"
                          onClick={(event) => openGenerateDocumentModal(event, cashboxOperation)}
                        >
                          <FileFlat sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    {false && (
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Tooltip title="Обновить">
                            <IconButton aria-label="Обновить" onClick={() => openUpdateModal(cashboxOperation)}>
                              <EditFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(cashboxOperation)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    )}

                    <TableCell>{organization?.full_name ?? '—'}</TableCell>
                    <TableCell>{point?.name ?? '—'}</TableCell>
                    <TableCell>
                      {customer ? `${customer?.last_name} ${customer?.name} ${customer?.father_name}` : '—'}
                    </TableCell>
                    <TableCell>{sum}</TableCell>
                    <TableCell>{reason ?? '—'}</TableCell>
                    <TableCell>{addition ?? '—'}</TableCell>
                    <TableCell>{CASHBOX_OPERATIONS.find(({ value }) => value === status)?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateCashboxOperation
        cashboxOperations={currentCashboxOperation!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteCashboxOperation
        cashboxOperations={currentCashboxOperation!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
      <GenerateCashboxOperationDocument
        cashboxOperation={currentCashboxOperation!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default CashboxOperationsTable;
