import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Currency } from '@/types';
import { useCreateCurrencyMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface CreateCurrencyProps extends DialogProps {
  onClose(): void;
}

const CreateCurrency: React.FC<CreateCurrencyProps> = ({ onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createCurrency, createCurrencyResponse] = useCreateCurrencyMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Currency>({
    initialValues: {
      code: '',
      full_name: '',
      short_name: '',
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(messages.form.REQUIRED),
      full_name: yup.string().required(messages.form.REQUIRED),
      short_name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(currency, { resetForm }) {
      try {
        await createCurrency(currency).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить валюту</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={createCurrencyResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="full_name"
                label="Полное наименование"
                placeholder="Введите значение"
                value={values.full_name}
                onChange={handleChange}
                disabled={createCurrencyResponse.isLoading}
                error={touched.full_name && Boolean(errors.full_name)}
                helperText={touched.full_name ? errors.full_name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="short_name"
                label="Краткое наименование"
                placeholder="Введите значение"
                value={values.short_name}
                onChange={handleChange}
                disabled={createCurrencyResponse.isLoading}
                error={touched.short_name && Boolean(errors.short_name)}
                helperText={touched.short_name ? errors.short_name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={createCurrencyResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={createCurrencyResponse.isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCurrency;
