import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Customer, ListResponse, StandardResponse } from '@/types';

const customerApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Customers', 'Customer'],
});

const customerApi = customerApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<ListResponse<Customer>, AxiosRequestConfig<Customer>>({
      query: (config) => ({ url: '/admin/customer', ...config }),
      providesTags: ['Customers'],
    }),
    getCustomer: builder.query<
      StandardResponse<Customer>,
      { id: Customer['id']; config?: AxiosRequestConfig<Customer> }
    >({ query: ({ id, config }) => ({ url: '/admin/customer/' + id, ...config }), providesTags: ['Customer'] }),
    createCustomer: builder.mutation<Customer, Customer>({
      query: ({ customer_category, customer_status, country, document_type, operator, ...customer }) => ({
        url: '/admin/customer',
        method: 'POST',
        data: {
          ...customer,
          customer_category_id: customer_category?.id,
          customer_status_id: customer_status?.id,
          country_id: country?.id,
          document_type_id: document_type?.id,
          operator_id: operator?.id,
        },
      }),
      invalidatesTags: ['Customers'],
    }),
    updateCustomer: builder.mutation<Customer, Customer>({
      query: ({ customer_category, customer_status, country, document_type, operator, id, ...customer }) => ({
        url: '/admin/customer/' + id,
        method: 'PUT',
        data: {
          ...customer,
          customer_category_id: customer_category?.id,
          customer_status_id: customer_status?.id,
          country_id: country?.id,
          document_type_id: document_type?.id,
          operator_id: operator?.id,
        },
      }),
      invalidatesTags: ['Customers', 'Customer'],
    }),
    deleteCustomer: builder.mutation<unknown, Customer>({
      query: (customer) => ({ url: '/admin/customer/' + customer.id, method: 'DELETE' }),
      invalidatesTags: ['Customers'],
    }),
    checkTerrorists: builder.query<ListResponse<Customer>, AxiosRequestConfig<Customer>>({
      query: (config) => ({ url: '/admin/customer/set-terrorists', ...config }),
    }),
  }),
});

const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useCheckTerroristsQuery,
} = customerApi;

export {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useCheckTerroristsQuery,
};
