import { useState } from 'react';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { FieldArray, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { CollateralItem, Pledge, PledgeStatuses } from '@/types';
import { useGetCustomerQuery, useLazyGetNomenclatureGroupIntervalPercentQuery } from '@/services';
import { useAuth, useConstant } from '@/hooks';
import { NomenclatureGroups, PaymentMethods } from '@/utils/enums';
import { PAYMENT_METHODS } from '@/utils/payment-methods';
import { EditFlat, TrashFlat } from '@/assets/icons';
import { SearchCustomers } from '@/pages/Customers/components';
import { SearchPoints } from '@/pages/Points/components';
import { SearchNomenclatureGroups } from '@/pages/NomenclatureGroups/components';

import { AddCollateralItem } from '../components';
import { PLEDGE_SCHEMA } from '../schemas';

enum PledgeTabs {
  COMMON = 'common',
  COLLATERAL_ITEMS = 'collateralItems',
}

export interface CreatePledgeProps extends Omit<DialogProps, 'onSubmit'> {
  pledge?: Pledge | null;
  disabled: boolean;
  onSubmit(pledge: Pledge, { resetForm }: FormikHelpers<Pledge>): void;
  onClose(): void;
}

const CreatePledge: React.FC<CreatePledgeProps> = ({ open, pledge, disabled, onClose, onSubmit, ...props }) => {
  const { customerId } = useParams<{ customerId: string }>();

  const pledge_days_count = useConstant('PLEDGE_DAYS_COUNT');
  const { user } = useAuth();

  const { data: customer } = useGetCustomerQuery({ id: +customerId! }, { skip: !customerId });

  const initialValues: Pledge = {
    comment: '',
    customer: customer?.data,
    collateral_items: [],
    days_count: +pledge_days_count.value,
    is_trusted_person: false,
    trusted_person: null,
    is_without_buyout: false,
    nomenclature_group: null,
    payment_method: PaymentMethods.CASH,
    point: user!.point,
    rate_for_day: 0,
    return_date: moment()
      .add(+pledge_days_count.value - 1, 'day')
      .toDate(),
    return_sum: 0,
    return_sum_to_hand: 0,
    status: PledgeStatuses.CARRIED_OUT,
    re_mortgage: false,
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth {...props}>
      <DialogTitle fontWeight="bold">Добавить залог</DialogTitle>

      <Formik<Pledge>
        initialValues={pledge ? { ...pledge, re_mortgage: true } : initialValues}
        validationSchema={PLEDGE_SCHEMA}
        onSubmit={onSubmit}
      >
        {() => <PledgeForm pledge={pledge} disabled={disabled} onClose={onClose} />}
      </Formik>
    </Dialog>
  );
};

const PledgeForm: React.FC<Omit<CreatePledgeProps, 'open' | 'onSubmit' | 'customer'>> = ({
  pledge,
  disabled,
  onClose,
}) => {
  const [getPercent] = useLazyGetNomenclatureGroupIntervalPercentQuery();

  const [activeTab, setActiveTab] = useState(PledgeTabs.COMMON);
  const [collateralItemDialogOpened, setOpenedCollateralItemDialog] = useState(false);
  const [currentCollateralItem, setCurrentCollateralItem] = useState<CollateralItem | null>(null);

  const { values, touched, errors, setValues, setFieldValue, handleChange } = useFormikContext<Pledge>();

  const MAX_COLLATERAL_ITEMS_CONSTRAINTS = values.collateral_items.length >= 5;

  async function handleChangeCollateralItems(collateralItems: CollateralItem[]) {
    const price = +collateralItems.reduce((acc, curr) => {
        acc += +curr.primary_price;
        return acc;
      }, 0),
      rate_for_day = collateralItems.length
        ? await getPercent({
            params: {
              group_id: values.nomenclature_group?.id,
              point_id: values.point?.id,
              customer_status_id: values.customer?.customer_status?.id,
              price,
            },
          }).unwrap()
        : 0,
      return_sum_to_hand = price,
      return_sum = Math.floor(return_sum_to_hand + (return_sum_to_hand / 100) * +rate_for_day * +values.days_count);

    setValues((values) => ({
      ...values,
      collateral_items: collateralItems,
      rate_for_day,
      return_sum_to_hand,
      return_sum,
    }));
  }

  function handleSubmitCollateralItem(collateralItem: CollateralItem) {
    currentCollateralItem
      ? (() => {
          const collateralItems = [...values.collateral_items];
          collateralItems[collateralItems.indexOf(currentCollateralItem)] = collateralItem;
          handleChangeCollateralItems(collateralItems);
        })()
      : handleChangeCollateralItems([...values.collateral_items, collateralItem]);
  }

  function openCollateralItemDialog(collateralItem: CollateralItem | null = null) {
    setCurrentCollateralItem(collateralItem);
    setOpenedCollateralItemDialog(true);
  }

  return (
    <Form>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item lg={5} md={6} xs={12}>
            <SearchPoints
              value={values.point}
              onChange={(event, point) =>
                setValues((values) => ({ ...values, point, collateral_items: [], rate_for_day: 0 }))
              }
              disabled={disabled}
              getOptionLabel={(option) => String(option.name)}
              readOnly
              renderInput={(props) => (
                <TextField
                  {...props}
                  name="point"
                  label="Точка"
                  placeholder="Выберите из списка"
                  error={Boolean(touched.point && errors.point)}
                  helperText={touched.point && errors.point}
                  InputProps={{ readOnly: true }}
                />
              )}
            />
          </Grid>

          <Grid item lg={5} md={6} xs={12}>
            <SearchNomenclatureGroups
              prefetch
              value={values.nomenclature_group}
              onChange={(event, nomenclature_group) =>
                setValues((values) => ({ ...values, nomenclature_group, collateral_items: [], rate_for_day: 0 }))
              }
              disabled={disabled || Boolean(pledge)}
              getOptions={(options) =>
                setFieldValue(
                  'nomenclature_group',
                  options.find(({ name }) => name === NomenclatureGroups.JEVELRY),
                )
              }
              getOptionLabel={(option) => String(option.name)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  name="nomenclature_group"
                  label="Номенклатурная группа"
                  placeholder="Выберите из списка"
                  error={Boolean(touched.nomenclature_group && errors.nomenclature_group)}
                  helperText={touched.nomenclature_group && errors.nomenclature_group}
                />
              )}
            />
          </Grid>

          <TabContext value={activeTab}>
            <Grid item xs={12}>
              <TabList onChange={(event, newValue) => setActiveTab(newValue)}>
                <Tab label="Основная информация" value={PledgeTabs.COMMON} />
                <Tab label="Залоговые вещи" value={PledgeTabs.COLLATERAL_ITEMS} />
              </TabList>

              <TabPanel value={PledgeTabs.COMMON} sx={{ mx: -3 }}>
                <Grid container spacing={3}>
                  <Grid item lg={7} md={7} xs={12}>
                    <SearchCustomers
                      value={values.customer}
                      onChange={(event, customer) =>
                        setValues((values) => ({ ...values, customer, collateral_items: [], rate_for_day: 0 }))
                      }
                      disabled={disabled}
                      getOptionLabel={({ name, last_name, father_name }) =>
                        `${last_name} ${name} ${father_name ?? ''} `
                      }
                      readOnly
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="customer"
                          label="Залогодатель"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.customer && errors.customer)}
                          helperText={touched.customer && errors.customer}
                          InputProps={{ readOnly: true }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                    <FormControlLabel
                      label="Без выкупа"
                      control={
                        <Switch
                          name="is_without_buyout"
                          value={values.is_without_buyout}
                          disabled
                          onChange={handleChange}
                        />
                      }
                    />
                  </Grid>

                  <Grid item alignSelf="center" lg={6} md={6} xs={12}>
                    <FormControlLabel
                      label="Доверенное лицо"
                      control={
                        <Switch
                          name="is_trusted_person"
                          value={values.is_trusted_person}
                          disabled={disabled}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Grid>

                  <Grid item lg={7} md={7} xs={12}>
                    <SearchCustomers
                      value={values.trusted_person}
                      onChange={(event, option) => setFieldValue('trusted_person', option)}
                      disabled={disabled || Boolean(!values.is_trusted_person)}
                      getOptionLabel={({ name, last_name, father_name }) =>
                        `${last_name ?? ''} ${name} ${father_name ?? ''} `
                      }
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="trusted_person"
                          label="Доверенное лицо"
                          placeholder="Выберите из списка"
                          error={Boolean(touched.trusted_person && errors.trusted_person)}
                          helperText={touched.trusted_person && errors.trusted_person}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <NumberFormat
                      customInput={TextField}
                      name="days_count"
                      type="tel"
                      label="Срок залога (кол-во дней)"
                      placeholder="Введите значение"
                      value={values.days_count}
                      onChange={handleChange}
                      disabled={disabled}
                      error={touched.days_count && Boolean(errors.days_count)}
                      helperText={touched.days_count ? errors.days_count : ''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <DatePicker
                      mask="__.__.____"
                      label="Дата возврата"
                      value={values.return_date}
                      onChange={(value) => setFieldValue('return_date', moment(value).toDate())}
                      disabled={disabled}
                      readOnly
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="return_date"
                          error={Boolean(touched.return_date && errors.return_date)}
                          helperText={touched.return_date && errors.return_date && String(errors.return_date)}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <NumberFormat
                      customInput={TextField}
                      name="rate_for_day"
                      label="Ставка за кредит (1 день)"
                      value={values.rate_for_day}
                      disabled={disabled}
                      error={touched.rate_for_day && Boolean(errors.rate_for_day)}
                      helperText={touched.rate_for_day ? errors.rate_for_day : ''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={5} xs={12}>
                    <Typography gutterBottom>
                      <b>Сумма % за 1 день: </b>
                      {Math.round((+values.return_sum - +values.return_sum_to_hand) / 30)} (тг)
                    </Typography>

                    <Typography>
                      <b>% за {values.days_count} дней: </b>
                      {Math.round(+values.return_sum - +values.return_sum_to_hand)} (тг)
                    </Typography>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <NumberFormat
                      customInput={TextField}
                      name="return_sum_to_hand"
                      label="На руки"
                      placeholder="Введите значение"
                      value={values.return_sum_to_hand}
                      onChange={handleChange}
                      disabled={disabled}
                      error={touched.return_sum_to_hand && Boolean(errors.return_sum_to_hand)}
                      helperText={touched.return_sum_to_hand ? errors.return_sum_to_hand : ''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <NumberFormat
                      customInput={TextField}
                      name="return_sum"
                      label="К возврату"
                      placeholder="Введите значение"
                      value={values.return_sum}
                      onChange={handleChange}
                      disabled={disabled}
                      error={touched.return_sum && Boolean(errors.return_sum)}
                      helperText={touched.return_sum ? errors.return_sum : ''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      select
                      name="payment_method"
                      label="Способ оплаты"
                      value={values.payment_method}
                      onChange={handleChange}
                      disabled={disabled}
                      error={touched.payment_method && Boolean(errors.payment_method)}
                      helperText={touched.payment_method ? errors.payment_method : ''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    >
                      {PAYMENT_METHODS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      multiline
                      name="comment"
                      label="Комментарий"
                      placeholder="Введите значение"
                      value={values.comment}
                      onChange={handleChange}
                      disabled={disabled}
                      error={touched.comment && Boolean(errors.comment)}
                      helperText={touched.comment ? errors.comment : ''}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={PledgeTabs.COLLATERAL_ITEMS} sx={{ mx: -3 }}>
                <FieldArray name="collateral_items">
                  {() => (
                    <>
                      <FormControl fullWidth error={Boolean(errors.collateral_items)}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Номенклатура</TableCell>
                                <TableCell>Начальная стоимость</TableCell>
                                <TableCell />

                                {values.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                                  <>
                                    <TableCell>Проба</TableCell>
                                    <TableCell>Вес изделия</TableCell>
                                    <TableCell>Вес металла</TableCell>
                                  </>
                                )}

                                <TableCell>Примечание</TableCell>
                                <TableCell>Характеристики</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {values.collateral_items.map((collateralItem, index) => {
                                const {
                                    description,
                                    nomenclature,
                                    primary_price,
                                    nomenclature_chars,
                                    nomenclature_group_chars,
                                    properties,
                                  } = collateralItem,
                                  chars = [...nomenclature_chars, ...nomenclature_group_chars]
                                    .map((char) => {
                                      const hasValue =
                                          ['string', 'number'].includes(typeof char.value) && Boolean(char.value),
                                        hasBooleanValue =
                                          ['boolean'].includes(typeof char.value) && Boolean(char.value);
                                      return hasValue ? `${char.name}: ${char.value}` : hasBooleanValue && char.name;
                                    })
                                    .filter(Boolean)
                                    .join(', ');

                                return (
                                  <TableRow key={index}>
                                    <TableCell>{nomenclature?.name || '—'}</TableCell>
                                    <TableCell>{primary_price}</TableCell>
                                    <TableCell>
                                      <Stack direction="row" spacing={1}>
                                        <Tooltip title="Обновить">
                                          <IconButton
                                            aria-label="Обновить"
                                            disabled={disabled}
                                            onClick={() => openCollateralItemDialog(collateralItem)}
                                          >
                                            <EditFlat sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Удалить">
                                          <IconButton
                                            aria-label="Удалить"
                                            disabled={disabled}
                                            onClick={() => {
                                              values.collateral_items.splice(index, 1);
                                              handleChangeCollateralItems(values.collateral_items);
                                            }}
                                          >
                                            <TrashFlat sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Stack>
                                    </TableCell>

                                    {values.nomenclature_group?.name === NomenclatureGroups.JEVELRY && (
                                      <>
                                        <TableCell>{properties?.sample?.name}</TableCell>
                                        <TableCell>{properties?.jewelry_weight}</TableCell>
                                        <TableCell>{properties?.metal_weight}</TableCell>
                                      </>
                                    )}

                                    <TableCell>{description || '—'}</TableCell>
                                    <TableCell title={chars || '—'}>{chars || '—'}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {touched.collateral_items && errors.collateral_items && (
                          <FormHelperText>{String(errors.collateral_items)}</FormHelperText>
                        )}
                      </FormControl>

                      {!pledge && !MAX_COLLATERAL_ITEMS_CONSTRAINTS && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                          <Button
                            variant="contained"
                            disabled={disabled || !Boolean(values.nomenclature_group && values.point)}
                            size="small"
                            onClick={() => openCollateralItemDialog(null)}
                          >
                            Добавить
                          </Button>
                        </Box>
                      )}

                      <AddCollateralItem
                        open={collateralItemDialogOpened}
                        customer={values.customer!}
                        collateralItem={currentCollateralItem}
                        nomenclatureGroup={values.nomenclature_group}
                        disabled={disabled}
                        onSubmit={handleSubmitCollateralItem}
                        onClose={() => setOpenedCollateralItemDialog(false)}
                      />
                    </>
                  )}
                </FieldArray>
              </TabPanel>
            </Grid>
          </TabContext>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button type="button" color="secondary" variant="outlined" disabled={disabled} onClick={onClose}>
          Закрыть
        </Button>

        <Button type="submit" variant="contained" disabled={disabled}>
          Провести
        </Button>
      </DialogActions>
    </Form>
  );
};

export default CreatePledge;
