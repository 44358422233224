import { GemCategoryPrice } from '@/types';

export function getPriceByCount(prices: GemCategoryPrice[], count: number) {
  return (
    prices?.find((numbers, index) => {
      const { from, to } = numbers;

      if (prices.length - 1 === index) return +from <= count;
      else if (
        to &&
        Array(+to)
          .fill(1)
          .map((number, index) => number + index)
          .includes(count)
      )
        return +from <= count || count <= +to;
      else return +from === count;
    })?.price ?? 0
  );
}
