import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Car } from '@/types';
import { useUpdateCarMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchCarBrands } from '@/pages/CarBrands/components';

export interface UpdateCarProps extends DialogProps {
  car: Car;
  onClose(): void;
}

const UpdateCar: React.FC<UpdateCarProps> = ({ car, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateCar, updateCarResponse] = useUpdateCarMutation();

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik<Car>({
    initialValues: car ?? {
      name: '',
      car_brand: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      car_brand: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(car) {
      try {
        await updateCar(car).unwrap();

        handleClose();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  function handleClose() {
    onClose();
    resetForm();
  }

  return (
    <Dialog onClose={handleClose} fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать модель автомобиля</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateCarResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <SearchCarBrands
                value={values.car_brand}
                onChange={(event, option) => setFieldValue('car_brand', option)}
                disabled={updateCarResponse.isLoading}
                getOptionLabel={(option) => option.name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name="car_brand"
                    label="Бренд"
                    placeholder="Выберите из списка"
                    error={Boolean(touched.car_brand && errors.car_brand)}
                    helperText={touched.car_brand && errors.car_brand}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            size="large"
            disabled={updateCarResponse.isLoading}
            onClick={handleClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" size="large" disabled={updateCarResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCar;
