export enum CollateralItemCharTypes {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMERIC = 'number',
}

export interface CollateralItemChar extends NumericCollateralItemChar {
  value?: string | boolean | number;
  name: string;
  type: CollateralItemCharTypes | '';
  required: boolean;
}

export interface NumericCollateralItemChar {
  min_value?: number;
}
