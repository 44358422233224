import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { CustomerDocumentType, ListResponse } from '@/types';

const customerDocumentTypeApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CustomerDocumentTypes'],
});

const customerDocumentTypeApi = customerDocumentTypeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerDocumentTypes: builder.query<
      ListResponse<CustomerDocumentType>,
      AxiosRequestConfig<CustomerDocumentType>
    >({
      query: (config) => ({ url: '/admin/customer-document-type', ...config }),
      providesTags: ['CustomerDocumentTypes'],
    }),
    createCustomerDocumentType: builder.mutation<CustomerDocumentType, CustomerDocumentType>({
      query: (customerDocumentType) => ({
        url: '/admin/customer-document-type',
        method: 'POST',
        data: customerDocumentType,
      }),
      invalidatesTags: ['CustomerDocumentTypes'],
    }),
    updateCustomerDocumentType: builder.mutation<CustomerDocumentType, CustomerDocumentType>({
      query: (customerDocumentType) => ({
        url: '/admin/customer-document-type/' + customerDocumentType.id,
        method: 'PUT',
        data: customerDocumentType,
      }),
      invalidatesTags: ['CustomerDocumentTypes'],
    }),
    deleteCustomerDocumentType: builder.mutation<unknown, CustomerDocumentType>({
      query: (customerDocumentType) => ({
        url: '/admin/customer-document-type/' + customerDocumentType.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomerDocumentTypes'],
    }),
  }),
});

const {
  useGetCustomerDocumentTypesQuery,
  useCreateCustomerDocumentTypeMutation,
  useUpdateCustomerDocumentTypeMutation,
  useDeleteCustomerDocumentTypeMutation,
} = customerDocumentTypeApi;

export {
  useGetCustomerDocumentTypesQuery,
  useCreateCustomerDocumentTypeMutation,
  useUpdateCustomerDocumentTypeMutation,
  useDeleteCustomerDocumentTypeMutation,
};
