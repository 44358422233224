import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Unit } from '@/types';
import { useUpdateUnitMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateUnitProps extends DialogProps {
  unit: Unit;
  onClose(): void;
}

const UpdateUnit: React.FC<UpdateUnitProps> = ({ unit, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateUnit, updateUnitResponse] = useUpdateUnitMutation();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik<Unit>({
    initialValues: unit ?? {
      buro_code: '',
      code: '',
      name: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      buro_code: yup.string().required(messages.form.REQUIRED),
      code: yup.string().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
    }),
    async onSubmit(unit, { resetForm }) {
      try {
        await updateUnit(unit).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать единицу измерения</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="buro_code"
                label="Код бюро"
                placeholder="Введите значение"
                value={values.buro_code}
                onChange={handleChange}
                disabled={updateUnitResponse.isLoading}
                error={touched.buro_code && Boolean(errors.buro_code)}
                helperText={touched.buro_code ? errors.buro_code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="code"
                label="Код"
                placeholder="Введите значение"
                value={values.code}
                onChange={handleChange}
                disabled={updateUnitResponse.isLoading}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code ? errors.code : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateUnitResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateUnitResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateUnitResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateUnit;
