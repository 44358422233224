import { useState } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from '@mui/material';

import { RealizationStatuses } from '@/types';
import { useCreateRealizationMutation, useGetRealizationPledgesQuery } from '@/services';
import { CheckEmployeeAdditionalPassword } from '@/pages/Employees/components';
import { Error, Loader } from '@/components';
import { messages } from '@/utils/helpers';

import { PledgesTable } from '../components';

export interface CreateRealizationProps extends DialogProps {
  onClose(): void;
}

const CreateRealization: React.FC<CreateRealizationProps> = ({ onClose, ...props }) => {
  const [{ pledges_page }, setQuery] = useQueryParams({
    pledges_page: withDefault(NumberParam, 1),
  });
  const [checkAdditionalPasswordModalOpened, setOpenedCheckAdditionalPasswordModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data: pledges, isLoading, isError } = useGetRealizationPledgesQuery({ params: { page: pledges_page } });

  const [createRealization, createRealizationResponse] = useCreateRealizationMutation();

  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <DialogTitle fontWeight="bold">Реализовать залоги</DialogTitle>

      <Formik
        initialValues={{
          realization: pledges?.data.length
            ? { pledges: pledges?.data, status: RealizationStatuses.CARRIED_OUT }
            : { pledges: [] },
          pledges: [],
        }}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={yup.object().shape({
          pledges: yup.array().of(yup.object().nullable().required(messages.form.SELECT_FROM_LIST)),
        })}
        onSubmit={async ({ realization, pledges }, { resetForm }) => {
          try {
            await createRealization({ ...realization, pledges, status: RealizationStatuses.CARRIED_OUT }).unwrap();

            onClose();
            resetForm();
          } catch (error) {
            enqueueSnackbar(error.data, { variant: 'error' });
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <Form>
              <DialogContent dividers>
                {isLoading ? (
                  <Loader />
                ) : isError ? (
                  <Error
                    text="Произошла ошибка с загрузкой залогов подлежащих реализации! Пожалуйста, попробуйте позже"
                    variant="outlined"
                  />
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <PledgesTable
                        list={pledges}
                        page={pledges_page}
                        disabled={createRealizationResponse.isLoading}
                        onChangePage={(page) => setQuery({ pledges_page: page })}
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  type="button"
                  color="secondary"
                  variant="outlined"
                  disabled={createRealizationResponse.isLoading}
                  onClick={onClose}
                >
                  Закрыть
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  disabled={!values.pledges.length || createRealizationResponse.isLoading}
                  onClick={() => setOpenedCheckAdditionalPasswordModal(true)}
                >
                  Провести
                </Button>
              </DialogActions>
            </Form>

            <CheckEmployeeAdditionalPassword
              open={checkAdditionalPasswordModalOpened}
              onCheck={handleSubmit}
              onClose={() => setOpenedCheckAdditionalPasswordModal(false)}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateRealization;
