import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { red } from '@mui/material/colors';

import { Customer, ListResponse } from '@/types';
import { useRoles } from '@/hooks';
import { Pagination } from '@/components';
import { GENDERS } from '@/utils/genders';
import { EditFlat, UserFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteCustomer, UpdateCustomer } from '../components';

export interface CustomerListProps {
  list?: ListResponse<Customer>;
  page: number;
  onChangePage(page: number): void;
}

const CustomersTable: React.FC<CustomerListProps> = ({ list, page, onChangePage }) => {
  const { isAdmin } = useRoles();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);

  function openUpdateModal(customer: Customer) {
    setCurrentCustomer(customer);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(customer: Customer) {
    setCurrentCustomer(customer);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell />
                <TableCell>ИИН</TableCell>
                <TableCell>Дата рождения</TableCell>
                <TableCell>Пол</TableCell>
                <TableCell>Иностранец</TableCell>
                <TableCell>Номер телефона</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((customer) => {
                const {
                  name,
                  born_at,
                  customer_category,
                  father_name,
                  gender,
                  iin,
                  last_name,
                  is_foreign_citizen,
                  phone_number,
                  is_terrorist,
                  id,
                } = customer;

                return (
                  <TableRow key={id} sx={{ backgroundColor: is_terrorist ? red[100] : customer_category?.color?.hex }}>
                    <TableCell>{`${last_name ?? ''} ${name ?? ''} ${father_name ?? ''}`}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {isAdmin && (
                          <Tooltip title="Обновить">
                            <IconButton aria-label="Обновить" onClick={() => openUpdateModal(customer)}>
                              <EditFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {isAdmin && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(customer)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Информация о клиенте">
                          <IconButton component={RouterLink} to={`/customers/${id}`} aria-label="Информация о клиенте">
                            <UserFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{iin ?? '—'}</TableCell>
                    <TableCell>{born_at ? moment(born_at).format('DD.MM.YYYY') : '—'}</TableCell>
                    <TableCell>{GENDERS.find(({ value }) => value === gender)?.label ?? '—'}</TableCell>
                    <TableCell>{is_foreign_citizen ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {phone_number ? (
                        <Link href={`tel:${phone_number}`} color="secondary">
                          {phone_number}
                        </Link>
                      ) : (
                        '—'
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateCustomer
        customer={currentCustomer!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteCustomer
        customer={currentCustomer!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default CustomersTable;
