import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Prolongation } from '@/types';
import { useDeleteProlongationMutation } from '@/services';

export interface DeleteProlongationProps extends DialogProps {
  prolongation: Prolongation;
  onClose(): void;
}

const DeleteProlongation: React.FC<DeleteProlongationProps> = ({ prolongation, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteProlongation, deleteProlongationResponse] = useDeleteProlongationMutation();

  async function handleDeleteProlongation() {
    try {
      await deleteProlongation(prolongation).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{prolongation?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{prolongation?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteProlongationResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteProlongationResponse.isLoading}
          size="small"
          onClick={handleDeleteProlongation}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProlongation;
