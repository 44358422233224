import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Account, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteAccount, UpdateAccount } from '../components';

export interface AccountListProps {
  list?: ListResponse<Account>;
  page: number;
  onChangePage(page: number): void;
}

const AccountsTable: React.FC<AccountListProps> = ({ list, page, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);

  function openUpdateModal(account: Account) {
    setCurrentAccount(account);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(account: Account) {
    setCurrentAccount(account);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ИИК</TableCell>
                <TableCell>БИК</TableCell>
                <TableCell>Название банка</TableCell>
                <TableCell>Валюта</TableCell>
                <TableCell>Организация</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((account) => {
                const { bank_name, bik, currency, iik, organization, id } = account;

                return (
                  <TableRow key={id}>
                    <TableCell>{iik}</TableCell>
                    <TableCell>{bik}</TableCell>
                    <TableCell>{bank_name}</TableCell>
                    <TableCell>{currency?.short_name ?? '—'}</TableCell>
                    <TableCell>{organization?.full_name ?? '—'}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(account)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(account)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateAccount account={currentAccount!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeleteAccount account={currentAccount!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default AccountsTable;
