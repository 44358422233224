import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { NomenclatureGroupDiscount, ListResponse } from '@/types';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeleteNomenclatureGroupDiscount, UpdateNomenclatureGroupDiscount } from '../components';

export interface NomenclatureGroupDiscountListProps {
  list?: ListResponse<NomenclatureGroupDiscount>;
  page: number;
  onChangePage(page: number): void;
}

const NomenclatureGroupDiscountsTable: React.FC<NomenclatureGroupDiscountListProps> = ({
  list,
  page,
  onChangePage,
}) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentNomenclatureGroupDiscount, setCurrentNomenclatureGroupDiscount] =
    useState<NomenclatureGroupDiscount | null>(null);

  function openUpdateModal(nomenclatureGroupDiscount: NomenclatureGroupDiscount) {
    setCurrentNomenclatureGroupDiscount(nomenclatureGroupDiscount);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(nomenclatureGroupDiscount: NomenclatureGroupDiscount) {
    setCurrentNomenclatureGroupDiscount(nomenclatureGroupDiscount);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer component={Paper} sx={{ maxHeight: `calc(100vh - ${theme.spacing(34.5)})` }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Процент скидки</TableCell>
                <TableCell>Точка</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((nomenclatureGroupDiscount) => {
                const { name, percent, point, id } = nomenclatureGroupDiscount;

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{percent}</TableCell>
                    <TableCell>{point?.name}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(nomenclatureGroupDiscount)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(nomenclatureGroupDiscount)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateNomenclatureGroupDiscount
        nomenclatureGroupDiscount={currentNomenclatureGroupDiscount!}
        open={updateModalOpened}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeleteNomenclatureGroupDiscount
        nomenclatureGroupDiscount={currentNomenclatureGroupDiscount!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
    </>
  );
};

export default NomenclatureGroupDiscountsTable;
