import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { useUpdateNomenclatureGroupMutation } from '@/services';
import { messages } from '@/utils/helpers';
import NumberFormat from 'react-number-format';

export interface UpdateNomenclatureGroupProps extends DialogProps {
  nomenclatureGroup: NomenclatureGroup;
  onClose(): void;
}

const UpdateNomenclatureGroup: React.FC<UpdateNomenclatureGroupProps> = ({ nomenclatureGroup, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateNomenclatureGroup, updateNomenclatureGroupResponse] = useUpdateNomenclatureGroupMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<NomenclatureGroup>({
    initialValues: nomenclatureGroup ?? {
      collateral_rate_percent: 0,
      gt_30_days: 0,
      lt_30_days: 0,
      name: '',
      is_price_editable: false,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      collateral_rate_percent: yup.number().required(messages.form.REQUIRED),
      gt_30_days: yup.number().required(messages.form.REQUIRED),
      lt_30_days: yup.number().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
      is_price_editable: yup.boolean().required(messages.form.REQUIRED),
    }),
    async onSubmit(nomenclatureGroup, { resetForm }) {
      try {
        await updateNomenclatureGroup(nomenclatureGroup).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать номенклатурную группу</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateNomenclatureGroupResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="collateral_rate_percent"
                label="Процентная ставка залога"
                placeholder="Введите значение"
                value={values.collateral_rate_percent}
                onChange={handleChange}
                disabled={updateNomenclatureGroupResponse.isLoading}
                error={touched.collateral_rate_percent && Boolean(errors.collateral_rate_percent)}
                helperText={touched.collateral_rate_percent ? errors.collateral_rate_percent : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="lt_30_days"
                label="Общая пени (<= 30)"
                placeholder="Введите значение"
                value={values.lt_30_days}
                onChange={handleChange}
                disabled={updateNomenclatureGroupResponse.isLoading}
                error={touched.lt_30_days && Boolean(errors.lt_30_days)}
                helperText={touched.lt_30_days ? errors.lt_30_days : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="gt_30_days"
                label="Общая пени (>= 30)"
                placeholder="Введите значение"
                value={values.gt_30_days}
                onChange={handleChange}
                disabled={updateNomenclatureGroupResponse.isLoading}
                error={touched.gt_30_days && Boolean(errors.gt_30_days)}
                helperText={touched.gt_30_days ? errors.gt_30_days : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label="Редактировать начальную стоимость"
                control={
                  <Checkbox
                    name="is_price_editable"
                    value={values.is_price_editable}
                    checked={values.is_price_editable}
                    onChange={(event, checked) => setFieldValue('is_price_editable', checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateNomenclatureGroupResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateNomenclatureGroupResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateNomenclatureGroup;
