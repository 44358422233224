import { useFormikContext } from 'formik';
import { Grid, TextField } from '@mui/material';

import { NomenclatureGroup } from '@/types';
import { SearchElectronicModels } from '@/pages/ElectronicModels/components';
import { SearchElectronicBrands } from '@/pages/ElectronicBrands/components';

export interface TechniqueProps {}

const Technique: React.FC<TechniqueProps> = () => {
  const { values, touched, errors, setFieldValue } = useFormikContext<{
    properties: NomenclatureGroup.Technique;
  }>();

  return (
    <>
      <Grid item lg={6} md={6} xs={12}>
        <SearchElectronicBrands
          value={values.properties.electronic_brand}
          onChange={(event, option) => setFieldValue('properties.electronic_brand', option)}
          getOptionLabel={(option) => option.name}
          renderInput={(props) => (
            <TextField
              {...props}
              name="properties.electronic_brand"
              label="Модель"
              placeholder="Выберите из списка"
              error={Boolean(touched.properties?.electronic_brand && errors.properties?.electronic_brand)}
              helperText={touched.properties?.electronic_brand && errors.properties?.electronic_brand}
            />
          )}
        />
      </Grid>

      <Grid item lg={6} md={6} xs={12}>
        <SearchElectronicModels
          value={values.properties.electronic_model}
          config={{ params: { electronic_brand_id: values.properties.electronic_brand?.id } }}
          onChange={(event, option) => setFieldValue('properties.electronic_model', option)}
          disabled={!values.properties.electronic_brand}
          getOptionLabel={(option) => option.name}
          renderInput={(props) => (
            <TextField
              {...props}
              name="properties.electronic_model"
              label="Марка"
              placeholder="Выберите из списка"
              error={Boolean(touched.properties?.electronic_model && errors.properties?.electronic_model)}
              helperText={touched.properties?.electronic_model && errors.properties?.electronic_model}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default Technique;
