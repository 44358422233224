import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Organization, ListResponse } from '@/types';

const organizationApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Organizations'],
});

const organizationApi = organizationApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<ListResponse<Organization>, AxiosRequestConfig<Organization>>({
      query: (config) => ({ url: '/admin/organization', ...config }),
      providesTags: ['Organizations'],
    }),
    createOrganization: builder.mutation<Organization, Organization>({
      query: (organization) => ({
        url: '/admin/organization',
        method: 'POST',
        data: organization,
      }),
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation<Organization, Organization>({
      query: (organization) => ({
        url: '/admin/organization/' + organization.id,
        method: 'PUT',
        data: organization,
      }),
      invalidatesTags: ['Organizations'],
    }),
    deleteOrganization: builder.mutation<unknown, Organization>({
      query: (organization) => ({ url: '/admin/organization/' + organization.id, method: 'DELETE' }),
      invalidatesTags: ['Organizations'],
    }),
  }),
});

const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationApi;

export {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
};
