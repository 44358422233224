import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { MobileOperator, ListResponse } from '@/types';

const mobileOperatorApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['MobileOperators'],
});

const mobileOperatorApi = mobileOperatorApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getMobileOperators: builder.query<ListResponse<MobileOperator>, AxiosRequestConfig<MobileOperator>>({
      query: (config) => ({ url: '/admin/mobile-operator', ...config }),
      providesTags: ['MobileOperators'],
    }),
    createMobileOperator: builder.mutation<MobileOperator, MobileOperator>({
      query: (mobileOperator) => ({ url: '/admin/mobile-operator', method: 'POST', data: mobileOperator }),
      invalidatesTags: ['MobileOperators'],
    }),
    updateMobileOperator: builder.mutation<MobileOperator, MobileOperator>({
      query: (mobileOperator) => ({
        url: '/admin/mobile-operator/' + mobileOperator.id,
        method: 'PUT',
        data: mobileOperator,
      }),
      invalidatesTags: ['MobileOperators'],
    }),
    deleteMobileOperator: builder.mutation<unknown, MobileOperator>({
      query: (mobileOperator) => ({ url: '/admin/mobile-operator/' + mobileOperator.id, method: 'DELETE' }),
      invalidatesTags: ['MobileOperators'],
    }),
  }),
});

const {
  useGetMobileOperatorsQuery,
  useCreateMobileOperatorMutation,
  useUpdateMobileOperatorMutation,
  useDeleteMobileOperatorMutation,
} = mobileOperatorApi;

export {
  useGetMobileOperatorsQuery,
  useCreateMobileOperatorMutation,
  useUpdateMobileOperatorMutation,
  useDeleteMobileOperatorMutation,
};
