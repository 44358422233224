import { AxiosRequestConfig } from 'axios';
import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Cashbox } from '@/types';
import { useGetCashdesksQuery } from '@/services';
import { useAsyncSearch } from '@/hooks';

function SearchCashdesks<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  config,
  value,
  ...props
}: { config?: AxiosRequestConfig<Cashbox> } & Omit<
  AutocompleteProps<Cashbox, Multiple, DisableClearable, FreeSolo>,
  'options'
>) {
  const asyncGetCashdesksQueryProps = useAsyncSearch<Cashbox, Multiple, DisableClearable, FreeSolo>(
    useGetCashdesksQuery,
    'id',
    value,
    config,
  );

  return <Autocomplete {...props} {...asyncGetCashdesksQueryProps} />;
}

export default SearchCashdesks;
