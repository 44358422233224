import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { NomenclatureGroupFine, ListResponse } from '@/types';

const nomenclatureGroupFineApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['NomenclatureGroupFines'],
});

const nomenclatureGroupFineApi = nomenclatureGroupFineApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureGroupFines: builder.query<
      ListResponse<NomenclatureGroupFine>,
      AxiosRequestConfig<NomenclatureGroupFine>
    >({
      query: (config) => ({ url: '/admin/nomenclature-group-fine', ...config }),
      providesTags: ['NomenclatureGroupFines'],
    }),
    createNomenclatureGroupFine: builder.mutation<NomenclatureGroupFine, NomenclatureGroupFine>({
      query: (nomenclatureGroupFine) => ({
        url: '/admin/nomenclature-group-fine',
        method: 'POST',
        data: nomenclatureGroupFine,
      }),
      invalidatesTags: ['NomenclatureGroupFines'],
    }),
    updateNomenclatureGroupFine: builder.mutation<NomenclatureGroupFine, NomenclatureGroupFine>({
      query: (nomenclatureGroupFine) => ({
        url: '/admin/nomenclature-group-fine/' + nomenclatureGroupFine.id,
        method: 'PUT',
        data: nomenclatureGroupFine,
      }),
      invalidatesTags: ['NomenclatureGroupFines'],
    }),
    deleteNomenclatureGroupFine: builder.mutation<unknown, NomenclatureGroupFine>({
      query: (nomenclatureGroupFine) => ({
        url: '/admin/nomenclature-group-fine/' + nomenclatureGroupFine.id,
        method: 'DELETE',
      }),
      invalidatesTags: ['NomenclatureGroupFines'],
    }),
  }),
});

const {
  useGetNomenclatureGroupFinesQuery,
  useCreateNomenclatureGroupFineMutation,
  useUpdateNomenclatureGroupFineMutation,
  useDeleteNomenclatureGroupFineMutation,
} = nomenclatureGroupFineApi;

export {
  useGetNomenclatureGroupFinesQuery,
  useCreateNomenclatureGroupFineMutation,
  useUpdateNomenclatureGroupFineMutation,
  useDeleteNomenclatureGroupFineMutation,
};
