import React, { useState } from 'react';
import { DateParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment';
import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { useGetTechnicalRequestsQuery } from '@/services';
import { AppBar, DebouncedTextField, Empty, Error, Loader } from '@/components';
import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';

import { TechnicalRequestsTable, CreateTechnicalRequest } from './components';
import { TECH_REQUEST_STATUSES } from '@/utils/tech-request-statuses';

const TechnicalRequestsPage: React.FC = () => {
  const [{ search, order_direction, status, page, date_from, date_to }, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
    status: withDefault(StringParam, ''),
    date_from: withDefault(DateParam, moment().toDate()),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const [createModalOpened, setOpenedCreateModal] = useState(false);

  const {
    data: technicalRequests,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetTechnicalRequestsQuery({
    params: {
      search: search ? search : null,
      order_direction,
      page,
      status,
      order_by: 'id',
      date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
      date_to: moment(date_to).format('YYYY-MM-DD'),
    },
  });

  return (
    <>
      <AppBar title="Технические заявки" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="flex-start" spacing={3}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DebouncedTextField
                  type="search"
                  name="search"
                  label="Поиск"
                  placeholder="Введите значение"
                  value={search}
                  onChange={(event) => setQuery({ search: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={order_direction}
                  label="Сортировать по"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ order_direction: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  {ORDER_DIRECTIONS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={status}
                  label="Статус"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ status: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  <MenuItem value="">Все</MenuItem>

                  {TECH_REQUEST_STATUSES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата от"
                  value={date_from}
                  minDate={moment(date_to).subtract(6, 'months')}
                  maxDate={moment(date_to)}
                  onChange={(value) => setQuery({ date_from: value ? moment(value).toDate() : undefined })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_from" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата до"
                  value={date_to}
                  maxDate={moment()}
                  onChange={(value) => setQuery({ date_to: value ? moment(value).toDate() : undefined })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_to" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>
            </Grid>

            <Box sx={{ width: 210 }}>
              <Button
                disabled={isLoading}
                onClick={() => setOpenedCreateModal(true)}
                size="large"
                variant="contained"
                fullWidth
              >
                Добавить
              </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой технических заявок! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(technicalRequests?.data?.length) ? (
            <TechnicalRequestsTable list={technicalRequests} page={page} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Технические заявки отсутствуют" />
          )}
        </Grid>
      </Grid>

      <CreateTechnicalRequest onClose={() => setOpenedCreateModal(false)} open={createModalOpened} />
    </>
  );
};

export default TechnicalRequestsPage;
