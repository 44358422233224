import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Color } from '@/types';
import { useDeleteColorMutation } from '@/services';

export interface DeleteColorProps extends Omit<DialogProps, 'color'> {
  color: Color;
  onClose(): void;
}

const DeleteColor: React.FC<DeleteColorProps> = ({ color, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteColor, deleteColorResponse] = useDeleteColorMutation();

  async function handleDeleteColor() {
    try {
      await deleteColor(color).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{color?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{color?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteColorResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button variant="contained" size="small" disabled={deleteColorResponse.isLoading} onClick={handleDeleteColor}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteColor;
