import { AngleLeftFlat, AngleRightFlat } from '@/assets/icons';
import { Pagination as MuiPagination, PaginationItem, PaginationProps, Paper } from '@mui/material';

const Pagination: React.FC<PaginationProps> = ({ count, ...props }) => {
  if (count && count <= 1) return null;

  return (
    <Paper sx={{ p: 0.75 }} className="no-print">
      <MuiPagination
        count={count}
        sx={{
          '.MuiPagination-ul': {
            'li:is(:first-of-type, :last-of-type)': { flex: 1 },
            'li:last-of-type': { textAlign: 'right' },
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            components={{
              previous: () => <AngleLeftFlat sx={{ fontSize: 16 }} />,
              next: () => <AngleRightFlat sx={{ fontSize: 16 }} />,
            }}
          />
        )}
        {...props}
      />
    </Paper>
  );
};

export default Pagination;
