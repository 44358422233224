import { CollateralItem, RePledgeOperationTypes } from '@/types';

export const OPERATION_TYPES = [
  { label: 'Уменьшение', value: RePledgeOperationTypes.DECREASE },
  { label: 'Увеличение', value: RePledgeOperationTypes.INCREASE },
];

type SumOperationByTypeProps = {
  sum: number;
  fine: number;
  sumForDay: number;
  totalSumForBuyout: number;
  collateralItems: CollateralItem[];
  daysFromPledgeWasCreated: number;
};

export const getSumOperationByType = ({
  sum,
  fine,
  sumForDay,
  collateralItems,
  totalSumForBuyout,
  daysFromPledgeWasCreated,
}: SumOperationByTypeProps) => [
  {
    value: RePledgeOperationTypes.DECREASE,
    max: totalSumForBuyout,
    sum: Math.round(sumForDay * daysFromPledgeWasCreated + fine) + -sum,
  },
  {
    value: RePledgeOperationTypes.INCREASE,
    max:
      collateralItems.reduce((acc, curr) => {
        const extraAmount = Math.round(curr.max_price - curr.primary_price);

        if (extraAmount > 0) acc += Math.round(curr.max_price - curr.primary_price);
        return acc;
      }, 0) - sumForDay,
    sum: Math.round(sumForDay * daysFromPledgeWasCreated + fine) + sum,
  },
];
