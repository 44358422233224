import React, { useState } from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Box, Button, Grid, Stack } from '@mui/material';

import { useGetCustomersQuery } from '@/services';
import { useAuth, useRoles, useShifts } from '@/hooks';
import { AppBar, DebouncedTextField, Empty, Error, Loader } from '@/components';
import { OrderDirections } from '@/utils/order-directions';

import { CustomersTable, CreateCustomer } from './components';

const CustomersPage: React.FC = () => {
  const { user } = useAuth();
  const { isCachier } = useRoles();
  const { shift, isShiftOpened, isShiftLoading, openShiftLoading, handleLoadShift } = useShifts();

  const [{ order_direction, page, search }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
    search: withDefault(StringParam, ''),
  });

  const [createModalOpened, setOpenedCreateModal] = useState(false);

  const {
    data: customers,
    isLoading,
    isSuccess,
    isError,
  } = useGetCustomersQuery({ params: { order_direction, page, order_by: 'id', search } });

  return (
    <>
      <AppBar title="Клиенты" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
            <Box sx={{ width: 310 }}>
              <DebouncedTextField
                type="search"
                name="search"
                label="Поиск"
                placeholder="Введите значение"
                value={search}
                onChange={(event) => setQuery({ search: event.target.value })}
                variant="filled"
                fullWidth
                size="small"
              />
            </Box>

            {isCachier && shift && (
              <>
                {!isShiftOpened ? (
                  <Box sx={{ width: 210 }}>
                    <Button
                      disabled={[isLoading, isShiftLoading, openShiftLoading].some(Boolean)}
                      onClick={() => user && handleLoadShift(user, 'open')}
                      size="large"
                      variant="contained"
                      fullWidth
                    >
                      Открыть смену
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ width: 210 }}>
                    <Button
                      disabled={isLoading}
                      onClick={() => setOpenedCreateModal(true)}
                      size="large"
                      variant="contained"
                      fullWidth
                    >
                      Добавить
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой клиентов! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(customers?.data?.length) ? (
            <CustomersTable list={customers} page={page} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Клиенты отсутствуют" />
          )}
        </Grid>
      </Grid>

      <CreateCustomer onClose={() => setOpenedCreateModal(false)} open={createModalOpened} />
    </>
  );
};

export default CustomersPage;
