import { useState } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from '@mui/material';

import { Employee } from '@/types';
import { useUpdateEmployeeMutation } from '@/services';
import { messages } from '@/utils/helpers';
import { SearchRoles } from '@/pages/Roles/components';
import { SearchPositions } from '@/pages/Positions/components';

import { ChangeEmployeeAdditionalPassword, ChangeEmployeePassword, UpdateEmployeeSalaryPercent } from '../components';

export interface UpdateEmployeeProps extends DialogProps {
  employee: Employee;
  onClose(): void;
}

const UpdateEmployee: React.FC<UpdateEmployeeProps> = ({ employee, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [changePasswordModalOpened, setOpenedChangePasswordModal] = useState(false);
  const [changeAdditionalPasswordModalOpened, setOpenedChangeAdditionalPasswordModal] = useState(false);
  const [salaryPercentModalOpened, setOpenedSalaryPercentModal] = useState(false);

  const [updateEmployee, updateEmployeeResponse] = useUpdateEmployeeMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<Employee>({
    initialValues: employee ?? {
      born_at: null,
      dismissed_at: null,
      father_name: '',
      login: '',
      password: '',
      hired_at: null,
      last_name: '',
      name: '',
      phone_number: '',
      percent_salary: '',
      role: null,
      position: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      born_at: yup.date().required(messages.form.SELECT_DATE).typeError(messages.form.SELECT_CORRECT_DATE),
      dismissed_at: yup.date().nullable(),
      father_name: yup.string().required(messages.form.REQUIRED),
      login: yup.string(),
      hired_at: yup.date().required(messages.form.SELECT_DATE).typeError(messages.form.SELECT_CORRECT_DATE),
      last_name: yup.string().required(messages.form.REQUIRED),
      name: yup.string().required(messages.form.REQUIRED),
      phone_number: yup.string().required(messages.form.REQUIRED),
      role: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
      position: yup.object().nullable().required(messages.form.SELECT_FROM_LIST),
    }),
    async onSubmit(employee, { resetForm }) {
      try {
        await updateEmployee(employee).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <>
      <Dialog fullWidth {...props}>
        <DialogTitle fontWeight="bold">Редактировать сотрудника</DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="name"
                  label="Имя"
                  placeholder="Введите значение"
                  value={values.name}
                  onChange={handleChange}
                  disabled={updateEmployeeResponse.isLoading}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name ? errors.name : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="father_name"
                  label="Отчество"
                  placeholder="Введите значение"
                  value={values.father_name}
                  onChange={handleChange}
                  disabled={updateEmployeeResponse.isLoading}
                  error={touched.father_name && Boolean(errors.father_name)}
                  helperText={touched.father_name ? errors.father_name : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="last_name"
                  label="Фамилия"
                  placeholder="Введите значение"
                  value={values.last_name}
                  onChange={handleChange}
                  disabled={updateEmployeeResponse.isLoading}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name ? errors.last_name : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата рождения"
                  value={values.born_at}
                  onChange={(value) => setFieldValue('born_at', moment(value).toDate())}
                  disabled={updateEmployeeResponse.isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="born_at"
                      error={Boolean(touched.born_at && errors.born_at)}
                      helperText={touched.born_at && errors.born_at}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <NumberFormat
                  customInput={TextField}
                  format="# (###) ###-##-##"
                  name="phone_number"
                  label="Номер телефона"
                  placeholder="Введите значение"
                  value={values.phone_number}
                  onChange={handleChange}
                  disabled={updateEmployeeResponse.isLoading}
                  error={touched.phone_number && Boolean(errors.phone_number)}
                  helperText={touched.phone_number ? errors.phone_number : ''}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата увольнения"
                  value={values.dismissed_at}
                  onChange={(value) => setFieldValue('dismissed_at', moment(value).toDate())}
                  disabled={updateEmployeeResponse.isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="dismissed_at"
                      error={Boolean(touched.dismissed_at && errors.dismissed_at)}
                      helperText={touched.dismissed_at && errors.dismissed_at}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Нанят"
                  value={values.hired_at}
                  onChange={(value) => setFieldValue('hired_at', moment(value).toDate())}
                  disabled={updateEmployeeResponse.isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="hired_at"
                      error={Boolean(touched.hired_at && errors.hired_at)}
                      helperText={touched.hired_at && errors.hired_at}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <SearchRoles
                  value={values.role}
                  onChange={(event, option) => setFieldValue('role', option)}
                  disabled={updateEmployeeResponse.isLoading}
                  getOptionLabel={(option) => option.name}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="role"
                      label="Роль"
                      placeholder="Выберите из списка"
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <SearchPositions
                  value={values.position}
                  onChange={(event, option) => setFieldValue('position', option)}
                  disabled={updateEmployeeResponse.isLoading}
                  getOptionLabel={(option) => option.name}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="position"
                      label="Должность"
                      placeholder="Выберите из списка"
                      error={Boolean(touched.position && errors.position)}
                      helperText={touched.position && errors.position}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  name="percent_salary"
                  label="Процент"
                  value={values.percent_salary}
                  disabled={updateEmployeeResponse.isLoading}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button
                    type="button"
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={() => setOpenedChangePasswordModal(true)}
                  >
                    Обновить пароль
                  </Button>

                  <Button
                    type="button"
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={() => setOpenedChangeAdditionalPasswordModal(true)}
                  >
                    Обновить пароль для проведения операций
                  </Button>

                  <Button
                    type="button"
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={() => setOpenedSalaryPercentModal(true)}
                  >
                    Сдельная оплата
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              disabled={updateEmployeeResponse.isLoading}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Button type="submit" variant="contained" disabled={updateEmployeeResponse.isLoading}>
              Редактировать
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ChangeEmployeePassword
        employee={employee}
        open={changePasswordModalOpened}
        onClose={() => setOpenedChangePasswordModal(false)}
      />

      <ChangeEmployeeAdditionalPassword
        employee={employee}
        open={changeAdditionalPasswordModalOpened}
        onClose={() => setOpenedChangeAdditionalPasswordModal(false)}
      />

      <UpdateEmployeeSalaryPercent
        employee={employee}
        open={salaryPercentModalOpened}
        onClose={() => setOpenedSalaryPercentModal(false)}
      />
    </>
  );
};

export default UpdateEmployee;
