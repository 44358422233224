import { PropsWithChildren, createContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Shift, ShiftTypes, User } from '@/types';
import { useOpenShiftMutation, useLazyGetShiftQuery, useCloseShiftMutation } from '@/services';
import { useAuth, useRoles } from '@/hooks';
import { CloseShift, OpenShift } from '@/pages/Shifts/components';

export interface ShiftProviderProps {
  shift?: Shift | null;
  isShiftOpened: boolean;
  isShiftLoading: boolean;
  openShiftLoading: boolean;
  closeShiftLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  handleLoadShift(user: User, reason: LoadShiftReason): Promise<Shift | undefined>;
  handleOpenShift(shift: Shift): Promise<void>;
  handleCloseShift(shift: Shift): Promise<void>;
}

export type LoadShiftReason = 'open' | 'close';

const initialShiftProviderValues: ShiftProviderProps = {
  shift: null,
  isShiftOpened: false,
  isShiftLoading: false,
  openShiftLoading: false,
  closeShiftLoading: false,
  isError: false,
  isSuccess: false,
  async handleLoadShift() {
    return undefined;
  },
  async handleOpenShift() {
    return undefined;
  },
  async handleCloseShift() {
    return undefined;
  },
};

export const ShiftContext = createContext<ShiftProviderProps>(initialShiftProviderValues);

const ShiftProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { user, handleLogout } = useAuth();
  const { isCachier } = useRoles();

  const [getShift, { isLoading, isFetching, isError, isSuccess }] = useLazyGetShiftQuery();
  const [openShift, openShiftResponse] = useOpenShiftMutation();
  const [closeShift, closeShiftResponse] = useCloseShiftMutation();

  const [shift, setShift] = useState<Shift | null>(null);

  const [openShiftDialogOpened, setOpenedOpenShiftDialog] = useState(false);
  const [closeShiftDialogOpened, setOpenedCloseShiftDialog] = useState(false);

  async function handleLoadShift(user: User | null, reason?: LoadShiftReason) {
    if (!user) return;

    try {
      if (reason === 'close') setOpenedCloseShiftDialog(true);

      const { data: shift } = await getShift({ params: { point_id: user.point?.id } });

      if (!shift) return;

      if (shift.type !== ShiftTypes.OPEN && reason === 'open') setOpenedOpenShiftDialog(true);

      setShift(shift);

      return shift;
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  async function handleOpenShift(shift: Shift) {
    try {
      await openShift(shift).unwrap();
      setOpenedOpenShiftDialog(false);
      user && (await handleLoadShift(user));
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  async function handleCloseShift(shift: Shift) {
    try {
      await closeShift(shift).unwrap();

      setShift(null);
      setOpenedCloseShiftDialog(false);
      handleLogout();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (!isCachier || shift?.type === ShiftTypes.OPEN || shift) return;
    handleLoadShift(user, 'open');
  }, [shift]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isCachier) return <>{children}</>;

  return (
    <ShiftContext.Provider
      value={{
        shift,
        isShiftOpened: shift?.type === ShiftTypes.OPEN,
        isShiftLoading: isLoading || isFetching,
        openShiftLoading: openShiftResponse.isLoading,
        closeShiftLoading: closeShiftResponse.isLoading,
        isError,
        isSuccess,
        handleLoadShift,
        handleOpenShift,
        handleCloseShift,
      }}
    >
      {children}

      <OpenShift open={openShiftDialogOpened} onClose={() => setOpenedOpenShiftDialog(false)} />
      <CloseShift open={closeShiftDialogOpened} onClose={() => setOpenedCloseShiftDialog(false)} />
    </ShiftContext.Provider>
  );
};

export default ShiftProvider;
