import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';

import { GemCutShape } from '@/types';
import { useUpdateGemCutShapeMutation } from '@/services';
import { messages } from '@/utils/helpers';

export interface UpdateGemCutShapeProps extends DialogProps {
  gemCutShape: GemCutShape;
  onClose(): void;
}

const UpdateGemCutShape: React.FC<UpdateGemCutShapeProps> = ({ gemCutShape, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateGemCutShape, updateGemCutShapeResponse] = useUpdateGemCutShapeMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<GemCutShape>({
    initialValues: gemCutShape ?? {
      name: '',
      coefficient: 1,
      has_height: false,
      has_length: true,
      has_width: false,
      formula: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(messages.form.REQUIRED),
      coefficient: yup.number().required(messages.form.REQUIRED),
      has_height: yup.boolean(),
      has_length: yup.boolean(),
      has_width: yup.boolean(),
      formula: yup.string(),
    }),
    async onSubmit(gemCutShape, { resetForm }) {
      try {
        await updateGemCutShape(gemCutShape).unwrap();

        onClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.data, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">Редактировать форму огранки</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                name="name"
                label="Наименование"
                placeholder="Введите значение"
                value={values.name}
                onChange={handleChange}
                disabled={updateGemCutShapeResponse.isLoading}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? errors.name : ''}
                fullWidth
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <NumberFormat
                customInput={TextField}
                name="coefficient"
                label="Коэффициент"
                placeholder="Введите значение"
                value={values.coefficient}
                onValueChange={({ value }) => setFieldValue('coefficient', +value)}
                disabled={updateGemCutShapeResponse.isLoading}
                error={touched.coefficient && Boolean(errors.coefficient)}
                helperText={touched.coefficient ? errors.coefficient : ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth disabled={updateGemCutShapeResponse.isLoading}>
                <FormLabel component="legend">Характеристики</FormLabel>

                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={values.has_length} onChange={handleChange} name="has_length" />}
                    label="Длина"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={values.has_width} onChange={handleChange} name="has_width" />}
                    label="Ширина"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={values.has_height} onChange={handleChange} name="has_height" />}
                    label="Высота"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="formula"
                label="Формула для подсчета веса"
                placeholder="Введите значение"
                value={values.formula}
                onChange={handleChange}
                disabled={updateGemCutShapeResponse.isLoading}
                error={touched.formula && Boolean(errors.formula)}
                helperText={
                  (touched.formula && errors.formula) ??
                  'Доступные переменные: length (длина), height (высота), width (ширина), density (плотность), coefficient (коэффициент)'
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            disabled={updateGemCutShapeResponse.isLoading}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Button type="submit" variant="contained" disabled={updateGemCutShapeResponse.isLoading}>
            Редактировать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateGemCutShape;
