import { AxiosRequestConfig } from 'axios';

import baseApi, { api } from '@/api';
import { Document, ListResponse } from '@/types';

const documentApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Documents'],
});

const documentApi = documentApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<ListResponse<Document>, AxiosRequestConfig<Document>>({
      query: (config) => ({ url: '/admin/document/list', ...config }),
      providesTags: ['Documents'],
    }),
    createDocument: builder.mutation<Document, Document>({
      query: (document) => ({ url: '/admin/document/add', method: 'POST', data: document }),
      invalidatesTags: ['Documents'],
    }),
    updateDocument: builder.mutation<Document, Document>({
      query: (document) => ({
        url: '/admin/document/edit',
        method: 'PUT',
        data: document,
        params: { id: document.id },
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
});

const getDocument = async (config: AxiosRequestConfig<Document>) =>
  (await api.get<BlobPart>('/admin/document/get', config)).data;

const { useGetDocumentsQuery, useCreateDocumentMutation, useUpdateDocumentMutation } = documentApi;

export { useGetDocumentsQuery, useCreateDocumentMutation, useUpdateDocumentMutation, getDocument };
