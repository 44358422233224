import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { Car, ListResponse } from '@/types';

const carApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['Cars'],
});

const carApi = carApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCars: builder.query<ListResponse<Car>, AxiosRequestConfig<Car>>({
      query: (config) => ({ url: '/admin/car', ...config }),
      providesTags: ['Cars'],
    }),
    createCar: builder.mutation<Car, Car>({
      query: ({ car_brand, ...car }) => ({
        url: '/admin/car',
        method: 'POST',
        data: { ...car, car_brand_id: car_brand?.id },
      }),
      invalidatesTags: ['Cars'],
    }),
    updateCar: builder.mutation<Car, Car>({
      query: ({ car_brand, ...car }) => ({
        url: '/admin/car/' + car.id,
        method: 'PUT',
        data: { ...car, car_brand_id: car_brand?.id },
      }),
      invalidatesTags: ['Cars'],
    }),
    deleteCar: builder.mutation<unknown, Car>({
      query: (car) => ({ url: '/admin/car/' + car.id, method: 'DELETE' }),
      invalidatesTags: ['Cars'],
    }),
  }),
});

const { useGetCarsQuery, useCreateCarMutation, useUpdateCarMutation, useDeleteCarMutation } = carApi;

export { useGetCarsQuery, useCreateCarMutation, useUpdateCarMutation, useDeleteCarMutation };
