import { AxiosRequestConfig } from 'axios';

import baseApi from '@/api';
import { ElectronicModel, ListResponse } from '@/types';

const electronicModelApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['ElectronicModels'],
});

const electronicModelApi = electronicModelApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getElectronicModels: builder.query<ListResponse<ElectronicModel>, AxiosRequestConfig<ElectronicModel>>({
      query: (config) => ({ url: '/admin/electronic-model', ...config }),
      providesTags: ['ElectronicModels'],
    }),
    createElectronicModel: builder.mutation<ElectronicModel, ElectronicModel>({
      query: ({ electronic_brand, ...electronicModel }) => ({
        url: '/admin/electronic-model',
        method: 'POST',
        data: { ...electronicModel, electronic_brand_id: electronic_brand?.id },
      }),
      invalidatesTags: ['ElectronicModels'],
    }),
    updateElectronicModel: builder.mutation<ElectronicModel, ElectronicModel>({
      query: ({ electronic_brand, ...electronicModel }) => ({
        url: '/admin/electronic-model/' + electronicModel.id,
        method: 'PUT',
        data: { ...electronicModel, electronic_brand_id: electronic_brand?.id },
      }),
      invalidatesTags: ['ElectronicModels'],
    }),
    deleteElectronicModel: builder.mutation<unknown, ElectronicModel>({
      query: (electronicModel) => ({ url: '/admin/electronic-model/' + electronicModel.id, method: 'DELETE' }),
      invalidatesTags: ['ElectronicModels'],
    }),
  }),
});

const {
  useGetElectronicModelsQuery,
  useCreateElectronicModelMutation,
  useUpdateElectronicModelMutation,
  useDeleteElectronicModelMutation,
} = electronicModelApi;

export {
  useGetElectronicModelsQuery,
  useCreateElectronicModelMutation,
  useUpdateElectronicModelMutation,
  useDeleteElectronicModelMutation,
};
