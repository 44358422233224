import { AxiosError } from 'axios';

import { MobileOperator, ServerResponseError } from '@/types';

import { numbersDeclension } from './numbers-declension';

export const messages = {
  form: {
    REQUIRED: 'Пожалуйста, заполните это поле',
    EMAIL: 'Пожалуйста, введите корректный e-mail',
    PHONE: 'Пожалуйста, введите корректный номер телефона',
    MIN_LENGTH: (length = 8) =>
      `Минимальная длина - ${length} ${numbersDeclension(length, ['символ', 'символа', 'символов'])}`,
    LESS_OR_EQUAL: (count: number) => `Значение должно быть меньше или равно ${count}`,
    LESS_OR_EQUAL_THAN: (refField: string) => `Это поле не должно превышать поле "${refField}"`,
    MORE_OR_EQUAL: (count: number) => `Значение должно быть больше или равно ${count}`,
    PASSWORD_MISMATCH: 'Пароли не совпадают',
    SELECT_FROM_LIST: 'Пожалуйста, выберите из списка',
    MIN_FROM_LIST: (count = 1, words: string[] = ['элемент', 'элемента', 'элементов']) =>
      `Пожалуйста, заполните не менее ${count} ${numbersDeclension(count, words)}`,
    SELECT_MIN_FROM_LIST: (count = 1, words: string[] = ['элемент', 'элемента', 'элементов']) =>
      `Пожалуйста, выберите из списка не менее ${count} ${numbersDeclension(count, words)}`,
    SELECT_DATE: 'Пожалуйста, выберите дату',
    SELECT_YEAR: 'Пожалуйста, выберите год',
    SELECT_CORRECT_DATE: 'Пожалуйста, выберите корректную дату',
    LARGE_FILE: 'Вес файла не должен превышать 30мб',
    UNSUPPORTED_FILE_TYPE: 'Недопустимый формат файла',
    SELECT_AVATAR: 'Пожалуйста, выберите аватар',
    SELECT_FILE: 'Пожалуйста, выберите файл',
    INVALID_IP: 'Пожалуйста, введите корректный IP-адрес',
    INCORRECT_IP: 'IP-адрес должен быть меньше или равен 255',
    LARGE_TEXT: (length = 255) =>
      `Максимальная длина текста - ${length} ${numbersDeclension(length, ['символ', 'символа', 'символов'])}`,
  },
};

export const FILE_SIZE = 30 * 1024 * 1024;
export const SUPPORTED_FORMATS = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export function identifyByIIN(iin: string) {
  if (iin.length < 12) return;

  const year = iin.slice(0, 2),
    month = iin.slice(2, 4),
    day = iin.slice(4, 6),
    gender = iin[6];

  return { born_at: new Date(`${month}/${day}/${year}`), gender: +gender % 2 ? 1 : 2 };
}

export function identifyOperatorByPhoneNumber(
  number: string,
  operators: readonly MobileOperator[],
): MobileOperator | null {
  if (number.length <= 3) return null;

  return operators.find(({ reserved_codes }) => reserved_codes.split(', ').includes(number.slice(1, 4))) ?? null;
}

export function exclude<T>(array: T[], excludeBy: keyof T, value: string) {
  return array.filter((item) => (item[excludeBy] as unknown as string) !== value);
}

/**
 * downloadFile
 * @param file
 * @param name
 * @param type
 */
export function downloadFile(file: Blob, name: string, type: BlobPropertyBag['type']) {
  const url = URL.createObjectURL(new Blob([file], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.download = name;
  link.click();
  URL.revokeObjectURL(url);
}

/**
 * getFilenameFromContentDisposition
 * @param contentDisposition
 * @returns string | null
 */
export const getFilenameFromContentDisposition = (contentDisposition: string) => {
  const regex = /filename\*?=(?:(['"])(.*?)\1|([^;\r\n]*))/g;
  const matches = regex.exec(contentDisposition);

  if (matches && matches.length > 1) {
    const filename = matches[2] || matches[3];

    if (filename) return filename;
  }

  return null;
};

/**
 * catchError
 * @param error
 * @returns
 */
export function catchError(error: AxiosError) {
  const { response, message } = error as AxiosError<{ message: string }>;

  return {
    error: {
      status: response?.status,
      data: response?.data?.message ?? message ?? response?.data ?? 'Что-то пошло не так',
    } as ServerResponseError,
  };
}

/**
 * catchErrorForBlob
 * @param error
 * @returns
 */
export function catchErrorForBlob(
  error: AxiosError,
): Promise<{ error: ServerResponseError }> | { error: ServerResponseError } {
  return new Promise((resolve) => {
    const { response, message } = error as AxiosError<{ message: string }>;

    if (response?.data instanceof Blob) {
      const reader = new FileReader();

      reader.onload = () =>
        resolve({
          error: {
            status: response?.status,
            data: JSON.parse(String(reader.result)).message || 'Что-то пошло не так',
          } as ServerResponseError,
        });

      reader.readAsText(response?.data);
    } else {
      resolve({
        error: { status: response?.status, data: message || 'Что-то пошло не так' } as ServerResponseError,
      });
    }
  });
}
