import React from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material';

import { useGetDocumentsQuery } from '@/services';
import { AppBar, Empty, Error, Loader } from '@/components';

import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';

import { DocumentsTable } from './components';

const DocumentsPage: React.FC = () => {
  const [{ order_direction, page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
  });

  const {
    data: documents,
    isLoading,
    isSuccess,
    isError,
  } = useGetDocumentsQuery({ params: { order_direction, page, order_by: 'id' } });

  return (
    <>
      <AppBar title="Шаблоны документов" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
            <Box sx={{ width: 310 }}>
              <TextField
                select
                value={order_direction}
                label="Сортировать по"
                disabled={isLoading}
                onChange={(event) => setQuery({ order_direction: event.target.value })}
                variant="filled"
                fullWidth
                size="small"
              >
                {ORDER_DIRECTIONS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой шаблонов документов! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(documents?.data?.length) ? (
            <DocumentsTable list={documents} page={page} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Шаблоны документов отсутствуют" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentsPage;
