import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Realization } from '@/types';
import { useDeleteRealizationMutation } from '@/services';

export interface DeleteRealizationProps extends DialogProps {
  realization: Realization;
  onClose(): void;
}

const DeleteRealization: React.FC<DeleteRealizationProps> = ({ realization, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteRealization, deleteRealizationResponse] = useDeleteRealizationMutation();

  async function handleDeleteRealization() {
    try {
      await deleteRealization(realization).unwrap();

      onClose();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle fontWeight="bold">{realization?.name}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>Вы действительно хотите удалить "{realization?.name}"?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={deleteRealizationResponse.isLoading}
          onClick={onClose}
        >
          Закрыть
        </Button>

        <Button
          variant="contained"
          disabled={deleteRealizationResponse.isLoading}
          size="small"
          onClick={handleDeleteRealization}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRealization;
