import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  '@global': {
    'body, * >': {
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: '#fff',
        borderRadius: 2,
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#eaedf3',
        borderRadius: 2,
      },
    },

    '@media print': {
      '.no-print, .no-print *': {
        display: 'none !important',
      },
    },
  },
});

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
